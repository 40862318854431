<template>
  <v-row class="ma-5 pa-0" v-if="currentAnalysis.pcap">
    <template>


      <template>
  <v-container fluid class="pa-6">
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <h1 class="display-2 font-weight-bold mb-4">IOGraph Visualization</h1>
        <p class="subtitle-1 mb-4">
          This graph provides a visual representation of your network I/O data.
          Use the controls provided to adjust the graph settings and tailor the visualization to your requirements.
        </p>
        <v-divider class="mb-4"></v-divider>
        <h3 class="headline mb-2">Graph Settings</h3>
        <p class="subtitle-1 mb-4">
          Modify the settings below to customize the appearance and functionality of the graph.
          Hover over any control for additional information.
        </p>
        <v-row class="mb-4" justify="center">
          <v-col cols="12" md="4">
            <h4 class="subtitle-1 font-weight-bold">Presets</h4>
            <p class="body-1">
              Quickly apply preset configurations for a consistent visualization experience.
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <h4 class="subtitle-1 font-weight-bold">Filters</h4>
            <p class="body-1">
              Use filters to refine the data displayed on the graph, adjusting aggregation and visual styles as needed.
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <h4 class="subtitle-1 font-weight-bold">Zoom Mode</h4>
            <p class="body-1">
              Enable zoom mode to focus on specific data ranges, allowing for detailed inspection of the packets.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

</template>

    <!-- Color Picker Overlay -->
    <v-overlay :value="showColorPicker" z-index="2">
      <v-card>
        <v-card-text>
          <v-color-picker
            class="ma-2"
            show-swatches
            :swatches="palette"
            v-model="color"
          ></v-color-picker>
          <v-text-field v-model="color" label="Selected Color"></v-text-field>
          <v-btn color="primary" @click="confirmColor">Confirm Color</v-btn>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Chart Container -->
    <v-col cols="10">
      <v-card outlined>
        <v-card-title>IOGraph Visualization</v-card-title>
        <v-card-text>
          <div class="iograph" ref="chartdiv" id="chartdiv"></div>
          <p class="mt-2">
            The chart above displays the current analysis data. Use the controls on
            the right to adjust the graph settings.
          </p>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Graph Settings Panel -->
    <v-col cols="2">
      <v-card class="ml-5" min-width="274" outlined>
        <v-card-title>Graph Settings</v-card-title>
        <v-card-text>
          <p>
            Adjust the options below to customize your graph. Hover over any control
            for more information.
          </p>
          <v-switch class="ml-5 ma-2" v-model="zoom" hide-details solo>
            <template v-slot:label>Enable Zoom Mode</template>
          </v-switch>
          <v-switch class="ml-5 ma-2" v-model="useSharkd" hide-details solo>
            <template v-slot:label>Sharkd Mode</template>
          </v-switch>
          <v-switch class="ml-5 ma-2" color="info" v-model="normalize_to_seconds" hide-details solo>
            <template v-slot:label>Normalize to Seconds</template>
          </v-switch>
          <v-switch class="ml-5 ma-2" color="info" v-model="oneAxis" hide-details solo>
            <template v-slot:label>Single Axis Display</template>
          </v-switch>
          <v-btn @click="resetZoom" class="ml-2 mt-5 ma-2" text>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">undo</v-icon>
              </template>
              <span>Reset Zoom</span>
            </v-tooltip>
          </v-btn>
          <v-btn @click="loadColoringRules" class="ml-2 mt-5 ma-2" text>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on">invert_colors</v-icon>
              </template>
              <span>Load Coloring Rules</span>
            </v-tooltip>
          </v-btn>
          <v-combobox
            class="ml-2"
            hide-details
            v-model="interval"
            :items="intervals"
            label="Time Interval"
            solo
            item-text="Interval"
          ></v-combobox>
          <v-combobox
            class="ml-2"
            hide-details
            v-model="iograph_preset"
            :items="iograph_presets"
            label="IOGraph Presets"
            solo
            item-text="name"
          ></v-combobox>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Filters Card -->
    <v-col cols="12" class="mt-3">
      <v-card outlined>
        <v-card-title>
          Plotted Graphs ({{ numDataPoints }} Data Points)
        </v-card-title>
        <v-card-text>
          <p>
            The filters below control what data is displayed on the chart. Edit the filter
            name, choose a filter type, adjust its style and aggregation, or change its color.
          </p>
          <v-row
            class="py-0 mt-0 pt-0"
            v-for="(filter, index) in ioFilters"
            :key="filter.key"
          >
            <v-col cols="2" class="py-0 px-1">
              <v-text-field
                solo
                v-model="filter.name"
                label="Filter Name"
                hide-details
                maxlength="20"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter.filter"
                label="Filter Expression"
                item-text="Filter"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="py-1 mx-0">
              <v-btn :color="filter.color" @click="selectColor(filter)" fab small></v-btn>
            </v-col>
            <v-col cols="1" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter.style"
                :items="styles"
                label="Style"
                item-text="Style"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter.type"
                :items="types"
                label="Type"
                item-text="Type"
              ></v-combobox>
            </v-col>
            <v-col cols="2" class="py-0 px-1">
              <v-combobox
                solo
                hide-details
                v-model="filter.aggregation_field"
                :items="currentAnalysis.indexedcolumns"
                label="Aggregation Field"
                item-text="Aggregation field"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="pt-3 px-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="red" dark @click="removeFilter(filter)" v-on="on">delete</v-icon>
                </template>
                <span>Delete Filter</span>
              </v-tooltip>
              <v-tooltip bottom v-if="index === ioFilters.length - 1">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark @click="newFilter" v-on="on">add</v-icon>
                </template>
                <span>Add Filter</span>
              </v-tooltip>
            </v-col>
            <v-col cols="11"></v-col>
            <v-col cols="1">
              <v-tooltip bottom v-if="index === ioFilters.length - 1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="apply" color="green" v-on="on" small>
                    Apply
                  </v-btn>
                </template>
                <span>Apply All Filters</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Additional Info for Modes -->
    <!-- <v-col cols="12" class="mt-3" v-if="useSharkd">
      <v-alert type="info" outlined>
        Note: Zooming is not available in Sharkd mode.
      </v-alert>
    </v-col> -->
    <v-col cols="12" class="mt-3" v-if="!useSharkd">
      <v-alert type="info" outlined>
        Please note that filters such as "tcp.analysis.flags" or "icmp" may not work in indexing mode. 
        Rewrite your filters using indexed fields like "ip.proto == 1" (ICMP) or "tcp.analysis.retransmission".
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import ApiPacketlist from "@/common/api/packetlist";
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { IOGRAPH_PRESETS, iographhelper } from "@/common/iograph";
import { navigation } from "@/common/navigation";

export default {
  name: "Iograph",
  props: {
    pcapid: {
      type: String,
      default: null,
    },
    index: {
      type: String,
      default: null,
    },
    graphplot: {
      type: String,
      default: null,
    },
  },
  components: {},
  mixins: [displayfilter, navigation, iographhelper],
  data() {
    return {
      color: "#ffffff",
      currentColorItem: null,
      showColorPicker: false,
      palette: [
        ["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51"],
        ["#ef476f", "#ffd166", "#06d6a0", "#118ab2", "#073b4c"],
        [
          "#f94144",
          "#f3722c",
          "#f8961e",
          "#f9c74f",
          "#90be6d",
          "#43aa8b",
          "#577590",
        ],
        ["#ffbc42", "#d81159", "#8f2d56", "#218380", "#73d2de"],
        ["#ff99c8", "#fcf6bd", "#d0f4de", "#a9def9", "#e4c1f9"],
      ],
      dateAxis: undefined,
      numDataPoints: 0,
      oneAxis: false,
      isAutoInterval: false,
      numaxes: 0,
      valueaxis: null,
      ipcapid: null,
      iindex: null,
      displayfilter: "none",
      moment: moment,
      iograph_preset: null,
      iograph_presets: IOGRAPH_PRESETS,
      interval: "1s",
      intervals: [
        "1s",
        "100ms",
        "10ms",
        "1ms",
        "10s",
        "1h",
        "1d",
        "1w",
        "1M",
        "1y",
      ],
      colors: ["#000000", "#ff0000", "#0000ff", "#00ff00", "#FFFF00"],
      styles: ["fill", "line"],
      dataUnit: "bytes",
      aggregations: ["sum", "avg", "min", "max"],
      dataUnitsSharkd: ["bytes", "bits"],
      dataUnits: ["bytes", "bits", "kbit", "Mbit", "Gbit"],
      ioFilters: [
        {
          name: "Packets per second",
          key: 0,
          type: "packets",
          filter: "",
          color: "#0000ff",
          style: "fill",
        },
      ],
      defaultfilter: [
        {
          name: "Packets per second",
          key: 0,
          type: "packets",
          filter: "",
          color: "#0000ff",
          style: "fill",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      zoom: false,
      useSharkd: true,
      normalize_to_seconds: true,
      timelinestart: 0,
      timelineend: 0,
      chart: null,
      root: null,
      xAxis: null,
      cursor: null,
      selectStartedX: null,
      selectEndedX: null,
    };
  },
  watch: {
    pcapid(newVal) {
      this.ipcapid = newVal;
    },
    zoom() {
      this.apply();
    },
    oneAxis() {
      this.apply();
    },
    "currentAnalysis.analysistabs"(newVal) {
      if (newVal === 1) {
        this.numaxes = 0;
        this.oneAxis = false;
        this.ioFilters = this.defaultfilter;
        this.addCurrentPacketFilter();
        this.loadCustomGraph(this.currentAnalysis.customgraph);
        this.apply();
      }
    },
    "currentAnalysis.customgraph"(newVal) {
      this.numaxes = 0;
      this.oneAxis = false;
      this.ioFilters = this.defaultfilter;
      if (newVal) {
        this.loadCustomGraph(newVal);
        this.apply();
      }
    },
    iograph_preset(newVal) {
      const newFilters = newVal.values;
      this.ioFilters = newFilters;
      this.apply();
    },
  },
  created() {
    // Set these variables here so that they are not reactive (avoiding observer overhead)
    this.timeline = null;
    this.axis = null;
    this.chart = null;
  },
  mounted() {
    this.iindex = this.index;
    this.ipcapid = this.pcapid;
    this.timelinestart = this.currentAnalysis.timelinestart;
    this.timelineend = this.currentAnalysis.timelineend;
    this.addCurrentPacketFilter();
    this.loadCustomGraph(this.currentAnalysis.customgraph);
    this.apply();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
    types() {
      if (this.useSharkd) {
        return ["packets"]
          .concat(this.dataUnitsSharkd)
          .concat(this.aggregations);
      }
      return ["packets"].concat(this.dataUnits).concat(this.aggregations);
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    if (this.root) {
      this.root.dispose();
    }
  },
  methods: {
    selectColor(c) {
      this.currentColorItem = c;
      this.showColorPicker = !this.showColorPicker;
      this.color = c.color;
    },
    confirmColor() {
      this.showColorPicker = false;
      if (this.color.length === 9) {
        this.color = this.color.slice(0, 7);
      }
      if (this.currentColorItem) {
        this.currentColorItem.color = this.color;
      }
    },
    getTimeUnit4Amcharts() {
      const unit = this.interval;
      const digits = unit.replace(/[^\d]/g, "");
      const runit = unit.replace(/[\d]/g, "");
      switch (runit) {
        case "s":
          return "second";
        case "ms":
          return "millisecond";
        case "m":
          return "minute";
        case "h":
          return "hour";
        case "d":
          return "day";
        case "w":
          return "week";
        case "M":
          return "month";
        case "y":
          return "year";
        default:
          return "second";
      }
    },
    initializeChart() {
      if (this.root) {
        // Dispose safely if chart exists
        if (this.chart) {
          this.chart.dispose();
        }
        this.root.dispose();
      }
      const root = am5.Root.new("chartdiv");
      root.utc = false;
      root.setThemes([am5themes_Animated.new(root)]);
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: !this.zoom,
          panY: !this.zoom,
          wheelX: "panX",
          wheelY: "zoomX",
        })
      );
      const timeUnit = this.getTimeUnit4Amcharts();
      const dateAxis = am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: timeUnit,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      });
      const xAxis = chart.xAxes.push(dateAxis);
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, { orientation: "horizontal" })
      );
      let cursor;
      if (this.zoom) {
        cursor = am5xy.XYCursor.new(root, { behavior: "selectX" });
        cursor.events.on("selectended", this.handleChanged.bind(this));
        cursor.events.on("selectstarted", () => {
          this.selectStartedX = cursor.getPrivate("positionX");
        });
        chart.set("cursor", cursor);
        cursor.lineY.set("visible", false);
      } else {
        cursor = chart.set(
          "cursor",
          am5xy.XYCursor.new(root, { behavior: "none" })
        );
        cursor.lineY.set("visible", false);
      }
      this.cursor = cursor;
      this.dateAxis = dateAxis;
      this.root = root;
      this.chart = chart;
      this.xAxis = xAxis;
    },
    loadCustomGraph(graph) {
      this.oneAxis = false;
      this.numaxes = 0;
      if (Object.keys(graph).length !== 0) {
        this.ioFilters = graph.values;
        this.oneAxis = graph.oneaxis ? graph.oneaxis : false;
      }
    },
    resetZoom() {
      this.timelinestart = undefined;
      this.timelineend = undefined;
      this.currentAnalysis.timelinestart = undefined;
      this.currentAnalysis.timelineend = undefined;
      this.fetchTimeline();
    },
    plotChart(data, series, sharkdDataArray = null) {
      let color;
      this.removeSeries();
      for (let i = 0; i < series.length; i++) {
        const s = series[i];
        color = am5.color(s.color);
        this.createAxisAndSeries(
          s.name,
          s.name,
          false,
          "circle",
          color,
          sharkdDataArray ? sharkdDataArray[i] : data
        );
      }
      this.chart.appear(1000, 100);
    },
    getToolTipText(name, valueY) {
      try {
        const ioFilter = this.getIOFilterByName(name);
        let divider = this.interval;
        let divident = ioFilter.type;
        let normalized = valueY;
        let text = "{name}: [bold]{valueY}";
        const myRegexp = /^([0-9]{0,9})([a-zA-Z]{1,2})/g;
        const match = myRegexp.exec(this.interval);
        if (match && match[1] === "1") {
          divider = match[2];
        }
        if (this.aggregations.includes(divident)) {
          divident = divident + "(" + ioFilter.aggregation_field + ")";
        }
        if (
          !this.isAutoInterval &&
          this.normalize_to_seconds &&
          this.dataUnits.includes(divident)
        ) {
          switch (this.interval) {
            case "100ms":
              normalized = valueY * 10;
              break;
            case "10ms":
              normalized = valueY * 100;
              break;
            case "1ms":
              normalized = valueY * 1000;
              break;
            case "10s":
              normalized = valueY / 10;
              break;
          }
          normalized = Math.round(normalized * 100) / 100;
          text = name + ": " + normalized + " " + divident + "/s";
        } else {
          text = text + " " + divident + "/" + divider;
        }
        return text;
      } catch (e) {
        return "";
      }
    },
    removeSeries() {
      while (this.chart.series.length > 0) {
        this.chart.series.removeIndex(0).dispose();
      }
      while (this.chart.yAxes.length) {
        this.chart.yAxes.removeIndex(0).dispose();
      }
    },
    createAxisAndSeries(field, name, opposite, icon, color, data) {
      const chart = this.chart;
      const root = this.root;
      const xAxis = this.xAxis;
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "date",
          stroke: color,
          fill: color,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name}: [bold]{valueY}[/]",
          }),
        })
      );
      const ioFilter = this.getIOFilterByName(name);
      if (ioFilter != null) {
        if (ioFilter.style === "fill") {
          series.fills.template.setAll({
            fillOpacity: 0.3,
            visible: true,
          });
        }
      }
      series.data.setAll(data);
      series.appear(1000);
    },
    handleChanged(ev) {
      const cursor = this.cursor;
      const startSelected = this.selectStartedX;
      const endSelected = this.cursor.getPrivate("positionX");
      const axis = ev.target.chart.xAxes.getIndex(0);
      if (!this.zoom) {
        const startDate = axis.positionToDate(startSelected);
        const endDate = axis.positionToDate(endSelected);
        this.timelinestart = startDate;
        this.timelineend = endDate;
        this.fetchTimeline();
      } else {
        const startDate = axis.positionToDate(startSelected);
        const endDate = axis.positionToDate(endSelected);
        this.currentAnalysis.showIOGraph = false;
        this.updateDatesFromSelection(startDate, endDate);
      }
      this.cursor.selection.hide();
    },
    updateDatesFromSelection(start, end) {
      const c = this.currentAnalysis;
      c.selectStartDate = start.toISOString();
      c.selectEndDate = end.toISOString();
      this.$store.dispatch(UPDATE_ANALYSIS, c);
      this.currentAnalysis.applyfilter = true;
      this.currentAnalysis.filterneedsupdate = true;
      this.navigatePacketview(this.ipcapid, this.iindex);
    },
    showPcap() {
      this.navigatePacketview(this.ipcapid, this.iindex);
    },
    filterToString(name) {
      const filter = this.getIOFilterByName(name);
      let out = filter.name;
      if (out) {
        if (filter.filter) {
          out = out + " filtering for " + filter.filter;
        }
        if (filter.dataunit) {
          out = out + " showing " + filter.dataunit + " per " + this.interval;
        }
      }
      return out;
    },
    handleClicked(ev) {
      const filtername = ev.target.dataItem.component.dataFields.valueY;
      let filterValue = null;
      for (const f of this.ioFilters) {
        if (f.name === filtername) {
          filterValue = f.filter;
        }
      }
      if (filterValue != null) {
        this.df_apply_to_packetlist(filterValue);
      }
    },
    convertUnit(input, unit) {
      let tv = input;
      switch (unit) {
        case "bits":
          tv = tv * 8;
          break;
        case "kbit":
          tv = (tv * 8) / 1024;
          break;
        case "Mbit":
          tv = (tv * 8) / 1024 / 1024;
          break;
        case "Gbit":
          tv = (tv * 8) / 1024 / 1024 / 1024;
          break;
        default:
          break;
      }
      return tv;
    },
    calculateDate(startdate, factor) {
      const unit = this.interval;
      const digits = parseInt(unit.replace(/[^\d]/g, ""), 10);
      const runit = unit.replace(/[\d]/g, "");
      const add = factor * digits;
      let parsedDate = new Date(startdate + "Z");
      if (this.timelinestart) {
        parsedDate = new Date(this.timelinestart);
      }
      let result;
      switch (runit) {
        case "s":
          result = parsedDate.setSeconds(parsedDate.getSeconds() + add);
          break;
        case "ms":
          result = parsedDate.setMilliseconds(
            parsedDate.getMilliseconds() + add
          );
          break;
        case "m":
          result = parsedDate.setMinutes(parsedDate.getMinutes() + add);
          break;
        case "h":
          result = parsedDate.setHours(parsedDate.getHours() + add);
          break;
        case "d":
          result = parsedDate.setDate(parsedDate.getDate() + add);
          break;
        case "w":
          result = parsedDate.setDate(parsedDate.getDate() + add * 7);
          break;
        case "M":
          result = parsedDate.setMonth(parsedDate.getMonth() + add);
          break;
        case "y":
          result = parsedDate.setFullYear(parsedDate.getFullYear() + add);
          break;
        default:
          result = parsedDate.getTime();
      }
      return result;
    },
    showSharkdTimeline(data) {
      const outData = [];
      let i = 0;
      const date = this.currentAnalysis.pcap.starttime;
      for (const series of data.iograph) {
        const chartData = [];
        try {
          const items = series.items;
          this.numDataPoints = items.length;
          const ioFilter = this.ioFilters[i++];
          let prepItems = 0;
          for (let j = 0; j < items.length; j++) {
            if (typeof items[j] === "string") {
              const nextItem = parseInt(items[j], 16);
              while (prepItems < nextItem) {
                const newElem = {};
                newElem[ioFilter.name] = 0.0;
                newElem.date = this.calculateDate(date, prepItems);
                chartData.push(newElem);
                prepItems++;
              }
            } else {
              const newElem = {};
              newElem[ioFilter.name] = items[j];
              newElem.date = this.calculateDate(date, prepItems);
              chartData.push(newElem);
              prepItems++;
            }
          }
        } catch (e) {
          if ("errmsg" in series) {
            this.error.type = "error";
            this.error.msg = series.errmsg;
          }
        }
        outData.push(chartData);
      }
      this.plotChart(undefined, this.ioFilters, outData);
    },
    showIndexTimeline(data) {
      const chartData = [];
      const c = this.currentAnalysis;
      c.startDate = data.histogram[0].key_as_string;
      c.endDate = data.histogram[data.histogram.length - 1].key_as_string;
      this.$store.dispatch(UPDATE_ANALYSIS, c);
      if ("autointerval" in data) {
        this.isAutoInterval = data.autointerval;
        this.interval = data.interval;
      } else {
        this.isAutoInterval = false;
      }
      for (const elem of data.histogram) {
        const myDate = moment
          .utc(elem.key_as_string)
          .tz(moment.tz.guess())
          .toDate();
        const newElem = { date: myDate };
        for (const f of this.ioFilters) {
          if (elem[f.name] && elem[f.name][f.name + "_aggs"] !== undefined) {
            newElem[f.name] = this.convertUnit(
              elem[f.name][f.name + "_aggs"].value,
              f.type
            );
          } else {
            newElem[f.name] = elem[f.name].doc_count;
          }
        }
        chartData.push(newElem);
      }
      this.plotChart(chartData, this.ioFilters);
    },
    fetchTimeline() {
      if (!this.ipcapid) {
        return;
      }
      const params = {
        pcapid: this.ipcapid,
        filters: this.ioFilters,
        interval: this.interval,
        type: "index",
      };
      if (this.useSharkd) {
        params.type = "sharkd";
      }
      for (const f of params.filters) {
        if (!f.filter) {
          delete f.filter;
        }
      }
      if (this.iindex && this.iindex !== "none") {
        params.index = this.iindex;
      }
      if (this.timelinestart != 0) {
        params.starttime = this.timelinestart;
        delete params.start;
      }
      if (this.timelineend != 0 && this.timelineend) {
        params.endtime = this.timelineend;
        delete params.end;
      }
      ApiPacketlist.iograph(params).then(({ data }) => {
        if (params.type === "index") {
          if (data.autointerval === true) {
            this.error.msg =
              "Interval setting of " +
              this.interval +
              " would result in too many data points, doing auto interval of 50 values, resulting in a smoothed curve. Select a larger interval or select a smaller time frame.";
            this.error.type = "Info";
          }
          this.showIndexTimeline(data);
        }
        if (params.type === "sharkd") {
          this.showSharkdTimeline(data);
        }
      });
    },
    getIOFilterByName(name) {
      for (const f of this.ioFilters) {
        if (f.name === name) {
          return f;
        }
      }
      return null;
    },
    newFilter() {
      const n = {
        key: this.ioFilters.length + 1,
        name: "",
        type: "packets",
        filter: "",
        color: "#000000",
        aggregation_field: "",
        style: "fill",
      };
      this.ioFilters.push(n);
    },
    removeFilter(filter) {
      this.ioFilters.splice(this.ioFilters.indexOf(filter), 1);
    },
    addCurrentPacketFilter() {
      if (this.currentAnalysis.filter) {
        const n = {
          key: this.ioFilters.length + 1,
          name: "Current",
          type: "packets",
          filter: this.currentAnalysis.filter,
          color: "#ff0000",
          aggregation_field: "",
          style: "fill",
        };
        this.ioFilters.push(n);
      }
    },
    apply() {
      // If the filter name is not set, use the filter expression instead.
      this.ioFilters.forEach((f) => {
        if (!f.name || f.name.trim() === "") {
          f.name = f.filter;
        }
      });
      this.initializeChart();
      this.fetchTimeline();
    },
    showConnections() {
      this.currentAnalysis.timelinestart = this.timelinestart;
      this.currentAnalysis.timelineend = this.timelineend;
      this.currentAnalysis.showConnections = true;
    },
    loadColoringRules() {
      const rules = this.currentAnalysis.profiles.selected.coloringrules;
      const newFilters = [];
      for (const r of rules) {
        const n = {
          name: r.rulename,
          key: newFilters.length + 1,
          type: "packets",
          filter: r.rule,
          color: r.foreground,
          style: "fill",
        };
        newFilters.push(n);
      }
      this.ioFilters = newFilters;
    },
  },
};
</script>

<style>
.iographcontainer {
  padding: 0;
}
.iograph {
  width: 100%;
  height: 480px;
}
.container {
  height: 100%;
  max-width: 100%;
  overflow-x: auto !important;
}
/* Hide elements with low opacity */
g[opacity="0.4"] {
  display: none;
  visibility: hidden;
}
/* Adjust checkbox margins */
.mycheckbox.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
