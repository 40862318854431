var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"analysis"},[_c('v-container',{staticClass:"pa-6",attrs:{"fluid":""}},[_c('div',{staticClass:"bg-white py-24 sm:py-32"},[_c('div',{staticClass:"mx-auto max-w-7xl px-6 lg:px-8"},[_c('div',{staticClass:"mx-auto max-w-2xl lg:text-center"},[_c('h1',{staticClass:"text-4xl font-bold mb-4"},[_vm._v("Analysis Overview")]),_c('p',{staticClass:"text-lg mb-4"},[_vm._v(" Analyses are interactive walkthroughs of PCAP files, allowing you to animate, annotate, and explore network traffic in a dynamic and engaging way. These analyses can be shared with others, providing a collaborative and insightful experience. ")])])])])]),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.analysis),function(a){return (_vm.found)?_c('v-row',{key:a.id,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('router-link',{attrs:{"to":{
                    name: 'User Profile',
                    params: {
                      username: a.permissions.permissions_owner[0].username,
                    },
                  }}},[_c('v-avatar',{staticClass:"mr-4",attrs:{"size":"36"}},[_c('img',{attrs:{"src":_vm.avatarpic4user(a)}})])],1),_vm._v(" "+_vm._s(a.name)+" of "+_vm._s(a.pcap_origname)+" by "+_vm._s(a.permissions.permissions_owner[0].username)+" ")],1),_c('v-card-text',[_vm._v(" Description "),(a.description)?_c('div',{staticClass:"pl-4 mb-2 subtitle-1 text--primary"},[_vm._v(" "+_vm._s(_vm._f("truncate")(a.description,800, "..."))+" ")]):_c('div',{staticClass:"pl-4 mb-2"},[_vm._v("No description")]),(a.analysissteps.length > 10)?[_vm._v(" Analysis steps (showing first 10 of "+_vm._s(a.analysissteps.length)+") ")]:[_vm._v(" Analysis steps ("+_vm._s(a.analysissteps.length)+") ")],_c('div',{staticClass:"pt-2"},_vm._l((a.analysissteps.slice(0, 10)),function(step){return _c('div',{key:step.timestamp,staticClass:"pl-3"},[(step.packetnum && step.action == 'goto')?[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","text":"","x-small":"","to":{
                          name: 'Analysis',
                          params: {
                            id: a.pcapid,
                            analysisid: a.id,
                            view: 'l',
                            filter: 'frame.number == ' + step.packetnum,
                          },
                        }}},[_vm._v("Frame "+_vm._s(step.packetnum))])]:(step.filter)?[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","text":"","x-small":"","to":{
                          name: 'Analysis',
                          params: {
                            id: a.pcapid,
                            analysisid: a.id,
                            filter: step.filter,
                          },
                        }}},[_vm._v("Filter for "+_vm._s(step.filter))])]:[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","text":"","x-small":"","to":{
                          name: 'Analysis',
                          params: {
                            id: a.pcapid,
                            analysisid: a.id,
                          },
                        }}},[_vm._v(_vm._s(step.action))])],_vm._v(" "+_vm._s(step.description)+" ")],2)}),0)],2),_c('v-card-actions',[_c('v-spacer'),(a.index !== undefined && a.index != '')?_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","color":"primary","to":{
                    name: 'Analysis',
                    params: {
                      id: a.pcapid,
                      analysisid: a.id,
                    },
                  }}},[_vm._v("View")]):_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","color":"primary","to":{
                    name: 'Analysis',
                    params: {
                      id: a.pcapid,
                      analysisid: a.id,
                    },
                  }}},[_vm._v("View")]),(
                    _vm.currentUser &&
                      a.permissions.permissions_owner[0].username ==
                        _vm.currentUser.username
                  )?[(a.permissions.permissions_public)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"slot":"activator","color":"primary","small":""},on:{"click":function($event){return _vm.togglePublic(a)}},slot:"activator"},on),[_vm._v("Unpublish "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("cloud")])],1)]}}],null,true)},[_c('span',[_vm._v("Analysis is public, click to deny public access")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"slot":"activator","small":"","color":"primary"},on:{"click":function($event){return _vm.togglePublic(a)}},slot:"activator"},on),[_vm._v(" Publish "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("lock")])],1)]}}],null,true)},[_c('span',[_vm._v("Analysis is private, click to allow public access")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"slot":"activator","small":"","color":"error"},on:{"click":function($event){return _vm.deleteAnalysis(a)}},slot:"activator"},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete this analysis")])])]:_vm._e()],2)],1)],1)],1):_c('error',{attrs:{"error":"Nothing found"}},[_c('template',{staticClass:"pt-5",slot:"actions"})],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }