<template>
  <v-row class="mx-auto" style="margin-top:64px">
    <v-container fluid class="pa-6">
      <div class="">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <h1 class="text-4xl font-bold mb-4">PCAP List Overview</h1>
      <p class="text-lg mb-4">
        Here, you can manage, search, and interact with all the PCAP files in the system.
      </p>
      <p class="text-lg mb-4">
        Use the search bar to quickly locate specific PCAP files. The table below provides detailed information about each PCAP, including its size, packet count, and indexing status.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
      <div class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <!-- Section 1: Searching and Filtering -->
        <div>
          <div class="flex items-center mb-2">
            <!-- Magnifying glass icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-4.35-4.35m1.7-5.3a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <h4 class="text-xl font-bold ml-2">Searching and Filtering</h4>
          </div>
          <p class="text-base">
            Quickly search and filter through your PCAP files based on name, description, or any other metadata.
          </p>
        </div>

        <!-- Section 2: PCAP Actions -->
        <div>
          <div class="flex items-center mb-2">
            <!-- Gear icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0a1.724 1.724 0 002.573 1.066c.764-.57 1.755.143 1.425 1.017a1.724 1.724 0 001.017 2.573c.921.3.921 1.603 0 1.902a1.724 1.724 0 00-1.017 2.573c.33.874-.661 1.587-1.425 1.017a1.724 1.724 0 00-2.573 1.017c-.3.921-1.603.921-1.902 0a1.724 1.724 0 00-2.573-1.017c-.764.57-1.755-.143-1.425-1.017a1.724 1.724 0 00-1.017-2.573c-.921-.3-.921-1.603 0-1.902a1.724 1.724 0 001.017-2.573c-.33-.874.661-1.587 1.425-1.017.996.745 2.33.745 3.326 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <h4 class="text-xl font-bold ml-2">PCAP Actions</h4>
          </div>
          <p class="text-base">
            You can delete, edit, index, tag, or publish your PCAP files. Use the available buttons and options to manage your files efficiently.
          </p>
        </div>

        <!-- Section 3: Indexes and Advanced Features -->
        <div>
          <div class="flex items-center mb-2">
            <!-- Chart/analytics icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19V6m4 13v-4m-8 4v-8m-4 4v-6a2 2 0 012-2h12a2 2 0 012 2v6" />
            </svg>
            <h4 class="text-xl font-bold ml-2">Indexes and Advanced Features</h4>
          </div>
          <p class="text-base">
            When a PCAP is indexed, specific fields of the file are indexed to make it searchable. This enables advanced features, such as filtering by specific packet fields or creating custom views.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </v-container>

    <searchhelpdialog></searchhelpdialog>

    <v-dialog v-model="pcapDeletionDialog" width="400px">
      <v-card class="pa-2">
        <v-card-title>
          <p class="display-1 text--primary" v-if="!pcapsdeleted">
            Delete PCAPs
          </p>
          <p class="display-1 text--primary" v-else>PCAPs deleted!</p>
        </v-card-title>
        <v-card-text v-if="!pcapsdeleted" class="">
          This will delete the PCAPs and all associated data from the database.
        </v-card-text>

        <v-card-actions v-if="!pcapsdeleted">
          <v-btn color="red" @click="deleteselected">Confirm deletion</v-btn>
          <v-btn color="green" @click="pcapDeletionDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn @click="pcapDeletionDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12" v-if="1 == 2">
      <displayfilterbox
        v-model="currentAnalysis.pcaplist.search"
        @confirm="deepsearch"
      ></displayfilterbox>
    </v-col>

    <v-col cols="12" v-if="type == 'search'">
      <v-text-field
        v-model="currentAnalysis.pcaplist.search"
        solo
        label="Search..."
        clearable
        hide-details
        v-on:keyup.enter="gotosearch"
        append-icon="help"
        class="pl-3"
        @click:append="currentAnalysis.pcaplist.searchhelpdialog = true"
      ></v-text-field>
    </v-col>
    <v-col cols="12" v-else-if="type != 'recent'">
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="currentAnalysis.pcaplist.search"
            solo
            label="Search..."
            clearable
            hide-details
            class="pl-3"
            v-on:keyup.enter="enterpressed"
            append-icon="help"
            @click:append="currentAnalysis.pcaplist.searchhelpdialog = true"
          ></v-text-field>
        </v-col>

        <v-col cols="2" text-right class="pr-3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn slot="activator" v-on="on" icon v-on:click="loaddata"
                ><v-icon>refresh</v-icon></v-btn
              >
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <v-menu v-if="currentUser">
            <template v-slot:activator="{ on }">
              <v-btn class="mt-1 ml-4" v-on="on" color="primary" hide-details
                >Actions</v-btn
              >
            </template>
            <v-list>
              <!--<v-list-item key="index" @click="indexselected">
                <v-list-item-action>
                  <v-icon>add</v-icon>
                </v-list-item-action>
                <v-list-item-title>Index</v-list-item-title>
              </v-list-item>-->
              <v-list-item
                key="massindex"
                @click="currentAnalysis.pcaplist.massindex = true"
              >
                <v-list-item-action>
                  <v-icon>add</v-icon>
                </v-list-item-action>
                <v-list-item-title>Create Index</v-list-item-title>
              </v-list-item>
              <v-list-item
                key="massedit"
                @click="currentAnalysis.pcaplist.massedit = true"
              >
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-title>Edit PCAP metadata</v-list-item-title>
              </v-list-item>
              <v-list-item key="delete" @click="pcapDeletionDialog = true">
                <v-list-item-action>
                  <v-icon>delete</v-icon>
                </v-list-item-action>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
              <v-list-item
                key="tag"
                @click="currentAnalysis.pcaplist.tagdialog = true"
              >
                <v-list-item-action>
                  <v-icon>style</v-icon>
                </v-list-item-action>
                <v-list-item-title>Tag</v-list-item-title>
              </v-list-item>
              <v-list-item key="publish" @click="publishselected">
                <v-list-item-action>
                  <v-icon>publish</v-icon>
                </v-list-item-action>
                <v-list-item-title>Publish</v-list-item-title>
              </v-list-item>
              <v-list-item key="unpublish" @click="unpublishselected">
                <v-list-item-action>
                  <v-icon>lock</v-icon>
                </v-list-item-action>
                <v-list-item-title>Unpublish</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="false"
                key="editpermissions"
                @click="editpermissions"
              >
                <v-list-item-action>
                  <v-icon>account_box</v-icon>
                </v-list-item-action>
                <v-list-item-title>Edit permissions</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="false" key="downloadall" @click="downloadall">
                <v-list-item-action>
                  <v-icon>get_app</v-icon>
                </v-list-item-action>
                <v-list-item-title>Download all</v-list-item-title>
              </v-list-item>

              <v-list-item
                key="decrypt"
                @click="currentAnalysis.pcaplist.decrypt = true"
              >
                <v-list-item-action>
                  <v-icon>lock_open</v-icon>
                </v-list-item-action>
                <v-list-item-title>Decrypt PCAP</v-list-item-title>
              </v-list-item>
              <v-list-item key="denselist">
                <v-list-item-action>
                  <v-switch
                    v-model="
                      currentAnalysis.profiles.selected.simplepcaplistheaders
                    "
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-title>Dense list</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" v-if="totalpcaps == 0 && !loading">
      <searcherror></searcherror>
    </v-col>

    <v-col cols="12" v-else-if="totalpcaps == null || totalpcaps > 0">
      <h3 v-if="simple" class="mb-3 ml-2">
        Latest pcaps (<router-link to="/pcaps/public">more</router-link>)
      </h3>

      <v-data-table
        v-if="
          !noquery /*type!='search' || (type=='search' && pcaps.length > 0)*/
        "
        dense
        :show-select="!!currentUser && !simple"
        :options.sync="tableoptions"
        :server-items-length="totalpcaps"
        :loading="loading"
        :headers="shownheaders"
        :items="pcaps"
        :footer-props="footerprops"
        class="elevation-1"
        item-key="id"
        v-model="currentAnalysis.pcaplist.checkedpcaps"
        :expanded.sync="expanded"
        :show-expand="!simple"
        :hide-default-footer="simple"
        :disable-sort="simple"
        :hide-default-header="simple"
        single-expand
      >
        <template v-slot:item.origname="{ item }">
          <template
            v-if="
              !item.indices || (getstate(item) && getstate(item) == 'failed')
            "
            >{{ item.origname }}</template
          >
          <!--<v-tooltip bottom>
          <span slot="activator">{{props.item.origname }}</span>
          <span>PCAP has not been indexed</span>
        </v-tooltip>-->
          <!-- for now always link to unindexed version -->

          <!--            style="text-decoration: none; "
            class="text-none" color="primary" text shaped ripple
            
            -->
          <router-link
            class="text-decoration-none"
            v-else
            :to="{
              name: 'Analysis',
              params: {
                view: 'l',
                id: item.id,
                index: 'none',
                analysisid: 'none',
              },
            }"
            >📦 {{ item.origname }}
            <template v-if="!isIndexed(item)"
              >(still processing)</template
            ></router-link
          >
          <template
            v-if="
              (simple ||
                currentAnalysis.profiles.selected.simplepcaplistheaders) &&
                item.description
            "
          >
            {{ item.description | truncate(100, "...") }}
            <b v-if="accessNeedsSubscription(item)"
              ><u
                ><router-link to="/pricing"
                  >Access needs paid account</router-link
                ></u
              ></b
            >
          </template>
          <div v-else-if="item.description">
            {{ item.description | truncate(100, "...") }}
            <b v-if="accessNeedsSubscription(item)"
              ><u
                ><router-link to="/pricing"
                  >Access needs paid account</router-link
                ></u
              ></b
            >
          </div>

          <!--<router-link
          v-else
          :to="{ name: 'Analysis', params: { id: props.item.id, index:  props.item.indices[0].id, analysisid: 'none' }}"
        >{{props.item.origname }}</router-link>-->

          <v-tooltip bottom v-if="!item.fileexists">
            <template v-slot:activator="{ on }">
              <v-icon slot="activator" small class="pl-1" v-on="on"
                >error</v-icon
              >
            </template>

            <span>PCAP has does not exists (maybe deleted)</span>
          </v-tooltip>
        </template>

        <template v-slot:item.histogram="{ item }">
          <template>
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: 'Analysis',
                params: {
                  id: item.id,
                  index: 'none',
                  view: 'i',
                  analysisid: 'none',
                },
              }"
            >
              <pcapsparkline :pcap="item"></pcapsparkline>
            </router-link>
          </template>
        </template>

        <template v-slot:item.filesize="{ item }">
          <template v-if="item.filesize">{{
            humanFileSize(item.filesize)
          }}</template>
        </template>

        <template v-slot:item.numberofpackets="{ item }">
          <template v-if="item.foundmatches"
            >{{ item.foundmatches }} /</template
          >
          {{ item.numberofpackets }}
        </template>

        <template v-slot:item.indices="{ item }">
          <i v-if="item.indices">
            <i v-for="(value, key) in item.indices" v-bind:key="key">
              <i
                v-if="key === 0 && value.built === true"
                transition="slide-x-transition"
              >
                <v-icon>check</v-icon>
              </i>
              <i v-else>
                <!-- Note this does not work properly just search for default index -->
              </i>
            </i>
          </i>
          <i v-else>
            <template v-if="getstate(item) && getstate(item) == 'running'">
              <v-progress-circular
                :size="18"
                :width="3"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </template>
            <template v-if="getstate(item) && getstate(item) == 'failed'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="red" slot="activator" v-on="on">error</v-icon>
                </template>

                <span>Broken PCAP: {{ getstate(item) }}</span>
              </v-tooltip>
            </template>
            <v-tooltip bottom v-else-if="currentUser">
              <template v-slot:activator="{ on }">
                <v-icon
                  color="primary"
                  @click="indexPcap(item)"
                  slot="activator"
                  v-on="on"
                  >refresh</v-icon
                >
              </template>

              <span>Create default index</span>
            </v-tooltip>
          </i>
        </template>

        <template v-slot:item.timestamp="{ item }">
          {{ item.timestamp | formatDate }}
        </template>

        <template v-slot:item.permissions_owner="{ item }">
          <v-btn
            small
            v-if="'permissions_owner' in item.permissions"
            outlined
            :to="{
              name: 'PCAPs',
              params: {
                type: currentAnalysis.pcaplist.type,
                query:
                  'username: ' + item.permissions.permissions_owner[0].username,
              },
            }"
          >
            {{ item.permissions.permissions_owner[0].username }}
          </v-btn>
          <template v-else>
            none
          </template>
        </template>

        <template v-slot:item.actions="{ item }" v-if="currentUser">
          <v-tooltip
            bottom
            v-if="item.permissions.permissions_public && currentUser"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                class="px-1"
                slot="activator"
                color="primary"
                small
                v-on="on"
                @click="togglePublic(item)"
                >cloud</v-icon
              >
            </template>

            <span>PCAP is public, click to deny public access to PCAP</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="currentUser">
            <template v-slot:activator="{ on }">
              <v-icon
                class="px-1"
                slot="activator"
                color="primary"
                small
                @click="togglePublic(item)"
                v-on="on"
                >lock</v-icon
              >
            </template>

            <span>PCAP is private, click to allow public access to PCAP</span>
          </v-tooltip>
          <v-tooltip bottom v-if="currentUser">
            <template v-slot:activator="{ on }">
              <v-icon
                class="px-1"
                slot="activator"
                small
                color="primary"
                @click="downloadPcap(item)"
                v-on="on"
                >cloud_download</v-icon
              >
            </template>

            <span>Download</span>
          </v-tooltip>
          <v-tooltip bottom v-if="opensearchresultenabled">
            <template v-slot:activator="{ on }">
              <v-icon
                class="px-1"
                slot="activator"
                small
                color="primary"
                @click="showMatches(item)"
                v-on="on"
                >search</v-icon
              >
            </template>

            <span>Show Matches</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="
              currentAnalysis.features.streammatching && opensearchresultenabled
            "
          >
            <template v-slot:activator="{ on }">
              <v-icon
                class="px-1"
                slot="activator"
                small
                color="primary"
                @click="showMatches(item)"
                v-on="on"
                >search</v-icon
              >
            </template>

            <span>Show matching streams</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-2">
            <pcapdetails :pcap="item"></pcapdetails>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <tagdialog></tagdialog>
    <massindex :pcaps="this.currentAnalysis.pcaplist.checkedpcaps"></massindex>
    <massedit :pcaps="this.currentAnalysis.pcaplist.checkedpcaps"></massedit>
    <decrypt :pcaps="this.currentAnalysis.pcaplist.checkedpcaps"></decrypt>
  </v-row>
</template>

<script>
import Pcapsparkline from "./basics/Pcapsparkline";
import Pcapdetails from "./Pcapdetails";
import Tagdialog from "./dialogs/Tagging";
import Massedit from "./dialogs/Massedit";
import Massindex from "./dialogs/Massindex";
import Decrypt from "./dialogs/Decrypt";
import Searchhelpdialog from "./dialogs/Searchhelp";
import Searcherror from "./dialogs/Searcherror";
import Displayfilterbox from "./settings/Displayfilterbox";
import ApiService from "@/common/api.service";
import ApiPcapmeta from "@/common/api/pcapmeta";
import ApiPcaplist from "@/common/api/pcaplist";
import ApiPcapindex from "@/common/api/pcapindex";
import ApiTask from "@/common/api/task";
import { mapGetters } from "vuex";
import { commonmethods } from "@/common/common";

import { permissionchecks } from "@/common/permissions";

export default {
  name: "pcaplist",
  mixins: [commonmethods, permissionchecks],
  components: {
    Pcapsparkline,
    Pcapdetails,
    Searchhelpdialog,
    Displayfilterbox,
    Tagdialog,
    Massindex,
    Massedit,
    Searcherror,
    Decrypt,
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "public",
    },
    query: {
      type: String,
      default: "",
    },
    noquery: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    if (this.currentUser) {
      this.simpleheaders.push({
        text: "Actions",
        value: "actions",
        sortable: false,
      });
      this.headers.push({ text: "Actions", value: "actions", sortable: false });
    }
    if (this.currentAnalysis.profiles.selected.pcapsPerPage) {
      this.tableoptions.itemsPerPage = this.currentAnalysis.profiles.selected.pcapsPerPage;
      this.footerprops["items-per-page-options"].push(
        this.tableoptions.itemsPerPage
      );
    } else {
      this.tableoptions.itemsPerPage;
    }
  },
  data() {
    return {
      pcapDeletionDialog: false,
      pcapsdeleted: false,
      opensearchresultenabled: false,
      totalpcaps: null,
      loading: true,
      tableoptions: {
        itemsPerPage: 50,
        page: 1,
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        sortBy: [],
        sortDesc: [],
      },
      footerprops: {
        "items-per-page-options": [
          20,
          40,
          60,
          80,
          100,
          120,
          140,
          160,
          180,
          200,
        ],
      },
      verysimpleheaders: [
        {
          text: "Name / Description",
          align: "left",
          sortable: false,
          value: "origname",
        },
        {
          text: "Histogram",
          align: "left",
          sortable: false,
          value: "histogram",
        },
        //{ text: "Packets", value: "numberofpackets", sortable: true },
      ],
      simpleheaders: [
        {
          text: "Name / Description",
          align: "left",
          sortable: false,
          value: "origname",
        },
        {
          text: "Histogram",
          align: "left",
          sortable: false,
          value: "histogram",
        },
        { text: "File Size", value: "filesize", sortable: true },
        { text: "Packets", value: "numberofpackets", sortable: true },
        { text: "Indexed", value: "indices", sortable: false },
        { text: "Owner", value: "permissions_owner", sortable: false },
      ],
      headers: [
        {
          text: "Name / Description",
          align: "left",
          sortable: false,
          value: "origname",
        },
        {
          text: "Histogram",
          align: "left",
          sortable: false,
          value: "histogram",
        },
        { text: "File Size", value: "filesize", sortable: true },
        { text: "Packets", value: "numberofpackets", sortable: true },
        { text: "Indexed", value: "indices", sortable: false },
        { text: "Analyses", value: "numanalyses", sortable: false },
        { text: "Creation Date", value: "timestamp", sortable: true },
        { text: "Owner", value: "permissions_owner", sortable: false },
      ],
      detailtab: null,
      pcaps: [],
      tasks: [],
      runningtasks: [],
      expanded: [],
    };
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length <= length) return text;
      return text.substring(0, length) + suffix;
    },
  },
  methods: {
    isIndexed(item) {
      if ("indices" in item) {
        for (const [key, value] of Object.entries(item.indices)) {
          if (value.built === true) {
            return true;
          }
        }
      }

      return false;
    },
    accessNeedsSubscription(pcap) {
      if (this.isAdmin(this.currentUser)) {
        return false;
      }

      if (this.hasSubscription(this.currentUser)) {
        return false;
      }

      return pcap.needs_subscription;
    },
    anyTaskRunningForLoadedPcaps(tasks) {
      var anytaskrunning = false;

      for (let t of tasks) {
        for (let pcap of this.pcaps) {
          if (t.pcapid == pcap.id) {
            console.log(t);
            anytaskrunning = true;
            this.runningtasks.push(pcap.id);
          }
        }
      }

      for (let rt of this.runningtasks) {
        var found = false;
        for (let t of tasks) {
          if (t.pcapid == rt) {
            found = true;
          }
        }
        if (!found) {
          this.runningtasks.splice(this.runningtasks.indexOf(rt), 1);

          this.reloadpcap({ id: rt });
        }
      }

      return anytaskrunning;
    },
    async getResource(resolve) {
      const tasks = await ApiTask.list("all");
      this.tasks = tasks.data;
      if (this.anyTaskRunningForLoadedPcaps(tasks.data)) {
        setTimeout(() => this.getResource(resolve), 2000);
      }
    },
    async progressPolling() {
      return new Promise((resolve) => this.getResource(resolve));
      // Finished!
    },
    expandAndUpdate(props, pcap, tab = 0) {
      this.currentAnalysis.detailstab = tab;
      // this.reloadpcap(pcap);
      props.expanded = !props.expanded;
    },
    expandAndGoto(props, tab) {
      this.currentAnalysis.detailstab = tab;
      props.expanded = !props.expanded;
    },
    reloadpcap(pcap) {
      ApiPcapmeta.get(pcap.id)
        .then(({ data }) => {
          var pcaps = this.pcaps;

          let index = pcaps.findIndex((i) => i.id === pcap.id);

          this.pcaps.splice(index, 1, data);
          this.progressPolling();
        })
        .catch(({ response }) => {});
    },
    indexselected() {
      for (let i of this.currentAnalysis.pcaplist.checkedpcaps) {
        this.indexPcap(i);
      }
    },
    deleteselected() {
      for (let i of this.currentAnalysis.pcaplist.checkedpcaps) {
        this.deletePcap(i);
      }

      this.pcapDeletionDialog = false;
    },
    publishselected() {
      for (let i of this.currentAnalysis.pcaplist.checkedpcaps) {
        this.publishPcap(i);
      }
    },
    unpublishselected() {
      for (let i of this.currentAnalysis.pcaplist.checkedpcaps) {
        this.lockPcap(i);
      }
    },
    editpermissions() {
      alert("Not implemented");
    },
    downloadall() {
      alert("Not implemented");
    },
    showPcapHelp() {
      alert(
        "FIXME Explain pcap searching commands tags:PAPW, origname:balfasel, filesize<100"
      );
    },
    greet: function(greeting) {
      alert(greeting);
    },
    presskey: function(e) {
      alert("Not implemented" + e.target.value);
    },
    reloadPcaps: function(e) {},
    gettasks: function(e) {
      // if (!this.currentUser) {
      //   return;
      // }
      ApiTask.list("all")
        .then(({ data }) => {
          this.tasks = data;
        })
        .catch(({ response }) => {});
    },
    deletePcap: function(pcap) {
      ApiPcapmeta.delete(pcap.id)
        .then(({ data }) => {
          this.pcaps.splice(this.pcaps.indexOf(pcap), 1);
          this.currentAnalysis.pcaplist.checkedpcaps = [];
        })
        .catch(({ response }) => {
          this.pcaps = [];
        });
    },
    indexPcap: function(pcap) {
      ApiPcapindex.create(pcap.id)
        .then(({ data }) => {
          this.error.msg = "";
          this.reloadpcap(pcap);
        })
        .catch(({ response }) => {
          this.reloadpcap(pcap);
        });
    },
    downloadPcap: function(pcap) {
      ApiService.downloadpcap(pcap.id);
    },
    refreshPcap: function(pcap) {
      ApiPcapmeta.get(pcap.id)
        .then(({ data }) => {
          this.pcaps[this.pcaps.indexOf(pcap)].indices = data.indices;
        })
        .catch(({ response }) => {
          this.pcaps = [];
        });
    },
    showMatches: function(pcap) {
      // FIXME got to the correct index based onthe search results
      this.$router
        .push({
          name: "Analysis",
          params: {
            view: "l",
            id: pcap.id,
            index: "none",
            analysisid: "none",
            filter: this.currentAnalysis.pcaplist.search,
          },
        })
        .catch((err) => {});
    },
    publishPcap: function(pcap) {
      pcap.permissions.permissions_public = true;
      ApiPcapmeta.update(pcap).then(({ data }) => {
        this.error.type = "info";
        this.error.msg = "Published";
      });
    },
    lockPcap: function(pcap) {
      pcap.permissions.permissions_public = false;
      ApiPcapmeta.update(pcap).then(({ data }) => {
        this.error.type = "info";
        this.error.msg = "Locked";
      });
    },
    togglePublic: function(pcap) {
      if (pcap.permissions.permissions_public) {
        this.lockPcap(pcap);
      } else {
        this.publishPcap(pcap);
      }
    },
    gotosearch: function(e) {
      this.$router
        .push({
          name: "PCAPs",
          params: {
            type: "search",
            query: this.currentAnalysis.pcaplist.search,
          },
        })
        .catch((err) => {});
    },
    enterpressed: function(e) {
      this.loaddata();
    },
    deepsearch: function() {
      ApiPcaplist.deepsearch(this.currentAnalysis.pcaplist.search)

        .then(({ data }) => {
          if (data.length == 0) {
            this.error.type = "info";
            this.error.msg = "No PCAPs found";
          }

          this.pcaps = data;
        })
        .catch(({ response }) => {
          this.pcaps = [];
        });
    },
    getstate: function(pcap) {
      for (let t of this.tasks) {
        if (t.pcapid == pcap.id) {
          return t.status;
        }
      }
    },
    loaddata: function() {
      if (this.noquery) {
        return;
      }
      this.loading = true;

      if (this.simple) {
        this.tableoptions.itemsPerPage = 3;
      }

      let { sortBy, sortDesc, page, itemsPerPage } = this.tableoptions;

      let offset = (page - 1) * itemsPerPage;

      if (offset == null) {
        offset = 0;
      }

      let query = {
        sortby: sortBy,
        sortdesc: sortDesc,
        perpage: itemsPerPage,
        offset: offset,
        type: this.currentAnalysis.pcaplist.type,
        filter: this.currentAnalysis.pcaplist.search,
      };

      ApiPcaplist.get(query)
        .then(({ data }) => {
          if (data.total == 0) {
            this.error.type = "info";
            this.error.msg = "No PCAPs found";
          }
          this.totalpcaps = data.total;
          this.pcaps = data.pcaps;
          if (query.filter != "") {
            this.opensearchresultenabled = true;
          } else {
            this.opensearchresultenabled = false;
          }
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          this.pcaps = [];
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "avatarPic", "error"]),
    fullname: function() {
      return this.currentUser.firstname + " " + this.currentUser.lastname;
    },
    shownheaders: function() {
      if (this.simple) {
        return this.verysimpleheaders;
      }
      if (this.currentAnalysis.profiles.selected.simplepcaplistheaders) {
        return this.simpleheaders;
      } else {
        return this.headers;
      }
    },
  },
  created: function() {
    this.currentAnalysis.pcaplist.type = this.type;
    this.currentAnalysis.pcaplist.search = this.query;
    //this.loaddata();
  },
  watch: {
    "$route.params.type": function(to, from) {
      this.currentAnalysis.pcaplist.type = to.params.type;
      this.currentAnalysis.pcaplist.search = this.query;
      this.loaddata();
    },
    "$route.params.query": function(to, from) {
      this.currentAnalysis.pcaplist.type = to.params.type;
      this.currentAnalysis.pcaplist.search = this.query;
      this.loaddata();
    },
    pcaps: function(n, o) {
      //this.gettasks();
    },
    tasks: function(n, o) {},
    tableoptions: {
      handler() {
        this.loaddata();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
