import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'typeface-roboto/index.css'
Vue.use(Vuetify);

//import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
  // theme: {
  //   options: { cspNonce: 'GDotj63vYt/SVNp3sNC7bQ==' },
  // },
  //theme: {
  //   themes: {
  //     light: {
  //       primary: colors.red.darken1, // #E53935
  //       secondary: colors.red.lighten4, // #FFCDD2
  //       accent: colors.indigo.base, // #3F51B5
  //     },
  //   },
  // },
  options: {
    customProperties: true, // Enables CSS custom properties for easy font overriding
  },
  icons: {
    iconfont: 'mdi',
  }
})