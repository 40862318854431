<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="pa-5" elevation="2">
          <v-card-title class="headline">
            Account Deleted
          </v-card-title>
          <v-card-text>
            Your account has been successfully deleted. We're sorry to see you
            go.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="goHome">
              Go to Home
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LOGOUT } from "@/store/actions.type";
export default {
  name: "DeletedPage",
  mounted() {
    // Automatically log out the user upon entering the /deleted page.
    // this.$store
    //     .dispatch(LOGOUT)
    //     .then(() =>
    //       this.$router.push({ name: "Packet Analyzer" }).catch((err) => {})
    //     );
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.fill-height {
  min-height: 100vh;
}
</style>
