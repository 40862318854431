<template>
  <div class="card-container">
    <!-- New Packet Safari Archive Section -->
    <div class="card archive-card">
      <h2>Unlock 1 Year of Packet Safari Archive Access</h2>
      <p>
        Access an extensive archive of publicly uploaded PCAP files and enhance your
        understanding of packet data with curated examples.
      </p>
      <a href="/pricing" class="btn archive-btn">
        Get 1-Year Access
      </a>
    </div>

    <!-- Bundle Offer Section -->
    <div class="card bundle-card">
      <h2>
        Exclusive Bundle: AI Shark, Packet Safari, and Ranger Lab Self-Paced Network Analysis Challenges
      </h2>
      <p>
        Get all three products for a discounted rate.
      </p>
      <a href="/pricing" class="btn bundle-btn">
        Grab the Bundle Now
      </a>
    </div>
  </div>
</template>

<script>
export default {
  // Component logic (if any)
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Changed from flex-start to stretch for equal heights */
  gap: 20px;
  margin: 20px;
  flex-wrap: wrap;
}

/* Base card styles */
.card {
  box-sizing: border-box;
  padding: 40px 30px;
  border-radius: 8px;
  text-align: center;
  flex: 1 1 300px;
  max-width: 500px;
}

/* Archive Card Styles */
.archive-card {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.archive-card h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 15px;
}

.archive-card p {
  color: #666;
  font-size: 16px;
  margin-bottom: 20px;
}

.archive-btn {
  text-decoration: none;
  color: white;
  background-color: #333333;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 18px;
}

/* Bundle Card Styles */
.bundle-card {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
}

.bundle-card h2 {
  color: #856404;
  font-size: 24px;
  margin-bottom: 15px;
}

.bundle-card p {
  color: #856404;
  font-size: 16px;
  margin-bottom: 20px;
}

.bundle-btn {
  text-decoration: none;
  color: white;
  background-color: #856404;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 18px;
}

/* Responsive adjustments for narrow screens */
@media (max-width: 600px) {
  .card-container {
    flex-direction: column;
    margin: 10px;
  }
  
  .card {
    padding: 20px;
    max-width: 100%;
  }
  
  .archive-card h2,
  .bundle-card h2 {
    font-size: 20px;
  }
  
  .archive-card p,
  .bundle-card p {
    font-size: 14px;
  }
  
  .archive-btn,
  .bundle-btn {
    font-size: 16px;
    padding: 10px 20px;
  }
}
</style>
