/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
<template>
  <v-row class="mt-0">
    
    <v-snackbar v-model="snackbar" top>{{ snackbartext }}</v-snackbar>

    <v-toolbar dense>
      <v-btn text v-on:click="newanalysis">New</v-btn>
      <v-tooltip bottom v-if="isupdatable()">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" slot="activator" text @click="updaterecipe">
            <v-icon>save</v-icon>
          </v-btn>
        </template>
        <span>Save this analysis</span>
      </v-tooltip>

      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text @click="postrecipe" :disabled="!edit">
            <v-icon>save_alt</v-icon>
          </v-btn>
        </template>
        <span>Add recipe</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            <v-icon :disabled="!edit" @click="deleterecipe">delete</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!edit">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            <v-icon :disabled="!isownerornew" @click="edit = !edit"
              >edit</v-icon
            >
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>

      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            <v-icon :disabled="!isownerornew" @click="edit = !edit"
              >playlist_play</v-icon
            >
          </v-btn>
        </template>
        <span>Toggle edit</span>
      </v-tooltip>

      <v-tooltip bottom v-if="edit">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            <v-icon :disabled="!isownerornew" v-on:click="addstep"
              >add_circle</v-icon
            >
          </v-btn>
        </template>
        <span>Add step</span>
      </v-tooltip>

      <v-tooltip
        bottom
        v-else-if="
          !edit && analysis.analysissteps && analysis.analysissteps.length > 0
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            <v-icon @click="runsimulationstep()">play_arrow</v-icon>
          </v-btn>
          <span>Run step</span>
        </template>
      </v-tooltip>

      <v-tooltip bottom >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            <v-icon v-on:click="currentAnalysis.showAnalysisDrawer = !currentAnalysis.showAnalysisDrawer"
              >close</v-icon
            >
          </v-btn>
        </template>
        <span>Close Drawer</span>
      </v-tooltip>


    </v-toolbar>

    <v-col cols="12" v-if="analysis.name" class="pa-3">
      <v-card flat>
        <v-card-title primary-title>
          <v-row v-if="edit">
            <v-col cols="12">
              <v-text-field
                label="Analysis Title"
                v-model="analysis.name"
                :readonly="!edit"
              ></v-text-field>
              <v-textarea
                label="Description"
                placeholder="Enter a description..."
                :readonly="!edit"
                v-model="analysis.description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="9">
              <div class="headline">
                {{ analysis.name }}
                <span class="small grey--text">
                  {{ analysis.created_at | formatDate }} by
                  <router-link
                    v-if="getOwner(analysis.permissions)"
                    :to="{
                      name: 'User Profile',
                      params: getOwner(analysis.permissions),
                    }"
                    >{{ getOwner(analysis.permissions) }}</router-link
                  >
                  <!--
                  - Presentation time: {{analysis.presentationtime | formatDuration}} - <i v-if="analysis.id">{{analysis.id}}</i>-->
                </span>
              </div>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-icon v-if="edit && !isnew">import_export</v-icon>
            </v-col>
          </v-row>
          <template v-if="!edit">
            <v-col class="pt-1" cols="12"></v-col>
            <v-col class="pt-1" cols="12">{{ analysis.description }}</v-col>
          </template>
        </v-card-title>

        <div class="analysisrecipe">
          <v-col cols="12">
            <v-subheader>Analysis Steps</v-subheader>
          </v-col>

          <!-- Note I don't really want to have expand here but v-model does not work if i dont put it here -->
          <v-expansion-panels
            inset
            :readonly="!edit"
            v-if="analysis.analysissteps && analysis.analysissteps.length > 0"
            v-model="analysispanel"
          >
            <v-expansion-panel
              expand-icon="mdi-menu-down"
              v-for="(step, i) in analysis.analysissteps"
              :key="i"
            >
              <v-expansion-panel-header>
                <b>{{ actiontostring(step) }}</b>
                <div class="float-right grey--text">
                  <!--{{step.id}} - {{step.timestamp | formatDate}}-->
                  <v-icon
                    v-if="edit"
                    small
                    @click.stop.prevent="deletestep(step)"
                    >delete</v-icon
                  >
                  <v-icon
                    v-if="edit"
                    small
                    @click.stop.prevent="insertstep(step)"
                    >note_add</v-icon
                  >
                  <v-icon small @click.stop.prevent="applyandnext(step, false)"
                    >play_arrow</v-icon
                  >
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row align="center" class="px-3 pb-3">
                  <v-col cols="6" class="pl-1">
                    <v-select
                      v-on:change="pickNumber(step)"
                      :items="actions"
                      label="Action"
                      item-text="name"
                      item-value="name"
                      :readonly="!edit"
                      v-model="step.action"
                    >
                      <template slot="selection" slot-scope="data">
                        <v-icon class="pr-2">{{ data.item.icon }}</v-icon>
                        {{ data.item.name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <v-icon class="pr-2">{{ data.item.icon }}</v-icon>
                        {{ data.item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pl-1"
                    v-if="
                      step.action == 'goto' ||
                      step.action == 'scrollto' ||
                      step.action == 'timereference'
                    "
                  >
                    <v-text-field
                      v-on:click="pickNumber(step)"
                      label="Packet number"
                      v-model="step.packetnum"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pl-1"
                    v-if="
                      step.action == 'goto' ||
                      step.action == 'scrollto' ||
                      step.action == 'timereference'
                    "
                  >
                    <v-switch
                      v-model="step.highlight"
                      label="Enable column highlighting"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" v-if="step.highlight">
                    <v-text-field
                      label="Column for highlighting"
                      v-model="step.highlight_column"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="step.highlight">
                    <v-switch
                      v-model="step.highlight_clear"
                      label="Clear highlight before this step"
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pl-1"
                    v-if="
                      step.action == 'filter' ||
                      step.action == 'search' ||
                      step.action == 'showcolumn' ||
                      step.action == 'hidecolumn'
                    "
                  >
                    <v-text-field
                      label="Filter"
                      v-model="step.filter"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class>
                    <v-textarea
                      auto-grow
                      rows="2"
                      label="Description"
                      placeholder="Enter a description..."
                      :readonly="!edit"
                      v-model="step.description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="9" class>
                    <v-switch
                      v-if="step.action == 'filter'"
                      v-model="step.clearfilter"
                      label="Clear filter after next step"
                    ></v-switch>
                    <v-switch
                      v-if="step.action == 'filter'"
                      v-model="step.streamfilter"
                      label="Is a stream filter"
                    ></v-switch>
                  </v-col>
                  <v-col cols="3">
                    <!--<v-btn class="right" v-on:click="simulate(step)">simulate</v-btn>-->
                    <v-btn
                      color="info"
                      class="float-right"
                      v-on:click="applyandnext(step)"
                      >Execute</v-btn
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--<analysisstep v-bind:step="step" :key="step.id" v-for="step in analysissteps"></analysisstep>-->
          </v-expansion-panels>
          <v-col v-else cols="12" class="text-center">No steps yet</v-col>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment-timezone";
import ApiAnalysisrecipe from "@/common/api/analysisrecipe";
import { mapGetters } from "vuex";
import Vue from "vue";

import { permissionchecks } from "@/common/permissions";
import { displayfilter } from "@/common/displayfilter";

export default {
  components: {},
  props: {},
  name: "analysisrecipe",
  mixins: [permissionchecks, displayfilter],
  mounted: function () {
    this.$eventHub.$on("next-step", (params) => {
      this.runsimulationstep();
    });

    let id = this.currentAnalysis.analysisid;
    this.getrecipe(id);
  },
  beforeDestroy() {
    this.$eventHub.$off("next-step");
  },
  watch: {
    // https://stackoverflow.com/questions/46368578/vuex-executing-function-after-async-state-change
    currentAnalysis: {
      handler: function (n, o) {
        if (
          n.analysisid !== undefined &&
          n.analysisid != null &&
          n.analysisid != "none"
        ) {
          // only load the recipe from the server if the analysisid changed
          if (
            this.analysis == null ||
            o.analysisid != n.analysisid ||
            this.analysis.analysisid != n.analysisid
          ) {
            this.getrecipe(n.analysisid);
          }
        }
        if (this.picknumberstep != null) {
          Vue.set(
            this.picknumberstep,
            "packetnum",
            this.currentAnalysis.currentpacket
          );

          this.picknumberstep = null;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      currentstep: 0,
      snackbar: false,
      snackbartext: "",
      clearfilter: false,
      streamfilter: false,
      edit: false,
      editdescription: false,
      isnew: false,
      analysis: {
        description: null,
        name: null,
        pcapid: null,
        index: null,
        analysissteps: [],
      },
      picknumberstep: null,
      analysispanel: [],
      actions: [
        {
          name: "text",
          icon: "toc",
        },
        {
          name: "filter",
          icon: "filter",
        },
        {
          name: "search",
          icon: "search",
        },
        {
          name: "goto",
          icon: "directions",
        },
        {
          name: "timereference",
          icon: "alarm_add",
        },
        {
          name: "showcolumn",
          icon: "view_column",
        },
        {
          name: "hidecolumn",
          icon: "close",
        },
      ],
    };
  },
  beforeUpdate: function () {
    // FIXME: this does not make any sense
    /*
    if(this.analysissteps != null){
      for (let step of this.analysis.analysissteps) {
        for (let action of this.actions) {
          if (action.name == step.action)
            step.icon = action.icon
        }
      }
    }*/
  },
  computed: {
    ...mapGetters([
      "currentAnalysis",
      "currentUser",
      "error",
      "analysismessage",
    ]),
    formattedTitle: function () {
      //return this.step.id + ": " + this.step.action
      return this.currentstep.action;
    },
    actionlist: function () {
      return Object.keys(this.actions);
    },
    defaultaction: function () {
      return this.actions[0].name;
    },
    isownerornew: function () {
      return (
        this.isOwner(this.currentUser, this.analysis.permissions) || this.isnew
      );
    },
  },
  methods: {
    isupdatable: function () {
      return (
        this.isOwner(this.currentUser, this.analysis.permissions) && !this.isnew
      );
    },
    newanalysis: function () {
      if (this.currentUser == null) {
        this.error.msg = "You must be logged in to create a new analysis";
        // this.$store.commit(
        //   SET_ERROR,
        //   "You must be logged in to create a new analysis "
        // );
        return;
      }

      this.isnew = true;
      this.analysis.name = "New analysis";
      this.analysis.description = "";
      this.edit = true;
      this.analysis.pcapid = this.currentAnalysis.pcapid;
      this.analysis.index = this.currentAnalysis.index;
      this.analysis.analysissteps = [];
      this.analysis.permissions = {
        permissions_owner: [
          {
            username: this.currentUser.username,
          },
        ],
      };
    },
    pickNumber: function (step) {
      this.picknumberstep = step;
    },
    simulate: function (step) {
      this.picknumberstep = null;

      if (this.clearfilter == true) {
        this.df_apply_to_packetlist("");
        this.clearfilter = false;
      }

      if (
        step.description !== undefined &&
        step.description != null &&
        step.description != ""
      ) {
  
        this.error.timeout = -1; // keep infinite
        this.error.type = "analysis";
        this.error.msg = step.description;
      }

      switch (step.action) {
        case "text":
          // this.analysismessage.show = true;
          // this.analysismessage.title = this.actiontostring(step);
          // this.analysismessage.msg = step.description;

          // this.analysismessage.domelem ='div[row-id="2"] div[col-id="frame.time_relative"]'
          // this.analysismessage.domelem = 'div[row-id="3"]';
          // this.error.msg = "Description: " + step.description;
          return;
        case "filter":
          if (step.clearfilter !== undefined && step.clearfilter == true)
            this.clearfilter = true;
          if (step.streamfilter !== undefined && step.streamfilter == true)
            this.currentAnalysis.streamFilter = true;
          else this.currentAnalysis.streamFilter = false;

          this.df_apply_to_packetlist(step.filter);
          // this.$eventHub.$emit("displayfilter-set", step.filter);
          return;
        case "goto":
          this.$eventHub.$emit("goto", step.packetnum);

          if (step.highlight) {
            var params = {
              row: step.packetnum,
              column: step.highlight_column,
              clear: step.highlight_clear,
            };
            this.$eventHub.$emit("highlight", params);
            this.$eventHub.$emit("highlight-decode", step.highlight_column);
          }

          return;
        case "timereference":
          this.$eventHub.$emit("goto", step.packetnum);
          this.$eventHub.$emit("timereference", step.packetnum);
          return;
        case "showcolumn":
          this.$eventHub.$emit("showcolumn", step.filter);
          return;
        case "hidecolumn":
          this.$eventHub.$emit("hidecolumn", step.filter);
          return;
        default:
          this.snackbartext = "Could not simulate step";
          this.snackbar = true;
      }
    },
    runsimulationstep: function () {
      var numsteps = this.analysis.analysissteps.length;

      if (numsteps < 1) {
        return;
      }

      if (numsteps <= this.currentstep) {
        this.currentstep = 0;
      }

      this.applyandnext(this.analysis.analysissteps[this.currentstep]);
      this.currentstep = this.currentstep + 1;
    },
    openExpansion: function (stepindex) {
      var newstate = [...this.analysis.analysissteps].map((_) => false);
      if (stepindex < this.analysis.analysissteps.length) {
        if (newstate[stepindex] == true) {
          newstate[stepindex] = false;
        } else {
          newstate[stepindex] = true;
        }
      }

      this.analysispanel = newstate;
    },
    applyandnext: function (step, expand = true) {
      this.simulate(step);
      this.openExpansion(this.analysis.analysissteps.indexOf(step) + 1);
    },
    actiontostring: function (step) {
      switch (step.action) {
        case "text":
          return "Analysis message"
          if (step.description === undefined || step.description == "")
            return "Analysis text (enter an text below)";
          else if (step.description.length < 40) {
            return step.description;
          }
          return step.description.substring(0, 40) + "...";
        case "goto":
          if (step.packetnum === undefined || step.packetnum == "")
            return "Goto frame (pick a packet)";
          else return "Goto frame " + step.packetnum;
        case "timereference":
          if (step.packetnum === undefined || step.packetnum == "")
            return "Set time reference on frame (pick a packet)";
          else return "Time reference on " + step.packetnum;
        case "search":
          if (step.filter === undefined || step.filter == "")
            return "Searching for (define a search below)";
          else return "Searching for " + step.filter;
        case "filter":
          if (step.filter === undefined || step.filter == "")
            return "Filtering for (define a filter below)";
          else if (step.streamfilter == true)
            return (
              "Showing streams with matching at least one packet with filter " +
              step.filter
            );
          return "Filtering on " + step.filter;
        case "showcolumn":
          if (step.filter === undefined || step.filter == "")
            return "Show column (define below)";
          else return "Show column " + step.filter;
        case "hidecolumn":
          if (step.filter === undefined || step.filter == "")
            return "Hide column (define below)";
          else return "Hide column " + step.filter;
        case "scrollto":
          if (step.packetnum === undefined || step.packetnum == "")
            return "Scroll to frame (pick a packet)";
          else return "Scrolling to " + step.packetnum;
        default:
          return "No description: " + step.action;
      }
    },
    renumbersteps: function () {
      var i = 0;
      for (let step of this.analysis.analysissteps) {
        step.id = i;
        i = i + 1;
      }
    },
    comparestep: function (a, b) {
      if (a.id > b.id) return 1;
      else if (a.id < b.id) return -1;
      else return 0;
    },
    insertstep: function (afterstep) {
      var newid;
      if (afterstep === undefined) {
        newid = 0;
      } else {
        newid = afterstep.id + 1;
      }
      var now = moment();
      var newstep = {
        id: newid,
        description: "Step: " + (newid + 1),
        action: "text",

        timestamp: now,
        highlight_clear: true,
      };

      this.analysis.analysissteps.splice(newid, 0, newstep);
      this.renumbersteps();
    },
    addstep: function () {
      this.insertstep(
        this.analysis.analysissteps[this.analysis.analysissteps.length - 1]
      );
    },
    deletestep: function (step) {
      this.analysis.analysissteps.splice(
        this.analysis.analysissteps.indexOf(step),
        1
      );
      this.renumbersteps();
    },
    getrecipe(id) {
      // if (!this.analysis.id) {
      //   this.isnew = false;
      //   return;
      // }
      if (id == this.analysis.id) {
        return;
      }

      if (id == null || id == "none" || id == "") {
        return;
      }
      ApiAnalysisrecipe.get(id)
        .then(({ data }) => {
          if (data) {
            this.analysis.id = data.id;
            this.analysis.name = data.name;
            this.analysis.description = data.description;
            this.analysis.pcapid = data.pcapid;
            this.analysis.index = data.index;
            this.analysis.permissions = data.permissions;
            this.analysis.analysissteps = data.analysissteps;
            if (this.isOwner(this.currentUser, this.analysis.permissions)) {
              this.edit = true;
            }
            this.isnew = false;
          }
        })
        .catch(({ response }) => {});
    },
    postrecipe() {
      ApiAnalysisrecipe.create(this.analysis)
        .then(({ data }) => {
          this.analysis.id = data["id"];
          this.isnew = false;
        })
        .catch(({ response }) => {
          this.isnew = false;
        });
    },
    updaterecipe() {
      ApiAnalysisrecipe.update(this.analysis)
        .then(({ data }) => {
          this.isnew = false;
        })
        .catch(({ response }) => {});
    },
    expandorsimulate(step) {
      if (this.edit) {
        this.openExpansion(this.analysis.analysissteps.indexOf(step));
      } else {
        this.applyandnext(step, false);
      }
    },
    deleterecipe() {
      if (!this.analysis.id) {
        this.analysis.name = null;
        this.isnew = true;
        this.analysis.id = null;
        return;
      }

      ApiAnalysisrecipe.delete(this.analysis.id)
        .then(({ data }) => {
          this.analysis.name = null;
          this.analysis.id = null;
          this.isnew = false;
        })
        .catch(({ response }) => {});
    },
  },
};
</script>

<style >
</style>
