export const TAB_PACKETVIEW = 0
export const TAB_CONNECTIONVIEW = 1
export const TAB_DNSVIEW = 2
export const TAB_IOGRAPH = 3
export const TAB_TAP = 4
export const TAB_META = 5

import ApiTask from "@/common/api/task";
import ApiPcapmeta from "@/common/api/pcapmeta";

export const navigation = {
    methods: {
        getPcapReturn: async function(id){
            if (id == null) return;
            const pcap = await ApiPcapmeta.get(id);
            return pcap.data
        },
        getPcap: function (id, to = null) {
            if (id == null) return;
        
            ApiPcapmeta.get(id)
                .then(({ data }) => {
                    if (to != null) {
                        // Ensure the 'recentlyused' array exists
                        if (!this.currentAnalysis.recentlyused) {
                            this.currentAnalysis.recentlyused = [];
                        }
                        // Remove any existing entry with the same 'to.fullPath'
                        this.currentAnalysis.recentlyused = this.currentAnalysis.recentlyused.filter(
                            item => item.to !== to.fullPath
                        );
                        // Add the new entry at the beginning of the array
                        this.currentAnalysis.recentlyused.unshift({
                            title: data.origname,
                            icon: "article",
                            to: to.fullPath
                        });
                    }
                    console.log("New PCAP set:", data);
                    this.currentAnalysis.pcap = data;
                })
                .catch(error => {
                    this.error.type = "error";
                    // Fallback to a generic error message if the expected error data is missing
                    this.error.msg = error?.response?.data?.error || error.message || "An unknown error occurred.";
                });
        },
        
        async getMatchingTask(pcapid) {
            const tasks = await ApiTask.list("all");

            for (let t of tasks.data) {
                if (t.pcapid == pcapid) {
                    return t
                }
                return undefined
            }
        },
        async updateTaskStatus(resolve, pcapid) {

            let pcap = await this.getPcapReturn(pcapid)
 
            if(pcap.id && !pcap.indexed){
                debugger
                this.currentAnalysis.longoperationoverlay = true;
                this.currentAnalysis.longoperationoverlayData.text = "PCAP is still processing, status: preprocessing"
                this.currentAnalysis.longoperationoverlayData.progress = 10;     
                setTimeout(() => this.updateTaskStatus(resolve, pcapid), 2000);
                return   
            }

            let t = await this.getMatchingTask(pcapid)
            console.log("Update "+t)
            console.log()
            if (t?.status != "finished") {
                this.currentAnalysis.longoperationoverlay = true;
                this.currentAnalysis.longoperationoverlayData.text = "PCAP is still processing, status: " + t.status
                this.currentAnalysis.longoperationoverlayData.progress = this.statusToProgress[t.status];

                setTimeout(() => this.updateTaskStatus(resolve, pcapid), 2000);
            } else {
                
                if (t.status == 'finished') {
                    
                    this.currentAnalysis.longoperationoverlayData.text = "PCAP processing finished, reloading..."
                    this.currentAnalysis.longoperationoverlayData.progress = 100;
                    // if(this.currentAnalysis.pcapid == pcap.id){
                    //     this.currentAnalysis.longoperationoverlay = false;
                    //     //this.currentAnalysis.pcap = pcap
                    //     return
                    // }
                    setTimeout(() => {
                        this.currentAnalysis.longoperationoverlay = false;
                        this.currentAnalysis.pcap = pcap
                        //this.$router.go()
                    }, 2000);
                }
                //
                //TODO reload
            }
            return false
        },
        async taskPolling(pcapid) {
            return new Promise((resolve) => this.updateTaskStatus(resolve, pcapid));
        },
        navigatedToMetaView(){
            this.currentAnalysis.analysistabs = TAB_META
        },
        navigateDnsView(pcapid = null, index = null, filter = null, analysisid = null, first = null, last = null) {
            if (index) {
                this.currentAnalysis.index = index
            }

            if (filter) {
                this.currentAnalysis.filter = filter
            }

            if (analysisid) {
                this.currentAnalysis.analysisid = analysisid
            }

            this.currentAnalysis.analysistabs = TAB_DNSVIEW

            let c = this.$route.name


            if (c != "Analysis") {
                this.$router.push({
                    name: "Analysis",
                    params: {
                        view: "d",
                        id: pcapid,
                        index: index,
                        filter: filter,
                        analysisid: analysisid
                    }
                }).catch(err => { });
            } else {
                // this.$router.replace({
                //     name: "Analysis",
                //     params: {
                //     view: "l",
                //     id: pcapid,
                //     index: index,
                //     filter: filter,
                //     analysisid: analysisid
                //     }
                // }).catch(err => {});                
            }
        },
        navigateConnectionview(pcapid = null, index = null, filter = null, analysisid = null, first = null, last = null) {

            if (index) {
                this.currentAnalysis.index = index
            }

            if (filter) {
                this.currentAnalysis.filter = filter
            }

            if (analysisid) {
                this.currentAnalysis.analysisid = analysisid
            }

            this.currentAnalysis.analysistabs = TAB_CONNECTIONVIEW

            let c = this.$route.name


            if (c != "Analysis") {
                this.$router.push({
                    name: "Analysis",
                    params: {
                        view: "c",
                        id: pcapid,
                        index: index,
                        filter: filter,
                        analysisid: analysisid
                    }
                }).catch(err => { });
            } else {
                // this.$router.replace({
                //     name: "Analysis",
                //     params: {
                //     view: "l",
                //     id: pcapid,
                //     index: index,
                //     filter: filter,
                //     analysisid: analysisid
                //     }
                // }).catch(err => {});                
            }

        },
        navigatePacketview(pcapid = null, index = null, filter = null, analysisid = null, first = null, last = null) {

            // we set this later to have a chance to get a glimpse at the old pcapid
            // if(pcapid){
            //     this.currentAnalysis.pcapid = pcapid
            // }

            if (index) {
                this.currentAnalysis.index = index
            }

            if (filter) {
                this.currentAnalysis.applyfilter = true;
                this.currentAnalysis.filterneedsupdate = true;
                this.currentAnalysis.filter = filter
            }

            if (analysisid) {
                this.currentAnalysis.analysisid = analysisid
            }

            this.currentAnalysis.analysistabs = TAB_PACKETVIEW

            let c = this.$route.name


            if (c != "Analysis") {
                this.$router.push({
                    name: "Analysis",
                    params: {
                        view: "l",
                        id: pcapid,
                        index: index,
                        filter: filter,
                        analysisid: analysisid
                    }
                }).catch(err => { });
            } else {
                // this.$router.replace({
                //     name: "Analysis",
                //     params: {
                //     view: "l",
                //     id: pcapid,
                //     index: index,
                //     filter: filter,
                //     analysisid: analysisid
                //     }
                // }).catch(err => {});                
            }
        },
        navigateIOGraph(pcapid = null, index = null, filter = null, custom = null) {

            if (pcapid) {
                this.currentAnalysis.pcapid = pcapid
            }

            if (index) {
                this.currentAnalysis.index = index
            }

            if (filter) {
                this.currentAnalysis.filter = filter
            }


            this.currentAnalysis.analysistabs = TAB_IOGRAPH

            if (custom) {
                this.currentAnalysis.customgraph = custom
            }

            let c = this.$route.name

            if (c != "Analysis") {
                this.$router.push({
                    name: "Analysis",
                    params: {
                        filter: filter,
                        view: "i",
                        id: pcapid,
                        index: index
                    }
                }).catch(err => { });
            } else {
                // this.$router.replace({
                //     name: "Analysis",
                //     params: {
                //     view: "i",
                //     id: pcapid,
                //     index: index,
                //     }
                // }).catch(err => {});            
            }

            //this.currentAnalysis.showIOGraph = !this.currentAnalysis.showIOGraph
            //this.$router.push({ name: 'Iograph', params: {pcapid: this.pcap.id, index: this.selectedview.id} }).catch(err => {})
        },
        navigateTap(pcapid, tap) {
            this.currentAnalysis.currentTap = tap
            this.currentAnalysis.pcapid = pcapid
            this.currentAnalysis.analysistabs = TAB_TAP

            let c = this.$route.name

            if (c != "Analysis") {
                this.$router.push({
                    name: "Analysis",
                    params: {
                        view: "t",
                        id: pcapid,
                        tap: tap
                    }
                }).catch(err => { });
            } else {
                /*               this.$router.replace({
                                  name: "Analysis",
                                  params: {
                                  view: "t",
                                  id: pcapid,
                                  tap: tap
                                  }
                              }).catch(err => {});    */
            }


            //this.currentAnalysis.showTapstatistics = true
            //this.$router.push({ name: "Statistics", params: {pcapid: this.currentAnalysis.pcapid, tap: tap } }).catch(err => {});
        }
    }
}