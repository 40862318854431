<template>
  <v-row justify="center" class="mt-5">
    <v-card width="400px" class="pa-4">
      <v-card-title class="headline justify-center">
        Reset Your Password
      </v-card-title>
      <v-card-text>
        <p class="text-center mb-4">
          Enter your username to receive a password reset code via email.
          Once you have the code, please enter it along with your new password.
        </p>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <!-- Display response errors or messages -->
              <v-col cols="12" v-if="submitresponse !== ''">
                <v-alert type="error" dense text>
                  {{ submitresponse }}
                </v-alert>
              </v-col>

              <!-- Step 1: Request code -->
              <v-col cols="12" v-if="!codesent">
                <v-text-field
                  v-model="user.username"
                  :counter="25"
                  label="Username"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="!codesent">
                <v-btn color="primary" @click="submit" block>
                  Submit
                </v-btn>
              </v-col>

              <!-- Step 2: Code sent -->
              <v-col cols="12" v-if="codesent">
                <v-alert type="info" dense text>
                  Code sent to: {{ senttoemail }}
                </v-alert>
              </v-col>

              <v-col cols="12" v-if="codesent">
                <v-text-field
                  v-model="user.code"
                  :counter="6"
                  label="Recovery Code"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="codesent">
                <v-text-field
                  v-model="user.password"
                  :append-icon="showpassword ? 'visibility_off' : 'visibility'"
                  :rules="[rules.required, rules.min]"
                  :type="showpassword ? 'text' : 'password'"
                  label="New Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="showpassword = !showpassword"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="codesent">
                <v-btn color="primary" :disabled="!valid" @click="setnewpassword" block>
                  Set Password
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import ApiUser from "@/common/api/user";
import { mapGetters } from "vuex";

export default {
  name: "forgotpassword",
  computed: {
    ...mapGetters(["error", "currentUser"]),
  },
  data: () => ({
    submitresponse: "",
    senttoemail: "",
    codesent: false,
    valid: true,
    user: {
      username: "",
      code: "",
      password: ""
    },
    showpassword: false,
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters"
    }
  }),
  mounted() {
    if (this.userin) {
      this.user.username = this.userin;
    }
  },
  methods: {
    submit() {
      this.submitresponse = "";
      if (this.$refs.form.validate()) {
        ApiUser.forgotpassword(this.user)
          .then(({ data }) => {
            this.codesent = true;
            this.senttoemail = data.email;
          })
          .catch(({ response }) => {
            this.submitresponse = response.data.error;
            this.codesent = false;
          });
      }
    },
    setnewpassword() {
      this.submitresponse = "";
      if (this.$refs.form.validate()) {
        ApiUser.setnewpassword(this.user)
          .then(() => {
            this.error.type = "info";
            this.error.msg = "Password set!";
            this.$router.push({ name: "Login" }).catch(() => {});
          })
          .catch(({ response }) => {
            this.submitresponse = response.data.error;
          });
      }
    },
    clear() {
      this.$refs.form.reset();
    }
  }
};
</script>
