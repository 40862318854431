var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentAnalysis)?_c('v-menu',{attrs:{"offset-x":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue-grey lighten-4","dark":""}},[_vm._v("build")])],1)]}}],null,false,2986567385),model:{value:(_vm.menuopen),callback:function ($$v) {_vm.menuopen=$$v},expression:"menuopen"}},[_c('decodeasdialog'),(_vm.currentAnalysis.pcaplist.editcap)?_c('editcap'):_vm._e(),_c('v-card',[(_vm.menuitems)?_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',[_vm._v("Actions")]),(_vm.currentUser)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.decodeas.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("blur_on")])],1),_c('v-list-item-title',[_vm._v("Decode as")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.linkToCurrent.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("link")])],1),_c('v-list-item-title',[(_vm.currentAnalysis.filter)?_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"id":"linkAnchor","to":{
              name: 'Analysis',
              params: {
                id: _vm.currentAnalysis.pcapid,
                index: 'none',
                analysisid: 'none',
                filter: _vm.currentAnalysis.filter,
                view: 'l'
              }
            }},nativeOn:{"click":function($event){$event.preventDefault();}}},[_vm._v(" Copy Link ")]):_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"id":"linkAnchor","to":{
              name: 'Analysis',
              index: 'none',
              analysisid: 'none',
              params: { id: _vm.currentAnalysis.pcapid, view: 'l' }
            }},nativeOn:{"click":function($event){$event.preventDefault();}}},[_vm._v(" Copy Link ")])],1)],1),(_vm.currentUser)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.analysisdrawer()}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("slideshow")])],1),_c('v-list-item-title',[_vm._v("Edit Analysis")])],1):_vm._e(),(_vm.currentUser)?_vm._l((_vm.menuitems),function(item,index){return _c('v-list-item',_vm._g({key:index,on:{"click":function($event){return item.action()}}},_vm.on),[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}):_vm._e(),_c('v-subheader',[_vm._v("Temporary Settings")]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.profiles.selected.highlightDecodeChanges),callback:function ($$v) {_vm.$set(_vm.currentAnalysis.profiles.selected, "highlightDecodeChanges", $$v)},expression:"currentAnalysis.profiles.selected.highlightDecodeChanges"}})],1),_c('v-list-item-title',[_vm._v("Highlight changes")])],1)]}}],null,false,1031356143)},[_c('span',[_vm._v("Highlight changes in decode")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.showFilterBoxOverlay),callback:function ($$v) {_vm.$set(_vm.currentAnalysis, "showFilterBoxOverlay", $$v)},expression:"currentAnalysis.showFilterBoxOverlay"}})],1),_c('v-list-item-title',[_vm._v("Filter snackbar")])],1)]}}],null,false,3749785915)},[_c('span',[_vm._v("Good for demos or teaching")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.streamFilter),callback:function ($$v) {_vm.$set(_vm.currentAnalysis, "streamFilter", $$v)},expression:"currentAnalysis.streamFilter"}})],1),_c('v-list-item-title',[_vm._v("Streamfilter")])],1)]}}],null,false,1962699890)},[_c('span',[_vm._v("The display filters shows all streams that have a matching packet")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.profiles.selected.currentPacketInfo),callback:function ($$v) {_vm.$set(_vm.currentAnalysis.profiles.selected, "currentPacketInfo", $$v)},expression:"currentAnalysis.profiles.selected.currentPacketInfo"}})],1),_c('v-list-item-title',[_vm._v("Packet Info")])],1)]}}],null,false,2374872618)},[_c('span',[_vm._v("Show packet info")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.quickfilter),callback:function ($$v) {_vm.$set(_vm.currentAnalysis, "quickfilter", $$v)},expression:"currentAnalysis.quickfilter"}})],1),_c('v-list-item-title',[_vm._v("Quick filter")])],1)]}}],null,false,2576178162)},[_c('span',[_vm._v("Filter in the table not on the backend")])]),(_vm.currentAnalysis.features.debugging)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.debug),callback:function ($$v) {_vm.$set(_vm.currentAnalysis, "debug", $$v)},expression:"currentAnalysis.debug"}})],1),_c('v-list-item-title',[_vm._v("Debug Output")])],1)]}}],null,false,3270481101)},[_c('span',[_vm._v("Debug Output")])]):_vm._e(),(_vm.currentAnalysis.features.timeline)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.profiles.selected.showTimeline),callback:function ($$v) {_vm.$set(_vm.currentAnalysis.profiles.selected, "showTimeline", $$v)},expression:"currentAnalysis.profiles.selected.showTimeline"}})],1),_c('v-list-item-title',[_vm._v("Timeline")])],1)]}}],null,false,1437090720)},[_c('span',[_vm._v("Timeline")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.profiles.selected.colorPackets),callback:function ($$v) {_vm.$set(_vm.currentAnalysis.profiles.selected, "colorPackets", $$v)},expression:"currentAnalysis.profiles.selected.colorPackets"}})],1),_c('v-list-item-title',[_vm._v("Color Packets")])],1)]}}],null,false,3828835939)},[_c('span',[_vm._v("Color Packets")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.currentAnalysis.profiles.selected.theme),callback:function ($$v) {_vm.$set(_vm.currentAnalysis.profiles.selected, "theme", $$v)},expression:"currentAnalysis.profiles.selected.theme"}})],1),_c('v-list-item-title',[_vm._v("Dark mode")])],1)]}}],null,false,3914078733)},[_c('span',[_vm._v("Dark mode")])]),_c('v-subheader',[_vm._v(" Current Profile "),_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","small":""},on:{"click":_vm.loadallprofiles}},[_vm._v(" load all ")])],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":_vm.currentAnalysis.profiles.available,"item-value":"id","item-text":"name","return-object":"","hide-details":"","label":"Available profiles","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("truncate")(data.item.name,15, "..."))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}}],null,true),model:{value:(_vm.cprofile),callback:function ($$v) {_vm.cprofile=$$v},expression:"cprofile"}})],1)],1)]}}],null,false,2615613242)},[_c('span',[_vm._v("Select Profile")])]),(_vm.currentAnalysis.features.enableindexedpacketlist)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[(_vm.currentAnalysis.pcap)?_c('v-list-item-action',[(_vm.currentAnalysis.pcap.origname)?_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"solo":"","items":_vm.availableviews,"item-text":"indexername","item-value":"id","return-object":"","label":"Select view...","hide-details":""},model:{value:(_vm.selectedview),callback:function ($$v) {_vm.selectedview=$$v},expression:"selectedview"}}):_vm._e()],1):_vm._e()],1)]}}],null,false,3132630777)},[_c('span',[_vm._v("Select Packet View")])]):_vm._e()],2):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }