<template>
    <!-- Your template content -->
  </template>
  
  <script>
  import { driver as Driver } from "driver.js/dist/driver.js.cjs";
  import "driver.js/dist/driver.css";
  
  export default {
    name: "Tour",
    data() {
      return {
        driver: null,
      };
    },
    mounted() {
      // Check immediately in case the route is already '/analyze...'
      this.checkRouteAndStartTour(this.$route);
    },
    watch: {
      // Watch for route changes.
      $route(to) {
        this.checkRouteAndStartTour(to);
      },
    },
    methods: {
      /**
       * Wait for the element specified by the selector to be available in the DOM.
       * @param {string} selector - The DOM selector.
       * @param {number} timeout - Maximum time to wait (in milliseconds).
       * @returns {Promise<Element>} Resolves with the element when found.
       */
      waitForElement(selector, timeout = 3000) {
        return new Promise((resolve, reject) => {
          const interval = 100;
          let elapsedTime = 0;
          const timer = setInterval(() => {
            const el = document.querySelector(selector);
            if (el) {
              clearInterval(timer);
              resolve(el);
            }
            elapsedTime += interval;
            if (elapsedTime >= timeout) {
              clearInterval(timer);
              reject(
                new Error(`Element '${selector}' not found within ${timeout}ms`)
              );
            }
          }, interval);
        });
      },
      async checkRouteAndStartTour(route) {
        const tourCompleted = localStorage.getItem("tourCompleted");
        if (route && route.path.startsWith("/analyze") && !tourCompleted) {
          // Create a new driver if one does not already exist.
          if (!this.driver) {
            this.driver = Driver({
              animate: true,
              opacity: 0.75,
              allowClose: false,
              // Use onDestroyed callback to mark the tour as completed.
              onDestroyed: () => {
                localStorage.setItem("tourCompleted", "true");
              },
            });
          }
          try {
            // Wait until the target element is in the DOM.
            await this.waitForElement("#aibutton");
            // Start the tour only when the element is ready.
            this.startTour();
          } catch (err) {
            console.error(err);
          }
        }
      },
      startTour() {
        // Define the steps for the tour with a more serious tone and detailed explanations.
        this.driver.setSteps([
          {
            popover: {
              title:
                "<span style='color:#28a745; font-weight:bold;'>Welcome to Packet Safari</span>",
              description: `<p>This guided tour will introduce you to the main features of our application, designed for effective packet analysis.</p>`,
              position: "right",
            },
          },
          {
            element: "#aibutton",
            popover: {
              title:
                "<span style='color:#007BFF; font-weight:bold;'>NEW! PacketSafari Copilot</span>",
              description: `<p>Select one or more packets and click the Copilot Button to initiate an interactive chat with our AI. This feature explains packet details in plain language, offering insights and aiding in complex analysis. <br><br>
                              <span style='background-color:#FFFF00; padding:2px 4px; border-radius:3px;'>Premium Feature</span> – Unlock advanced explanations and insights with this option.
                              <br>
                              <a href="/pricing" style="color:#007BFF; text-decoration:underline;">View pricing details</a>
                            </p>`,
              position: "top",
            },
          },
          {
            element: "#packetlist",
            popover: {
              title:
                "<span style='color:#17a2b8; font-weight:bold;'>Packet List</span>",
              description: `<p>This section displays all the captured packets. Each packet represents a data unit transmitted over the network. Click any packet to view its detailed information.</p>`,
              position: "right",
            },
          },
          {
            element: "#filterbox",
            popover: {
              title:
                "<span style='color:#ffc107; font-weight:bold;'>Filter Bar</span>",
              description: `<p>The filter bar allows you to narrow down the displayed packets using Wireshark display filters. For example, try filters like <code>tcp</code>, <code>tcp.port == 443</code>, or <code>ip.addr == 192.168.1.1</code> to refine your search.</p>`,
              position: "right",
            },
          },
          {
            element: "#decode",
            popover: {
              title:
                "<span style='color:#6f42c1; font-weight:bold;'>Packet Details</span>",
              description: `<p>In this view, you can inspect the technical details of a packet. Important parameters such as the IP ID (vital for packet correlation) and TCP sequence numbers with flags are clearly presented.</p>`,
              position: "right",
            },
          },
          {
            element: "#hexview",
            popover: {
              title:
                "<span style='color:#e83e8c; font-weight:bold;'>Hex View</span>",
              description: `<p>This view shows the raw hexadecimal representation of the packet. It is intended for advanced users who need to perform low-level analysis of the packet data.</p>`,
              position: "right",
            },
          },
          {
            element: "#viewbutton",
            popover: {
              title:
                "<span style='color:#fd7e14; font-weight:bold;'>View Buttons</span>",
              description: `<p>Use these buttons to switch between different data representations. You can toggle between graphical displays, detailed connections, and the standard packet view to best suit your analysis needs.</p>`,
              position: "top",
            },
          },
        ]);
  
        // Start the tour.
        this.driver.drive();
      },
    },
  };
  </script>
  