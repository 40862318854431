<template>
  <div class="userprofile">
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm Account Deletion</v-card-title>
        <v-card-text>
          Are you sure you want to delete your account? This action is
          irreversible.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelDelete">Cancel</v-btn>
          <v-btn color="red" text @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-row v-if="!user" class="justify-center align-center gap-4">
        <v-progress-circular
          indeterminate
          color="primary"
          class="my-5"
        ></v-progress-circular>
        <div class="loading-message">Fetching updated user data</div>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="6" offset-sm="3">
          <v-card v-if="user">
            <v-img :src="avatarpic4user(user)" height="300px">
              <v-row class="fill-height">
                <v-card-title>
                  <v-spacer></v-spacer>
                </v-card-title>

                <v-spacer></v-spacer>
              </v-row>
            </v-img>

            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="indigo">person</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.firstname }}
                    <template v-if="user.lastname">{{
                      user.lastname
                    }}</template></v-list-item-title
                  >
                  <v-list-item-subtitle>Name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.motto">
                <v-list-item-action>
                  <v-icon color="indigo">looks</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ user.motto }}</v-list-item-title>
                  <v-list-item-subtitle>Motto</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-icon color="indigo">person</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.username
                    }}<template v-if="user.date_registered">
                      is member since
                      {{ user.date_registered | formatDateMonth }}</template
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle>Username</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.twitter">
                <v-list-item-action>
                  <v-icon color="indigo">public</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <a :href="'https://twitter.com/' + user.twitter">{{
                      user.twitter
                    }}</a>
                  </v-list-item-title>
                  <v-list-item-subtitle>Twitter handle</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.ask">
                <v-list-item-action>
                  <v-icon color="indigo">public</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ user.ask }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Ask Wireshark handle</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.website">
                <v-list-item-action>
                  <v-icon color="indigo">public</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <a :href="getwebsite()">{{ user.website }}</a>
                  </v-list-item-title>
                  <v-list-item-subtitle>Website</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item v-if="user.ratelimiting">
                <v-list-item-action>
                  <v-icon color="indigo">monetization_on</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    v-if="user.ratelimiting.subscription == 'lowtieryearly'"
                    ><b>1 year access</b></v-list-item-title
                  >
                  <v-list-item-title
                    v-else-if="
                      user.ratelimiting.subscription == 'lowtiermonthly'
                    "
                    ><b>Subscription: Monthly</b></v-list-item-title
                  >
                  <v-list-item-title
                    v-else-if="
                      user.ratelimiting.subscription == 'lowtieryearlybundle'
                    "
                    ><b>1 year access bundle</b></v-list-item-title
                  >

                  <v-list-item-title v-else
                    >Subscription:
                    {{ user.ratelimiting.subscription }}</v-list-item-title
                  >
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.deactivation_date">
                <v-list-item-action>
                  <v-icon color="indigo">list</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <template
                      v-if="user.ratelimiting.subscription === 'lowtiermonthly'"
                    >
                      Next payment due:
                      {{ user.deactivation_date | formatDateOnly }}
                    </template>
                    <template v-else>
                      Plan expiry date:
                      {{ user.deactivation_date | formatDateOnly }}
                      <b v-if="!hasSubscription(user)">
                        (plan expired,
                        <router-link to="/pricing">buy new access</router-link>)
                      </b>
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.countpublicpcaps">
                <v-list-item-action>
                  <v-icon color="indigo">spa</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.countpublicpcaps }} Public /
                    {{ user.counttotalpcaps }} Total PCAPs</v-list-item-title
                  >
                  <v-list-item-subtitle>PCAPs</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.countpublicindexes">
                <v-list-item-action>
                  <v-icon color="indigo">list</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.countpublicindexes }} Public /
                    {{ user.counttotalindexes }} Total
                    Indexes</v-list-item-title
                  >
                  <v-list-item-subtitle>Indexes</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.countpublicanalyses">
                <v-list-item-action>
                  <v-icon color="indigo">dvr</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.countpublicanalyses }} Public /
                    {{ user.counttotalanalyses }} Total
                    Analyses</v-list-item-title
                  >
                  <v-list-item-subtitle>Analysis</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item v-if="user.current_usage">
                <v-list-item-action>
                  <v-icon color="indigo">list</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.current_usage.current_month_indexing }} /
                    {{
                      formatDivisor(user.ratelimiting.max_indexing_per_month)
                    }}
                    indexing operations per month</v-list-item-title
                  >
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.current_usage">
                <v-list-item-action>
                  <v-icon color="indigo">cloud_upload</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.current_usage.current_month_uploaded_files }} /
                    {{ formatDivisor(user.ratelimiting.max_files_per_month) }}
                    uploads per month</v-list-item-title
                  >
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.current_usage">
                <v-list-item-action>
                  <v-icon color="indigo">cloud_download</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.current_usage.current_month_downloaded_files }} /
                    {{
                      formatDivisor(user.ratelimiting.max_downloads_per_month)
                    }}
                    downloads per month</v-list-item-title
                  >
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.current_usage">
                <v-list-item-action>
                  <v-icon color="indigo">dynamic_feed</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.current_usage.currently_total_files }} /
                    {{ formatDivisor(user.ratelimiting.max_files_total) }}
                    maxmium total files</v-list-item-title
                  >
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.current_usage">
                <v-list-item-action>
                  <v-icon color="indigo">storage</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.current_usage.currently_user_storage }} MB /
                    {{ formatDivisor(user.ratelimiting.max_storage_total) }} MB
                    maxmium storage</v-list-item-title
                  >
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="user.ratelimiting">
                <v-list-item-action>
                  <v-icon color="indigo">create_new_folder</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{
                      formatDivisor(user.ratelimiting.max_upload_file_size)
                    }}
                    MB max upload file size</v-list-item-title
                  >
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item v-if="user.countpubliccomments">
                <v-list-item-action>
                  <v-icon color="indigo">comment</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title
                    >{{ user.countpubliccomments }} Comments</v-list-item-title
                  >
                  <v-list-item-subtitle>Comment</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item v-if="user.location">
                <v-list-item-action>
                  <v-icon color="indigo">location_on</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ user.location }}</v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
  
              <!-- Subscription Management Links (if an active subscription exists) -->
              <v-list-item v-if="hasActiveSubscription">
                <v-list-item-action>
                  <v-icon color="indigo">payment</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Manage Subscription</v-list-item-title>
                  <v-list-item-subtitle>
  
                    <div
                      
                      class="d-flex"
                    >
             
            
                      <v-btn
                        v-if="activeSubscription.management_urls.cancel"
                        :href="activeSubscription.management_urls.cancel"
                        target="_blank"
                        color="error"
                        small
                        class="mr-2"
                      >
                        Cancel Subscription
                      </v-btn>
                      <v-btn
                        v-if="activeSubscription.management_urls.update_payment_method"
                        :href="activeSubscription.management_urls.update_payment_method"
                        target="_blank"
                        color="primary"
                        small
                      >
                        Update Payment Method
                      </v-btn>
                    </div>
             
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- Delete Account Button -->
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="red">delete</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-alert color="red lighten-2" dark v-if="hasActiveSubscription">
                      You need to cancel your subscriptions first 
                    </v-alert>

                    <v-btn
                      color="red"
                      small
                      :disabled="hasActiveSubscription"
                      @click="openDeleteDialog"
                    >
                      Delete Account
                    </v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import ApiUser from "@/common/api/user";
import { commonmethods } from "@/common/common";
import { permissionchecks } from "@/common/permissions";

export default {
  props: {
    username: {
      type: String,
      default: "public",
    },
  },
  data() {
    return {
      user: null,
      deleteDialog: false,

      subscriptionLinks: [], // holds the management links from the API
    };
  },
  mixins: [permissionchecks, commonmethods],
  computed: {
    // Returns the first active subscription object (if any)
    activeSubscription() {

      return this.subscriptionLinks.find((sub) => sub.status === "active");
    },
    // Returns true if an active subscription exists
    hasActiveSubscription() {
      return !!this.activeSubscription;
    },
  },
  mounted() {
    /*let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://raw.githubusercontent.com/cure53/DOMPurify/master/dist/purify.min.js')
      document.head.appendChild(recaptchaScript)*/

    this.loaduser();
    this.loadSubscriptions();
  },
  components: {},
  name: "userprofile",
  methods: {
    formatDivisor(val) {
      if (val == -1) {
        return "∞";
      }
      return val;
    },
    validURL: function(str) {
      var parser = document.createElement("a");
      parser.href = str;

      if (parser.protocol == "http:" || parser.protocol == "https:") {
        return true;
      }
      return false;
    },
    getwebsite: function() {
      // Note we assure this is a valid url when the user updates on the serverside
      if (this.validURL(this.user.website)) {
        return this.user.website;
      } else {
        return "/";
      }
      //var clean = DOMPurify.sanitize(this.user.website)
    },
    loaduser: function() {
      setTimeout(() => {
        ApiUser.getprofile(this.username)
          .then(({ data }) => {
            this.user = data;
          })
          .catch(({ response }) => {})
          .finally(() => {
            this.isLoading = false;
          });
      }, 3000);

      // ApiUser.getprofile(this.username)
      //   .then(({ data }) => {
      //     this.user = data;
      //   })
      //   .catch(({ response }) => {});
    },
    openDeleteDialog() {
      // If an active subscription exists, show an alert; otherwise, open the deletion dialog.
      if (this.hasActiveSubscription) {
        this.alertMessage =
          "You need to cancel your subscriptions first before deleting your account";
        this.alertSnackbar = true;
      } else {
        this.deleteDialog = true;
      }
    },
    cancelDelete() {
      this.deleteDialog = false;
    },
    confirmDelete() {
      this.deleteDialog = false;
      // Call the API to delete the account
      ApiUser.delete()
        .then(() => {
          this.$router.push("/deleted");
        })
        .catch((error) => {
          console.error("Error deleting account:", error);
        });
    },
    loadSubscriptions() {
      // Call the API to fetch subscription data
      ApiUser.subscription_management()
        .then(({ data }) => {
          // The API returns an array of subscription objects under "subscriptions"
          this.subscriptionLinks = data.subscriptions || [];
        })
        .catch((error) => {
          console.error("Error loading subscription links:", error);
        });
    },
  },
};
</script>

<style scoped>
.loading-indicator {
  margin-bottom: 16px; /* Space between the indicator and the message */
}
</style>
