<template>
  <div></div>
</template>

<script>
import { driver as Driver } from "driver.js/dist/driver.js.cjs";
import "driver.js/dist/driver.css";
import { mapGetters } from "vuex";
export default {
  name: "Tour",
  data() {
    return {
      driver: null,
    };
  },

  computed: {
    ...mapGetters(["currentAnalysis"]),
  },
  mounted() {
    this.checkRouteAndStartTour();
  },

  methods: {
    async checkRouteAndStartTour() {
      this.driver = Driver({
        animate: true,
        opacity: 0.75,
        allowClose: false,
        onDestroyed: () => {
          localStorage.setItem("autoaitourCompleted", "true");
        },
      });
      this.startTour();
    },
    startTour() {
      this.driver.setSteps([
      {

          popover: {
            title: "<span style='font-weight:bold;'>Welcome to PacketSafari Copilot</span>",
            description:
              "In this short tour we will guide you through the main features of the PacketSafari Copilot AI.",
            position: "right",
          },
        },
        {
          element: "#packetlist",
          popover: {
            title: "<span style='font-weight:bold;'>Packet List</span>",
            description:
              "This section displays all the captured packets. You need to select the packets that you want the AI to analyze. Use <kbd>Shift</kbd> for consecutive selection or <kbd>Ctrl</kbd>/<kbd>Command</kbd> for non‑sequential selection. Use CTRL + A to select all packets.",
            position: "right",
          },
        },
        {
          element: "#conversationstarters",
          popover: {
            title:
              "<span style='font-weight:bold;'>Conversation starters</span>",
            description:
              "After selecting the packets click any of the conversation starters to start the AI analysis.",
            position: "right",
          },
        },
        {
          element: "#aichatinput",
          popover: {
            title: "<span style='font-weight:bold;'>Custom message</span>",
            description:
              "If the predefined starters do not suit your needs, you can type your own prompt here and click the send button to start the AI analysis.",
            position: "right",
          },
        },
        {
          element: "#guideancebubble",
          popover: {
            title: "<span style='font-weight:bold;'>Guidance</span>",
            description:
              "Packet data can be extensive, we try our best to pass as much of the data of the packets you selected to the AI but there are hard limits. In case you selected to many packets we will inform you here. Selecting less packets or just the interesting ones or tuning the advanced settings might help.",
            position: "right",
          },
        },

        {
          element: "#advancedsettings",
          popover: {
            title: "<span style='font-weight:bold;'>Advanced settings</span>",
            description:
              "Click the gear icon to tune advanced settings. By default the app tries to be as useful as possible but in some cases you might want to tweak the settings.",
            position: "right",
          },
        },

        {
          element: "#resetchat",
          popover: {
            title: "<span style='font-weight:bold;'>Reset chat</span>",
            description:
              "We use LLMs to process your data in the background. Occasionally, responses may be incomplete or unsatisfactory. If this happens, restarting the conversation can provide a fresh analysis, often leading to significantly better results than simply continuing the current chat.",
            position: "left",
          },
        },
  
      ]);

      // Start the tour.
      this.driver.drive();
    },
  },
};
</script>
