<template>
  <div class="relative isolate overflow-hidden bg-white">
    <!-- Background pattern -->
    <svg
      class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="hero-pattern"
          width="200"
          height="200"
          x="50%"
          y="-1"
          patternUnits="userSpaceOnUse"
        >
          <path d="M.5 200V.5H200" fill="none" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" stroke-width="0" fill="url(#hero-pattern)" />
    </svg>

    <!-- Main Hero Section -->
    <div class="mx-auto max-w-8xl px-6 py-20 sm:py-24 lg:py-12">
      <div class="lg:flex lg:items-center lg:justify-between">
        <!-- Left Side: Text Content -->
        <div class="mx-auto max-w-3xl text-center lg:text-left">
          <!-- App Logo -->
          <img
            src="/images/packetsafari-title-logo.png"
            alt="Packetsafari Logo"
            class="mx-auto lg:mx-0 mb-8 max-w-xs"
          />

          <!-- Headline -->
          <h1 class="text-4xl sm:text-5xl md:text-6xl font-extrabold tracking-tight text-gray-900">
            PacketSafari PCAP Analyser
          </h1>
          <p class="mt-6 text-xl text-gray-600">
            Now with AI-assisted packet analysis as a premium feature.
          </p>
          <p class="mt-4 text-lg text-gray-500">
            Analyze packet traces without installing Wireshark, select specific packets, and chat with our AI
            (PacketSafari Copilot) for instant insights.
          </p>

          <!-- Call-to-Action Buttons -->
          <div class="mt-10 flex flex-col sm:flex-row sm:justify-center lg:justify-start gap-4">
            <button
              @click="triggerUpload"
              class="inline-flex items-center justify-center rounded-md bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Explore Copilot
            </button>
            <button
              @click="watchVideo"
              class="inline-flex items-center justify-center rounded-md border border-indigo-600 px-6 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Watch 1 Minute Video
            </button>
          </div>
        </div>

        <!-- Right Side: Larger App Image -->
        <div class="mt-12 lg:mt-0 lg:ml-10 flex justify-center">
          <img
            src="/packetsafari-app.png"
            alt="Packetsafari App"
            class="w-full max-w-4xl rounded-md shadow-2xl ring-1 ring-gray-900/10"
          />
        </div>
      </div>
    </div>

    <!-- Feature Section (Separate Row) -->
    <div class="mx-auto max-w-7xl px-6 mt-36 pb-16">
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-8">
        <!-- Feature 1 -->
        <div class="flex flex-col items-center">
          <svg
            class="h-10 w-10 text-indigo-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h4l3-7 4 14 3-7h4" />
          </svg>
          <p class="mt-3 text-base font-medium text-gray-700 text-center">
            No need for Wireshark installation
          </p>
        </div>

        <!-- Feature 2 -->
        <div class="flex flex-col items-center">
          <a href="/copilot" class="flex flex-col items-center transition-colors hover:text-gray-600">
            <img
              src="/openailogo.png"
              alt="AI Icon"
              class="w-12 h-12 object-contain animate-pulse"
              style="filter: brightness(0.8);"
            />
            <p class="mt-3 text-base font-medium text-gray-700 text-center">
              <span class="block text-xl font-bold">PacketSafari Copilot</span>
              <span class="text-sm">(ChatGPT for Packets)</span>
            </p>
          </a>
        </div>

        <!-- Feature 3 -->
        <div class="flex flex-col items-center">
          <svg
            class="h-10 w-10 text-indigo-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a4 4 0 00-4-4H6a4 4 0 00-4 4v2h5" />
            <circle cx="12" cy="7" r="4" stroke="currentColor" stroke-width="2" fill="none" />
          </svg>
          <p class="mt-3 text-base font-medium text-gray-700 text-center">
            Free up to 1MB
          </p>
        </div>
      </div>
    </div>

    <!-- Video Modal -->
    <transition name="fade">
      <div
        v-if="showVideoDialog"
        class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75"
      >
        <div class="bg-white rounded-lg overflow-hidden shadow-xl max-w-3xl w-full">
          <!-- Modal Header -->
          <div class="flex items-center justify-between bg-indigo-600 px-4 py-3">
            <h3 class="text-lg font-medium text-white">
              PacketSafari Copilot Demo Video
            </h3>
            <button @click="closeDialog" class="text-white hover:text-gray-200 focus:outline-none">
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <!-- Modal Content -->
          <div class="p-0">
            <video
              ref="video"
              src="/packetsafari-copilot-4k.mp4"
              controls
              autoplay
              class="w-full h-96 object-cover"
            ></video>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showVideoDialog: false,
    };
  },
  methods: {
    triggerUpload() {
      this.$router.push("/copilot")
      // this.$emit("tryitnow");
    },
    watchVideo() {
      this.showVideoDialog = true;
      this.$nextTick(() => {
        const videoEl = this.$refs.video;
        if (videoEl) {
          videoEl.pause();
          videoEl.currentTime = 0;
          videoEl.play();
        }
      });
    },
    closeDialog() {
      this.showVideoDialog = false;
    },
  },
};
</script>

<style scoped>
/* Fade transition for the modal */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
