<template>
  <div class="analysis">
    <v-container fluid class="pa-6">
      <div class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <h1 class="text-4xl font-bold mb-4">Analysis Overview</h1>
      <p class="text-lg mb-4">
        Analyses are interactive walkthroughs of PCAP files, allowing you to animate, annotate, and explore network traffic in a dynamic and engaging way. These analyses can be shared with others, providing a collaborative and insightful experience.
      </p>
    </div>
  </div>
</div>

    </v-container>

    <v-row class="mt-5">
      <v-col cols="12">
        <v-row dense v-for="a in analysis" v-if="found" :key="a.id">
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-h5">
                <router-link
                  :to="{
                    name: 'User Profile',
                    params: {
                      username: a.permissions.permissions_owner[0].username,
                    },
                  }"
                >
                  <v-avatar class="mr-4" size="36">
                    <img :src="avatarpic4user(a)" />
                  </v-avatar>
                </router-link>
                {{ a.name }} of {{ a.pcap_origname }} by
                {{ a.permissions.permissions_owner[0].username }}
              </v-card-title>

              <v-card-text>
                Description
                <div
                  class="pl-4 mb-2 subtitle-1 text--primary"
                  v-if="a.description"
                >
                  {{ a.description | truncate(800, "...") }}
                </div>
                <div class="pl-4 mb-2" v-else>No description</div>

                <template v-if="a.analysissteps.length > 10">
                  Analysis steps (showing first 10 of
                  {{ a.analysissteps.length }})
                </template>
                <template v-else>
                  Analysis steps ({{ a.analysissteps.length }})
                </template>
                <div class="pt-2">
                  <div
                    class="pl-3"
                    v-for="step in a.analysissteps.slice(0, 10)"
                    :key="step.timestamp"
                  >
                    <template v-if="step.packetnum && step.action == 'goto'">
                      <v-btn
                        color="primary"
                        text
                        x-small
                        class="ma-1"
                        :to="{
                          name: 'Analysis',
                          params: {
                            id: a.pcapid,
                            analysisid: a.id,
                            view: 'l',
                            filter: 'frame.number == ' + step.packetnum,
                          },
                        }"
                        >Frame {{ step.packetnum }}</v-btn
                      >
                    </template>
                    <template v-else-if="step.filter">
                      <v-btn
                        color="primary"
                        text
                        x-small
                        class="ma-1"
                        :to="{
                          name: 'Analysis',
                          params: {
                            id: a.pcapid,
                            analysisid: a.id,
                            filter: step.filter,
                          },
                        }"
                        >Filter for {{ step.filter }}</v-btn
                      >
                    </template>

                    <template v-else>
                      <v-btn
                        color="primary"
                        text
                        x-small
                        class="ma-1"
                        :to="{
                          name: 'Analysis',
                          params: {
                            id: a.pcapid,
                            analysisid: a.id,
                          },
                        }"
                        >{{ step.action }}</v-btn
                      >
                    </template>

                    {{ step.description }}
                  </div>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  color="primary"
                  class="ma-2"
                  v-if="a.index !== undefined && a.index != ''"
                  :to="{
                    name: 'Analysis',
                    params: {
                      id: a.pcapid,
                      analysisid: a.id,
                    },
                  }"
                  >View</v-btn
                >
                <v-btn
                  small
                  color="primary"
                  class="ma-2"
                  v-else
                  :to="{
                    name: 'Analysis',
                    params: {
                      id: a.pcapid,
                      analysisid: a.id,
                    },
                  }"
                  >View</v-btn
                >
                <template
                  v-if="
                    currentUser &&
                      a.permissions.permissions_owner[0].username ==
                        currentUser.username
                  "
                >
                  <v-tooltip bottom v-if="a.permissions.permissions_public">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        small
                        class="ma-2"
                        v-on="on"
                        slot="activator"
                        @click="togglePublic(a)"
                        >Unpublish
                        <v-icon small class="ml-2">cloud</v-icon>
                      </v-btn>
                    </template>
                    <span>Analysis is public, click to deny public access</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        color="primary"
                        v-on="on"
                        class="ma-2"
                        slot="activator"
                        @click="togglePublic(a)"
                      >
                        Publish
                        <v-icon small class="ml-2">lock</v-icon>
                      </v-btn>
                    </template>

                    <span
                      >Analysis is private, click to allow public access</span
                    >
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        v-on="on"
                        slot="activator"
                        class="ma-2"
                        color="error"
                        @click="deleteAnalysis(a)"
                      >
                        delete
                      </v-btn>
                    </template>
                    <span>Delete this analysis</span>
                  </v-tooltip>
                </template>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <error v-else error="Nothing found">
          <template slot="actions" class="pt-5">
            <!-- <v-btn
              :to="{
                name: 'Analysis',
                params: {
                  id: 'sample-pcap',
                  analysisid: 'new',
                },
              }"
              >Try it here</v-btn
            > -->
          </template>
        </error>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Error from "./Error";
import ApiAnalysisrecipe from "@/common/api/analysisrecipe";
import { commonmethods } from "@/common/common";

import { mapGetters } from "vuex";

export default {
  components: { Error },
  props: {
    type: String,
  },
  name: "analysislist",
  mixins: [commonmethods],
  data() {
    return {
      found: true,
      analysis: [],
    };
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length <= length) return text;
      return text.substring(0, length) + suffix;
    },
  },
  mounted: function() {
    this.fetchAnalyses();
  },
  computed: {
    ...mapGetters(["currentUser", "avatarPic"]),
  },
  methods: {
    fetchAnalyses: function() {
      ApiAnalysisrecipe.list({ type: "private" }).then(({ data }) => {
        this.analysis.push(...data);
        if (data.length == 0) {
          this.found = false;
        }
      });
    },
    deleteAnalysis: function(a) {
      ApiAnalysisrecipe.delete(a.id)
        .then(() => {
          this.analysis.splice(this.analysis.indexOf(a), 1);
        })
        .catch(() => {
          this.analysis.splice(this.analysis.indexOf(a), 1);
        });
    },
    togglePublic: function(a) {
      if (a.permissions.permissions_public) {
        this.lockAnalysis(a);
      } else {
        this.publishAnalysis(a);
      }
    },
    publishAnalysis: function(a) {
      ApiAnalysisrecipe.publish(a).then(() => {
        this.errormsg = "";
        a.permissions.permissions_public = true;
      });
    },
    lockAnalysis: function(a) {
      ApiAnalysisrecipe.lock(a).then(() => {
        this.errormsg = "";
        a.permissions.permissions_public = false;
      });
    },
  },
};
</script>

<style scoped></style>
