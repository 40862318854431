<template>
  <div v-if="handler">
    <v-card class="ma-5">
      <v-card-title>
        {{ handler.name }}
      </v-card-title>
      <v-card-text class="pl-5 pr-5">
        <!-- Switch for grouping data -->
        <v-switch class="pa-0" v-model="groupTable" label="Group data"></v-switch>
        <!-- AG Grid Component -->
        <ag-grid-vue
          style="width: 100%; height: 100%"
          :class="theme"
          :gridOptions="gridOptions"
          id="tapstatsgrid"
        ></ag-grid-vue>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { AGGRID_LICENSE } from "@/common/config";

// Set the AG Grid license key
LicenseManager.setLicenseKey(AGGRID_LICENSE);

// Import API and helper modules
import ApiPacketlist from "@/common/api/packetlist";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";
import { sharkdhelper } from "@/common/sharkdhelper";
import { conversations } from "./conversations";
import { endpoints } from "./endpoints";
import { serviceresponsetime } from "./serviceresponsetime";
import { statstap } from "./statstap";
import { exportobjects } from "./exportobjects";
import { seqa } from "./seqa";
import { expertinfo } from "./expertinfo";
import { rtp } from "./rtp";

export default {
  name: "taptable",
  props: {
    value: null,
    pcapid: null,
  },
  components: {
    AgGridVue,
  },
  mixins: [
    displayfilter,
    sharkdhelper,
    conversations,
    endpoints,
    serviceresponsetime,
    statstap,
    exportobjects,
    seqa,
    expertinfo,
    rtp,
  ],
  data() {
    return {
      type: null,
      statistics: null,
      handler: null,
      aggridhandler: {},
      groupTable: false,
      gridOptions: {
        domLayout: "autoHeight",
        context: {
          parent: this,
        },
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        rowSelection: "single",
        rowGroupPanelShow: "always",
        // Define custom context menu items
        getContextMenuItems: this.mainGetContextMenuItems,
        columnDefs: [],
        getDataPath: this.getDataPath,
        getMainMenuItems: function (params) {}.bind(this),
        onGridReady: function (params) {
          this.mainFullUpdate();
        }.bind(this),
        onRowDataChanged: function (params) {}.bind(this),
        onRowClicked: function (params) {}.bind(this),
        onRowSelected: function (params) {}.bind(this),
      },
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
    theme() {
      return this.currentAnalysis.profiles.selected.theme
        ? "ag-theme-balham-dark"
        : "ag-theme-balham";
    },
  },
  watch: {
    groupTable(newVal, oldVal) {
      this.mainRefreshView();
    },
    value(newVal, oldVal) {
      // Prevent unnecessary updates if the tap has not changed
      if (newVal.tap === oldVal.tap) {
        return;
      }
      this.statistics = newVal;
      this.type = this.statistics.tap;
      this.handler = this.selectHandler();
      this.mainFullUpdate();
    },
  },
  mounted() {
    this.statistics = this.value;
    this.type = this.statistics.tap;
    this.handler = this.selectHandler();
    // Uncomment the line below if you want an initial full update on mount.
    // this.mainFullUpdate();
  },
  methods: {
    /**
     * Downloads the object using the provided token.
     */
    download_object(token) {
      const params = {
        token: token,
        pcapid: this.pcapid,
      };

      ApiPacketlist.exportobject(params)
        .then(({ data }) => {
          this.stats = data["taps"][0];
        })
        .catch(() => {
          // Handle error accordingly
        });
    },
    /**
     * Generates custom context menu items based on the row data.
     */
    mainGetContextMenuItems(params) {
      const currentItems = params.defaultItems.slice(0);
      const result = [];

      // Add filter option if available
      if ("filter" in params.node.data) {
        result.push({
          name: "Filter Conversation",
          action: () => {
            const filterOption = params.node.data["filter"];
            this.df_apply_to_packetlist(filterOption);
            this.currentAnalysis.showTapstatistics = false;
          },
          cssClasses: ["redFont", "bold"],
        });
      }

      // Add IP lookup option for specific columns
      const ipKey = ["saddr", "daddr", "host"].find(
        (key) => key === params.column.colId
      );
      if (ipKey !== undefined) {
        const ip = params.node.data[ipKey];
        result.push({
          name: `Lookup: ${ip}`,
          action: () => {
            const url = `https://whatismyipaddress.com/ip/${ip}`;
            window.open(url, "_blank");
          },
          cssClasses: ["redFont", "bold"],
        });
      }

      // Add download option if available
      if ("_download" in params.node.data) {
        result.push({
          name: "Download",
          action: () => {
            this.download_object(params.node.data["_download"]);
          },
          cssClasses: ["redFont", "bold"],
        });
      }

      // Prepend custom items to the default context menu
      result.forEach((item) => currentItems.unshift(item));
      return currentItems;
    },
    /**
     * Performs a full update of the grid, including refreshing columns and rows.
     */
    mainFullUpdate() {
      // Refresh column definitions and row data
      this.gridOptions.api.setColumnDefs(
        this.handler.getColumnDefs(this.statistics, this.groupTable)
      );
      this.gridOptions.api.setRowData(
        this.handler.formatData(this.statistics)
      );
      this.gridOptions.api.sizeColumnsToFit();
    },
    /**
     * Refreshes the grid view when only minor changes are needed.
     */
    mainRefreshView() {
      this.gridOptions.api.setColumnDefs(
        this.handler.getColumnDefs(this.statistics, this.groupTable)
      );
      this.gridOptions.api.sizeColumnsToFit();
    },
    /**
     * Retrieves the data path for tree data if applicable.
     */
    getDataPath(data) {
      if (this.handler) {
        return this.handler.getDataPath(data);
      }
    },
    /**
     * Selects the correct handler based on the tap type.
     */
    selectHandler() {
      const myType = this.type.split(":")[0];
      if (myType in this.aggridhandler) {
        const h = this.aggridhandler[myType];
        if ("isTreeData" in h && h.isTreeData) {
          this.gridOptions.treeData = true;
        }
        return h;
      }
      return null;
    },
  },
};
</script>

<style>
#tapstatsgrid .ag-root-wrapper-body {
  min-height: 500px;
}
</style>
