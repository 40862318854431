<template>
  <v-row class="mx-7 mt-3 mb-3">
    <v-container fluid class="pa-6">
      <v-row justify="center">
        <v-col cols="12" md="8" class="text-center">
          <h1 class="display-2 font-weight-bold mb-4">Network Connections Dashboard</h1>
          <p class="subtitle-1">
            Welcome to the comprehensive Network Connections Dashboard. Here you can explore, analyze, and export detailed information about your network traffic captured in PCAP files. The dashboard includes multiple interactive views—ranging from detailed connection tables to timeline visualizations, chord diagrams, and protocol word clouds. Use the intuitive filters and export functionality to gain valuable insights into your network performance and security.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <!-- Capture Point Graph Dialog -->
    <v-dialog v-model="showCapturePoint" max-width="1200">
      <v-card>
        <v-card-title class="headline">
          <v-icon left color="primary">mdi-chart-line</v-icon>
          Capture Point Graph per Connection
        </v-card-title>
        <v-card-text>
          This graph represents connection capture points based on TTL, MAC addresses, and RTT during handshake.
          It provides insight into network performance and patterns. Note that the client refers to the TCP layer client
          (i.e. the one sending the SYN) which may differ from the layer 7 client.
        </v-card-text>
        <capturepointgraph
          v-if="selectedConnection"
          :pcapid="currentAnalysis.pcapid"
          :connection="selectedConnection">
        </capturepointgraph>
        <handshakegraph
          v-if="selectedConnection"
          :pcapid="currentAnalysis.pcapid"
          :connection="selectedConnection">
        </handshakegraph>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showCapturePoint = false">
            Close <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- TCP Stream Graph Dialog -->
    <v-dialog v-model="showTcpGraph" max-width="1200">
      <v-card>
        <v-card-title class="headline">
          <v-icon left color="primary">mdi-chart-bell-curve-cumulative</v-icon>
          TCP Stream Graph
        </v-card-title>
        <v-card-text>
          This graph provides a detailed analysis of the TCP stream, including SYN, ACK, and FIN sequences.
          It helps in visualizing the state and progression of TCP connections.
        </v-card-text>
        <tcptrace
          v-if="selectedConnection"
          :pcapid="currentAnalysis.pcapid"
          :connection="selectedConnection">
        </tcptrace>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showTcpGraph = false">
            Close <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Tabbed Views: Connections, Timeline, Chord Diagram, and Protocol Word Cloud -->
    <v-col cols="12" class="mt-8">
      <v-tabs v-model="activeTab" background-color="grey lighten-4" grow>
        <v-tab>
          <v-icon left>mdi-connection</v-icon>
          Connections
        </v-tab>
        <v-tab>
          <v-icon left>mdi-timeline-text-outline</v-icon>
          Timeline
        </v-tab>
        <v-tab>
          <v-icon left>mdi-sitemap</v-icon>
          Chord Diagram
        </v-tab>
        <v-tab>
          <v-icon left>mdi-cloud</v-icon>
          Protocol Word Cloud
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <!-- Tab 0: Connections Data Table -->
        <v-tab-item>
          <v-card class="mx-auto">
            <v-card-title>
              <v-icon left color="primary">mdi-connection</v-icon>
              {{ type | capitalize }} Connections in PCAP
            </v-card-title>
            <v-card-subtitle>
              Overview of network connections captured in the PCAP file.
              Click on any field or icon for a detailed packet view.
            </v-card-subtitle>
            <v-card-text>
              <v-data-table
                class="connections elevation-1"
                :headers="headers"
                :items="connections"
                :hide-default-footer="totalItems < 100"
                :options.sync="tableOptions"
                disable-pagination>
                <template slot="item" slot-scope="props">
                  <tr>
                    <!-- Source MAC -->
                    <td v-if="props.item.src.eth">
                      <a
                        style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'eth.src == ' + props.item.src.eth, null)">
                        {{ props.item.src.eth }}
                      </a>
                    </td>

                    <!-- Source IP -->
                    <td v-if="props.item.src.ip" @contextmenu="contextMenu($event, props.item)">
                      <a
                        style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'ip.src == ' + props.item.src.ip, null)">
                        {{ props.item.src.ip }}
                      </a>
                    </td>

                    <!-- Source Port -->
                    <td v-if="props.item.src.port">
                      <a
                        style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'tcp.port == ' + props.item.src.port, null)">
                        {{ props.item.src.port }}
                      </a>
                    </td>

                    <!-- Destination MAC -->
                    <td v-if="props.item.dst.eth">
                      <a
                        style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'eth.dst == ' + props.item.dst.eth, null)">
                        {{ props.item.dst.eth }}
                      </a>
                    </td>

                    <!-- Destination IP -->
                    <td v-if="props.item.dst.ip">
                      <a
                        style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'ip.dst == ' + props.item.dst.ip, null)">
                        {{ props.item.dst.ip }}
                      </a>
                    </td>

                    <!-- Destination Port -->
                    <td v-if="props.item.dst.port">
                      <a
                        style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', 'tcp.port == ' + props.item.dst.port, null)">
                        {{ props.item.dst.port }}
                      </a>
                    </td>

                    <!-- TCP Flags -->
                    <td v-if="props.item.flags">
                      <v-tooltip top v-if="lookupFlagDef(props.item.flags)">
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            style="text-decoration: none; color: inherit;"
                            @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', getFilter(props.item, type), null)">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                :color="lookupFlagDef(props.item.flags).color"
                                :class="lookupFlagDef(props.item.flags).class">
                                {{ lookupFlagDef(props.item.flags).icon }}
                              </v-icon>
                            </v-btn>
                          </a>
                        </template>
                        <span>
                          {{ lookupFlagDef(props.item.flags).tooltip }} (Flags: {{ props.item.flags }})
                        </span>
                      </v-tooltip>
                    </td>

                    <!-- Packets Over Time -->
                    <td v-if="props.item.packets_over_time">
                      <a
                        style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', getFilter(props.item, type), null)">
                        <pcapsparkline :packetsovertime="props.item.packets_over_time"></pcapsparkline>
                      </a>
                    </td>
                    <td v-else class="py-4"></td>

                    <!-- Aggregated Data -->
                    <td>{{ props.item.total.doc_count }}</td>
                    <td>{{ props.item.total.cummulative_bytes }}</td>
                    <td>{{ props.item.src.doc_count }}</td>
                    <td>{{ props.item.src.cummulative_bytes }}</td>
                    <td>{{ props.item.dst.doc_count }}</td>
                    <td>{{ props.item.dst.cummulative_bytes }}</td>
                    <td>{{ props.item.total.delta | formatDelta }}</td>

                    <!-- Throughput columns for TCP -->
                    <td v-if="activeType === 'tcp'">{{ props.item.thptSrc }}</td>
                    <td v-if="activeType === 'tcp'">{{ props.item.thptDst }}</td>

                    <!-- Custom Columns -->
                    <td v-for="c in getCustomColumns()" :key="c.name">
                      <a
                        style="text-decoration: none; color: inherit;"
                        @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', '(' + getFilter(props.item, type) + ') and ' + c.field, null)">
                        <v-chip
                          v-if="c.field === 'tcp.analysis.retransmission'"
                          color="red lighten-1"
                          small
                          ripple>
                          {{ props.item.total[df_wireshark_field_to_es(c.field)] }}
                        </v-chip>
                        <template v-else-if="props.item.total[df_wireshark_field_to_es(c.field)]">
                          {{ props.item.total[df_wireshark_field_to_es(c.field)] }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </a>
                    </td>

                    <!-- Actions -->
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            style="text-decoration: none; color: inherit;"
                            @click.prevent.stop="navigatePacketview(currentAnalysis.pcapid, 'none', getFilter(props.item, type), null)">
                            <v-icon small v-on="on" color="primary">mdi-filter</v-icon>
                          </a>
                        </template>
                        <span>Filter</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="type === 'tcp'">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            class="pl-1"
                            small
                            color="primary"
                            @click="openCapturePointGraph(props.item)">
                            mdi-lan
                          </v-icon>
                        </template>
                        <span>Show Capture Graph</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="type === 'tcp'">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            class="pl-1"
                            small
                            color="primary"
                            @click="openTcpGraph(props.item)">
                            mdi-chart-line
                          </v-icon>
                        </template>
                        <span>Show TCP Graph</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <!-- Advanced Options -->
              <v-expansion-panels v-model="advancedPanel" class="mt-7">
                <v-expansion-panel key="1">
                  <v-expansion-panel-header>
                    <v-icon left color="primary">mdi-tune</v-icon>
                    Advanced Options
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="advancedPanel === 0">
                    <v-row class="ma-7">
                      <!-- Contextual Menu for additional actions -->
                      <v-menu
                        v-model="menu.showMenu"
                        :position-x="menu.x"
                        :position-y="menu.y"
                        absolute
                        offset-y>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in menu.items"
                            :key="index"
                            @click>
                            <v-list-item-title @click="item.action">
                              {{ item.title }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-col cols="12" class="py-2">
                        <v-icon left small>mdi-filter-outline</v-icon>
                        Filter connections based on display filter:
                      </v-col>
                      <v-col cols="8">
                        <filterbox :livemode="true" v-model="displayFilter"></filterbox>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          hide-details
                          v-model="type"
                          :items="endpointTypes"
                          label="Endpoint Type"
                          solo
                          item-text="Type">
                        </v-select>
                      </v-col>
                      <v-col cols="12" class="py-2 mt-5">
                        <v-icon left small>mdi-plus-box</v-icon>
                        Add aggregated fields to connection overview:
                      </v-col>
                      <v-col cols="12" class="ml-5">
                        <v-row
                          class="py-0 mt-0 pt-0"
                          v-for="c in customColumns"
                          :key="c.key">
                          <v-col cols="2" class="py-0 px-1">
                            <v-text-field
                              solo
                              v-model="c.name"
                              label="Column Name"
                              hide-details>
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" class="py-0 px-1">
                            <v-combobox
                              solo
                              hide-details
                              v-model="c.type"
                              :items="types"
                              label="Aggregation Type"
                              item-text="Type">
                            </v-combobox>
                          </v-col>
                          <v-col cols="5" class="py-0 px-4 mr-2">
                            <v-combobox
                              solo
                              hide-details
                              v-model="c.field"
                              :items="indexedColumns"
                              item-text="wiresharkfield"
                              item-value="wiresharkfield"
                              label="Aggregation Field">
                            </v-combobox>
                          </v-col>
                          <v-col cols="1" class="pt-3 px-1">
                            <v-icon color="info" @click="removeColumn(c)">mdi-delete</v-icon>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="8" class="mt-4"></v-col>
                      <v-col cols="2" class="mt-4">
                        <v-btn color="info" @click="addColumn">
                          Add Column
                          <v-icon right>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="2" class="mt-4">
                        <v-btn color="info" @click="getConnectionsCustom">
                          Refresh
                          <v-icon right>mdi-refresh</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="exportConnections">
                <v-icon left>mdi-export</v-icon>
                Export Data
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>

        <!-- Tab 1: Timeline -->
        <v-tab-item>
          <timelinechart
            :pcapid="currentAnalysis.pcapid"
            :connections="connections"
            :ctype="type">
          </timelinechart>
        </v-tab-item>

        <!-- Tab 2: Chord Diagram -->
        <v-tab-item>
          <connectionchord
            :pcapid="currentAnalysis.pcapid"
            :connections="connections"
            :ctype="type">
          </connectionchord>
        </v-tab-item>

        <!-- Tab 3: Protocol Word Cloud -->
        <v-tab-item>
          <protocoldistribution
            :pcapid="currentAnalysis.pcapid"
            :pcap="pcap">
          </protocoldistribution>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import ApiConnections from "@/common/api/connections";
import ApiIndexerProfile from "@/common/api/indexerprofile";
import Filterbox from "./Filterbox";
import Timelinechart from "./Timelinechart";
import Connectionchord from "./Connectionchord";
import Capturepointgraph from "./Capturepointgraph";
import Tcptrace from "./Tcptrace";
import Handshakegraph from "./Handshakegraph";
import { navigation } from "@/common/navigation";
import Pcapsparkline from "./basics/Pcapsparkline";
import Protocoldistribution from "./Protocoldistribution.vue";
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";

export default {
  name: "Connections",
  props: {
    index: null,
    pcapid: null,
    pcap: null
  },
  components: {
    Connectionchord,
    Filterbox,
    Capturepointgraph,
    Tcptrace,
    Handshakegraph,
    Timelinechart,
    Pcapsparkline,
    Protocoldistribution
  },
  mixins: [displayfilter, navigation],
  data() {
    return {
      totalItems: 0,
      tableOptions: { itemsPerPage: 10 },
      advancedPanel: null,
      activeTab: 0,
      selectedConnection: null,
      showCapturePoint: false,
      showTcpGraph: false,
      flagDefs: [
        {
          flagstr: "SR",
          class: "",
          color: "red",
          icon: "mdi-circle-outline",
          tooltip: "Connection handshake was reset"
        },
        {
          flagstr: "SREC",
          class: "",
          color: "red",
          icon: "mdi-circle-outline",
          tooltip: "Connection handshake of connection supporting RFC3168 was reset"
        },
        {
          flagstr: "SF",
          class: "",
          color: "orange",
          icon: "mdi-circle-outline",
          tooltip: "Connection reset was closed"
        },
        {
          flagstr: "S",
          class: "",
          color: "blue",
          icon: "mdi-circle-outline",
          tooltip: "Handshake not answered"
        },
        {
          flagstr: "SA",
          class: "",
          color: "blue",
          icon: "mdi-circle-slice-2",
          tooltip: "Connection in progress"
        },
        {
          flagstr: "SAP",
          class: "",
          color: "blue darken-2",
          icon: "mdi-circle-slice-2",
          tooltip: "Connection in progress with push bit"
        },
        {
          flagstr: "SAPE",
          class: "",
          color: "blue darken-2",
          icon: "mdi-circle-slice-2",
          tooltip: "Connection supporting RFC3168 in progress with push bit"
        },
        {
          flagstr: "SAPEC",
          class: "",
          color: "blue darken-2",
          icon: "mdi-circle-slice-2",
          tooltip: "Connection in progress with push bit and supporting RFC3168"
        },
        {
          flagstr: "AP",
          class: "icon__rotate",
          color: "blue",
          icon: "mdi-circle-slice-3",
          tooltip: "Connection in progress but start and end not in trace"
        },
        {
          flagstr: "AF",
          class: "icon__flip",
          color: "green",
          icon: "mdi-circle-slice-3",
          tooltip: "Connection without handshake closed"
        },
        {
          flagstr: "AR",
          class: "icon__flip",
          color: "red",
          icon: "mdi-circle-slice-3",
          tooltip: "Connection without handshake reset"
        },
        {
          flagstr: "SAF",
          class: "",
          color: "green",
          icon: "mdi-circle-slice-8",
          tooltip: "Complete transfer successful"
        },
        {
          flagstr: "SAPF",
          class: "",
          color: "green",
          icon: "mdi-circle-slice-8",
          tooltip: "Complete transfer successful"
        },
        {
          flagstr: "SAPR",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer completed but closed with reset"
        },
        {
          flagstr: "SAFR",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer completed but connection was reset after FIN"
        },
        {
          flagstr: "SAPFR",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer with PUSH bit completed but connection was reset after FIN"
        },
        {
          flagstr: "SAPFEC",
          class: "",
          color: "green darken-2",
          icon: "mdi-circle-slice-8",
          tooltip: "Connection in progress with push bit"
        },
        {
          flagstr: "SAPREC",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer supporting RFC316 completed but closed with reset"
        },
        {
          flagstr: "SAR",
          class: "",
          color: "red",
          icon: "mdi-circle-slice-8",
          tooltip: "Transfer completed but closed with reset"
        }
      ],
      rpcapid: null,
      rindex: "none",
      indexedColumns: [],
      displayFilter: "",
      types: ["sum", "avg", "min", "max"],
      customColumns: [
        {
          name: "Initial RTT",
          type: "avg",
          field: "tcp.analysis.initial_rtt"
        },
        {
          name: "Retransmissions",
          type: "sum",
          field: "tcp.analysis.retransmission"
        }
      ],
      connections: [],
      cmap: {},
      type: "tcp",
      activeType: "tcp",
      endpointTypes: ["ipv4", "ipv6", "tcp", "udp", "eth"],
      endpointTypeVis: {
        ipv4: { ipv4: true, ipv6: false, ipmerged: false, tcp: false, udp: false, eth: false },
        ipv6: { ipv4: false, ipv6: true, ipmerged: false, tcp: false, udp: false, eth: false },
        tcp:  { ipv4: false, ipv6: false, ipmerged: true, tcp: true, udp: false, eth: false },
        udp:  { ipv4: false, ipv6: false, ipmerged: true, tcp: false, udp: true, eth: false },
        eth:  { ipv4: false, ipv6: false, ipmerged: false, tcp: false, udp: false, eth: true }
      },
      menu: {
        showMenu: false,
        x: 0,
        y: 0,
        items: []
      },
      precision: 2
    };
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
    headers() {
      const h = [];

      switch (this.activeType) {
        case "eth":
          h.push({ text: "Source", value: "src.eth", tooltip: "Source MAC address" });
          h.push({ text: "Destination", value: "dst.eth", tooltip: "Destination MAC address" });
          break;
        case "ipv4":
        case "ipv6":
          h.push({ text: "Source", value: "src.ip", tooltip: "Source IP address" });
          h.push({ text: "Destination", value: "dst.ip", tooltip: "Destination IP address" });
          break;
        case "tcp":
          h.push({ text: "Source", value: "src.ip", tooltip: "Source IP address" });
          h.push({ text: "SrcPort", value: "src.port", tooltip: "Source Port" });
          h.push({ text: "Destination", value: "dst.ip", tooltip: "Destination IP address" });
          h.push({ text: "DstPort", value: "dst.port", tooltip: "Destination Port" });
          h.push({ text: "TCP Flags", value: "flags", tooltip: "TCP Flags" });
          break;
        case "udp":
          h.push({ text: "Source", value: "src.ip", tooltip: "Source IP address" });
          h.push({ text: "Destination", value: "dst.ip", tooltip: "Destination IP address" });
          h.push({ text: "Source Port", value: "src.port", tooltip: "Source Port" });
          h.push({ text: "Destination Port", value: "dst.port", tooltip: "Destination Port" });
          break;
      }

      const defaults = [
        { text: "Graph", value: "flags", tooltip: "Connection Graph / Flags" },
        { text: "Packets", value: "total.doc_count", tooltip: "Total Packets" },
        { text: "Bytes", value: "total.cummulative_bytes", tooltip: "Total Bytes" },
        { text: "Packets S->D", value: "src.doc_count", tooltip: "Packets from Source to Destination" },
        { text: "Bytes S->D", value: "src.cummulative_bytes", tooltip: "Bytes from Source to Destination" },
        { text: "Packets D->S", value: "dst.doc_count", tooltip: "Packets from Destination to Source" },
        { text: "Bytes D->S", value: "dst.cummulative_bytes", tooltip: "Bytes from Destination to Source" },
        { text: "Duration", value: "total.delta", tooltip: "Connection Duration (seconds)" }
      ];

      h.push(...defaults);

      if (this.activeType === "tcp") {
        h.push({ text: "Thpt S->D", value: "thptSrc", tooltip: "Throughput from Source to Destination" });
        h.push({ text: "Thpt D->S", value: "thptDst", tooltip: "Throughput from Destination to Source" });
      }

      for (const c of this.customColumns) {
        h.push({
          text: c.name,
          value: "total." + this.df_wireshark_field_to_es(c.field),
          tooltip: c.name
        });
      }

      h.push({ text: "Actions", value: null, tooltip: "Available actions" });
      h.push({ text: "", value: "data-table-expand", tooltip: "" });

      return h;
    }
  },
  watch: {
    type(newVal, oldVal) {
      if (newVal !== "tcp") {
        this.customColumns = [];
      }
    },
    advancedPanel(newVal, oldVal) {
      if (newVal === 0) {
        this.loadIndexedColumns();
      }
    }
  },
  filters: {
    capitalize(value) {
      return value ? value.toUpperCase() : "";
    }
  },
  mounted() {
    if (this.pcapid) {
      this.currentAnalysis.pcapid = this.pcapid;
    }
    if (this.index) {
      this.currentAnalysis.index = this.index;
    }
    this.getConnectionsCustom();
  },
  methods: {
    lookupFlagDef(flag) {
      for (const f of this.flagDefs) {
        if (flag === f.flagstr) {
          return f;
        }
      }
      return {
        flagstr: "-",
        class: "",
        color: "yellow",
        icon: "mdi-help-circle",
        tooltip: "No help for this flag combination"
      };
    },
    openCapturePointGraph(item) {
      this.selectedConnection = item;
      this.showCapturePoint = true;
    },
    openTcpGraph(item) {
      this.selectedConnection = item;
      this.showTcpGraph = true;
    },
    loadIndexedColumns() {
      const i = this.currentAnalysis.index;
      ApiIndexerProfile.get(i).then(({ data }) => {
        for (const f of data.fields) {
          this.indexedColumns.push({
            esfield: f,
            wiresharkfield: this.df_es_field_to_wireshark(f)
          });
        }
      });
    },
    filterFlags(flags) {
      const f = "tcp.flags == " + flags;
      this.df_apply_to_packetlist(f);
      this.currentAnalysis.showConnections = false;
      this.currentAnalysis.showIOGraph = false;
    },
    filter(item) {
      const f = this.getFilter(item, this.type);
      this.df_apply_to_packetlist(f);
      this.currentAnalysis.showConnections = false;
      this.currentAnalysis.showIOGraph = false;
      this.navigatePacketview(this.currentAnalysis.pcapid, this.currentAnalysis.index, f);
    },
    makeKey(a, b) {
      const port1 = a.split(",")[1];
      const port2 = b.split(",")[1];
      return parseInt(port1) > parseInt(port2) ? a + "->" + b : b + "->" + a;
    },
    aggregationOperation(s, d, c) {
      const src = parseFloat(s);
      const dst = parseFloat(d);
      switch (c.type) {
        case "sum":
          return src + dst;
        case "min":
          return src < dst ? src : dst;
        case "max":
          return src > dst ? src : dst;
        case "avg":
          return (src + dst) / 2;
        default:
          return 0;
      }
    },
    aggregate(connections) {
      const out = {};
      const aggs = [
        { name: "Total Packets", type: "sum", field: "doc_count" },
        { name: "Total Bytes", type: "sum", field: "cummulative_bytes" },
        { name: "Total Time", type: "max", field: "delta" }
      ];
      const totals = this.getCustomColumns().concat(aggs);

      connections.forEach(src => {
        src.dest.buckets.forEach(dest => {
          const key = this.makeKey(src.key, dest.key);
          if (out[key]) {
            for (const c in dest) {
              if (c === "doc_count") {
                out[key].dst[c] = dest[c];
              } else if (c !== "key") {
                out[key].dst[c] = dest[c].value;
              }
            }
            totals.forEach(c => {
              const s = out[key].src[c.field];
              const d = out[key].dst[c.field];
              out[key].total[c.field] = this.aggregationOperation(s, d, c);
            });
          } else {
            const e = { src: {}, dst: {}, total: {}, type: this.type };
            switch (this.type) {
              case "ipv4":
              case "ipv6":
                e.src.ip = src.key;
                e.dst.ip = dest.key;
                break;
              case "tcp": {
                const srcs = src.key.split(",");
                const dests = dest.key.split(",");
                e.src.ip = srcs[0];
                e.src.port = parseInt(srcs[1]);
                e.dst.ip = dests[0];
                e.dst.port = parseInt(dests[1]);
                const flags = dest.flags.buckets.map(flag => flag.key);
                const mergedFlags = this.tcp_merge_flags(flags);
                e.flagsraw = mergedFlags;
                e.flags = this.tcp_flags_human_readable(mergedFlags);
                e.packets_over_time = dest.packets_over_time.buckets;
                break;
              }
              case "udp": {
                const srcs = src.key.split(",");
                const dests = dest.key.split(",");
                e.src.ip = srcs[0];
                e.src.port = parseInt(srcs[1]);
                e.dst.ip = dests[0];
                e.dst.port = parseInt(dests[1]);
                e.packets_over_time = dest.packets_over_time.buckets;
                break;
              }
              case "eth":
                e.src.eth = src.key;
                e.dst.eth = dest.key;
                break;
            }
            for (const c in dest) {
              if (c === "doc_count") {
                e.src[c] = dest[c];
              } else if (c !== "key") {
                e.src[c] = dest[c].value;
              }
              e.dst[c] = 0;
            }
            totals.forEach(c => {
              const field = c.field;
              if (field.includes(".")) {
                const esField = this.df_wireshark_field_to_es(field);
                try {
                  const value = parseFloat(dest[esField].value);
                  e.total[esField] = Number.isInteger(value)
                    ? value
                    : value.toFixed(this.precision);
                } catch (error) {
                  e.total[esField] = dest[esField].value;
                }
              } else {
                const s = e.src[field];
                const d = 0;
                e.total[field] = this.aggregationOperation(s, d, c);
              }
            });
            out[key] = e;
          }
        });
      });

      if (this.type === "tcp" || this.type === "udp") {
        for (const key in out) {
          const tmpSrc = out[key].src;
          const tmpDst = out[key].dst;
          if (this.comparePorts(tmpSrc.port, tmpDst.port)) {
            out[key].src = tmpDst;
            out[key].dst = tmpSrc;
          }
        }
      }
      this.connections = Object.values(out);

      // Calculate throughput for each connection (assumes total.delta is in seconds).
      this.connections.forEach(conn => {
        const duration = parseFloat(conn.total.delta) || 0;
        if (duration > 0) {
          const thptSrcRaw = conn.src.cummulative_bytes / duration;
          const thptDstRaw = conn.dst.cummulative_bytes / duration;
          conn.thptSrc = this.humanizeThroughput(thptSrcRaw);
          conn.thptDst = this.humanizeThroughput(thptDstRaw);
        } else {
          conn.thptSrc = "-";
          conn.thptDst = "-";
        }
      });
    },
    comparePorts(a, b) {
      const activeFtpPort = 20;
      return b > a && a !== activeFtpPort;
    },
    addColumn() {
      const newColumn = {
        key: this.customColumns.length + 1,
        name: "",
        type: "sum",
        field: ""
      };
      this.customColumns.push(newColumn);
    },
    removeColumn(column) {
      this.customColumns.splice(this.customColumns.indexOf(column), 1);
    },
    getCustomColumns() {
      return this.customColumns.map(c => {
        const field = typeof c.field === "string" ? c.field : c.field.wiresharkfield;
        return {
          key: c.key,
          name: c.name,
          type: c.type,
          field: field
        };
      });
    },
    getConnectionsCustom() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.tableOptions;
      let offset = (page - 1) * itemsPerPage;
      offset = offset || 0;

      const params = {
        sortBy,
        sortDesc,
        perpage: 100,
        offset,
        page,
        pcapid: this.currentAnalysis.pcapid,
        index: this.currentAnalysis.index,
        type: this.type,
        start: this.currentAnalysis.timelinestart,
        end: this.currentAnalysis.timelineend,
        filter: this.displayFilter,
        customcolumns: this.getCustomColumns()
      };

      ApiConnections.list(params).then(({ data }) => {
        this.activeType = this.type;
        this.aggregate(data);
        this.totalItems = data.length;
      });
    },
    contextMenu(e, item) {
      e.preventDefault();
      this.menu.showMenu = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;
      this.menu.items = [
        {
          title: "Conversation Filter",
          action: () => {
            this.filter(item);
          }
        }
      ];
      this.$nextTick(() => {
        this.menu.showMenu = true;
      });
    },
    humanizeThroughput(bytesPerSec) {
      if (bytesPerSec >= 1e6) {
        return (bytesPerSec / 1e6).toFixed(2) + ' MB/s';
      } else if (bytesPerSec >= 1e3) {
        return (bytesPerSec / 1e3).toFixed(2) + ' KB/s';
      } else {
        return bytesPerSec.toFixed(2) + ' B/s';
      }
    },
    exportConnections() {
      const dataStr = JSON.stringify(this.connections, null, 2);
      const blob = new Blob([dataStr], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "connections_export.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  }
};
</script>

<style>
.connections td {
  height: 24px !important;
}

.icon__flip {
  transform: scaleX(-1);
}

.icon__rotate {
  transform: rotate(80deg);
}
</style>
