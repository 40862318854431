import ApiService from "@/common/api.service";

const ApiAiChat = {

    chat(messages, model, stream = false, onChunkReceived) {
        if (!stream) {
            // Use normal Axios-based POST request
            return ApiService.post("ajax/ai/chat", {
                messages: messages,
                model: model,
                stream: false,
            });
        }

        // Use the new streamPost method for streaming
        return ApiService.streamPost("/ajax/ai/chat", {
            messages: messages,
            model: model,
            stream: true,
        }, onChunkReceived);
    },
}

export default ApiAiChat

