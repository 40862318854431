<template>
  <v-row
    id="packetlist"
    style="width: 100%; height: 100%"
    no-gutters
    v-if="currentAnalysis.profiles.available.length > 0"
  >
    <v-dialog v-model="dialogIllustration" width="950px">
      <packetillustration
        v-model="currentAnalysis.illustratepaket"
        :key="currentAnalysis.illustratepaket.packet.framenumber"
        v-if="dialogIllustration"
      ></packetillustration>
    </v-dialog>

    <v-dialog v-model="dialogGotoFrame" width="350px">
      <v-card>
        <v-card-title>
          <v-icon></v-icon>
          <div class="display-1">Goto Frame</div>
          <v-form @submit.prevent="gotoFrame(gotoFrameNumber)">
            <v-text-field
              ref="gotoframeelement"
              v-model="gotoFrameNumber"
              label="Frame Number"
              solo
            ></v-text-field>
          </v-form>
        </v-card-title>
      </v-card>
    </v-dialog>

    <v-col md="auto" v-if="currentAnalysis.profiles.selected.currentPacketInfo">
      <v-sheet class="py-1 pr-1 ma-0 d-flex">
        <template v-if="currentAnalysis.loadingpackets">
          <template
            v-if="
              currentAnalysis.pcap &&
                currentAnalysis.loadstatus.progress !=
                  currentAnalysis.pcap.numberofpackets &&
                currentAnalysis.loadstatus.progress
            "
          >
            <v-progress-circular
              small
              class="mx-1"
              :color="loadingcolor"
              :value="loadingprogress"
            ></v-progress-circular>

            <v-chip label small class="mx-1" color="secondary">
              Loaded: {{ currentAnalysis.loadstatus.progress }}/{{
                currentAnalysis.pcap.numberofpackets
              }}
            </v-chip>
          </template>

          <v-progress-circular
            v-else-if="false"
            class="mx-1"
            small
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>

        <template v-if="true">
          <v-tooltip bottom v-if="currentAnalysis.selectStartDate">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                label
                small
                class="mx-1"
                v-bind="attrs"
                v-on="on"
              >
                Time Interval:
                {{ currentAnalysis.selectStartDate | formatSelectedDate }} -
                {{ currentAnalysis.selectEndDate | formatSelectedDate }}
              </v-chip>
            </template>

            <span>Time Interval selected in Graph</span>
          </v-tooltip>

          <v-tooltip bottom v-if="currentAnalysis.iostart != undefined">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="red"
                label
                small
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                close
                @click:close="resetIOGraphSelection()"
              >
                IO Graph Interval:
                {{ currentAnalysis.iostart }} - {{ currentAnalysis.ioend }}
              </v-chip>
            </template>

            <span>Packet interval selected in IOGraph</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                label
                small
                color="secondary"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
              >
                Total: {{ currentAnalysis.total }}
              </v-chip>
            </template>

            <span>Total packets in trace</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                label
                color="secondary"
                small
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                ><template
                  v-if="currentAnalysis.limit >= currentAnalysis.matching"
                  >Displayed: {{ currentAnalysis.totalshown }}</template
                >
                <template v-else-if="currentAnalysis.filter"
                  >Displayed: {{ currentAnalysis.totalshown
                  }}<template
                    v-if="!currentAnalysis.profiles.selected.skipmatchcount"
                  >
                    of {{ currentAnalysis.matching }} matching</template
                  ></template
                >
                <template v-else>
                  Displayed: {{ currentAnalysis.totalshown }} of total
                  {{ currentAnalysis.total }}
                </template>
              </v-chip>
            </template>

            <span>Number of shown packets</span>
          </v-tooltip>

          <!-- 
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip color="secondary" label small class="mx-1" v-bind="attrs" v-on="on">
            Limited to: {{ currentAnalysis.limit }}</v-chip
          >
        </template>

        <span
          >
          <template v-if="currentAnalysis.limit < currentAnalysis.matching">
             Only {{ currentAnalysis.limit }} of a total of
          {{ currentAnalysis.matching }} loaded
            </template>
            <template v-else>
              All {{ currentAnalysis.matching }} of the trace
              </template>
         </span
        >
      </v-tooltip> -->

          <template v-if="currentAnalysis.limit < currentAnalysis.matching">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  small
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  label
                  color="secondary"
                  @click="loadallpackets(300000)"
                >
                  <v-icon left>mdi-progress-download</v-icon>
                  Load all matching packets
                </v-chip>
              </template>

              <span
                >Only {{ currentAnalysis.limit }} of a total of
                {{ currentAnalysis.matching }} loaded</span
              >
            </v-tooltip>
          </template>
        </template>
      </v-sheet>
    </v-col>

    <v-tooltip bottom v-if="currentAnalysis.features.enableindexedpacketlist">
      <template v-slot:activator="{ on, attrs }">
        <v-chip label small class="mx-1" v-bind="attrs" v-on="on">
          Index: {{ currentAnalysis.index }}
        </v-chip>
      </template>

      <span></span>
    </v-tooltip>

    <v-tooltip bottom v-if="currentAnalysis.features.timeline">
      <template v-slot:activator="{ on, attrs }">
        <v-chip label small class="mx-1" v-bind="attrs" v-on="on">
          Range: {{ currentAnalysis.timelinestart }}/{{
            currentAnalysis.timelineend
          }}
        </v-chip>
      </template>

      <span>Current analysis range</span>
    </v-tooltip>

    <v-col md="auto">
      <v-sheet class="py-1 pr-1 my-0 d-flex">
        <filterbuttons v-if="currentAnalysis.profiles.selected.filterbuttons">
        </filterbuttons>
      </v-sheet>
    </v-col>

    <template
      v-if="currentPacketColorrule && currentPacketColorrule.length > 0"
    >
      <v-icon>mdi-slash-forward</v-icon>
      <v-col md="auto">
        <v-sheet class="py-1 ma-0 d-flex">
          <i v-for="(value, key) in currentPacketColorrule" v-bind:key="key">
            <v-chip
              v-if="value.background == 'white'"
              label
              outline
              small
              color="grey"
              :text-color="value.foreground"
              class="mx-1"
              >{{ value.rulename }}</v-chip
            >
            <v-chip
              v-else
              @click="setDisplayFilter(value.rule)"
              label
              small
              class="mx-1"
              :color="value.background"
              :text-color="value.foreground"
              >{{ value.rulename }}</v-chip
            >
          </i>
        </v-sheet>
      </v-col>
    </template>

    <v-col md="auto" v-if="currentAnalysis.curfield.fieldname">
      <v-sheet class="py-1 pr-1 my-0 d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip label small class="mx-1" v-bind="attrs" v-on="on"
              >{{ currentAnalysis.curfield.fieldname }}
            </v-chip>
          </template>

          <span>Field</span>
        </v-tooltip>
      </v-sheet>
    </v-col>

    <v-spacer />

    <v-col md="auto" v-if="currentAnalysis.cachedresult">
      <v-sheet class="py-1 pr-1 my-0 d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              label
              small
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              color="red"
              close
              @click:close="removeCurrentFromCacheAndRefresh()"
              >cached
            </v-chip>
          </template>
          <span>Cached result</span>
        </v-tooltip>
      </v-sheet>
    </v-col>

    <template v-if="true">
      <v-col md="auto">
        <v-sheet class="py-1 ma-0 d-flex">
          <v-chip
            label
            color="green lighten-3"
            small
            class="mx-1"
            v-if="canEditCurrentProfile"
            draggable
            >Profile: {{ currentAnalysis.profiles.selected.name }}</v-chip
          >
          <v-chip label color="grey lighten-3" small class="mx-1" v-else
            >Profile:
            {{ currentAnalysis.profiles.selected.name }} (read-only)</v-chip
          >
        </v-sheet>
      </v-col>
    </template>
    <!-- 
    <v-col cols="12" v-if="$vuetify.breakpoint.mobile" class="mt-0 pt-0">
      <v-sheet class="py-1 pr-1 my-0 d-flex">
        <filterbuttons v-if="currentAnalysis.profiles.selected.filterbuttons">
        </filterbuttons>
      </v-sheet>
    </v-col> -->

    <v-col
      v-if="currentAnalysis.profiles.selected.currentPacketInfo"
      cols="12"
      style="height: calc(100% - 32px)"
    >
      <ag-grid-vue
        v-if="modeloaded"
        style="width: 100%; height: 100%"
        :class="theme"
        :gridOptions="gridOptions"
        :frameworkComponents="frameworkComponents"
      ></ag-grid-vue>
    </v-col>

    <v-col v-else cols="12" style="height: 100%">
      <ag-grid-vue
        v-if="modeloaded"
        style="width: 100%; height: 100%"
        :class="theme"
        :gridOptions="gridOptions"
        :frameworkComponents="frameworkComponents"
      ></ag-grid-vue>
    </v-col>
  </v-row>
</template>
<script>
import ApiPacketlist from "@/common/api/packetlist";
import ApiPcapmeta from "@/common/api/pcapmeta";
import ApiProfile from "@/common/api/profile";

import ApiIndexerprofile from "@/common/api/indexerprofile";
import { commonmethods } from "@/common/common";
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { mapGetters } from "vuex";

// // import all Enterprise modules
// import { ModuleRegistry, AllModules } from '@ag-grid-enterprise/all-modules';
// ModuleRegistry.registerModules(AllModules);

import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";

import { LicenseManager } from "ag-grid-enterprise";
import { AGGRID_LICENSE, DEFAULT_TABLEFONT } from "@/common/config";
LicenseManager.setLicenseKey(AGGRID_LICENSE);

import { displayfilter } from "@/common/displayfilter";
import packethandling from "@/common/packethandling";
import packetlist from "@/common/packetlist";
import Filterbuttons from "@/components/basics/Filterbuttons";
import Packetillustration from "@/components/Packetillustration";
import ApiDisplayfilter from "@/common/api/displayfilter";

import IntRenderer from "@/components/renderer/IntRenderer";
import IpRenderer from "@/components/renderer/IpRenderer";
import ChipRenderer from "@/components/renderer/ChipRenderer";
import FlagRenderer from "@/components/renderer/FlagRenderer";
import RelativeTimeRenderer from "@/components/renderer/RelativeTimeRenderer";

import { DEFAULT_PROFILE } from "@/common/config";
import { navigation } from "@/common/navigation";
import { iographhelper } from "@/common/iograph";
import { permissionchecks } from "@/common/permissions";
import { RowNodeSorter } from "ag-grid-community";
/* we don't want this to be reactive for performance reasons */
var cachedRows = [];
//const marked = {}

export default {
  name: "packetlistinfinite",
  mixins: [
    displayfilter,
    commonmethods,
    navigation,
    iographhelper,
    permissionchecks,
    packethandling,
    packetlist,
  ],
  props: {
    id: {
      type: String,
      default: "",
    },
    indexid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectionAnchor: null, // The anchor row index for shift selection
      currentFocusedIndex: null, // The row index of the "active" row (changes with arrow keys)
      gridApi: null,
      columnApi: null,
      loadstatus: null,
      initialpacketfetch: true,
      modeloaded: false,
      defaultprofile: DEFAULT_PROFILE,
      disablePointerEvents: false,
      essentialscolumns: [
        "tcp#stream",
        "frame#colorrules_matched",
        "udp#stream",
        "ip#src",
        "ip#dst",
        "tcp#srcport",
        "tcp#dstport",
        "udp#srcport",
        "udp#dstport",
        "eth#src",
        "eth#dst",
      ],
      dialogIllustration: false,
      dialogGotoFrame: false,
      gotoFrameNumber: "",
      previousselectedindex: null,
      currentPacketColorrule: [],
      /* display fields that are available in the trace file */
      traceFields: null,
      /* display fields and column definitions that are saved by the user */
      savedColumns: null,

      total: 0,
      columnstate: null,
      filter: "",
      /* used to lookup tshark column names from display filters */
      columnlookup: null,
      result: "",
      index: "default",
      type: "query",
      currentid: "",
      newrows: null,
      columnDefs: null,
      rowData: null,
      starttime: "0",
      timereferences: new Set(),
      frameworkComponents: {
        intRenderer: IntRenderer,
        ipRenderer: IpRenderer,
        chipRenderer: ChipRenderer,
        flagRenderer: FlagRenderer,
        relativeTimeRenderer: RelativeTimeRenderer,
      },
      gridOptions: {
        overlayLoadingTemplate: '<span style="padding: 10px;">Loading</span>',
        overlayNoRowsTemplate: '<span style="padding: 10px;">No rows</span>',
        rowModelType: "infinite",
        datasource: this.getDataSource(),
        //cacheOverflowSize: 2000,
        //rowBuffer: 500,
        cacheBlockSize: 1000,
        //cacheBlockSize: 100000,
        //rowBuffer: 5000,
        //maxConcurrentDatasourceRequests: 10,
        //infiniteInitialRowCount: 5000,
        context: {
          parent: this,
        },
        defaultColDef: {
          sortable: false, // disable sorting here as it is an infinite view
          resizable: true,
        },
        getRowNodeId: function(item) {
          return item["frame#number"];
        },
        //navigateToNextCell: this.navigateToNextCell,
        suppressRowHoverHighlight: true,
        rowSelection: "multiple",
        //rowDeselection: true,
        enableCharts: true,
        // Hyper important to support dots in field names like frame#number
        //
        suppressFieldDotNotation: true,
        columnDefs: [],
        getMainMenuItems: function(params) {
          var id = params.column.getId();
          var currentitems = params.defaultItems.splice(0);

          currentitems.push({
            name: "Field: " + id,
            action: function() {
              //console.log("ag-Grid is great was selected");
            },
          });

          currentitems.push({
            name: "Remove column",
            action: function() {
              this.removeColumn(params.column);
            }.bind(this),
          });

          // currentitems.push({
          //   name: "Save columns",
          //   action: function() {
          //     this.saveColumnState();
          //   }.bind(this)
          // });

          // currentitems.push({
          //   name: "Update columns",
          //   action: function() {
          //     this.updateColumnState();
          //   }.bind(this)
          // });

          //currentitems.splice(currentitems.indexOf("toolPanel"), 1);
          return currentitems;
        }.bind(this),
        getContextMenuItems: this.getContextMenuItems,

        onGridReady: function(params) {
          // this.setFontForStyle("#packetlist .ag-theme-balham-dark", "'Times New Roman', Times, serif !important;")
          this.gridApi = params.api;
          this.gridColumnApi = params.columnApi;
          this.currentAnalysis.loading = false;

          if (this.currentAnalysis.profiles.selected.colorPackets) {
            this.provideColoringRules();
          }

          if (this.currentAnalysis.profiles.selected.packetlistgrouping) {
            this.gridOptions.rowGroupPanelShow = "always";
          }

          this.filter = this.currentAnalysis.filter;
          this.triggerUpdate();

          //this.autoSizeAll(false)
          //this.gridOptions.api.sizeColumnsToFit()
        }.bind(this),
        onCellMouseDown: this.cellMouseDown,
        onRowDataChanged: function(params) {
          //this.setRowClassRules(null);
        }.bind(this),
        onRowClicked: function(params) {
          this.selectionAnchor = params.node.rowIndex;
          this.currentFocusedIndex = params.node.rowIndex;
          //var framenum = params.data["frame_number"];
          //pcapdecode(key, framenum, framenum);
          //this.eventHub.$emit('active-packet', params.data["frame_number"]);
        },
        onSelectionChanged: this.onSelectionChanged, // Handle selection change
        onRowSelected: function(params) {
          if (params.node.isSelected()) {
            // Note: We need to use local storage here because we go across windows

            this.currentAnalysis.packetlistpacket = params.node.data;
            this.$eventHub.$emit("active-packet", params.data["frame#number"]);
            localStorage.setItem(this.id, params.data["frame#number"]);

            this.currentAnalysis.currentpacket = parseInt(
              params.data["frame#number"]
            );

            // disable this by default
            let selectedDom = document.getElementsByClassName(
              "ag-row-selected"
            )[0];

            let rows = this.gridOptions.api.getSelectedNodes();

            if (this.previousselectedindex != null) {
              let previousrow = this.gridOptions.api.getDisplayedRowAtIndex(
                this.previousselectedindex
              );

              rows.push(previousrow);
            }

            this.previousselectedindex = rows[0].rowIndex;

            const WANT_EXPENSIVE_ROW_VISUAL = false;
            if (WANT_EXPENSIVE_ROW_VISUAL) {
              // this does not privde
              //this.gridOptions.api.refreshCells({ rowNodes: rows })
              //this.gridOptions.api.redrawRows({ rowNodes: rows });
            }

            this.currentPacketColorrule = [];

            let matchedcolors = undefined;
            try {
              matchedcolors = params.data["frame#colorrules_matched"].split(
                ","
              );
              var rules = this.currentAnalysis.profiles.selected.coloringrules;

              for (let i = 0; i < matchedcolors.length; i++) {
                // 1 is index 0 so substract one
                let curmatch = parseInt(matchedcolors[i]) - 1;

                let cenabled = 0;

                for (let j = 0; j < rules.length; j++) {
                  if (cenabled == curmatch) {
                    break;
                  }

                  if (rules[j].show) {
                    cenabled++;
                  }
                }
                if (curmatch == cenabled) {
                  this.currentPacketColorrule.push(rules[cenabled]);
                }
              }
            } catch (e) {
              matchedcolors = undefined;
            }

            for (let c of selectedDom.classList) {
              var rules = this.currentAnalysis.profiles.selected.coloringrules;
              if (c.startsWith("colorrule-")) {
                for (let j = 0; j < rules.length; j++) {
                  if (rules[j].id == c) {
                    this.currentPacketColorrule.push(rules[j]);
                    break;
                  }
                }
              }
            }

            this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
          }
        }.bind(this),
      },
      metaColumns: [
        {
          headerName: "Source",
          field: "_ws#col#Source",
          width: 140,
          suppressSizeToFit: true,
          sources: ["_ws#col#Source", "eth#src", "ip#src", "ipv6#src"],
        },
        {
          headerName: "Destination",
          field: "_ws#col#Destination",
          width: 140,
          suppressSizeToFit: true,
          sources: ["_ws#col#Destination", "eth#dst", "ip#dst", "ipv6#dst"],
        },
        // ,
        // {
        //   headerName: "Source Port",
        //   field: "meta#srcport",
        //   width: 140,
        //   suppressSizeToFit: true,
        //   sources: ["udp#srcport", "tcp#srcport"]
        // },
        // {
        //   headerName: "Destination Port",
        //   field: "meta#dstport",
        //   width: 140,
        //   suppressSizeToFit: true,
        //   sources: ["udp#dstport", "tcp#dstport"]
        // }
      ],
    };
  },
  components: {
    AgGridVue,
    IntRenderer,
    Filterbuttons,
    IpRenderer,
    ChipRenderer,
    FlagRenderer,
    RelativeTimeRenderer,
    Packetillustration,
  },
  beforeMount() {
    this.index = this.indexid;
    this.currentAnalysis.index = this.index;
    if (this.index != "none") {
      this.getcolumnsdefsforindexer();
    }
  },
  mounted() {
    this.initialpacketfetch = true;
    if ("infinitepacketlist" in this.currentAnalysis.profiles.selected) {
      if (!this.currentAnalysis.profiles.selected.infinitepacketlist) {
        this.gridOptions.rowModelType = "clientSide";
        this.gridOptions.defaultColDef.sortable = true;
      }
    }

    if (this.currentAnalysis.profiles.selected.rowBuffer) {
      this.gridOptions.rowBuffer = this.currentAnalysis.profiles.selected.rowBuffer;
    }

    if (this.currentAnalysis.profiles.selected.cacheBlockSize) {
      this.gridOptions.cacheBlockSize = this.currentAnalysis.profiles.selected.cacheBlockSize;
    }

    this.gridOptions.enableRangeSelection = true; //this.$store.getters.currentAnalysis.features.enablerangeselection;
    this.gridOptions.enableCharts = this.$store.getters.currentAnalysis.features.enablerangeselection;

    this.modeloaded = true;

    // this.$eventHub.$on("displayfilter", filter => {
    //   this.updateWithDisplayFilter(filter);
    // });

    this.$eventHub.$on("goto", (frame) => {
      this.gotoFrame(frame);
    });

    this.$eventHub.$on("highlight", (params) => {
      this.highlight(params);
    });

    this.$eventHub.$on("update", (state) => {
      var c = this.currentAnalysis;

      if (this.gridOptions.api.getInfiniteRowCount() < c.start) {
        this.gridOptions.api.setInfiniteRowCount(c.start, false);
      }
      // next, we can jump to the row
      this.gridOptions.api.ensureIndexVisible(c.start);

      //this.fetchRowsDataSource({ startRow: c.start, endRow: c.stop });
      //this.fetchRows(c.start, c.end);
    });

    this.$eventHub.$on("timereference", (frame) => {
      this.setTimeReferenceByFrameNumber(frame);
    });

    this.$eventHub.$on("showcolumn", (column) => {
      this.showColumn(column);
      this.gridOptions.api.sizeColumnsToFit();
    });

    this.$eventHub.$on("hidecolumn", (column) => {
      this.hideColumn(column);
      this.gridOptions.api.sizeColumnsToFit();
    });

    this.$eventHub.$on("autosizecolumns", (column) => {
      this.gridOptions.api.sizeColumnsToFit();
    });

    window.addEventListener("keydown", this.globalShortcuts);

    window.addEventListener("resize", this.debounce(this.on_resize, 250));

    /* apply selected row style */
    try {
      var fg_selected = this.currentAnalysis.profiles.selected.selectedfg;
      var bg_selected = this.currentAnalysis.profiles.selected.selectedbg;
    } catch (err) {
      var fg_selected = "white";
      var bg_selected = "lightblue";
    }
  },
  updated() {
    if (this.dialogGotoFrame) {
      this.$nextTick(this.$refs.gotoframeelement.focus);
    }
  },
  ensurevisible(index) {
    this.gridOptions.api.ensureIndexVisible(index);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.globalShortcuts);
    this.$eventHub.$off("displayfilter");
    this.$eventHub.$off("goto");
    this.$eventHub.$off("highlight");
    this.$eventHub.$off("update");
    this.$eventHub.$off("timereference");
    this.$eventHub.$off("showcolumn");
    this.$eventHub.$off("hidecolumn");
    this.$eventHub.$off("autosizecolumns");
    this.$eventHub.$off("resize");
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error"]),
    loadingprogress: function() {
      let percentage = 0;
      if (this.currentAnalysis.loadstatus && this.currentAnalysis.pcap) {
        if (
          this.currentAnalysis.loadstatus.progress > 0 &&
          this.currentAnalysis.pcap.numberofpackets
        ) {
          percentage =
            this.currentAnalysis.loadstatus.progress /
            this.currentAnalysis.pcap.numberofpackets;
          percentage = percentage * 100;
        }
      }

      return percentage;
    },
    loadingcolor: function() {
      if (this.loadingprogress == 100) {
        return "green";
      } else {
        return "blue";
      }
    },

    theme: function() {
      if (this.currentAnalysis.profiles.selected.theme) {
        return "ag-theme-balham-dark";
      }
      return "ag-theme-balham";
    },
    colorshown: function() {
      if (this.currentAnalysis.filter) {
        if (this.currentAnalysis.matching === this.currentAnalysis.totalshown) {
          return "green";
        } else {
          return "orange";
        }
      } else {
        if (this.currentAnalysis.total === this.currentAnalysis.totalshown) {
          return "green";
        } else {
          return "orange";
        }
      }
    },
    colormatching: function() {
      if (this.currentAnalysis.matching === this.currentAnalysis.totalshown) {
        return "green";
      } else {
        return "orange";
      }
    },
  },
  watch: {
    "currentAnalysis.packetlistcacheinvalidate": function(n, o) {
      if (n) {
        this.removeCurrentFromCacheAndRefresh();
        this.currentAnalysis.packetlistcacheinvalidate = false;
      }
    },
    "currentAnalysis.showNavDrawer": function(n, o) {
      if (!n) {
        setTimeout(() => this.gridOptions.api.sizeColumnsToFit(), 400);
      }
    },
    "currentAnalysis.showFollow": function(n, o) {
      //this.disablePointerEvents = n;
    },
    "currentAnalysis.showTapstatistics": function(n, o) {
      //this.disablePointerEvents = n;
    },
    "currentAnalysis.showConnections": function(n, o) {
      //this.disablePointerEvents = n;
    },
    "currentAnalysis.showIOGraph": function(n, o) {
      //this.disablePointerEvents = n;
    },
    "currentAnalysis.filterneedsupdate": function(n, o) {
      // triggerUpdate();

      if (
        this.currentAnalysis.quickfilter &&
        n &&
        this.currentAnalysis.filter
      ) {
        this.gridOptions.api.setQuickFilter(this.currentAnalysis.filter);
        this.currentAnalysis.filterneedsupdate = false;
        return;
      }

      if (n) {
        try {
          this.gridOptions.api.setQuickFilter("");
        } catch {}

        this.updateWithDisplayFilter(this.currentAnalysis.filter);
        this.currentAnalysis.filterneedsupdate = false;
      }
    },
    "currentAnalysis.profileneedsupdate": function(n, o) {
      if (n) {
        this.currentAnalysis.applyfilter = true;
        this.updateWithDisplayFilter(this.currentAnalysis.filter);
        this.loadColumnPreferences();
        this.currentAnalysis.profileneedsupdate = false;
      }
    },
    // https://stackoverflow.com/questions/46368578/vuex-executing-function-after-async-state-change
    "currentAnalysis.profiles.selected.colorPackets": function(n, o) {
      if (this.gridOptions.api) {
        if (n == true) {
          this.provideColoringRules(true);
          this.gridOptions.api.redrawRows();
        } else {
          this.disableColoringRules();
        }
      }
    },
    "currentAnalysis.triggers.refreshpacketlist": function(n, o) {
      if (n) {
        this.gridOptions.api.redrawRows();
        this.currentAnalysis.triggers.refreshpacketlist = false;
      }
    },
    // currentAnalysis: {
    //   handler: function(n, o) {
    //     ;
    //     if (this.gridOptions.api) {
    //       this.loadColumnPreferences();
    //       if (n.profiles.selected.colorPackets == true) {
    //         this.provideColoringRules();
    //         if (this.gridOptions.api) {
    //           this.gridOptions.api.redrawRows();
    //         }
    //       } else {
    //         this.disableColoringRules();
    //       }
    //     }
    //   },
    //   deep: true
    // },
    /* if savedColumns is updated we want to reload the columnpreferences */
    savedColumns: {
      handler: function(newColumns) {
        this.loadColumnPreferences();
      },
    },
    columnlookup: {
      handler: function(n) {
        this.loadColumnPreferences();
      },
    },
    traceFields: {
      handler: function(n, o) {
        if (!o) {
          this.loadColumnPreferences();
          return;
        }
        // only load if traceFields changed
        const found = n.every((r) => o.includes(r));
        if (!found) {
          this.loadColumnPreferences();
        }
      },
    },
  },
  methods: {
    onSelectionChanged() {
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      let packets = selectedNodes.map((node) => node.data);

      this.currentAnalysis.aiSelectedPackets = {
        packets: packets,
      };
    },
    onRowDrag: function(params) {
      var rowNode = params.rowNode;
      var e = params.dragEvent;

      var jsonObject = {
        grid: "GRID_001",
        operation: "Drag on Column",
        rowId: rowNode.data.id,
        selected: rowNode.isSelected(),
      };
      var jsonData = JSON.stringify(jsonObject);

      e.dataTransfer.setData("application/json", jsonData);
      e.dataTransfer.setData("text/plain", jsonData);
    },
    resetIOGraphSelection: function() {
      this.currentAnalysis.iostart = undefined;
      this.currentAnalysis.ioend = undefined;
      this.currentAnalysis.selectStartDate = undefined;
      this.currentAnalysis.selectEndDate = undefined;
      this.currentAnalysis.applyfilter = true;
      this.currentAnalysis.filterneedsupdate = true;
    },
    debounce: function(func, wait, immediate) {
      let timeout;
      return function() {
        let context = this,
          args = arguments,
          later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
          },
          call_now = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (call_now) func.apply(context, args);
      };
    },
    on_resize: function() {
      try {
        this.gridOptions.api.sizeColumnsToFit();
      } catch (e) {}
    },
    loadAll: function() {
      setTimeout(function() {
        self.basketAddSuccess = false;
      }, 1000);
    },
    saveProfile: function() {
      ApiProfile.update(this.currentAnalysis.profiles.selected)
        .then(({ data }) => {
          this.currentAnalysis.profileneedsupdate = true;
        })
        .catch(({ response }) => {
          this.error.msg = "Error saving the profile";
        });
    },
    togglemarked(packet) {
      let cachekey = this.currentAnalysis.pcapid;

      if (!(cachekey in this.currentAnalysis.packetlist.marked)) {
        this.currentAnalysis.packetlist.marked[cachekey] = [];
      }

      if (this.ismarked(packet)) {
        this.currentAnalysis.packetlist.marked[
          cachekey
        ] = this.currentAnalysis.packetlist.marked[cachekey].filter(
          (item) => item !== packet
        );
      } else {
        this.currentAnalysis.packetlist.marked[cachekey].push(packet);
      }
      return packet;
    },
    loadallpackets(valuetoset = 300000) {
      //let valuetoset = 300000 // maximum TODO: make it a feature
      if (this.currentAnalysis.filter) {
        if (this.currentAnalysis.matching < valuetoset) {
          valuetoset = this.currentAnalysis.matching;
        }
      } else {
        if (this.currentAnalysis.total < valuetoset) {
          valuetoset = this.currentAnalysis.total;
        }
      }

      if (this.currentAnalysis.total > 80000) {
        this.currentAnalysis.longoperationoverlay = true;
      }

      this.currentAnalysis.profiles.selected.maxPackets = valuetoset;
      this.currentAnalysis.applyfilter = true;
      this.currentAnalysis.filterneedsupdate = true;
    },
    gotoMarked(prev = false) {
      let cachekey = this.currentAnalysis.pcapid;
      let markedpackets = this.currentAnalysis.packetlist.marked[cachekey];
      let gotothis;
      let cur = this.currentAnalysis.currentpacket;

      markedpackets.sort((a, b) => a - b);

      if (prev) {
        gotothis = 0;
      } else {
        gotothis = Number.MAX_SAFE_INTEGER;
      }

      for (let m of markedpackets) {
        if (!prev && m > cur && m < gotothis) {
          gotothis = m;
        } else if (prev && m < cur && m > gotothis) {
          gotothis = m;
        }
      }

      if (prev) {
        if (gotothis != 0) {
          this.gotoFrame(gotothis);
        } else if (markedpackets.length > 1) {
          this.gotoFrame(markedpackets[markedpackets.length - 1]);
        }
      } else if (!prev) {
        if (gotothis != Number.MAX_SAFE_INTEGER) {
          this.gotoFrame(gotothis);
        } else if (markedpackets.length > 1) {
          this.gotoFrame(markedpackets[0]);
        }
      }
    },
    iscached(packet) {
      return this.checkCache(packet);
    },
    ismarked(packet) {
      let cachekey = this.currentAnalysis.pcapid;

      if (!(cachekey in this.currentAnalysis.packetlist.marked)) {
        this.currentAnalysis.packetlist.marked[cachekey] = [];
      }
      return this.currentAnalysis.packetlist.marked[cachekey].includes(packet);
    },
    togglemarkedrefresh(node) {
      this.togglemarked(node.id);
      this.gridOptions.api.redrawRows([node]);
    },
    getContextMenuItems: function(params) {
      var currentitems = params.defaultItems.slice(0);
      //currentitems.splice(currentitems.indexOf("toolPanel"), 1);

      //TODO refactor this in a function
      // and resuse
      var out = null;

      /* look up left and side of comparision in metacolumns */
      for (var j = 0; j < this.metaColumns.length; j++) {
        if (this.metaColumns[j].field == params.column.colId) {
          for (var k = 0; k < this.metaColumns[j]["sources"].length; k++) {
            if (
              params.node.data[this.metaColumns[j]["sources"][k]] != "" &&
              params.node.data[this.metaColumns[j]["sources"][k]] !== undefined
            ) {
              out = this.metaColumns[j].sources[k];
            }
          }
        }
      }

      if (out == null) {
        out = params.column.colId;
      }
      var result = [];

      var l_timereference = {
        icon: '<i class="material-icons" style="font-size: 16px;">alarm</i>',
        name: "Toggle timereference",
        action: function() {
          this.toggleTimeReference(params);
        }.bind(this),
        shortcut: "ALT+T",
        cssClasses: ["redFont", "bold"],
      };

      var l_markpacket = {
        name: "Mark packet",
        icon: '<i class="material-icons" style="font-size: 16px;">adjust</i>',
        action: function() {
          this.togglemarkedrefresh(params.node); // maybe get id from framenumber in row
        }.bind(this),
        cssClasses: ["redFont", "bold"],
      };

      var l_udpconv = {
        name: "UDP Conversation",
        icon: '<i class="material-icons" style="font-size: 16px;">more</i>',
        action: function() {
          var o = this.getConversationTuple(params, "udp");

          this.df_conv_filter("udp", o.src, o.srcport, o.dst, o.dstport, true);

          // $("#expression").val(out + " == " + params.value);
          // $("#submit-expression").trigger("click");
        }.bind(this),
        disabled: !this.isUdp(params),
        cssClasses: ["redFont", "bold"],
      };

      var l_tcpconv = {
        name: "TCP Conversation",
        icon: '<i class="material-icons" style="font-size: 16px;">more</i>',
        action: function() {
          var o = this.getConversationTuple(params, "tcp");
          this.df_conv_filter("tcp", o.src, o.srcport, o.dst, o.dstport, true);
        }.bind(this),
        disabled: !this.isTcp(params),
        cssClasses: ["redFont", "bold"],
      };

      /*{
        name: "Explain packet",
        action: function() {
          this.currentAnalysis.illustratepaket.framenumber =
            params.node.data["frame#number"];
          this.currentAnalysis.illustratepaket.info =
            params.node.data["_ws#col#Info"];
          this.dialogIllustration = true;
        }.bind(this),
        cssClasses: ["redFont", "bold"]
      },*/

      result.push(l_timereference);
      result.push(l_markpacket);
      result.push(l_tcpconv);
      result.push(l_udpconv);

      if (params.value !== undefined && !this.df_isunfilterablefield(out)) {
        var filter_for = {
          icon: '<i class="material-icons" style="font-size: 16px;">done</i>',
          name:
            "Filter for " +
            this.truncatestring(
              this.df_build_filter(out, params.node.data[out]),
              30,
              "..."
            ),
          action: function() {
            /* if there is name resolution active for l2, l3 addresses we need to lookup the original value from the packet list table */
            this.df_filter(out, params.node.data[out], "==", true);
          }.bind(this),
          cssClasses: ["redFont", "bold"],
        };
        result.push(filter_for);
      }

      if (params.value !== undefined && !this.df_isunfilterablefield(out)) {
        var prepare_filter_for = {
          icon: '<i class="material-icons" style="font-size: 16px;">east</i>',
          name:
            "Prepare filter for " +
            this.truncatestring(
              this.df_build_filter(out, params.node.data[out]),
              30,
              "..."
            ),
          action: function() {
            this.df_filter(out, params.node.data[out], "==", false);
          }.bind(this),
          cssClasses: ["redFont", "bold"],
        };
        result.push(prepare_filter_for);
      }

      if (params.value !== undefined) {
        let follow_submenu = {
          icon: '<i class="material-icons" style="font-size: 16px;">route</i>',
          name: "Follow",
          /* get taps using {"jsonrpc":"2.0","id":1,"method":"info"} */
          subMenu: [
            {
              name: "TCP",
              action: function() {
                let s = params.node.data["tcp#stream"];
                this.followcontent("TCP", "tcp.stream == " + s);
              }.bind(this),
              disabled: !this.isTcp(params),
              cssClasses: ["redFont", "bold"],
            },
            {
              name: "HTTP",
              action: function() {
                let s = params.node.data["tcp#stream"];
                this.followcontent("HTTP", "tcp.stream == " + s);
              }.bind(this),
              disabled: !this.isTcp(params),
              cssClasses: ["redFont", "bold"],
            },
            {
              name: "SIP Stream",
              action: function() {
                let s = params.node.data["udp#stream"];
                this.followcontent("SIP", "udp.stream == " + s);
              }.bind(this),
              disabled: !this.isUdp(params),
              cssClasses: ["redFont", "bold"],
            },
            {
              name: "TLS Stream",
              action: function() {
                let s = params.node.data["tcp#stream"];
                this.followcontent("TLS", "tcp.stream == " + s);
              }.bind(this),
              disabled: !this.isTcp(params),
              cssClasses: ["redFont", "bold"],
            },
            {
              name: "QUIC Stream",
              action: function() {
                let s = params.node.data["udp#stream"];
                this.followcontent("QUIC", "udp.stream == " + s);
              }.bind(this),
              disabled: !this.isUdp(params),
              cssClasses: ["redFont", "bold"],
            },

            {
              name: "HTTP/2 Stream",
              action: function() {
                let s = params.node.data["tcp#stream"];
                this.followcontent("HTTP2", "tcp.stream == " + s);
              }.bind(this),
              disabled: !this.isTcp(params),
              cssClasses: ["redFont", "bold"],
            },
            {
              name: "UDP",
              action: function() {
                let s = params.node.data["udp#stream"];
                this.followcontent("UDP", "udp.stream == " + s);
              }.bind(this),
              disabled: !this.isUdp(params),
              cssClasses: ["redFont", "bold"],
            },
          ],
        };

        result.push(follow_submenu);
      }

      // var l_disable = {
      //   name: "Disable pointer events",
      //   action: function() {

      //     this.disablePointerEvents = !this.disablePointerEvents
      //   }.bind(this),
      //   cssClasses: ["redFont", "bold"]
      // };

      // result.push(l_disable);

      let plot_submenu = {
        name: "Plot",
        icon: '<i class="material-icons" style="font-size: 16px;">timeline</i>',
        subMenu: [
          {
            name: "TCP Sequence Graph",

            action: function() {
              let { sender, receiver } = this.getSenderandReceiverFilters(
                params,
                "tcp"
              );

              let preset = this.copy_preset("TCP Sequence");
              preset.values[0].filter = sender;
              preset.values[1].filter = receiver;
              preset.values[2].filter = sender;
              preset.values[3].filter = receiver;
              this.navigateIOGraph(this.id, this.indexid, "", preset);
            }.bind(this),
            cssClasses: ["redFont", "bold"],
          },
          {
            name: "TCP Retransmissions",
            action: function() {
              let { sender, receiver, both } = this.getSenderandReceiverFilters(
                params,
                "tcp"
              );
              let preset = this.copy_preset("TCP Retransmissions");
              preset.values[0].filter = both;
              preset.values[1].filter = both;
              this.navigateIOGraph(this.id, this.indexid, "", preset);
            }.bind(this),
            cssClasses: ["redFont", "bold"],
          },
          {
            name: "TCP Length",
            action: function() {
              let { sender, receiver } = this.getSenderandReceiverFilters(
                params,
                "tcp"
              );
              let preset = this.copy_preset("TCP Length");
              preset.values[0].filter = sender;
              preset.values[1].filter = receiver;
              this.navigateIOGraph(this.id, this.indexid, "", preset);
            }.bind(this),
            cssClasses: ["redFont", "bold"],
          },
          {
            name: "IP TTL",
            action: function() {
              let { sender, receiver } = this.getSenderandReceiverFilters(
                params,
                "tcp"
              );
              let preset = this.copy_preset("IP TTL");
              preset.values[0].filter = sender;
              preset.values[1].filter = receiver;
              this.navigateIOGraph(this.id, this.indexid, "", preset);
            }.bind(this),
            cssClasses: ["redFont", "bold"],
          },
        ],
      };

      result.push(plot_submenu);

      if (params.value !== undefined && params.node.data["tcp#stream"] >= 0) {
        var fix_tcp_order = {
          icon:
            '<i class="material-icons" style="font-size: 16px;">plumbing</i>',
          name: "Fix TCP order",
          action: function() {
            let s = params.node.data["tcp#stream"];

            this.fixtcporder(s);
          }.bind(this),
          disabled: !this.isTcp(params),
          cssClasses: ["redFont", "bold"],
        };
        result.push(fix_tcp_order);
      }

      for (var item of currentitems) {
        result.push(item);
      }

      return result;
    },
    getSenderandReceiverFilters(params, type) {
      var o = this.getConversationTuple(params, type);
      let sender = this.df_conv_filter(
        type,
        o.src,
        o.srcport,
        o.dst,
        o.dstport,
        false,
        true
      );

      let both = this.df_conv_filter(
        type,
        o.src,
        o.srcport,
        o.dst,
        o.dstport,
        false,
        false
      );

      let receiver = this.df_conv_filter(
        type,
        o.dst,
        o.dstport,
        o.src,
        o.srcport,
        false,
        true
      );

      let tuple = { sender: sender, receiver: receiver, both: both };
      return tuple;
    },
    getConversationTuple(p, type) {
      var o = {};
      if (type == "eth") {
        o.src = p.node.data["eth#src"];
        o.dst = p.node.data["eth#dst"];
      }

      if (type == "ip" || type == "ipv6" || type == "udp" || type == "tcp") {
        o.src = p.node.data["ip#src"];
        if (o.src == undefined) {
          o.src = p.node.data["ipv6#src"];
        }

        o.dst = p.node.data["ip#dst"];
        if (o.dst == undefined) {
          odst = p.node.data["ipv6#src"];
        }
      }
      if (type == "udp") {
        o.srcport = p.node.data["udp#srcport"];
        o.dstport = p.node.data["udp#dstport"];
      } else if (type == "tcp") {
        o.srcport = p.node.data["tcp#srcport"];
        o.dstport = p.node.data["tcp#dstport"];
      }
      return o;
    },
    globalShortcuts(event) {
      var KEY_UP = 38;
      var KEY_DOWN = 40;
      var KEY_T = 84;
      var KEY_G = 71;
      var KEY_N = 78;
      var KEY_P = 80;

      const KEY_A = 65; // A key for ctrl+a

      if (!event.target.closest(".ag-root")) {
        return;
      }

      // Handle ctrl+a (or cmd+a) to select all rows
      if ((event.ctrlKey || event.metaKey) && event.keyCode === KEY_A) {
        event.preventDefault(); // Prevent browser default select-all
        this.gridOptions.api.selectAll();
        this.gridOptions.api.dispatchEvent({ type: "selectionChanged" });
        // Also update the anchor and focus
        this.selectionAnchor = 0;
        this.currentFocusedIndex = 0;
        return;
      }

      // Initialize anchor and focus if not already set.
      // For example, if no row is selected, use the first row (index 0).
      let selectedNodes = this.gridOptions.api.getSelectedNodes();
      if (this.selectionAnchor === null) {
        if (selectedNodes.length > 0) {
          this.selectionAnchor = selectedNodes[0].rowIndex;
          this.currentFocusedIndex = selectedNodes[0].rowIndex;
        } else {
          this.selectionAnchor = 0;
          this.currentFocusedIndex = 0;
        }
      }

      // Handle shift + arrow keys for range selection
      if (event.shiftKey) {
        let newIndex = this.currentFocusedIndex;
        if (event.keyCode === KEY_UP) {
          newIndex = Math.max(0, this.currentFocusedIndex - 1);
        } else if (event.keyCode === KEY_DOWN) {
          newIndex = Math.min(
            this.currentAnalysis.total - 1,
            this.currentFocusedIndex + 1
          );
        } else {
          return;
        }

        // Update our "current focus" index
        this.currentFocusedIndex = newIndex;

        // Determine the range between the anchor and the new focused row
        const start = Math.min(this.selectionAnchor, newIndex);
        const end = Math.max(this.selectionAnchor, newIndex);

        // Clear current selection, then select all rows in the range
        this.gridOptions.api.deselectAll();
        for (let i = start; i <= end; i++) {
          const node = this.gridOptions.api.getDisplayedRowAtIndex(i);
          if (node) {
            // Use the second parameter false so we are not incrementally adding.
            // (We’re building the complete range from scratch.)
            node.setSelected(true, false);
          }
        }
        // Optionally dispatch an event if you rely on rowSelected callbacks:
        this.gridOptions.api.dispatchEvent({ type: "selectionChanged" });
        // Ensure the newly focused row is visible
        this.gridOptions.api.ensureIndexVisible(newIndex);
      } else {
        // When shift is not pressed, treat the arrow keys as a simple move.
        // Here we clear previous selection and select only the new row.
        // Also update the anchor to the new row.
        let currentIndex = 0;
        if (selectedNodes.length > 0) {
          currentIndex = selectedNodes[0].rowIndex;
        }
        let newIndex = currentIndex;
        if (event.keyCode === KEY_UP && currentIndex > 0) {
          newIndex = currentIndex - 1;
        } else if (
          event.keyCode === KEY_DOWN &&
          currentIndex < this.currentAnalysis.total - 1
        ) {
          newIndex = currentIndex + 1;
        } else {
          return;
        }
        // Clear selection and select only the new row
        this.gridOptions.api.deselectAll();
        const node = this.gridOptions.api.getDisplayedRowAtIndex(newIndex);
        if (node) {
          node.setSelected(true);
          this.gridOptions.api.dispatchEvent({
            type: "rowSelected",
            node: node,
          });
          this.gridOptions.api.ensureIndexVisible(newIndex);
        }
        // Reset the anchor and current focus to the new row
        this.selectionAnchor = newIndex;
        this.currentFocusedIndex = newIndex;
      }

      if (event.altKey == true && event.keyCode == KEY_T) {
        this.toggleTimeReference(event);
        return;
      }

      if (event.altKey == true && event.keyCode == KEY_G) {
        this.gotoFrameDialog();
        return;
      }

      if (event.altKey == true && event.keyCode == KEY_N) {
        this.gotoMarked();
        return;
      }

      if (event.altKey == true && event.keyCode == KEY_P) {
        this.gotoMarked(true);
        return;
      }

      var selectedarr = this.gridOptions.api.getSelectedNodes();

      if (selectedarr.length != 1) {
        return;
      }

      var oldnodeindex = selectedarr[0].rowIndex;

      var newnode;
      // switch (event.keyCode) {
      //   case KEY_UP:
      //     if (oldnodeindex == 0) {
      //       return;
      //     }
      //     newnode = this.gridOptions.api.getDisplayedRowAtIndex(
      //       oldnodeindex - 1
      //     );
      //     newnode.setSelected(true);
      //     // Note: this eats a lot of performance but
      //     // in the case of key presses there is no way around it
      //     this.gridOptions.api.ensureIndexVisible(oldnodeindex - 1);
      //     break;
      //   case KEY_DOWN:
      //     if (oldnodeindex == this.currentAnalysis.total - 1) {
      //       return;
      //     }
      //     newnode = this.gridOptions.api.getDisplayedRowAtIndex(
      //       oldnodeindex + 1
      //     );
      //     newnode.setSelected(true);
      //     // Note: this eats a lot of performance but
      //     // in the case of key presses there is no way around it
      //     this.gridOptions.api.ensureIndexVisible(oldnodeindex + 1);
      //     break;
      // }
    },
    isTcp(params) {
      let tcpstream = params.node.data["tcp#stream"];
      return tcpstream >= 0 && tcpstream !== "";
    },
    isUdp(params) {
      let udpstream = params.node.data["udp#stream"];
      return udpstream >= 0 && udpstream !== "";
    },
    showColumn(column) {
      this.gridOptions.columnApi.setColumnVisible(column, true);
    },
    hideColumn(column) {
      this.gridOptions.columnApi.setColumnVisible(column, false);
    },
    setTimeReferenceByFrameNumber(frame) {
      if (frame < 1) {
        return;
      }
      // frame numbers start at 1 while arrays at 0
      let node = this.gridOptions.api.getRowNode(frame - 1);
      this.setTimeReference(node);
    },
    gotoFrameDialog() {
      this.gotoFrameNumber = "";
      this.dialogGotoFrame = true;
    },
    toggleTimeReference(row) {
      var selectedarr = this.gridOptions.api.getSelectedNodes();
      if (selectedarr.length == 0) {
        return;
      }

      if (this.index == "none") {
        this.setTimeReferenceBackend(selectedarr[0].id);
      } else {
        this.setTimeReference(selectedarr[0]);
      }
    },
    setTimeReferenceBackend(selected) {
      if (this.timereferences.has(selected)) {
        this.timereferences.delete(selected);
      } else {
        this.timereferences.add(selected);
      }
      this.currentAnalysis.timereferences = this.prepareTimeReferencesToSend();
      this.triggerUpdate();
    },
    setTimeReference(selected) {
      var myid = parseInt(selected.id);
      // 1 is for frame one
      this.starttime = this.gridOptions.api.getRowNode(1).data[
        "frame#time_epoch"
      ];

      if (myid == 0) {
        return;
      }

      for (let j = 1; ; j++) {
        var node = this.gridOptions.api.getRowNode(j);

        if (node == null) {
          return;
        }
        var thisid = node.id;

        let newtime = this.epochToRelative(node.data["frame#time_epoch"]);

        if (thisid == myid) {
          // remove the ref from the currently selected row
          if (selected.data["frame#time_relative"] == "*REF*") {
            node.setDataValue("frame#time_relative", newtime);
          } else {
            this.starttime = node.data["frame#time_epoch"];
            selected.setDataValue("frame#time_relative", "*REF*");
          }
        } else {
          if (node.data["frame#time_relative"] == "*REF*") {
            this.starttime = node.data["frame#time_epoch"];
          } else {
            node.setDataValue("frame#time_relative", newtime);
          }
        }
      }
    },
    disableColoringRules: function() {
      this.gridOptions.rowClassRules = {};
      this.gridOptions.getRowStyle = function(params) {
        let foreground;
        let background;
        if (this.ismarked(params.node.id)) {
          // TODO using id here may not work in all cases. e.g. if packet list does not start at 1
          foreground = "#ffffff";
          background = "#000000";
        } else if (params.node.selected) {
          try {
            var fg_selected = this.currentAnalysis.profiles.selected.selectedfg;
            var bg_selected = this.currentAnalysis.profiles.selected.selectedbg;
          } catch (err) {
            var fg_selected = "white";
            var bg_selected = "lightblue";
          }
          foreground = fg_selected;
          background = bg_selected;
        }

        if (this.isvaluergb(foreground) && this.isvaluergb(background)) {
          return {
            color: foreground,
            background: background,
          };
        } else {
          return {
            color: "black",
            background: "white",
          };
        }
      }.bind(this);
      this.gridOptions.api.redrawRows();
    },
    pad: function(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    provideColoringRules: function(force = false) {
      this.gridOptions.rowClassRules = {};

      try {
        var fg_selected = this.currentAnalysis.profiles.selected.selectedfg;
        var bg_selected = this.currentAnalysis.profiles.selected.selectedbg;
      } catch (err) {
        var fg_selected = "white";
        var bg_selected = "lightblue";
      }

      if (!this.currentAnalysis.profiles.selected) {
        return;
      }

      if (!this.currentAnalysis.profiles.selected.coloringrules && !force) {
        this.disableColoringRules();
      }

      if (this.index == "none") {
        // TODO extract this function

        let foreground;
        let background;

        this.gridOptions.getRowStyle = function(params) {
          if (this.ismarked(params.node.id)) {
            // TODO using id here may not work in all cases. e.g. if packet list does not start at 1
            foreground = "#ffffff";
            background = "#000000";
          } else if (
            this.currentAnalysis.features.debugdecodecaching &&
            this.iscached(params.node.id)
          ) {
            foreground = "#00ff00";
            background = "#ffffff";
          } else if (params.node.selected) {
            foreground = fg_selected;
            background = bg_selected;
          } else if (
            !params.node.selected &&
            params.data &&
            "fg" in params.data
          ) {
            foreground = "#" + this.pad(params.data["fg"], 6);
            background = "#" + this.pad(params.data["bg"], 6);
          } else {
            return;
          }

          if (this.isvaluergb(foreground) && this.isvaluergb(background)) {
            return {
              color: foreground,
              background: background,
            };
          } else {
            return;
          }
        }.bind(this);
      } else if (this.currentAnalysis.profiles.selected.coloringrules) {
        var rules = this.currentAnalysis.profiles.selected.coloringrules;

        for (let i = 0; i < rules.length; i++) {
          if (rules[i]["show"]) {
            /* There should be no DOM XSS here anymore
             * It is very dangerous to generate the style definitions here
             * But we control all parameters
             *  - foreground and background are checked by isvaluergb (also checked on serverside)
             *  - stylename
             */

            let cur = rules[i];
            let stylename = cur.id;
            let styleDef;

            if (
              this.isvaluergb(cur.foreground) &&
              this.isvaluergb(cur.background)
            ) {
              styleDef = this.generateStyleDef(
                stylename,
                cur.foreground,
                cur.background
              );
            } else {
              styleDef = this.generateStyleDef(stylename, "#010101", "#020202");
            }

            this.applyStyle(styleDef);
            // this.gridOptions.rowClassRules[stylename] = cur.colorrule;
          }
        }

        this.gridOptions.getRowClass = function(params) {
          if (params === undefined || params.data === undefined) {
            return;
          }

          if (!this.currentAnalysis.profiles.selected.coloringrules) return;

          var rules = this.currentAnalysis.profiles.selected.coloringrules;
          for (let i = 0; i < rules.length; i++) {
            if (rules[i]["show"]) {
              let cur = rules[i];
              let data = params.data;
              let result = eval(cur.colorrule);
              if (result) {
                return cur.id;
              }
            }
          }
        }.bind(this);
      }

      // this.gridOptions.rowClassRules = {
      //   "rag-green-outer": function(params) {
      //     ;
      //     return params.data.year === 2008;
      //   }
      // };
    },
    // cell renderer class
    /* TODO deprecate this function and do a real syntax check in the backend */
    checkIfDisplayfilterContainsUnindexedColumn: function(filter) {
      /* TODO stupid regex, also matches value strings */
      /* we remove all quoted strings first because they
       * should not be considered display filters */
      filter = filter.replace(/"[^"]+"/, "");

      /* this regexp matches all display field names
       * which may contain letters upper/lower, dot or underscore*/
      var re = /[A-Za-z]+(\._[a-z]*)+/g;
      var s = filter;
      var m;

      var unindexedColumns = [];

      do {
        let found = false;
        m = re.exec(s);
        if (m) {
          for (var l of this.columnlookup) {
            if (l.field == m[0]) {
              found = true;
            }
          }
          if (!found) {
            unindexedColumns.push(m[0]);
          }
        }
      } while (m);

      if (unindexedColumns.length > 0) {
        this.error.msg = "Field not indexed: " + unindexedColumns.join(", ");
        this.error.type = "error";
        // force update
        this.currentAnalysis.filterstate = undefined;
        this.currentAnalysis.filterstate = false;
        return false;
      }
      return true;
    },
    updateWithDisplayFilter: function(filter) {
      //this.columnstate = this.gridOptions.columnApi.getColumnState();

      if (filter == null || filter == "") {
        this.filter = "";
      } else {
        this.filter = filter;
        if (!this.checkIfDisplayfilterContainsUnindexedColumn(filter)) {
          return;
        }
      }

      if (!this.currentAnalysis.applyfilter) {
        this.currentAnalysis.filterstate = null;
        return;
      }

      if (this.gridOptions.api) {
        this.triggerUpdate();
      }
    },
    triggerUpdate: function(start = 0, stop = 10000) {
      if (
        this.currentAnalysis.profiles.selected &&
        this.currentAnalysis.profiles.selected.maxPackets
      ) {
        start = 0;
        stop = this.currentAnalysis.profiles.selected.maxPackets;
      }

      if (this.gridOptions.rowModelType == "infinite") {
        this.gridOptions.api.refreshInfiniteCache();
        // this.gridOptions.api.purgeInfiniteCache();
        // this.fetchRowsDataSource({ startRow: start, endRow: stop });
      } else {
        this.fetchRowsDataSource({ startRow: start, endRow: stop });
      }
      this.jumpToPacketBeforeFilterChange();
    },
    jumpToPacketBeforeFilterChange: function() {
      let pnum = this.currentAnalysis.currentpacket;
      this.$eventHub.$emit("goto", pnum);
    },
    highlight: function(params) {
      if (params.clear) {
        this.gridOptions.api.clearRangeSelection();
      }
      this.gridOptions.api.addRangeSelection({
        rowStart: params.row - 1,
        rowEnd: params.row - 1,
        columnStart: params.column,
        columnEnd: params.column,
      });
    },
    gotoFrame: function(frame, highlight = null) {
      if (this.dialogGotoFrame) {
        this.dialogGotoFrame = false;
      }
      if (frame == null || frame === "") {
        return;
      }

      // Ensure gridOptions and its API exist
      if (!this.gridOptions || !this.gridOptions.api) {
        console.warn("gridOptions or its API is not defined.");
        return;
      }

      this.gridOptions.api.forEachNode((node) => {
        // Ensure node.data exists and the "frame#number" field is defined
        if (
          node.data &&
          typeof node.data["frame#number"] !== "undefined" &&
          node.data["frame#number"] == frame
        ) {
          node.setSelected(true);

          // Use node.gridApi if available; otherwise, fall back to gridOptions.api
          if (
            node.gridApi &&
            typeof node.gridApi.ensureNodeVisible === "function"
          ) {
            node.gridApi.ensureNodeVisible(node, "top");
          } else if (this.gridOptions.api.ensureNodeVisible) {
            this.gridOptions.api.ensureNodeVisible(node, "top");
          }
        }
      });
    },

    getheadercolumns: function(arr) {
      if (this.columnlookup) {
        return;
      }
      ApiDisplayfilter.getmultifields({ fields: arr })
        .then(({ data }) => {
          this.columnlookup = data;
        })
        .catch(() => {
          this.snackbartext =
            "Could not lookup multi thsark fields for profile " + profile.name;
          this.snackbar = true;
        });
    },
    getcolumnsdefsforindexer: function() {
      ApiIndexerprofile.get(this.index);
      ApiIndexerprofile.columns(this.index).then(({ data }) => {
        this.columnlookup = data.fields;
        this.currentAnalysis.fielddefinitions = data.fields;
        //TODO map header names and replace old array
        //appending array to array with spread operator
      });
    },
    fixtcporder: function(streamid) {
      let p = {
        pcapid: this.id,
        stream: streamid,
      };

      this.error.type = "info";
      this.error.msg = "A new file with the fixed stream will be created";

      ApiPacketlist.fixtcporder(p).then(({ data }) => {});
    },
    followcontent: function(follow, filter) {
      let p = {
        pcapid: this.id,
        follow: follow,
        filter: filter,
      };

      ApiPacketlist.follow(p).then(({ data }) => {
        this.currentAnalysis.followdata = data;
        this.currentAnalysis.showFollow = true;
      });
    },
    replaceRowsAsync: async function(data) {
      var completed = false;
      var counter = 0;
      var status;

      while (1 == 1) {
        await this.sleep(10);

        if (counter > 10) {
          console.log("Aborting waiting for API");
          return;
        }

        if (this.gridOptions && this.gridOptions.api) {
          this.gridOptions.api.setRowData(data);
          return;
        } else {
          counter++;
        }
      }
    },

    replaceRows: async function(data) {
      if (this.gridOptions && this.gridOptions.api && data) {
        this.gridOptions.api.setRowData(data);

        // if (data[0]["frame#number"] == 1) {
        //   let node = this.gridOptions.api.getRowNode(1);
        //   node.setSelected(true);
        // }
      } else {
        this.newrows = data;
      }

      //await this.replaceRowsAsync(data)

      // if (this.gridOptions && this.gridOptions.api && data) {
      //   this.gridOptions.api.setRowData(data);
      // } else {

      // }
    },
    setDisplayFilter: function(filter) {
      var value = filter.replace(/data\["(.*)"\]/, "$1");
      this.df_apply_to_packetlist(value);
      // this.$eventHub.$emit("displayfilter-set", value);
    },
    sleep: async function(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    monitorLoading: async function(pcapid, interval) {
      var completed = false;
      var status;

      while (1 == 1) {
        await this.sleep(interval);

        status = await ApiPcapmeta.loadstatus(pcapid);

        this.loadstatus = status.data;
        if (status.data.status == 3) {
          return true;
        } else {
          this.loadstatus = status.data;
        }
      }
    },
    fetchRowsDataSource: async function(params) {
      if (this.newrows) {
        this.replaceRows(this.newrows);
        this.newrows = null;
      }

      if (this.gridOptions && this.gridOptions.api) {
        this.filter = this.currentAnalysis.filter;
        var start = params.startRow;
        var end = params.endRow;
        if (start < 0) {
          return;
        }

        if (this.index == "none") {
          //var loaded = await this.monitorLoading(this.id, 1000)
          //if(loaded){
          this.fetchUnindexed(params);
          //}
        } else {
          this.fetchRows(start, end, null, params);
        }
      }
    },

    // static const gchar *const slist[NUM_COL_FMTS] = {
    //     "%q",                                       /* 0) COL_8021Q_VLAN_ID */
    //     "%Yt",                                      /* 1) COL_ABS_YMD_TIME */
    //     "%YDOYt",                                   /* 2) COL_ABS_YDOY_TIME */
    //     "%At",                                      /* 3) COL_ABS_TIME */
    //     "%V",                                       /* 4) COL_VSAN - !! DEPRECATED !!*/
    //     "%B",                                       /* 5) COL_CUMULATIVE_BYTES */
    //     "%Cus",                                     /* 6) COL_CUSTOM */
    //     "%y",                                       /* 7) COL_DCE_CALL */
    //     "%Tt",                                      /* 8) COL_DELTA_TIME */
    //     "%Gt",                                      /* 9) COL_DELTA_TIME_DIS */
    //     "%rd",                                      /* 10) COL_RES_DST */
    //     "%ud",                                      /* 11) COL_UNRES_DST */
    //     "%rD",                                      /* 12) COL_RES_DST_PORT */
    //     "%uD",                                      /* 13) COL_UNRES_DST_PORT */
    //     "%d",                                       /* 14) COL_DEF_DST */
    //     "%D",                                       /* 15) COL_DEF_DST_PORT */
    //     "%a",                                       /* 16) COL_EXPERT */
    //     "%I",                                       /* 17) COL_IF_DIR */
    //     "%F",                                       /* 18) COL_FREQ_CHAN */
    //     "%hd",                                      /* 19) COL_DEF_DL_DST */
    //     "%hs",                                      /* 20) COL_DEF_DL_SRC */
    //     "%rhd",                                     /* 21) COL_RES_DL_DST */
    //     "%uhd",                                     /* 22) COL_UNRES_DL_DST */
    //     "%rhs",                                     /* 23) COL_RES_DL_SRC*/
    //     "%uhs",                                     /* 24) COL_UNRES_DL_SRC */
    //     "%e",                                       /* 25) COL_RSSI */
    //     "%x",                                       /* 26) COL_TX_RATE */
    //     "%f",                                       /* 27) COL_DSCP_VALUE */
    //     "%i",                                       /* 28) COL_INFO */
    //     "%rnd",                                     /* 29) COL_RES_NET_DST */
    //     "%und",                                     /* 30) COL_UNRES_NET_DST */
    //     "%rns",                                     /* 31) COL_RES_NET_SRC */
    //     "%uns",                                     /* 32) COL_UNRES_NET_SRC */
    //     "%nd",                                      /* 33) COL_DEF_NET_DST */
    //     "%ns",                                      /* 34) COL_DEF_NET_SRC */
    //     "%m",                                       /* 35) COL_NUMBER */
    //     "%L",                                       /* 36) COL_PACKET_LENGTH */
    //     "%p",                                       /* 37) COL_PROTOCOL */
    //     "%Rt",                                      /* 38) COL_REL_TIME */
    //     "%s",                                       /* 39) COL_DEF_SRC */
    //     "%S",                                       /* 40) COL_DEF_SRC_PORT */
    //     "%rs",                                      /* 41) COL_RES_SRC */
    //     "%us",                                      /* 42) COL_UNRES_SRC */
    //     "%rS",                                      /* 43) COL_RES_SRC_PORT */
    //     "%uS",                                      /* 44) COL_UNRES_SRC_PORT */
    //     "%E",                                       /* 45) COL_TEI */
    //     "%Yut",                                     /* 46) COL_UTC_YMD_TIME */
    //     "%YDOYut",                                  /* 47) COL_UTC_YDOY_TIME */
    //     "%Aut",                                     /* 48) COL_UTC_TIME */
    //     "%t"                                        /* 49) COL_CLS_TIME */

    prepareColumnsParams: function() {
      var columns = [
        // "frame#number",
        // "frame#time_relative",
        // "frame#time_delta_displayed",
        // "_ws#col#Source",
        // "_ws#col#Destination",
        // "_ws#col#Protocol",
        // "frame#len",
        // "_ws#col#Info",
        // "tcp#flags#syn"
      ];

      var profilecolumns = this.currentAnalysis.profiles.selected.columns;

      for (let c of profilecolumns) {
        columns.push(c.field);
      }

      // adding essential columns

      for (let c of this.essentialscolumns) {
        if (!(c in columns)) {
          columns.push(c);
        }
      }

      return columns;
    },
    prepareTimeReferencesToSend() {
      var timereferences = [...this.timereferences].sort(function(a, b) {
        return a - b;
      });
      return timereferences;
    },
    fetchUnindexed: function(params) {
      let columns = this.prepareColumnsParams();
      let timereferences = this.prepareTimeReferencesToSend();

      var columnformatlookup = {
        "frame#time_delta_displayed": "9",
        "_ws#col#Source": "39",
        "_ws#col#Destination": "14",
        "_ws#col#Protocol": "37",
        "_ws#col#Info": "28",
      };

      var columnstosend = [];
      for (let c of columns) {
        if (c in columnformatlookup) {
          columnstosend.push(columnformatlookup[c]);
        } else {
          columnstosend.push(c);
        }
      }

      let options = {
        pcapid: this.id,
        filter: this.filter,
        start: params.startRow,
        end: params.endRow,
        columns: columnstosend,
        timereferences: timereferences,
      };

      options["profileid"] = this.currentAnalysis.profiles.selected.id;

      if (
        "skipmatchcount" in this.currentAnalysis.profiles.selected &&
        this.currentAnalysis.profiles.selected.skipmatchcount
      ) {
        options.skipmatchcount = this.currentAnalysis.profiles.selected.skipmatchcount;
      }

      if (this.currentAnalysis.selectStartDate != undefined) {
        options.starttime = this.currentAnalysis.selectStartDate;
        options.endtime = this.currentAnalysis.selectEndDate;
      }

      if (this.currentAnalysis.iostart != undefined) {
        options.start = this.currentAnalysis.iostart;
        options.end = this.currentAnalysis.ioend;
      }

      // force update
      this.currentAnalysis.filterstate = undefined;
      this.currentAnalysis.loadstatus.progress = undefined;
      this.currentAnalysis.totalshown = undefined;
      this.currentAnalysis.matching = undefined;
      //this.currentAnalysis.limit = undefined

      // if(this.currentAnalysis.loadingpackets){
      //   return
      // }

      this.currentAnalysis.loadingpackets = true;

      /* check cache */
      let cdata = this.checkCache(options);
      if (cdata !== undefined) {
        this.updateGrid(cdata, columns, params, true);
        return;
      }
      if (this.currentAnalysis.firstloadpacketlist) {
        this.currentAnalysis.longoperationoverlay = true;
      }
      this.currentAnalysis.firstloadpacketlist = false;

      if (this.currentAnalysis.streamFilter == true) {
        options.streamsforfilter = true;
      }


      ApiPacketlist.packetlist(options)
        .then(({ data }) => {
          if ("error" in data) {
            //throw new Error(data.error.message)
          }
          this.updateGrid(data.result, columns, params);
        })
        .catch((error) => {
          this.currentAnalysis.longoperationoverlay = false;
          this.currentAnalysis.loadingpackets = false;
          var err = error.response.data["error"];
          this.result = err;
          if (err == "unauthorized" || err == "does not exist") {
            this.currentAnalysis.error =
              this.currentAnalysis.pcapid + " " + err;
            this.currentAnalysis.authorized = false;
            this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
          }
          this.currentAnalysis.filterstate = false;
        });
    },
    removeCurrentFromCacheAndRefresh() {
      this.removeCurrentFromCache();
      this.triggerUpdate();
    },
    updateGrid(data, columns, params, iscached = false) {
      this.currentAnalysis.longoperationoverlay = false;
      this.currentAnalysis.loadingpackets = false;
      this.currentAnalysis.authorized = true;
      this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);

      if (!iscached) {
        this.currentAnalysis.columnsparam = columns;
        this.packetListToCache(data);
      }

      this.currentAnalysis.cachedresult = iscached;

      this.currentAnalysis.filterstate = true;
      //var lastRow = data.length;

      this.traceFields = columns;
      var table = this.sharkd2UgTable(data["packets"], columns);
      // if (start == 0) {
      //   this.traceFields = data["columns"];

      //   // this.loadColumnPreferences();
      //   //gridOptions.api.setColumnDefs(datatable2UgColumns(data));
      //   this.gridOptions.api.sizeColumnsToFit();
      // }

      if (this.currentAnalysis.profiles.selected.minimap) {
        this.packetcolorsToScrollbar(table);
      }

      //let numFetchedPackets = data.packets.length;
      let numMatchingPackets = data.frames_matching;
      let numDisplayedPackets = data.frames_displayed;
      let numLimitPackets = data.limit;
      let numTotalPackets = data.frames_total;

      this.currentAnalysis.packetlist.displayed.length = 0;
      //remember which packet numbers we are currently showing
      for (let item of table) {
        this.currentAnalysis.packetlist.displayed.push(item["frame#number"]);
      }

      this.result = "ok";

      if (this.currentAnalysis.selectStartDate != undefined) {
        this.updateAnalysis(
          numTotalPackets,
          numDisplayedPackets,
          numMatchingPackets,
          numLimitPackets
        );

        if (this.gridOptions.rowModelType != "infinite") {
          this.replaceRows(table, iscached);
          return;
        } else {
          params.successCallback(table, numDisplayedPackets);
        }
      } else {
        this.updateAnalysis(
          numTotalPackets,
          numDisplayedPackets,
          numMatchingPackets,
          numLimitPackets
        );
        if (this.gridOptions.rowModelType != "infinite") {
          this.replaceRows(table, iscached);
          return;
        } else {
          params.successCallback(table, numDisplayedPackets);
        }
      }
    },
    fetchRows: function(s = 0, e = 10000, total = null, params) {
      let options = {
        index: this.index,
        pcapid: this.id,
        filter: this.filter,
        start: s,
        end: e,
        streamsforfilter: false,
      };

      if (this.currentAnalysis.selectStartDate != undefined) {
        params.start = this.currentAnalysis.selectStartDate;
        params.end = this.currentAnalysis.selectEndDate;
      }

      /* TODO this is highlty performacne relevant */
      //this.$eventHub.$emit("cachepackets", [start, end]);
      this.getSavedColumns();

      // force update
      this.currentAnalysis.filterstate = undefined;

      //TODO getstreamsfor filter is of now
      if (this.currentAnalysis.streamFilter == true) {
        options.streamsforfilter = true;
      }

      // FIXME broken
      ApiPacketlist.indexedpacketlist(options)
        .then(({ data }) => {
          this.currentAnalysis.authorized = true;
          this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
          this.result = "ok";
          this.currentAnalysis.filterstate = true;

          //var lastRow = data.length;

          var table = this.elastic2UgTable(data);

          if (options.start == 0) {
            this.traceFields = data["columns"];

            // this.loadColumnPreferences();
            //gridOptions.api.setColumnDefs(datatable2UgColumns(data));
            this.gridOptions.api.sizeColumnsToFit();
          }

          params.successCallback(table, data["total"]);

          let numberofpackets = data.data.length;
          if (numberofpackets > 0) {
            var firstPacket = parseInt(data.data[0][1]);
            this.updateAnalysis(data["total"], numberofpackets);
          } else {
            this.updateAnalysis(data["total"], 0);
          }

          this.gridOptions.api.ensureIndexVisible(
            data.data[0]["frame#number"][0]
          );
        })
        .catch((error) => {
          var err = error.response.data["error"];
          this.result = err;
          if (err == "unauthorized" || err == "does not exist") {
            this.currentAnalysis.error =
              this.currentAnalysis.pcapid + " " + err;
            this.currentAnalysis.authorized = false;
            this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
          }
          this.currentAnalysis.filterstate = false;
        });
    },
    updateAnalysis: function(
      total,
      shownpackets,
      matching = 0,
      limitpackets = 0
    ) {
      this.initialpacketfetch = false;
      // in client side mode

      let limit = this.currentAnalysis.profiles.selected.maxPackets;

      // if (!this.currentAnalysis.profiles.selected.infinitepacketlist) {
      //   if (limit == shownpackets && limit < total) {
      //     this.error.type = "clientsidewarning";
      //     this.error.val = total;
      //     this.error.msg =
      //       "" +
      //       "Only " +
      //       shownpackets +
      //       " shown but trace has " +
      //       total +
      //       " packets." +
      //       "In client side mode the maximum number of loaded packets is determined " +
      //       "by the maximum packets profile setting. " +
      //       "Change to infinite mode, set the maximum number higher to see all packets or just click load all";
      //   }
      // }

      var a = this.currentAnalysis;
      if (total != 0) {
        a.total = total;
      }
      a.totalshown = shownpackets;
      a.matching = matching;
      a.limit = limitpackets;
      this.$store.dispatch(UPDATE_ANALYSIS, a);
    },
    getDataSource: function(count) {
      function MyDatasource(rowCount) {
        this.rowCount = rowCount;
      }
      MyDatasource.prototype.getRows = this.fetchRowsDataSource;
      return new MyDatasource(count);
    },
    populateMetaColumns: function(input) {
      return this.fixAddressColumns(input);
    },
    setIfNotEmpty: function(data, column, target) {
      if (data[column] != "" && data[column] !== undefined) {
        data[target] = data[column];
      }
    },

    /* meta columns
     *
     * meta_src, meta_dst, meta_srcport, meta_dstport
     *
     */
    fixAddressColumns: function(input) {
      for (var i = 0; i < input.length; i++) {
        for (var j = 0; j < this.metaColumns.length; j++) {
          for (var k = 0; k < this.metaColumns[j]["sources"].length; k++) {
            this.setIfNotEmpty(
              input[i],
              this.metaColumns[j].sources[k],
              this.metaColumns[j]["field"]
            );
          }
        }
      }
      return input;
    },
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridOptions.api.sizeColumnsToFit();
      //this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    sharkd2UgTable: function(data, columns) {
      var items = data;
      this.currentAnalysis.showncolumns = columns;

      var output = [];

      for (var i = 0; i < items.length; i++) {
        var outitem = {};
        for (var j = 0; j < columns.length; j++) {
          /* adjust frame time for local timezone in packetlist */
          if (columns[j] == "frame#time") {
            let time = items[i]["c"][j];
            let clientTime = this.renderUTCtoClientTime(time);
            outitem[columns[j]] = clientTime;
          } else {
            outitem[columns[j]] = items[i]["c"][j];
          }
        }

        if ("bg" in items[i]) {
          outitem["bg"] = items[i]["bg"];
        }

        if ("fg" in items[i]) {
          outitem["fg"] = items[i]["fg"];
        }

        output.push(outitem);
      }
      /* TODO needs serious optimization
       * BUT we only need this in indexed mode which we don't use nowadays,
       * this function being called already implies we use sharkd and not indexed
       */
      // if(this.index == "none"){
      //   output = this.populateMetaColumns(output);
      // }

      return output;
    },
    elastic2UgTable: function(input) {
      var columns = input.columns;
      var items = input.data;
      this.currentAnalysis.showncolumns = input.columns;

      var output = input.data;

      for (var i = 0; i < output.length; i++) {
        for (const [key, value] of Object.entries(output[i])) {
          output[i][key] = value[0];
        }
      }

      /* TODO needs serious optimization */
      output = this.populateMetaColumns(output);
      return output;
    },
    mapHeaderName: function(inname) {
      var headerNames = {
        "_ws#col#Source": "Source",
        "_ws#col#Destination": "Destination",
        "eth#src": "Ethernet Source",
        "eth#dst": "Ethernet Destination",
        "ip#src": "Source IP",
        "ip#dst": "Destination IP",
        "ipv6#src": "Source IPv6",
        "ipv6#dst": "Destination IPv6",
        "tcp#srcport": "TCP Source Port",
        "tcp#dstport": "UDP Destination Port",
        "udp#srcport": "TCP Source Port",
        "udp#dstport": "UDP Destination Port",
        "frame#number": "Number",
        "frame#len": "Length",
        "_ws#col#Info": "Info",
        "frame#colorrules_matched": "Matching colorrules",
        "frame#time_relative": "Time",
        "frame#time_delta_displayed": "Delta time",
        "frame#time_epoch": "Time Epoch",
      };

      if (inname in headerNames) {
        return headerNames[inname];
      }

      if (this.columnlookup == null) {
        return "unknown column";
      }

      for (var i of this.columnlookup) {
        var m = i.field;
        if (m == inname) {
          return i.description;
        }
      }
      return inname;
    },

    datatable2UgColumns: function(input) {
      var columns = input;

      var output = [];

      this.getheadercolumns(columns);

      for (var i = 0; i < columns.length; i++) {
        var outitem = {};
        outitem["headerName"] = this.mapHeaderName(columns[i]);
        outitem["field"] = columns[i];
        outitem["width"] = 140;
        outitem["suppressSizeToFit"] = true;

        /*
              if(outitem["field"] == "eth_src"){
                  continue
              }

              if(outitem["field"] == "eth_dst"){
                  continue
              }*/

        if (outitem["field"] == "_ws.col.Info") {
          outitem["width"] = 300;
          outitem["suppressSizeToFit"] = false;
        }
        output.push(outitem);
      }

      /* add meta columns */
      output = output.concat(this.metaColumns);

      /* add color columns */
      output = output.concat();

      return output;
    },
    /* source address - destination address
     * source port  destination prot
     *
     * are special columns that merge several vaulues together
     * we decided which ones to merge based on the columnpreferences
     * and available data
     */
    specialDisplayColumns: function(data) {},
    getDeltaTime: function(prevp, thisp) {
      // var prevt = prevp.split(".");
      // var thist = thisp.split(".");

      var diff = Math.abs(thisp - prevp);
      return diff.toFixed(6);
    },
    epochToRelative: function(time) {
      // var times = time.split(".");
      // var epochmilli = times[0] + times[1].slice(0, 3);
      // var epochmicro = times[1].slice(3);

      if (this.starttime == "0") {
        this.starttime = time;
        return "0.000000";
      }

      var diff = Math.abs(time - this.starttime);
      return diff.toFixed(6);
    },
    containsColumnField: function(field, columns) {
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].field == field) {
          return true;
        }
      }
      return false;
    },
    getSavedColumns: function() {
      /*ApiService.query("ajax/columns").then(({ data }) => {
        this.savedColumns = data;
      });*/
    },
    getColumnStatus: function() {
      var colstate = this.gridOptions.columnApi.getColumnState();
      var columns = [];
      for (var c of colstate) {
        var i = this.gridOptions.columnApi.getColumn(c["colId"]).getColDef();
        i.hide = c["hide"];
        i.width = c["width"];
        columns.push(i);
      }
      return JSON.stringify(columns);
    },
    loadColumnPreferences: function() {
      if (!this.traceFields) {
        return;
      }

      this.currentAnalysis.indexedfields = this.traceFields;

      if (this.columnstate != null) {
        this.gridOptions.columnApi.setColumnState(this.columnstate);
        //this.gridOptions.api.setColumnDefs(this.columnstate);
        return;
      }
      /* these are the columns we have in the queried data */
      var dataColumns = this.datatable2UgColumns(this.traceFields);

      //columnState = gridOptions.columnApi.getAllDisplayedColumns();
      /* these are the columns the user wants displayed */
      var savedColumns = [
        {
          headerName: "Number",
          field: "frame#number",
          width: 80,
          suppressSizeToFit: true,
        },
        {
          headerName: "Time",
          field: "frame#time_relative",
          width: 90,
          suppressSizeToFit: true,
        },
        {
          headerName: "Delta time",
          field: "frame#time_delta_displayed",
          width: 90,
          suppressSizeToFit: true,
        },
        {
          headerName: "Source",
          field: "_ws#col#Source",
          width: 130,
          suppressSizeToFit: true,
        },
        {
          headerName: "Destination",
          field: "_ws#col#Destination",
          width: 130,
          suppressSizeToFit: true,
        },
        {
          headerName: "Length",
          field: "frame#len",
          width: 75,

          suppressSizeToFit: true,
        },
        {
          headerName: "Info",
          field: "_ws#col#Info",
          width: 540,
          suppressSizeToFit: false,
        },
      ];
      if (this.currentAnalysis.profiles.selected.columns) {
        savedColumns = this.currentAnalysis.profiles.selected.columns;
      }

      var displayColumns = [];

      /* compare the above to and:
       *  if the column is in both -> display it
       *  if in saved but not in data -> don't display it at all
       *  if in data but not displayed -> include it but hide it
       *  TODO naive approach needs to be optimized
       */
      for (var i = 0; i < savedColumns.length; i++) {
        if (this.containsColumnField(savedColumns[i].field, dataColumns)) {
          displayColumns.push(savedColumns[i]);
        } else {
          continue;
        }
      }
      //console.log(displayColumns);

      for (i = 0; i < dataColumns.length; i++) {
        if (this.containsColumnField(dataColumns[i].field, savedColumns)) {
          continue;
        } else {
          dataColumns[i]["hide"] = true;
          displayColumns.push(dataColumns[i]);
        }
      }

      if (!this.currentAnalysis.profiles.selected.enableCustomCellRenders) {
        for (i = 0; i < displayColumns.length; i++) {
          /* because sharkd produces exponential notation we need to
           * render relative and delta time always with a renderer */
          if (
            displayColumns[i].field == "frame#time_relative" ||
            displayColumns[i].field == "frame#time_delta_displayed"
          ) {
            // use formatter instead for performance
            displayColumns[i].valueFormatter = (params) => {
              try {
                return params.value.toFixed(9);
              } catch {
                return params.value;
              }
            };
            //displayColumns[i].cellRendererFramework = "RelativeTimeRenderer"
          } else {
            delete displayColumns[i].cellRendererFramework;
          }
        }
      } else {
        // load some helper data to help with rendering
        for (i = 0; i < displayColumns.length; i++) {
          if (
            displayColumns[i].cellRendererFramework &&
            displayColumns[i].cellRendererFramework != "Default"
          ) {
            this.loadStatistics(displayColumns[i].field);
          } else {
            delete displayColumns[i].cellRendererFramework;
          }
        }
      }
      //displayColumns[1].dndSource = true
      //displayColumns[1].dndSourceOnRowDrag= this.onRowDrag
      //console.log(columnState);
      /*for(var i=0; i < columnState.length; i++){
              states.push(columnState[i].colDef);
          }*/
      //console.log(displayColumns);

      //apply font family
      for (i = 0; i < displayColumns.length; i++) {
        displayColumns[i]["cellStyle"] = {
          "font-family": this.getFont(),
          "font-size": this.getFontSize(),
        };
        if (this.currentAnalysis.profiles.selected.packetlistgrouping) {
          displayColumns[i].enableRowGroup = true;
        }
      }
      this.gridOptions.api.setColumnDefs(displayColumns);
      this.autoSizeAll(false);
      //this.gridOptions.api.sizeColumnsToFit();
    },
    cellMouseDown: function(params) {
      return; //work on dnd
      let left = params.column.colId;
      let filter = this.df_build_filter(left, params.value);
      this.currentAnalysis.dndfilter = filter;
    },
    loadStatistics: function(field) {
      ApiDisplayfilter.explainfield({
        field: field,
        pcapid: this.currentAnalysis.pcapid,
        index: this.currentAnalysis.index,
      }).then(({ data }) => {
        try {
          data["fieldinfo"]["field"] = this.df_es_field_to_wireshark(
            data["fieldinfo"]["field"]
          );
          this.currentAnalysis.rendering.fields[field] = {
            histogram: data["histogram"],
            fieldinfo: data["fieldinfo"],
            extended: data["extendedstats"],
          };
        } catch (e) {}
      });
    },
    /* for now just hide column */
    removeColumn: function(c) {
      for (let e of this.currentAnalysis.profiles.selected.columns) {
        if (e.headerName == c.colDef.headerName) {
          let index = this.currentAnalysis.profiles.selected.columns.indexOf(e);
          this.currentAnalysis.profiles.selected.columns.splice(index, 1);
        }
      }
      if (this.currentUser) {
        this.saveProfile();
      }

      this.currentAnalysis.profileneedsupdate = true;
      //this.gridOptions.columnApi.setColumnVisible(column, false);
    },
    /* for now just show column */
    addColumn: function(column) {
      this.gridOptions.columnApi.setColumnVisible(column, true);
    },

    /* change name of column */
    changeColumnName: function() {
      this.columnState = this.gridOptions.columnApi.getAllDisplayedColumns();
      /*  modifications */
      this.gridOptions.columnApi.setColumnState(this.columnState);
    },

    packetcolorsToScrollbar: function(packets) {
      let len = packets.length;
      let stylebegin =
        "#packetlist ::-webkit-scrollbar-track {	background: linear-gradient(";
      let styleend = ")}";
      let colorstops = [];
      let stopcolor = "";
      let stop = "";
      let stopval = 0;

      let same = 0;
      let last = "#ffffff00";
      for (let i = 0; i < len; i++) {
        stopval = (100 / len) * i;

        if (!("bg" in packets[i])) {
          stopcolor = "#ffffff00";
        } else {
          if (packets[i].bg == "0") {
            stopcolor = "#000000";
          } else if (packets[i].bg == "ffffff") {
            stopcolor = "#ffffff00";
          } else {
            stopcolor = "#" + packets[i].bg.padStart(6, "0");
          }
        }

        if (last == stopcolor) {
          same++;
          if (same > 2) {
            continue;
          }
        }

        //stop = "color-stop(" + stopval + ",#" + stopcolor + "), ";
        stop = stopcolor + " " + stopval.toFixed(4) + "%, ";
        let whitefactor = stopval * 0.9999;
        let difffactor = stopval * 1.000001;
        if (last == "#ffffff00" && stopcolor != "#ffffff00") {
          stylebegin =
            stylebegin + "#ffffff00" + " " + whitefactor.toFixed(4) + "%, ";
        } else if (last != stopcolor) {
          stylebegin = stylebegin + last + " " + difffactor.toFixed(4) + "%, ";
        }

        stylebegin = stylebegin + stop;

        last = stopcolor;
      }

      let finished = stylebegin + stopcolor + " 100%" + styleend;

      this.applyStyle(finished);
      this.applyStyle(
        "#packetlist  .ag-body-viewport::-webkit-scrollbar {width: 30px;})"
      );
      this.applyStyle(
        "#packetlist  .ag-body-viewport::-webkit-scrollbar-thumb {height: 15px; border-radius: 3px;-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.9);background-color: rgb(168, 164, 218, 0.3);}"
      );
    },
    navigateToNextCell: function(params) {
      var previousCell = params.previousCellDef;
      var suggestedNextCell = params.nextCellDef;

      var KEY_UP = 38;
      var KEY_DOWN = 40;
      var KEY_LEFT = 37;
      var KEY_RIGHT = 39;

      switch (params.key) {
        case KEY_DOWN:
          previousCell = params.previousCellDef;
          // set selected cell on current cell + 1
          this.gridOptions.api.forEachNode((node) => {
            if (previousCell.rowIndex + 1 === node.rowIndex) {
              node.setSelected(true);
            }
          });
          return suggestedNextCell;
        case KEY_UP:
          previousCell = params.previousCellDef;
          // set selected cell on current cell - 1
          this.gridOptions.api.forEachNode((node) => {
            if (previousCell.rowIndex - 1 === node.rowIndex) {
              node.setSelected(true);
            }
          });
          return suggestedNextCell;
        case KEY_LEFT:
        case KEY_RIGHT:
          return suggestedNextCell;
        default:
          throw "this will never happen, navigation is always on of the 4 keys above";
      }
    },
  },
};
</script>

<style>
/*
.theme--light.application {
  background: #ffffff !important;
}*/
#packetlist .ag-theme-balham .ag-root {
  border: 0px !important;
}

#packetlist .ag-theme-balham .ag-row:not(.ag-row-first) {
  border-width: 0px 0 0;
}

/*
#packetlist .ag-theme-balham,
#packetlist .ag-theme-balham-dark {
  font-family: monospace !important;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}*/

#packetlist .ag-row {
  border-bottom: 0px solid #bdc3c7 !important;
}

#packetlist .ag-cell,
#packetlist .ag-cell-focus,
#packetlist .ag-cell-no-focus {
  border: none !important;
}

#packetlist .no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

#packetlist .ag-cell:focus {
  border: none !important;
  outline: none;
}

#packetlist .marked-frame {
  background: black;
  color: white;
}

#packetlist .ag-status-bar {
  display: none !important;
}

#packetlistrow {
  margin-bottom: 0px;
}

.disable-pointer-events {
  pointer-events: none;
}

/*
.ag-row-odd:not(.ag-row-selected) {
  background-color: #fff !important;
}*/

.ag-theme-balham .ag-row-selected {
  background-color: #02107b !important;
  color: #edeff0 !important;
}

/*
.ag-tool-panel {
  visibility: hidden;
  width: 0px;
}*/

#packetlist {
  font-size: 80%;
}

/* This CSS is to not apply the border for the column having 'no-border' class */

/* .ag-layout-normal.ag-body-viewport {
  overflow: hidden !important;
} */

/* This CSS is to not apply the border for the column having 'no-border' class */

/* width */
</style>
