<template>
    <!-- The text is output using v-html so that any valid HTML in the string is rendered -->
    <div v-html="displayText"></div>
  </template>
  
  <script>
  export default {
    name: "TypingText",
    props: {
      text: {
        type: String,
        required: true
      },
      // The interval (in ms) between adding words (adjust for your desired speed)
      interval: {
        type: Number,
        default: 50
      },
      // If false the full text is rendered immediately
      animate: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        displayText: "",
        words: [],
        currentIndex: 0,
        timer: null
      };
    },
    watch: {
      text: {
        immediate: true,
        handler(newText) {
          // Restart the animation each time the text changes
          this.displayText = "";
          this.words = newText.split(" ");
          this.currentIndex = 0;
          if (this.animate) {
            this.startAnimation();
          } else {
            this.displayText = newText;
          }
        }
      }
    },
    methods: {
      startAnimation() {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          if (this.currentIndex < this.words.length) {
            // Append a space before each word (except the first)
            if (this.currentIndex === 0) {
              this.displayText = this.words[this.currentIndex];
            } else {
              this.displayText += " " + this.words[this.currentIndex];
            }
            this.currentIndex++;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, this.interval);
      }
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  };
  </script>
  