<template>
    <div class="bg-white">
      <div class="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div class="lg:grid lg:grid-cols-12 lg:gap-8">
          <div class="lg:col-span-5">
            <h2 class="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Frequently Asked Questions
            </h2>
            <p class="mt-4 text-pretty text-base text-gray-600">
              Can’t find the answer you’re looking for? Reach out to our
              <a href="/support" class="font-semibold text-indigo-600 hover:text-indigo-500">
                customer support
              </a>
              team.
            </p>
          </div>
          <div class="mt-10 lg:col-span-7 lg:mt-0">
            <dl class="space-y-10">
              <div v-for="faq in faqs" :key="faq.question">
                <dt class="text-base font-semibold text-gray-900">{{ faq.question }}</dt>
                <dd class="mt-2 text-base text-gray-600">{{ faq.answer }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PacketSafariFaq",
    data() {
      return {
        faqs: [
          {
            question: "Is PacketSafari free?",
            answer:
              "Yes, PacketSafari is free for uploads up to 1 MB."
          },
          {
            question: "What are the benefits of registering?",
            answer:
              "Registering gives you an easier upload experience without having to provide a description every time."
          },
          {
            question: "What does PacketSafari Pro offer?",
            answer:
              "PacketSafari Pro provides private uploads and the ability to use Copilot, an AI-assisted packet analysis tool."
          }
        ]
      };
    }
  };
  </script>
  