<template>
  <div>
    <!-- Show a loader while data is being fetched -->

    <v-container fluid class="pa-6">
      <v-row justify="center">
        <v-col cols="12" md="10" class="text-center">
          <h1 class="display-2 font-weight-bold mb-4">Summary</h1>
          <p class="subtitle-1 mb-4">
            This view provides a
            comprehensive overview of your PCAP file's basic information,
            permissions, comments, and indexes. An index catalogs specific fields within
            your PCAP file to make it searchable and unlock advanced features.
            By indexing these fields, PackertSafari allows for rapid filtering,
            sorting, and detailed exploration of your network traffic.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <!-- Once loaded, render the pcapdetails component with the fetched PCAP item -->
    <div v-else>
      <pcapdetails :pcap="item"></pcapdetails>
    </div>
  </div>
</template>

<script>
import Pcapdetails from "./Pcapdetails";
import ApiPcapmeta from "@/common/api/pcapmeta";

export default {
  name: "Pcapsummary",
  components: {
    Pcapdetails,
  },
  // With `props: true` in the router config, the id is automatically passed as a prop
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      item: null,
    };
  },
  created() {
    this.fetchPcap();
  },
  methods: {
    fetchPcap() {
      ApiPcapmeta.get(this.id)
        .then(({ data }) => {
          this.item = data;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching PCAP:", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
