<template>
  <div class="pa-0 ma-0" style="display: inline">
    <!-- Context menu for editing/deleting (shown if the user can edit) -->
    <v-menu
      v-model="showfilterbuttonmenu"
      :position-x="filterbuttonmenux"
      :position-y="filterbuttonmenuy"
      absolute
      offset-y
      v-if="canEditCurrentProfile"
    >
      <v-card>
        <v-list dense class="dense-list">
          <v-list-item @click="editfilterbutton(menubutton)" class="dense-list-item">
            <v-list-item-action>
              <v-icon small>edit</v-icon>
            </v-list-item-action>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>

          <v-list-item @click="removefilterbutton(menubutton)" class="dense-list-item">
            <v-list-item-action>
              <v-icon small>delete</v-icon>
            </v-list-item-action>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <!-- Mobile view: Filters button opens a menu with the filter buttons -->
    <!-- <template v-if="$vuetify.breakpoint.mobile"> -->
    <template v-if="true">
      <v-menu
        v-model="mobileFilterMenu"
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
 <v-chip
  v-bind="attrs"
  v-on="on"
  label
  small
  color="secondary"
  class="mx-1 my-0"
>
  <v-icon left>filter_list</v-icon>
  Filters
</v-chip>

        </template>
        <v-card>
          <v-list dense class="dense-list">
            <div
              v-for="(grouporbutton, index) in groupedbuttons"
              :key="index"
            >
              <!-- Simple button item -->
              <v-list-item
                v-if="grouporbutton.type === 'button'"
                class="dense-list-item"
                @click="applyDisplayFilterAndClose(grouporbutton.rule)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ grouporbutton.rulename }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Group with nested buttons -->
              <v-list-group v-else no-action class="dense-list-item">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ grouporbutton.groupname }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(button, idx) in grouporbutton.buttons"
                  :key="idx"
                  class="dense-list-item"
                  @click="applyDisplayFilterAndClose(button.rule)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ button.rulename }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
          </v-list>
        </v-card>
      </v-menu>
    </template>

    <!-- Desktop view: render inline chips in a no-wrap flex container -->
    <template v-else>
      <div class="desktop-buttons" style="display: flex; flex-wrap: nowrap; overflow-x: auto;">
        <template v-for="(grouporbutton, index) in groupedbuttons">
          <div :key="index">
            <v-chip
              v-if="grouporbutton.type === 'button'"
              label
              small
              class="mx-1 my-0"
              color="primary"
              @contextmenu.prevent="doshowfilterbuttonmenu($event, grouporbutton.idx)"
              @click="applyDisplayFilterAndClose(grouporbutton.rule)"
            >
              {{ grouporbutton.rulename }}
            </v-chip>

            <v-menu v-else offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  label
                  small
                  class="mx-1"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ grouporbutton.groupname }}
                </v-chip>
              </template>
              <v-list dense class="dense-list">
                <v-list-item
                  link
                  v-for="(button, idx) in grouporbutton.buttons"
                  :key="idx"
                  class="dense-list-item"
                >
                  <v-list-item-title
                    @click="applyDisplayFilterAndClose(button.rule)"
                    @contextmenu.prevent="doshowfilterbuttonmenu($event, button.idx)"
                  >
                    {{ button.rulename }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </div>
    </template>

    <!-- Tooltip for adding new filter button -->
    <v-tooltip
      v-if="
        currentAnalysis.profiles.selected.filterbuttons &&
        currentAnalysis.profiles.selected.filterbuttons.length <= 20 &&
        canEditCurrentProfile
      "
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn icon x-small v-on="on" text @click="addbutton">
          <v-icon small>mdi-filter-plus</v-icon>
        </v-btn>
      </template>
      <span>Add filter button</span>
    </v-tooltip>

    <v-tooltip v-else-if="false" bottom>
      <template v-slot:activator="{ on }">
        <v-btn disabled icon small v-on="on" text>
          <v-icon small>mdi-filter-plus</v-icon>
        </v-btn>
      </template>
      <span>To add new buttons please create a new profile</span>
    </v-tooltip>

    <filterbuttondialog
      v-if="currentAnalysis.showfilterbuttondialog"
      v-model="menubutton"
      @updated="groupbuttons"
    />
  </div>
</template>

<script>
import { displayfilter } from "@/common/displayfilter";
import ApiProfile from "@/common/api/profile";
import Filterbuttondialog from "@/components/settings/Filterbuttondialog";
import { permissionchecks } from "@/common/permissions";
import { mapGetters } from "vuex";
import { DEFAULT_PROFILE } from "@/common/config";

export default {
  name: "filterbuttons",
  props: {
    index: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: null,
  },
  components: { Filterbuttondialog },
  mixins: [displayfilter, permissionchecks],
  data() {
    return {
      defaultprofile: DEFAULT_PROFILE,
      groupedbuttons: null,
      filterbuttonmenux: 0,
      filterbuttonmenuy: 0,
      menubutton: -1,
      showfilterbuttonmenu: false,
      mobileFilterMenu: false, // controls mobile filter menu
      filterbutton: {
        name: "",
        rulename: "",
      },
    };
  },
  mounted() {
    this.groupbuttons();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),
  },
  methods: {
    groupbuttons() {
      const buttons = this.currentAnalysis.profiles.selected.filterbuttons;
      const groups = [];

      buttons.forEach((button, index) => {
        if (button.rulename.includes("//")) {
          const out = button.rulename.split("//");
          const group = out[0].trim();
          const label = out.slice(1).join(" // ").trim();
          const findex = groups.findIndex(({ groupname }) => groupname === group);
          if (findex < 0) {
            groups.push({
              type: "group",
              groupname: group,
              buttons: [
                {
                  rulename: label,
                  rule: button.rule,
                  idx: index,
                },
              ],
            });
          } else {
            groups[findex].buttons.push({
              idx: index,
              rulename: label,
              rule: button.rule,
            });
          }
        } else {
          groups.push({
            type: "button",
            idx: index,
            rulename: button.rulename,
            rule: button.rule,
          });
        }
      });
      this.groupedbuttons = groups;
    },
    editfilterbutton(n) {
      this.currentAnalysis.showfilterbuttondialog = true;
    },
    addbutton() {
      this.menubutton = -1;
      this.currentAnalysis.showfilterbuttondialog = true;
    },
    applyDisplayFilterAndClose(rule) {
      // Manually close the mobile menu after selecting a filter.
      // if (this.$vuetify.breakpoint.mobile) {
        this.mobileFilterMenu = false;
      // }
      this.df_apply_to_packetlist(rule);
    },
    removefilterbutton(button) {
      if (button !== undefined) {
        this.currentAnalysis.profiles.selected.filterbuttons.splice(button, 1);
        ApiProfile.update(this.currentAnalysis.profiles.selected)
          .then(() => {
            this.groupbuttons();
            this.currentAnalysis.profileneedsupdate = true;
          })
          .catch(() => {
            // Handle error as needed
          });
      }
    },
    doshowfilterbuttonmenu(e, button) {
      this.menubutton = button;
      this.showfilterbuttonmenu = false;
      this.filterbuttonmenux = e.clientX;
      this.filterbuttonmenuy = e.clientY;
      this.$nextTick(() => {
        this.showfilterbuttonmenu = true;
      });
    },
  },
};
</script>

<!-- Deep CSS overrides for dense items -->
<style scoped>
::v-deep .v-list-item,
::v-deep .dense-list-item {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  min-height: 24px !important;
}

::v-deep .v-list-item__content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
    padding-left: 24px; 
}

::v-deep .v-list-item-title {
  font-size: 0.85rem !important;
  line-height: 1.2;
}
</style>
