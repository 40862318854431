<template>
  <div>
    <!-- Error dialog remains unchanged -->
    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          {{ errorTitle }}
        </v-card-title>
        <v-card-text class="mt-8 text-body-1" v-html="errorMessage" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Notification Message (shown above uploads) -->
    <div v-if="notificationMessage" class="notification">
      {{ notificationMessage }}
    </div>

    <!-- Simplified dropzone container -->
    <div
      v-show="showDropzone"
      id="pcapuploadzone"
      class="custom-dropzone-container"
    >
      <div class="upload">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          class="custom-dropzone"
          :options="dropzoneOptions"
          @vdropzone-sending="sendingEvent"
          @vdropzone-max-files-exceeded="maxFilesExceeded"
          @vdropzone-success="completeUpload"
          @vdropzone-files-added="filesAdded"
          @vdropzone-error="handleError"
        ></vue-dropzone>
      </div>
      <div class="upload-info">
        By submitting your file to this site you are asking us to store,
        process, and <b>share the PCAP with the public</b>. You also agree to
        the <b><a :href="tosurl">Terms of Service</a></b> and
        <b><a :href="privacyurl">Privacy Policy</a></b
        >. Please do not submit any personal information; we are not responsible
        for the contents of your submission. PCAPs uploaded by
        <b>registered users are private by default</b>.
      </div>
      <div
        style="margin-top: 48px"
        v-if="
          currentUser && currentAnalysis.profiles.selected.massuploadtagging
        "
        class="upload-tags mx-auto max-w-xl"
      >
        Select tags for your uploaded files (<router-link
          :to="{ name: 'Tags' }"
        >
          create the tag</router-link
        >
        first)
        <tags
          label="Tags..."
          type="pcap"
          :isowner="true"
          :setvuex="true"
          class="px-10 pt-2"
        ></tags>
      </div>
    </div>

    <!-- Processing, uploaded files list, and metadata dialog remain unchanged -->
     <div id="processingSection"></div>
    <v-row
      v-if="processing"

      align="center"
      justify="center"
      class="pa-4"
    >
      <v-col cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <p class="mt-2">{{ processingText }}</p>
      </v-col>
    </v-row>

    <v-row
      v-if="successfulUploads.length > 0"
      align="center"
      justify="center"
      class="pa-2 grid-list-md full-width"
    >
      <v-card class="mx-auto full-width" max-width="none" width="100%" tile>
        <v-card-title>Uploaded files (click to open)</v-card-title>
        <v-list dense>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in successfulUploads" :key="i">
              <v-list-item-icon>
                <v-icon>check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-if="!item.processing"
                  @click.stop.prevent="gotoPcap(item.response.id, item)"
                  v-text="item.file.upload.filename"
                ></v-list-item-title>
                <v-list-item-title
                  v-else
                  class="grey--text"
                  v-text="item.file.upload.filename + ' (processing)'"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <p class="mt-4 ml-4">
          Click
          <a href="#" @click.prevent.stop="enableUpload">here to upload more</a>
        </p>
      </v-card>
    </v-row>

    <v-dialog
      v-model="showMetadataDialog"
      max-width="500px"
      content-class="custom-dialog"
    >
      <upload-pcap-dialog
        v-model="pcapMetadata"
        @close="metadataDialogClosed"
        @cancel="metadataDialogCanceled"
        :show="showMetadataDialog"
        :pcaps="droppedUploads"
      ></upload-pcap-dialog>
    </v-dialog>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import UploadPcapDialog from "./dialogs/UploadPcapDialog";
import { PCAP_URL } from "@/common/config";
import {
  UPLOAD_URL_CHUNKED_FAST,
  UPLOAD_ANON_URL,
  PRIVACY_URL,
  TOS_URL,
} from "@/common/config";
import { mapGetters } from "vuex";
import JwtService from "@/common/jwt.service";
import Tags from "./settings/Tags";
import axios from "axios";
import ApiPcapmeta from "@/common/api/pcapmeta";

const errorTooLargeAnon =
  "File too large, anonymous upload is restricted to <b>1MB</b>.<br/><a href='https://app.packetsafari.com/register'>Register for uploading larger files</a>.";

export default {
  name: "upload",
  components: {
    vueDropzone: vue2Dropzone,
    UploadPcapDialog,
    Tags,
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser"]),
    maxFiles() {
      return !this.currentUser ? 1 : null;
    },
    dropzoneOptions() {
      return {
        url: UPLOAD_URL_CHUNKED_FAST,
        thumbnailWidth: 80,
        maxFilesize: 1000,
        maxFiles: this.maxFiles,
        autoQueue: false,
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
        },
        dictDefaultMessage: `

<div class="rounded-lg p-4 bg-blue-50 hover:bg-blue-100 max-w-md mx-auto transition-colors duration-300">
  <svg class="mx-auto size-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
    <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
  </svg>
  <h3 class="mt-2 text-sm font-semibold text-gray-900">Upload PCAP</h3>
  <p class="mt-1 text-sm text-gray-500">Select a PCAP file to upload.</p>
  <div class="mt-6">
    <!-- Additional content can go here -->
  </div>
</div>
`,
      };
    },
  },
  beforeMount() {
    if (!this.currentUser) {
      this.dropzoneOptions.url = UPLOAD_ANON_URL;
    }
  },
  data() {
    return {
      notificationMessage: "",
      errorTitle: undefined,
      errorMessage: undefined,
      errorDialog: false,
      metadataAdded: false,
      showMetadataDialog: false,
      pcapMetadata: {
        description: "",
        email: "",
        wanthelp: true,
      },
      showDropzone: true,
      selectedItem: null,
      droppedUploads: [],
      successfulUploads: [],
      privacyurl: PRIVACY_URL,
      tosurl: TOS_URL,
      processing: false,
      processingText: "",
    };
  },
  methods: {
    openFilePicker() {



      return 
      if (this.$refs.myVueDropzone && this.$refs.myVueDropzone.dropzone) {
        this.$refs.myVueDropzone.dropzone.hiddenFileInput.click();
      }

    },
    async completeUpload(file, response) {
      // Ensure the response is an object
      let parsedResponse =
        typeof response === "string" ? JSON.parse(response) : response;

      this.processing = true;
      this.showDropzone = false;
      this.processingText =
        "Processing PCAP. Please wait a moment; you can click it once processing is complete.";

      const tempItem = {
        file: file,
        description: this.pcapMetadata.description,
        email: this.pcapMetadata.email,
        wanthelp: this.pcapMetadata.wanthelp,
        processing: true,
        response: { id: parsedResponse.id },
      };

      this.successfulUploads.push(tempItem);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      let updatedResponse;
      let attempts = 0;
      do {
        try {
          updatedResponse = await ApiPcapmeta.get(parsedResponse.id);
          console.log("Updated response", updatedResponse);
          if (updatedResponse.data.indexed === true) {
            break;
          }
        } catch (err) {
          console.error("Error fetching metadata", err);
        }
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } while (attempts < 10);

      if (updatedResponse && updatedResponse.data && updatedResponse.data.id) {
        tempItem.response = { id: updatedResponse.data.id };
      }
      tempItem.processing = false;
      this.processing = false;
      if (this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles();
      }

      // Notify the user about the upload details
      let message = "Upload completed successfully.";
      if (parsedResponse.trimmed) {
        message += ` The PCAP was trimmed because it was too large. Original packet count: ${parsedResponse.orig_packet_count}, New packet count: ${parsedResponse.new_packet_count}.`;
      }
      this.notificationMessage = message;
    },
    handleError(file, message, xhr) {
      this.errorTitle = "Upload error";
      const status = xhr && xhr.status ? xhr.status : null;
      if (status === 413) {
        this.errorMessage = this.currentUser
          ? "File too large for your pricing plan."
          : errorTooLargeAnon;
      } else if (status === 429) {
        this.errorMessage =
          "Too many uploads, wait some time before you upload more PCAPs." +
          "<br>See <a href='https://www.packetsafari.com/docs/features/'>pricing plans</a> to upgrade capacity.";
      } else {
        const errorText =
          message && message.error
            ? message.error
            : message || "Unknown error.";
        this.errorMessage = "Error: " + errorText;
      }
      if (this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles();
      }
      this.errorDialog = true;
    },
    metadataDialogClosed() {
      this.$refs.myVueDropzone.getAcceptedFiles().forEach((f) => {
        this.$refs.myVueDropzone.dropzone.enqueueFile(f);
      });
      this.$refs.myVueDropzone.processQueue();
      this.metadataAdded = true;
      this.showMetadataDialog = false;
    },
    metadataDialogCanceled() {
      if (this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles();
      }
      this.metadataAdded = false;
      this.showMetadataDialog = false;
      this.showDropzone = true;
    },
    filesAdded(files) {
      console.log("Files added", files);
      axios
      .post("https://95f7qv4x3j.execute-api.eu-central-1.amazonaws.com/v1", {
        data: {user: this.currentUser?.email, files: files},
        description: "Files added but not uploaded yet",
      })
      .catch(() => {});
      let myMBlimit = 1;
      if (this.currentUser) {
        myMBlimit = this.currentUser.ratelimiting.max_upload_file_size;
      }
      this.pcapMetadata.description = "";
      this.pcapMetadata.email = "";
      this.pcapMetadata.wanthelp = true;
      this.droppedUploads = files;
      this.showMetadataDialog = true;
    },
    maxFilesExceeded(file) {
      console.log("Max files exceeded", file);
    },
    gotoPcap(pcapid, item) {
      window.location.replace("/analyze/l/" + pcapid);
    },
    enableUpload() {
      if (this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles();
      }
      this.pcapMetadata.description = "";
      this.pcapMetadata.email = "";
      this.pcapMetadata.wanthelp = true;
      this.metadataAdded = false;
      this.showDropzone = true;
    },
    sendingEvent(file, xhr, formData) {
      formData.append("tags", JSON.stringify(this.currentAnalysis.uploadtags));
      formData.append(
        "description",
        JSON.stringify(this.pcapMetadata.description)
      );
      if (this.pcapMetadata.email) {
        formData.append("email", JSON.stringify(this.pcapMetadata.email));
      } else if (this.currentUser) {
        formData.append("email", JSON.stringify(this.currentUser.email));
      }
      formData.append("wanthelp", JSON.stringify(this.pcapMetadata.wanthelp));
      this.notifyLambda(formData, file);
    },
    notifyLambda(formData, file) {
      let description =
        "New upload " +
        file.upload.filename +
        " by " +
        (this.currentUser ? this.currentUser.username : "anonymous");
      if (this.pcapMetadata && this.pcapMetadata.wanthelp) {
        description = this.currentUser
          ? "Help request for " +
            file.upload.filename +
            " by " +
            this.currentUser.username
          : "Help request for " +
            file.upload.filename +
            " by " +
            this.pcapMetadata.email;
      }
      const c = {
        data: {
          file: file,
          user: this.currentUser,
          metadata: this.pcapMetadata,
        },
        description: description,
      };
      axios
        .post("https://95f7qv4x3j.execute-api.eu-central-1.amazonaws.com/v1", c)
        .then((res) => {
          // Optionally handle the response here
        })
        .catch((error) => {
          console.error("Error notifying lambda", error);
        });
    },
  },
};
</script>

<style>
.custom-dropzone-container {
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.custom-dropzone {
  width: 100%;
  border: none;
  background: transparent;
  box-shadow: none;
}

.upload-info {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  font-size: 14px;
}

.custom-dropzone:hover,
.dz-hover {
  background: transparent !important;
}

.upload-icon {
  transition: filter 0.5s ease;
}

.upload-icon:hover {
  filter: brightness(0.94);
}

.upload-pcap-dialog {
  position: relative;
  z-index: 1000;
}

.custom-dialog {
  z-index: 1500 !important;
}

.notification {
  background-color: #e0f7fa;
  border: 1px solid #4dd0e1;
  color: #006064;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 4px;
}
</style>
