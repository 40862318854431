var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 flex flex-col items-center mx-auto"},[_vm._m(0),_c('v-card',{staticClass:"pb-2 mt-4",attrs:{"max-width":"900px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"responsive-table",attrs:{"headers":_vm.headers,"items":_vm.cases,"items-per-page":50},scopedSlots:_vm._u([{key:"item.pcapid",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("/analyze/l/" + (item.pcapid) + "/none/none"),"target":"_blank"}},[_vm._v(" View PCAP ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.author",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.contactUser(item.email)}}},[_vm._v(" "+_vm._s(item.author ? item.author : item.email)+" ")]):[(item.author && item.author.toLowerCase() !== 'anonymous')?[_c('router-link',{attrs:{"to":("/profile/" + (item.author))}},[_vm._v(" "+_vm._s(item.author)+" ")])]:[_vm._v(" "+_vm._s(item.author)+" ")]]]}}])})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto max-w-7xl px-6 lg:px-8"},[_c('div',{staticClass:"mx-auto max-w-2xl lg:text-center"},[_c('h1',{staticClass:"text-4xl font-bold mb-4"},[_vm._v("Cases")]),_c('p',{staticClass:"text-lg mb-4"},[_vm._v(" These are user help requests for PCAP analysis. Users have submitted their requests seeking assistance with their PCAP files. Only cases with detailed descriptions (at least 20 characters) are displayed below. ")]),_c('p',{staticClass:"text-lg mb-4"},[_vm._v(" The cases are sorted so that the newest uploads appear first. Please review the cases and, if necessary, contact the user directly using the provided email. ")])])])}]

export { render, staticRenderFns }