<template>
  <div>
    <!-- Ranger PCAP Lab Section (Text on Left, Image on Right) -->
    <section class="bg-white py-12 sm:py-12">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
          <!-- Text Content -->
          <div>
            <h2 class="text-base font-semibold text-indigo-600">Master Packet Analysis</h2>
            <p class="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              Self-Paced CTF Labs
            </p>
            <p class="mt-6 text-lg text-gray-600">
              Gain hands-on experience in packet analysis with our expert-designed labs.
              <b> +25 labs</b>
            </p>
            <p class="mt-6 text-lg text-gray-600">
              Use code <span class="font-mono font-semibold">RANGER50</span> at checkout to get <b>50% off</b>!
              <br />
              <span class="text-sm">Valid until {{ lastDayOfMonth }} ({{ daysLeft }} days left!)</span>
            </p>
            <div class="mt-8">
              <a
                href="https://labs.oripka.de/course/wsadven"
                target="_blank"
                class="inline-block rounded-md bg-indigo-600 px-6 py-3 text-base font-medium !text-white hover:bg-indigo-700"
              >
                Start Your Lab Today
              </a>
            </div>
          </div>
          <!-- Image -->
          <div class="flex justify-center">
            <img
              src="/rangerlab.png"
              alt="Ranger Lab Logo"
              class="w-full max-w-lg object-contain rounded-lg"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- AI Shark Section (Image on Left, Text on Right) -->
    <section class="bg-white py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
          <!-- Image -->
          <div class="flex justify-center">
            <img
              src="/aishark.png"
              alt="AI Shark Logo"
              class="w-full max-w-lg object-contain rounded-lg"
            />
          </div>
          <!-- Text Content -->
          <div>
            <h2 class="text-base font-semibold text-indigo-600">Automate Packet Analysis</h2>
            <p class="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              AI Shark
            </p>
            <p class="mt-6 text-lg text-gray-600">
              AI Shark is a fully automated PCAP analysis service, best suited for analyzing PCAP files containing up to 100 packets.
              It is the predecessor of the PacketSafari Copilot, which offers semi-automatic packet analysis. We now recommend using the Copilot,
              as it provides improved results and allows you to iterate and ask questions about specific packets.
              AI Shark remains a useful tool for small traces and for users without prior network analysis experience.
            </p>
            <p class="mt-6 text-lg text-gray-600">
              Use code <span class="font-mono font-semibold">HELPME55</span> at checkout to get <b>55% off</b>!
              <br />
              <span class="text-sm">Valid until {{ lastDayOfMonth }} ({{ daysLeft }} days left!)</span>
            </p>
            <div class="mt-8">
              <a
                href="https://packetsafari.com/aishark"
                target="_blank"
                class="inline-block rounded-md bg-indigo-600 px-6 py-3 text-base font-medium !text-white hover:bg-indigo-700"
              >
                Try AI Shark Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      today: new Date()
    };
  },
  computed: {
    lastDayOfMonth() {
      const year = this.today.getFullYear();
      const month = this.today.getMonth() + 1; // Months are 0-indexed
      const lastDay = new Date(year, month, 0).toISOString().split("T")[0];
      return lastDay;
    },
    daysLeft() {
      const year = this.today.getFullYear();
      const month = this.today.getMonth() + 1;
      const lastDay = new Date(year, month, 0);
      const diffTime = lastDay.getTime() - this.today.getTime();
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
  }
};
</script>
