<template>
  <div class="mx-auto">
    <v-row justify="center" class="mt-4 pb-4 mb-2 grid-list-md">
      <!-- Features Component -->
      <FeaturesNew
        class="mt-40 "
        style="margin-bottom: 120px;"
        @tryitnow="openUploadFilePicker"
      />

      <v-container fluid>
        <v-row justify="center">
          <v-col
            xs="12"
            sm="10"
            md="8"
            lg="6"
            class="mt-0"
            v-if="
              (!currentUser && currentAnalysis.features.enableanonupload) ||
                (currentUser && currentAnalysis.features.enableupload)
            "
          >
            <Upload ref="uploadComponent" />
          </v-col>
        </v-row>
      </v-container>

      <!-- PCAP List Section -->
      <!-- <v-col cols="12"></v-col>
      <v-col cols="0" sm="1" md="2" lg="3"></v-col>
      <v-col cols="12" sm="10" md="8" lg="6" class="mt-0">
        <Pcaplist type="search" :noquery="false" :simple="true" />
      </v-col>
      <v-col cols="0" sm="1" md="2" lg="3"></v-col> -->

      <!-- Centered Other Products Header -->
      <v-col cols="12" class=" text-center mt-40">
        <div class="bg-white px-6  lg:px-8">
          <div class="mx-auto max-w-2xl text-center">
            <h2
              class="text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl"
            >
              Other Products
            </h2>
            <p
              class="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8"
            ></p>
          </div>
        </div>
      </v-col>

      <!-- Needfunding Components -->
      <v-col cols="0"></v-col>
      <v-col cols="12" class="">
        <Needfunding />
      </v-col>
      <v-col cols="0"></v-col>
      <!-- 
      <v-col cols="2"></v-col>
      <v-col cols="8" class="mt-5">
        <Needfunding2 />
      </v-col>
      <v-col cols="2"></v-col> -->
    </v-row>
    <div class="bg-indigo-100">
      <div
        class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8"
      >
        <h2
          class="max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl"
        >
          Ready to dive in? <br />Register today.
        </h2>
        <div class="mt-10 ml-40 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
          <a
            href="/register"
            class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold !text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >Register</a
          >
   
        </div>
      </div>
    </div>
    <Faq/>
    <!-- Optional Rows -->
    <v-row></v-row>
    <v-row v-if="false">
      <v-col lg="2" md="1" sm="0"></v-col>
      <v-col lg="8" md="10" sm="12" class="mt-3">
        <Searcherror :error="false" />
      </v-col>
      <v-col lg="2" md="1" sm="0"></v-col>
    </v-row>
  </div>
</template>

<script>
import Upload from "./Upload";
import Faq from "./Faq.vue";
import Tagcloud from "./Tagcloud";
import Pcaplist from "./Pcaplist";
import { mapGetters } from "vuex";
import Searcherror from "./dialogs/Searcherror";
import { PACKETSAFARI_LOGO } from "@/common/config";
import Needfunding from "./Needfunding.vue";
import Needfunding2 from "./Needfunding2.vue";
import FeaturesNew from "./FeaturesNew.vue";

export default {
  name: "Welcome",
  components: {
    Upload,
    Tagcloud,
    Faq,
    Pcaplist,
    Searcherror,
    Needfunding,
    Needfunding2,
    FeaturesNew,
  },
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser"]),
  },
  methods: {
    openUploadFilePicker() {
      if (
        this.$refs.uploadComponent &&
        this.$refs.uploadComponent.openFilePicker
      ) {
        this.$refs.uploadComponent.openFilePicker();
      }
    },
  },
  data() {
    return {
      logo: PACKETSAFARI_LOGO,
      msg: "Welcome to packetwave",
      upload: 1,
      recent: 2,
    };
  },
  mounted() {},
};
</script>

<style scoped>
.v-card {
  min-height: 300px;
  height: 100%;
}
</style>
