<template>
  <div class="bg-white mx-auto">
    <!-- Hero Section -->
    <section class="py-12">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl sm:text-center">
          <h2 class="text-base/7 font-semibold text-indigo-600">
            Everything You Need for Packet Analysis
          </h2>
          <p
            class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-5xl"
          >
            PacketSafari Copilot
          </p>
          <p class="mt-6 text-lg/8 text-gray-600">
            PacketSafari Copilot revolutionizes the way you analyze network
            data. Enjoy seamless, AI-powered packet analysis that transforms
            complexity into clarity.
          </p>
        </div>
      </div>
    </section>

    <!-- Video Section (Stacked) -->
    <section class="py-8">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <video controls autoplay muted loop class="w-full object-contain">
          <source src="/packetsafari-copilot-4k.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>

    <!-- Product Description -->
    <section class="py-8">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <p class="text-xl py-7">
          PacketSafari Copilot transforms complex network data into clear,
          understandable information. Whether you're troubleshooting network
          issues or ensuring optimal performance, our intuitive interface and
          powerful analysis tools work together to simplify even the most
          challenging tasks. Enjoy seamless integration with your existing
          workflow and experience the future of packet analysis today.
        </p>
      </div>
    </section>

    <!-- Divider -->
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <hr class="border-gray-300 my-8" />
    </div>

    <template>
      <div class="bg-gray-50 py-24 sm:py-32">
        <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          <h2 class="text-center text-base/7 font-semibold text-indigo-600">
            Analyse faster
          </h2>
          <p
            class="mx-auto mt-2 max-w-lg text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl"
          >
            Everything you need get insights into network traffic
          </p>
          <div class="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
            <div class="relative lg:row-span-2">
              <div
                class="absolute inset-px rounded-lg bg-white lg:rounded-l-[2rem]"
              />
              <div
                class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]"
              >
                <div class="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                  <p
                    class="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center"
                  >
                    Security insights
                  </p>
                  <p
                    class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center"
                  >
                    Using its extensive world-knowledge Copilot can analyse the
                    payload of packets to identify potential security threats.
                  </p>
                </div>


                <div class="relative min-h-[30rem] w-full grow">
                  <div
                    class="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-gray-900 shadow-2xl"
                  >
                    <div class="flex bg-gray-800/40 ring-1 ring-white/5">
                      <div
                        class="-mb-px flex text-sm/6 font-medium text-gray-400"
                      >
                        <div
                          class="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white"
                        >
                          Copilot Security Chat
                        </div>
                      </div>
                    </div>
                    <div class="px-6 pb-14 pt-6 text-white">
                      <h2>Authentication Bypass Vulnerability</h2><br>
                      The file&apos;s name and the malformed HTTP POST request hint at an attempt to exploit Cisco&apos;s CVE-2023-20198 vulnerability&period;&NewLine;The encoded URL &lpar;&quot;&percnt;2577 ebui&lowbar;wsma&lowbar;Http&quot;&rpar; might be part of the mechanism used to bypass authentication on WSMA &lpar;Web Services Management Appliance&rpar; configurations&period;&NewLine;          
                    </div>
                  </div>
                </div>
            
              </div>
              <div
                class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"
              />
            </div>
            <div class="relative max-lg:row-start-1">
              <div
                class="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem]"
              />
              <div
                class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]"
              >
                <div class="px-8 pt-8 sm:px-10 sm:pt-10">
                  <p
                    class="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center"
                  >
                    Performance
                  </p>
                  <p
                    class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center"
                  >
                    Copilot can troubleshoot performance issues by analysing the
                    packets and identifying bottlenecks.
                  </p>
                </div>
                <div
                  class="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2"
                >
                  <img
                    class="w-full max-lg:max-w-xs"
                    src="/bento-03-performance.png"
                    alt=""
                  />
                </div>
              </div>
              <div
                class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"
              />
            </div>
            <div
              class="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2"
            >
              <div class="absolute inset-px rounded-lg bg-white" />
              <div
                class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]"
              >
                <div class="px-8 pt-8 sm:px-10 sm:pt-10">
                  <p
                    class="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center"
                  >
                    Security
                  </p>
                  <p
                    class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center"
                  >
                    Copilot can analyse the packets to identify potential
                    security threats.
                  </p>
                </div>
                <div
                  class="flex flex-1 items-center [container-type:inline-size] max-lg:py-6 lg:pb-2"
                >
                  <img
                    class="h-[min(152px,40cqw)] object-cover"
                    src="/bento-03-security.png"
                    alt=""
                  />
                </div>
              </div>
              <div
                class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"
              />
            </div>
            <div class="relative lg:row-span-2">
              <div
                class="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"
              />
              <div
                class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]"
              >
                <div class="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                  <p
                    class="mt-2 text-lg font-medium tracking-tight text-gray-950 max-lg:text-center"
                  >
                    Powerful insights ai-insights.png
                  </p>
                  <p
                    class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center"
                  >
                    PacketSafari Copilot provides powerful insights into network
                    traffic.
                  </p>
                </div>

                <div class="relative min-h-[30rem] w-full grow">
                  <div
                    class="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-gray-900 shadow-2xl"
                  >
                    <div class="flex bg-gray-800/40 ring-1 ring-white/5">
                      <div
                        class="-mb-px flex text-sm/6 font-medium text-gray-400"
                      >
                        <div
                          class="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white"
                        >
                          Copilot Chat
                        </div>
                      </div>
                    </div>
                    <div class="px-6 pb-14 pt-6 text-white">
                      <h2>TLS Protocol Version Mismatch</h2><br>In one of the TS sessions
                      (for example, in the early packets from TCP stream 0) the
                      handshake includes a message "Ignored Unknown Record"
                      followed shortly by an "Alert (Level: Fatal, Description:
                      Protocol Version)." This alert is generated when one
                      side (usually the server) does not support or accept the
                      TLS protocol version offered by the client.
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Features Section -->
    <section class="py-20">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="text-center mb-12">
          <h2 class="text-3xl font-bold text-gray-900">
            PacketSafari Copilot Exclusive Features
          </h2>
          <p class="mt-4 text-lg text-gray-600">
            Unlock advanced packet analysis with our premium suite of tools.
          </p>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <!-- Feature 1: Chat with Selected Packets -->
          <div class="p-6 text-center">
            <i class="mdi mdi-chat text-indigo-600 text-4xl"></i>
            <h3 class="text-xl font-semibold mt-3 mb-2">
              Chat with Selected Packets
            </h3>
            <p class="text-gray-600">
              Start a conversation with individual packets to extract tailored
              insights.
            </p>
          </div>
          <!-- Feature 2: Payload-Level Chat -->
          <div class="p-6 text-center">
            <i
              class="mdi mdi-file-document-outline text-indigo-600 text-4xl"
            ></i>
            <h3 class="text-xl font-semibold mt-3 mb-2">Payload-Level Chat</h3>
            <p class="text-gray-600">
              Dive into packet details and payloads for comprehensive analysis.
            </p>
          </div>
          <!-- Feature 3: Iterative Query Workflow -->
          <div class="p-6 text-center">
            <i class="mdi mdi-sync text-indigo-600 text-4xl"></i>
            <h3 class="text-xl font-semibold mt-3 mb-2">
              Iterative Query Workflow
            </h3>
            <p class="text-gray-600">
              Refine your questions step by step to pinpoint exactly the
              insights you need.
            </p>
          </div>
          <!-- Feature 4: Fast, Accurate Responses -->
          <div class="p-6 text-center">
            <i class="mdi mdi-speedometer text-indigo-600 text-4xl"></i>
            <h3 class="text-xl font-semibold mt-3 mb-2">
              Fast, Accurate Responses
            </h3>
            <p class="text-gray-600">
              Benefit from rapid analysis even when processing hundreds of
              packets simultaneously.
            </p>
          </div>
        </div>
        <!-- Call to Action -->
        <div class="mt-12 text-center">
          <a
            href="/pricing"
            class="inline-block bg-indigo-600 !text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-indigo-700"
          >
            See Pricing
          </a>
          <p class="mt-4 text-gray-500">
            Experience the full power of advanced packet analysis.
          </p>
        </div>
      </div>
    </section>

    <!-- Extra Bottom Padding -->
    <div class="h-10"></div>
  </div>
</template>

<script>
export default {
  name: "PacketSafariCopilot",
};
</script>
