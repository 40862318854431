import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import Users from '@/components/Users'
import Pcaplist from '@/components/Pcaplist'
import Upload from '@/components/Upload'
import Analyze from '@/components/Analyze'
import DecodePopout from '@/components/DecodePopout'
import Register from '@/components/Register'
import Signupcode from '@/components/Signupcode'
import Forgotpassword from '@/components/Forgotpassword'
import Login from '@/components/Login'
import Userprofile from '@/components/Userprofile'
import Analysisrecipe from '@/components/Analysisrecipe'
import Indexer from '@/components/Indexer'
import Edituser from '@/components/Edituser'
import Iograph from '@/components/Iograph'
import Settings from '@/components/settings/Settings'
import Analysisprofile from '@/components/settings/Analysisprofile'
import Rolelist from '@/components/settings/Rolelist'
import Taglist from '@/components/settings/Taglist'
import Test from '@/components/Test'
import Tasklist from '@/components/settings/Tasklist'
import Caselist from '@/components/settings/Caselist'
import Appinfo from '@/components/settings/Appinfo'
import Pricing from '@/components/Pricing'
import ProductCopilot from '@/components/ProductCopilot.vue'
import Pricingold from '@/components/Pricingold'
import Support from '@/components/Support'
import Wiresharksettings from '@/components/settings/Wiresharksettings'
import Packetillustration from '@/components/Packetillustration'
import Analysislist from '@/components/Analysislist'
import Tapstatistics from '@/components/Tapstatistics'
import Profilelist from "@/components/settings/Profilelist";
import Capturecalculator from "@/components/tools/Capturecalculator";
import Pcapsummary from '@/components/Pcapsummary.vue'
import Deleted from '@/components/Deleted.vue'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Packet Analyzer',
      component: HelloWorld,
      meta: { footer: true }
    },
    {
      path: '/tools/capturecalculator',
      name: 'Capture Calculator',
      component: Capturecalculator,
      meta: { footer: true }
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,
      meta: { footer: true }
    },
    {
      path: '/test',
      name: 'Test',
      component: Test
    },
    {
      path: '/copilot',
      name: 'Copilot',
      component: ProductCopilot,
      meta: { footer: true }
    },
    {
      path: '/iograph/:pcapid/:index',
      name: 'Iograph',
      props: true,
      component: Iograph
    },
    {
      path: '/statistic/:pcapid/:tap',
      name: 'Statistics',
      component: Tapstatistics,
      props: true,
      meta: { fullscreen: true }
    },
    {
      path: '/roles',
      name: 'Roles',
      component: Rolelist,
      meta: { footer: true }
    },
    {
      path: '/analysisprofiles',
      name: 'Analysis Profiles',
      component: Profilelist,
      meta: { footer: true }
    },
    {
      path: '/tags',
      name: 'Tags',
      component: Taglist,
      meta: { footer: true }
    },
    {
      path: '/tasks',
      name: 'Tasks',
      component: Tasklist,
      meta: { footer: true }
    },
    {
      path: '/cases',
      name: 'Cases',
      component: Caselist,
      meta: { footer: true }
    },
    {
      path: '/appinfo',
      name: 'Appinfo',
      component: Appinfo,
      meta: { footer: true }
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing,
      meta: { footer: true }
    },
    {
      path: '/support',
      name: 'Support',
      component: Support,
      meta: { footer: true }
    },
    {
      path: '/pricingold',
      name: 'Pricingold',
      component: Pricingold
    },
    {
      path: '/wiresharksettings',
      name: 'Wiresharksettings',
      component: Wiresharksettings
    },
    {
      path: '/pcaps/:type?/:query?',
      name: 'PCAPs',
      component: Pcaplist,
      props: true,
      meta: { widescreen: true, footer: true }
    },
    {
      path: '/analyses/:type/:query?',
      name: 'Analysis List',
      component: Analysislist,
      props: true,
      meta: { widescreen: true, footer: true }
    },
    {
      path: '/pcap/:id',
      name: 'PCAP Summary',
      component: Pcapsummary,
      props: true,
      meta: { widescreen: true, footer: true }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings
    },
    {
      path: '/analysisprofile/:profileid',
      name: 'Analysis Profile',
      props: true,
      component: Analysisprofile
    },
    {
      path: '/upload',
      name: 'Upload',
      component: Upload
    },
    {
      path: '/analyze/:view/:id/:index?/:analysisid?/:filter?/:graphplot?',
      name: 'Analysis',
      component: Analyze,
      props: true,
      meta: { fullscreen: true, filterbar: true }
    },
    {
      path: '/decode/:id',
      name: 'Decode Popout',
      component: DecodePopout,
      props: true,
      meta: { fullscreen: true, filterbar: false }
    },
    {
      path: '/profile/:username',
      name: 'User Profile',
      component: Userprofile,
      props: true,
      meta: { footer: true }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: { footer: true }
    },
    {
      path: '/signupcode',
      name: 'Signup code',
      props: true,
      component: Signupcode,
      meta: { footer: true }
    },
    {
      path: '/forgotpassword',
      name: 'Forgot Password',
      component: Forgotpassword,
      meta: { footer: true }
    },
    {
      path: '/editprofile',
      name: 'Edit Profile',
      component: Edituser,
      meta: { footer: true }
    },
    {
      path: '/deleted',
      name: 'Deleted',
      component: Deleted,
      meta: { footer: true }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { footer: true }
    },
    {
      path: '/recipetmp',
      name: 'Recipe',
      component: Analysisrecipe
    },
    {
      path: '/indexer',
      name: 'Indexer',
      component: Indexer
    },
    {
      path: '/illustration',
      name: 'Illustration',
      component: Packetillustration
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    // If a saved position is available (e.g., browser back/forward), use it
    if (savedPosition) {
      return savedPosition
    } else {
      // Otherwise, scroll to the top of the page on navigation
      return { x: 0, y: 0 }
    }
  }
})

export default router
