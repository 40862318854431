<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-24 w-auto"
        src="/images/packetsafari-logo.png"
        alt="PacketSafari"
      />
  
    </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <!-- Title -->
        <h2 class="text-center text-2xl font-bold text-gray-900">Login</h2>
        <!-- Explanation Text -->
        <p class="mt-4 text-center text-sm text-gray-600">
          Logging in gives you access to more features and personalized content.
          Please note that a valid login is required to enjoy all functionalities.
        </p>
        <!-- Login Form -->
        <form class="mt-6 space-y-6" @submit.prevent="submit">
          <!-- Username Field -->
          <div>
            <label for="username" class="block text-sm font-medium text-gray-700">
              Username
            </label>
            <div class="mt-1">
              <input
                id="username"
                name="username"
                type="text"
                v-model="username"
                maxlength="25"
                class="!block !w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-indigo-500 !focus:ring-indigo-500 !sm:text-sm"
                required
              />
              <p v-if="usernameError" class="mt-1 text-xs text-red-600">{{ usernameError }}</p>
            </div>
          </div>

          <!-- Password Field with Toggle -->
          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="relative mt-1">
              <input
                :type="showpassword ? 'text' : 'password'"
                id="password"
                name="password"
                v-model="password"
                placeholder="At least 8 characters"
                class="!block !w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-indigo-500 !focus:ring-indigo-500 !sm:text-sm !pr-10"
                required
              />
              <button
                type="button"
                class="absolute inset-y-0 right-0 flex items-center pr-3"
                @click="togglePassword"
              >
                <svg
                  v-if="showpassword"
                  class="h-5 w-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-5.523 0-10-4.477-10-10 0-1.114.184-2.18.525-3.175M9.88 9.88a3 3 0 104.242 4.242M15 15l3 3m6-3a9.966 9.966 0 01-2.637 4.086M3.363 6.637A9.966 9.966 0 011 10c0 5.523 4.477 10 10 10 1.886 0 3.68-.526 5.215-1.44"
                  />
                </svg>
                <svg
                  v-else
                  class="h-5 w-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-1.274 4.057-5.065 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </button>
              <p v-if="passwordError" class="mt-1 text-xs text-red-600">{{ passwordError }}</p>
            </div>
          </div>

          <!-- Forgot Password Link -->
          <div class="text-right">
            <router-link
              :to="{ name: 'Forgot Password' }"
              class="text-sm text-indigo-600 hover:text-indigo-500"
            >
              Forgot Password?
            </router-link>
          </div>

          <!-- Submit Button -->
          <div>
            <button
              type="submit"
              :disabled="!isValid"
              class="flex w-full justify-center rounded-md !bg-indigo-600 px-4 py-2 text-sm font-semibold !text-white shadow-sm hover:!bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN } from "@/store/actions.type";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";

export default {
  name: "Login",
  mixins: [permissionchecks],
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error", "avatarPic"]),
    isValid() {
      // Basic check: both fields must be filled and password is at least 8 characters
      return this.username && this.password && this.password.length >= 8;
    },
  },
  data() {
    return {
      username: "",
      password: "",
      showpassword: false,
      usernameError: "",
      passwordError: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v && v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    togglePassword() {
      this.showpassword = !this.showpassword;
    },
    submit() {
      // Reset error messages
      this.usernameError = "";
      this.passwordError = "";

      // Basic validation
      if (!this.username) {
        this.usernameError = "Username is required";
      }
      if (!this.password) {
        this.passwordError = "Password is required";
      } else if (this.password.length < 8) {
        this.passwordError = "Password must be at least 8 characters";
      }
      if (this.usernameError || this.passwordError) {
        return;
      }

      // Dispatch login action
      this.$store
        .dispatch(LOGIN, { username: this.username, password: this.password })
        .then(() => {
          if (this.hasSubscription(this.currentUser)) {
            this.currentAnalysis.showNavDrawer = true
            this.$router.push({ name: "Packet Analyzer" });
          } else {
            this.currentAnalysis.showNavDrawer = true
            this.$router.push({ name: "Pricing" });
          }
        })
        .catch(() => {
          this.error.type = "error";
          this.error.msg = "Could not login";
        });
    },
    clear() {
      this.username = "";
      this.password = "";
      this.usernameError = "";
      this.passwordError = "";
    },
  },
};
</script>

<style scoped>
/* Add any additional component-specific styles here if needed */
</style>
