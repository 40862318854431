<template>
  <v-container
    class=""
    style="margin-top: 80px; display: flex; flex-direction: column; align-items: center; gap: 20px;"
  >
    <!-- Contact Support Section -->
    <v-row justify="center" style="width: 100%;">
      <v-col cols="12" md="6">
        <v-card
          outlined
          class="pa-5"
          style="background-color: #f5f5f5; border-radius: 8px; border: 1px solid #ccc; text-align: center;"
        >
          <v-card-title class="headline" style="color: #333; font-size: 24px;">
            Contact Support
          </v-card-title>
          <v-card-text>
            <p style="color: #666; font-size: 16px; margin-bottom: 20px;">
              Need help? Reach out to our support team for assistance with any issues or questions.
            </p>
            <v-btn
              href="mailto:contact@packetsafari.com"
              style="background-color: #333333; color: white; padding: 12px 24px; border-radius: 5px; font-size: 18px;"
            >
              Email Support
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Packetsafari Product Logo & Company Info Section -->
    <v-row justify="center" style="width: 100%;">
      <v-col cols="12" md="6" class="text-center">
        <!-- Packetsafari Logo -->
        <v-img
          src="/images/packetsafari-title-logo.png"
          alt="Packetsafari Logo"
          contain
          class="mx-auto my-3"
          style="max-width: 200px;"
        ></v-img>
        <!-- "is a product by" Text -->
        <div style="color: #333; font-size: 18px; margin: 10px 0;">
          is a product by
        </div>
        <!-- Larger Ripka Technologies Logo -->
        <v-img
          src="/logo-ripka.png"
          alt="Ripka Technologies Logo"
          contain
          class="mx-auto my-3"
          style="max-width: 300px;"
        ></v-img>
        <!-- Contact Information -->
        <div style="color: #333; font-size: 16px; margin-top: 20px;">
          <p style="margin: 0;"><strong>Ripka Technologies S.L.</strong></p>
          <p style="margin: 0;">Calle Peris Brell 7 Trip Bajo</p>
          <p style="margin: 0;">46022 Valencia, Spain</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SupportPage",
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
