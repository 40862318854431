<template>
    <div class="mx-auto isolate overflow-hidden">
      <!-- Main Pricing Section -->
      <div class="flow-root pb-16 pt-24 sm:pt-32 lg:pb-0">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="relative z-10">
            <h2 class="mx-auto max-w-4xl text-center text-5xl font-semibold tracking-tight sm:text-6xl">
              PacketSafari Pricing
            </h2>
            <p class="mx-auto mt-6 max-w-2xl text-center text-lg font-medium sm:text-xl">
              Choose an affordable plan that’s packed with the best features.
            </p>
          </div>
          <!-- Pricing Cards / Subscription -->
          <div
            class="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3"
          >
            <!-- Subscription Card (for active subscription) -->
            <div
              v-if="hasSubscription || paymentSuccess"
              class="col-span-3"
            >
              <div class="bg-white rounded-xl shadow p-8">
                <h3 class="text-2xl font-bold">
                  <template v-if="paymentSuccess">
                    🥳 Congratulations on Your Purchase!
                  </template>
                  <template v-else>
                    🎉 You Already Have a Subscription!
                  </template>
                </h3>
                <p class="mt-4">
                  You currently have an active subscription. Enjoy these benefits:
                </p>
                <div class="mt-4 flex flex-wrap gap-4">
                  <router-link
                    to="/pcaps/public"
                    class="bg-blue-500 hover:bg-blue-600 !text-white py-2 px-4 rounded"
                  >
                    🎉 Access Full PCAP Archive
                  </router-link>
                  <router-link
                    to="/upload"
                    class="bg-blue-500 hover:bg-blue-600 !text-white py-2 px-4 rounded"
                  >
                    🎉 Upload Private PCAP and use Copilot (up to 5 MB)
                  </router-link>
                </div>
                <hr class="my-6" />
                <h4
                  class="text-xl font-semibold"
                  v-if="currentSubscriptionBenefits && currentSubscriptionBenefits.length > 0"
                >
                  Your Benefits:
                </h4>
                <ul class="mt-4 space-y-2">
                  <li
                    v-for="(benefit, index) in currentSubscriptionBenefits"
                    :key="index"
                    class="flex items-center"
                  >
                    <span class="text-green-500 mr-2">&#10003;</span>
                    {{ benefit }}
                  </li>
                </ul>
                <div v-if="showVouchers" class="mt-6">
                  <h4 class="text-xl font-semibold">Your Bundled Products</h4>
                  <div
                    class="mt-2 bg-blue-100 border border-blue-300 text-blue-800 rounded p-4"
                  >
                    <strong>
                      The bundled products require separate accounts. Follow these
                      steps:
                    </strong>
                    <ol class="list-decimal list-inside mt-2 space-y-2">
                      <li>
                        Create a separate account for AI Shark and Ranger Labs at
                        <a
                          href="https://www.packetsafari.com/signup"
                          target="_blank"
                          class="text-blue-600"
                          >this link</a
                        >.
                      </li>
                      <li>
                        Visit the pricing page at
                        <a
                          href="https://www.packetsafari.com/aishark/buy"
                          target="_blank"
                          class="text-blue-600"
                          >this link</a
                        >
                        and click <strong>"Buy AI Shark Pro Credits"</strong>.
                      </li>
                      <li>
                        Use the AI Shark 100% discount code (provided below) when
                        prompted.
                      </li>
                      <li>Enjoy full access to AI Shark Pro.</li>
                      <li>
                        Log in to the
                        <a
                          href="https://labs.oripka.de/signin"
                          target="_blank"
                          class="text-blue-600"
                          >lab platform</a
                        >
                        using the same credentials.
                      </li>
                      <li>
                        On the
                        <a
                          href="https://labs.oripka.de/course/wsadven"
                          target="_blank"
                          class="text-blue-600"
                          >Ranger Lab course page</a
                        >, click <strong>"Get Access"</strong>.
                      </li>
                      <li>
                        Use the Ranger Labs 100% discount code (provided below)
                        when prompted.
                      </li>
                      <li>
                        Go to
                        <a
                          href="https://labs.oripka.de/mycourses"
                          class="text-blue-600"
                          >My Labs</a
                        >.
                      </li>
                    </ol>
                  </div>
                  <ul class="mt-4 space-y-2">
                    <li
                      v-for="(voucher, index) in vouchers"
                      :key="index"
                      class="flex items-center justify-between"
                    >
                      <div>
                        <div class="font-semibold">{{ voucher.title }}</div>
                        <div class="text-sm">Feature: {{ voucher.feature }}</div>
                      </div>
                      <button
                        @click="copyVoucher(voucher.code)"
                        class="bg-blue-500 hover:bg-blue-600 !text-white py-1 px-3 rounded text-sm"
                      >
                        Copy Code
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="mt-6 text-center">
                  <router-link
                    :to="`/profile/${currentUser.username}`"
                    class="bg-blue-500 hover:bg-blue-600 !text-white py-2 px-4 rounded"
                  >
                    View Your Profile
                  </router-link>
                </div>
              </div>
            </div>
            <!-- Pricing Cards for non-subscribed users -->
            <template v-else>
              <!-- PacketSafari Analyser Free Card (Light Background) -->
              <div
                v-if="packetSafariFree"
                class="bg-white rounded-2xl p-8 text-gray-900 shadow flex flex-col justify-between"
              >
                <h3 class="text-lg font-semibold">
                  {{ packetSafariFree.name }}
                </h3>
                <div class="mt-4 text-4xl font-bold">
                  {{ packetSafariFree.price }}
                </div>
                <p class="mt-2 text-sm">
                  {{ packetSafariFree.description }}
                </p>
                <div class="mt-4" v-html="packetSafariFree.detaildescription"></div>
                <ul class="mt-4 space-y-2">
                  <li
                    v-for="(item, i) in packetSafariFree.features"
                    :key="i"
                    class="flex items-center"
                  >
                    <span
                      class="mr-2"
                      v-if="item.icon === 'mdi-check-circle-outline'"
                    >
                      <span class="text-green-500">&#10003;</span>
                    </span>
                    <span class="mr-2" v-else>
                      <span class="text-red-500">&#10007;</span>
                    </span>
                    <span>{{ item.text }}</span>
                  </li>
                </ul>
                <div class="mt-6 text-center">
                  <button
                    @click="openFreeAccess(packetSafariFree)"
                    class="bg-blue-500 hover:bg-blue-600 !text-white py-2 px-4 rounded"
                  >
                    Get Free Access
                  </button>
                </div>
              </div>
              <!-- New PacketSafari Pro + Copilot Subscription Card (Dark Background) -->
              <div
                v-if="packetSafariSubscription"
                class="scale-105 bg-gray-800 rounded-2xl p-8 shadow-xl !text-white flex flex-col justify-between"
              >
                <h3 class="text-lg font-semibold">
                  {{ packetSafariSubscription.name }}
                </h3>
                <div class="mt-4 text-4xl font-bold">
                  {{ packetSafariSubscription.price }}
                </div>
                <p class="mt-2 text-sm">
                  {{ packetSafariSubscription.description }}
                </p>
                <div class="mt-4" v-html="packetSafariSubscription.detaildescription"></div>
                <ul class="mt-4 space-y-2">
                  <li
                    v-for="(item, i) in packetSafariSubscription.features"
                    :key="i"
                    class="flex items-center"
                  >
                    <span
                      class="mr-2"
                      v-if="item.icon === 'mdi-check-circle-outline'"
                    >
                      <span class="text-green-500">&#10003;</span>
                    </span>
                    <span class="mr-2" v-else>
                      <span class="text-red-500">&#10007;</span>
                    </span>
                    <span>{{ item.text }}</span>
                  </li>
                </ul>
                <div class="mt-6 text-center">
                  <button
                    @click="openCheckout(packetSafariSubscription)"
                    class="bg-blue-600 hover:bg-blue-700 !text-white py-2 px-4 rounded"
                  >
                    Get Access
                  </button>
                </div>
              </div>
              <!-- PacketSafari Pro + Copilot Annual Card (Light Background) -->
              <div
                v-if="packetSafariPro"
                class="bg-white rounded-2xl p-8 text-gray-900 shadow flex flex-col justify-between"
              >
                <h3 class="text-lg font-semibold">
                  {{ packetSafariPro.name }}
                </h3>
                <div class="mt-4 text-4xl font-bold">
                  {{ packetSafariPro.price }}
                </div>
                <p class="mt-2 text-sm">
                  {{ packetSafariPro.description }}
                </p>
                <div class="mt-4" v-html="packetSafariPro.detaildescription"></div>
                <ul class="mt-4 space-y-2">
                  <li
                    v-for="(item, i) in packetSafariPro.features"
                    :key="i"
                    class="flex items-center"
                  >
                    <span
                      class="mr-2"
                      v-if="item.icon === 'mdi-check-circle-outline'"
                    >
                      <span class="text-green-500">&#10003;</span>
                    </span>
                    <span class="mr-2" v-else>
                      <span class="text-red-500">&#10007;</span>
                    </span>
                    <span>{{ item.text }}</span>
                  </li>
                </ul>
                <div class="mt-6 text-center">
                  <button
                    @click="openCheckout(packetSafariPro)"
                    class="bg-blue-500 hover:bg-blue-600 !text-white py-2 px-4 rounded"
                  >
                    Get Access
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
  
      <!-- Feature Comparison Table -->
      <div class="py-24 bg-white">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 class="text-center text-3xl font-bold text-gray-900">
            Product Feature Comparison
          </h2>
          <div class="mt-8 overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    class="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Feature
                  </th>
                  <th
                    v-for="product in comparisonProducts"
                    :key="product.key"
                    class="px-6 py-3 text-center text-sm font-semibold text-gray-900"
                  >
                    {{ product.label }}
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(feature, index) in features" :key="index">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {{ feature.name }}
                  </td>
                  <td
                    v-for="product in comparisonProducts"
                    :key="product.key"
                    class="px-6 py-4 whitespace-nowrap text-center"
                  >
                    <span
                      v-if="feature.products[product.key]"
                      class="text-green-500"
                      >&#10003;</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Additional Products Section -->
      <div class="bg-gray-50 py-24">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 class="text-center text-3xl font-bold text-gray-900">
            Additional Products
          </h2>
          <div class="mt-8 grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            <div
              v-for="price in additionalProducts"
              :key="price.priceId || price.name"
              class="bg-white rounded-2xl shadow p-8 flex flex-col justify-between"
            >
              <h3 class="text-lg font-semibold">{{ price.name }}</h3>
              <div class="mt-4 text-2xl font-bold">{{ price.price }}</div>
              <p class="mt-2 text-sm text-gray-700">{{ price.description }}</p>
              <div class="mt-4" v-html="price.detaildescription"></div>
              <ul class="mt-4 space-y-2">
                <li
                  v-for="(item, i) in price.features"
                  :key="i"
                  class="flex items-center"
                >
                  <span
                    class="mr-2"
                    v-if="item.icon === 'mdi-check-circle-outline'"
                  >
                    <span class="text-green-500">&#10003;</span>
                  </span>
                  <span class="mr-2" v-else>
                    <span class="text-red-500">&#10007;</span>
                  </span>
                  <span>{{ item.text }}</span>
                </li>
              </ul>
              <div class="mt-6 text-center">
                <a
                  v-if="price.link"
                  :href="price.link"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="bg-blue-500 hover:bg-blue-600 !text-white py-2 px-4 rounded"
                >
                  Learn More
                </a>
                <button
                  v-else
                  @click="openCheckout(price)"
                  class="bg-blue-500 hover:bg-blue-600 !text-white py-2 px-4 rounded"
                >
                  Get Access
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Registration Dialog -->
      <div
        v-if="dialog"
        class="fixed inset-0 flex items-center justify-center z-50"
      >
        <div class="bg-black opacity-50 absolute inset-0"></div>
        <div class="bg-white rounded-lg p-6 z-50 max-w-sm mx-auto">
          <h3 class="text-xl font-bold mb-4">Registration Required</h3>
          <p class="mb-4">
            You need to register an account before buying. Please complete the
            registration process and then return to the pricing page to buy access.
          </p>
          <div class="flex justify-end space-x-4">
            <button
              @click="closeDialog"
              class="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded"
            >
              Close
            </button>
            <button
              @click="goToRegister"
              class="bg-blue-500 hover:bg-blue-600 !text-white py-2 px-4 rounded"
            >
              Register
            </button>
          </div>
        </div>
      </div>
  
      <!-- Snackbar for Notifications -->
      <div
        v-if="snackbar.show"
        class="fixed top-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow"
      >
        <div class="flex items-center">
          <span>{{ snackbar.message }}</span>
          <button @click="closeSnackbar" class="ml-4 text-white font-bold">
            Close
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import ApiUser from "@/common/api/user";
  
  export default {
    name: "Pricing",
    data() {
      return {
        dialog: false,
        paymentSuccess: false,
        currentSubscriptionBenefits: [],
        vouchers: [],
        showVouchers: false,
        snackbar: {
          show: false,
          message: "",
          color: "success",
        },
        prices: [
          {
            priceId: "pri_01hq0yz6edjrbnv0x07wc80gge",
            price: "$200/year",
            name: "PacketSafari Analyser Pro + Copilot",
            description:
              "🔥 PacketSafari Copilot: Select packets and ask AI for insights.",
            img: "/pricingcard-packetsafari.jpeg",
            detaildescription:
              "One-year access to the comprehensive PacketSafari PCAP archive and AI. Higher limits and an AI assistant you can chat with for packet analysis. Access to <a href='/copilot'>Copilot</a>",
            features: [
              { icon: "mdi-check-circle-outline", text: "PacketSafari Copilot" },
              { icon: "mdi-check-circle-outline", text: "Access to +5000 PCAPs" },
              { icon: "mdi-check-circle-outline", text: "5 MB Upload Limit" },
              { icon: "mdi-check-circle-outline", text: "200 MB Total Storage" },
              { icon: "mdi-check-circle-outline", text: "PCAPs are kept private" },
              { icon: "mdi-check-circle-outline", text: "No subscription required" },
            ],
            isBundle: false,
          },
          {
            priceId: "pri_01jk13sj9aev2rdh455y6evpcf",
            price: "$40/month",
            name: "PacketSafari Pro + Copilot (Subscription)",
            description:
              "Monthly subscription to PacketSafari Pro + Copilot, offering AI-assisted packet analysis, access to +5000 PCAPs, 5 MB upload limit, and 200 MB total storage.",
            img: "/pricingcard-packetsafari-subscription.jpeg",
            detaildescription:
              "One-month access to PacketSafari Pro + Copilot with recurring billing. Enjoy all pro features with a flexible monthly payment.",
            features: [
              { icon: "mdi-check-circle-outline", text: "PacketSafari Copilot" },
              { icon: "mdi-check-circle-outline", text: "Access to +5000 PCAPs" },
              { icon: "mdi-check-circle-outline", text: "5 MB Upload Limit" },
              { icon: "mdi-check-circle-outline", text: "200 MB Total Storage" },
              { icon: "mdi-check-circle-outline", text: "PCAPs are kept private" },
              { icon: "mdi-check-circle-outline", text: "Monthly Subscription Billing" },
            ],
            isSubscription: true,
          },
          {
            price: "$89",
            name: "AI Shark",
            description: "Fully Automated PCAP Analysis",
            img: "/pricingcard-aishark.png",
            detaildescription:
              "Unlock automated analysis for small PCAPs with AI Shark.",
            features: [
              { icon: "mdi-check-circle-outline", text: "Automatic PCAP Analysis" },
              { icon: "mdi-check-circle-outline", text: "AI-driven Insights" },
              { icon: "mdi-check-circle-outline", text: "Optimized for Small PCAPs" },
              { icon: "mdi-check-circle-outline", text: "50 Analysis Runs" },
            ],
            link: "https://www.packetsafari.com/aishark",
            isBundle: false,
          },
          {
            price: "$200",
            name: "Ranger Labs",
            description: "Self-paced PCAP Lab for Advanced Analysis",
            img: "/pricingcard-ranger.png",
            detaildescription:
              "Hands-on labs featuring real-world case studies and advanced network analysis.",
            features: [
              { icon: "mdi-check-circle-outline", text: "Advanced Network Analysis" },
              { icon: "mdi-check-circle-outline", text: "Comprehensive Case Studies" },
              { icon: "mdi-check-circle-outline", text: "Interactive Lab Environments" },
              { icon: "mdi-check-circle-outline", text: "Built-in Hint System" },
            ],
            link: "https://labs.oripka.de/course/wsadven",
            isBundle: false,
          },
          {
            price: "Free",
            name: "PacketSafari Analyser Free",
            description:
              "Basic PCAP analysis at no cost. Analyse packets yourself.",
            img: "/pricingcard-free.jpeg",
            detaildescription:
              "Our free version offers a simple PCAP analysis for small files. Perfect for quick tests and to get a feel for our platform without AI-driven insights.",
            features: [
              { icon: "mdi-check-circle-outline", text: "Basic PCAP Analysis" },
              { icon: "mdi-close", text: "No AI-driven Insights" },
              { icon: "mdi-close", text: "No private uploads" },
              { icon: "mdi-alert", text: "1 MB Upload Limit" },
              { icon: "mdi-alert", text: "Limited Storage" },
              { icon: "mdi-alert", text: "Limited API Access" },
            ],
            isBundle: false,
            free: true,
          },
          {
            priceId: "pri_01jhmj5py0wknn4bmnbeg3xg15",
            price: "$350",
            name: "Bundle – Full Access",
            description: "Complete access to all our products",
            img: "/pricingcard-bundle.jpeg",
            detaildescription:
              "Enjoy one-year access to PacketSafari’s PCAP archive, Ranger Labs, and AI Shark.",
            features: [
              { icon: "mdi-check-circle-outline", text: "PacketSafari Analyser & Archive" },
              { icon: "mdi-check-circle-outline", text: "50 AI Shark Analysis Runs" },
              { icon: "mdi-check-circle-outline", text: "Access to Ranger Lab" },
              { icon: "mdi-check-circle-outline", text: "No Subscription Required" },
            ],
            discount: "20% off",
            isBundle: true,
          },
          {
            price: "$12000/year",
            name: "PacketSafari Enterprise",
            description: "Enterprise-grade",
            img: "/pricingcard-onprem.jpeg",
            detaildescription:
              "On-Prem, Self-Hosted solution. Available as Docker Compose or VM. Perfect for organizations to store PCAPs privately.",
            features: [
              { icon: "mdi-check-circle-outline", text: "Private PCAPs" },
              { icon: "mdi-check-circle-outline", text: "Self-Service Storage" },
              { icon: "mdi-check-circle-outline", text: "PacketSafari Copilot" },
              { icon: "mdi-check-circle-outline", text: "Use own OpenAI API Key for Copilot" },
              { icon: "mdi-check-circle-outline", text: "1 Year Support" },
            ],
            isBundle: false,
          },
        ],
        products: [
          { key: "free", label: "PacketSafari Analyser Free" },
          { key: "packetsafari_sub", label: "PacketSafari Pro + Copilot (Subscription)" },
          { key: "packetsafari", label: "PacketSafari Pro + Copilot (Annual)" },
          { key: "aishark", label: "AI Shark" },
        ],
        features: [
          {
            name: "AI-driven Insights",
            products: {
              free: false,
              packetsafari_sub: true,
              packetsafari: true,
              aishark: true,
              bundle: true,
            },
          },
          {
            name: "AI-assisted Chat with PCAP",
            products: {
              free: false,
              packetsafari_sub: true,
              packetsafari: true,
              aishark: false,
              bundle: true,
            },
          },
          {
            name: "Fully Automated AI PCAP Analysis",
            products: {
              free: false,
              packetsafari_sub: false,
              packetsafari: false,
              aishark: true,
              bundle: true,
            },
          },
          {
            name: "Access to archive of +5000 PCAPs",
            products: {
              free: false,
              packetsafari_sub: true,
              packetsafari: true,
              aishark: false,
              bundle: true,
            },
          },
          {
            name: "Maximum Upload Size: 5 MB",
            products: {
              free: false,
              packetsafari_sub: true,
              packetsafari: true,
              aishark: false,
              bundle: true,
            },
          },
          {
            name: "Total Storage: 200 MB",
            products: {
              free: false,
              packetsafari_sub: true,
              packetsafari: true,
              aishark: false,
              bundle: true,
            },
          },
          {
            name: "Bring your own storage",
            products: {
              free: false,
              packetsafari_sub: false,
              packetsafari: false,
              aishark: false,
              bundle: false,
            },
          },
          {
            name: "PCAPs are kept private",
            products: {
              free: false,
              packetsafari_sub: true,
              packetsafari: true,
              aishark: true,
              bundle: true,
            },
          },
          {
            name: "PCAPs are on-prem",
            products: {
              free: false,
              packetsafari_sub: false,
              packetsafari: false,
              aishark: false,
              bundle: false,
            },
          },
          {
            name: "No subscription required",
            products: {
              free: true,
              packetsafari_sub: false,
              packetsafari: true,
              aishark: true,
              bundle: true,
            },
          },
          {
            name: "No Wireshark installation required",
            products: {
              free: true,
              packetsafari_sub: true,
              packetsafari: true,
              aishark: true,
              bundle: true,
            },
          },
        ],
      };
    },
    created() {
      this.checkRouteParams();
    },
    watch: {
      $route(to, from) {
        if (to.path === from.path) {
          this.checkRouteParams();
        }
      },
    },
    computed: {
      ...mapGetters(["currentUser"]),
      hasSubscription() {
        if (
          !this.currentUser ||
          !this.currentUser.deactivation_date ||
          this.currentUser.ratelimiting.subscription === "unsubscribed_user"
        ) {
          return false;
        }
        const deactivation = new Date(this.currentUser.deactivation_date);
        const today = new Date();
        return today <= deactivation;
      },
      // Primary products for cards
      packetSafariPro() {
        return this.prices.find(
          (p) => p.name === "PacketSafari Analyser Pro + Copilot"
        );
      },
      packetSafariSubscription() {
        return this.prices.find(
          (p) => p.name === "PacketSafari Pro + Copilot (Subscription)"
        );
      },
      packetSafariFree() {
        return this.prices.find(
          (p) => p.name === "PacketSafari Analyser Free"
        );
      },
      // Additional products: AI Shark, Ranger Labs, Bundle Offer, and Enterprise moved here
      additionalProducts() {
        return this.prices.filter(
          (p) =>
            p.name === "AI Shark" ||
            p.name === "Ranger Labs" ||
            p.isBundle ||
            p.name === "PacketSafari Enterprise"
        );
      },
      // Comparison table products – free, subscription, annual, AI Shark
      comparisonProducts() {
        return [
          { key: "free", label: "PacketSafari Analyser Free" },
          this.products.find((p) => p.key === "packetsafari_sub"),
          this.products.find((p) => p.key === "packetsafari"),
          this.products.find((p) => p.key === "aishark"),
        ];
      },
    },
    methods: {
      checkRouteParams() {
        const urlParams = new URLSearchParams(this.$route.query);
        if (urlParams.has("success")) {
          this.paymentSuccess = true;
          this.fetchBenefits();
        }
        if (this.hasSubscription) {
          this.fetchBenefits();
        }
      },
      async fetchBenefits() {
        try {
          const response = await ApiUser.getbenefits();
          const benefits = response.data;
          const codes = benefits.discount_codes;
          this.currentSubscriptionBenefits = codes.map(
            (benefit) => `${benefit.title} (${benefit.feature})`
          );
          this.vouchers = codes.map((benefit) => ({
            code: benefit.code,
            title: benefit.title,
            signupLink: benefit.signupLink,
            feature: benefit.feature,
          }));
          if (this.hasSubscription) {
            this.currentSubscriptionBenefits = [
              "Access to PacketSafari Copilot",
              "1-Year Access to the Entire PacketSafari PCAP Archive",
              "200 MB Storage",
              "5 MB PCAP Upload Limit",
              "PCAPs are Kept Private",
              "Regularly Updated Content",
              "Higher Download Limits",
            ];
          }
          if (this.vouchers.length > 0) {
            this.showVouchers = true;
          }
        } catch (error) {
          console.error("Error fetching benefits:", error);
          this.snackbar.message = "Failed to load benefits.";
          this.snackbar.color = "error";
          this.snackbar.show = true;
        }
      },
      closeDialog() {
        this.dialog = false;
      },
      goToRegister() {
        this.dialog = false;
        this.$router.push({ name: "Register" });
      },
      openCheckout(selectedPrice) {
        if (!this.currentUser) {
          this.dialog = true;
          return;
        }
        if (!window.Paddle) {
          console.error("Paddle is not loaded.");
          return;
        }
        const successUrl = `${window.location.origin}${this.$route.path}?success=true&product=${selectedPrice.priceId}`;
        window.Paddle.Setup({
          token: process.env.VUE_APP_PADDLE_CLIENT_SIDE_TOKEN,
        });
        window.Paddle.Checkout.open({
          email: this.currentUser.email,
          settings: {
            displayMode: "overlay",
            theme: "dark",
            successUrl: successUrl,
            showAddTaxId: "true",
          },
          items: [{ priceId: selectedPrice.priceId, quantity: 1 }],
          customData: {
            userId: this.currentUser.username,
          },
        });
      },
      openFreeAccess(selectedPrice) {
        this.$router.push({ name: "Register" });
        return;
      },
      openOnPremInfo(selectedPrice) {
        window.location.href = "mailto:contact@packetsafari.com";
      },
      copyVoucher(code) {
        navigator.clipboard
          .writeText(code)
          .then(() => {
            this.snackbar.message = "Voucher code copied to clipboard!";
            this.snackbar.color = "success";
            this.snackbar.show = true;
          })
          .catch(() => {
            this.snackbar.message = "Failed to copy voucher code.";
            this.snackbar.color = "error";
            this.snackbar.show = true;
          });
      },
      closeSnackbar() {
        this.snackbar.show = false;
      },
    },
  };
  </script>
  
  <!-- No extra scoped CSS is needed since Tailwind handles styling -->
  