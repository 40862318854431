var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',{staticClass:"pa-6",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"display-2 font-weight-bold mb-4"},[_vm._v("DNS Dashboard")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" Here you can explore and analyze DNS query and response traffic in detailed tables. Use the filtering tools to search domain names, detect anomalies, and gain actionable insights into your network’s DNS. ")])])],1)],1),(!_vm.pcap)?_c('div',[_c('v-alert',{attrs:{"type":"warning","dense":""}},[_vm._v(" No PCAP data loaded. Please load a PCAP file to view DNS and TLS entries. ")])],1):_c('div',[_c('v-row',{staticClass:"ma-7"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"section-header"},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-domain")]),_c('span',{staticClass:"headline"},[_vm._v("DNS Entries")])],1),_c('p',{staticClass:"grey--text mb-3"},[_vm._v(" These entries represent the DNS data extracted from the capture. Use the search field to filter results. ")]),_c('v-text-field',{staticClass:"mb-3",attrs:{"append-icon":"mdi-magnify","label":"Search DNS","single-line":"","hide-details":""},model:{value:(_vm.dnsSearch),callback:function ($$v) {_vm.dnsSearch=$$v},expression:"dnsSearch"}}),(_vm.filteredDnsEntries && _vm.filteredDnsEntries.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredDnsEntries,"hide-default-footer":"","disable-pagination":"","dense":"","group-by":_vm.groupby,"show-group-by":"","expanded":_vm.expanded},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_c('router-link',{attrs:{"target":"_blank","to":{
                    name: 'Analysis',
                    params: {
                      id: _vm.pcap.id,
                      filter: _vm.filternamer(props.item.name),
                      index: 'none',
                      view: 'l',
                      analysisid: 'none',
                    },
                  }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-web")]),_vm._v(" "+_vm._s(props.item.name)+" ")],1)],1),_c('td',[_c('router-link',{attrs:{"target":"_blank","to":{
                    name: 'Analysis',
                    params: {
                      id: _vm.pcap.id,
                      filter: _vm.filterip(props.item.value),
                      index: 'none',
                      view: 'l',
                      analysisid: 'none',
                    },
                  }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-lan")]),_vm._v(" "+_vm._s(props.item.value)+" ")],1)],1)])]}}],null,false,3323652192)}):_c('div',{staticClass:"text-center grey--text"},[_vm._v(" No DNS entries found. ")])],1)],1),_c('v-row',{staticClass:"ma-7"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"section-header"},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-lock")]),_c('span',{staticClass:"headline"},[_vm._v("TLS SNI Entries")])],1),_c('p',{staticClass:"grey--text mb-3"},[_vm._v(" The table below lists the TLS SNI entries detected in the capture. Click on an entry for detailed analysis. ")]),_c('v-text-field',{staticClass:"mb-3",attrs:{"append-icon":"mdi-magnify","label":"Search TLS SNI","single-line":"","hide-details":""},model:{value:(_vm.tlsSearch),callback:function ($$v) {_vm.tlsSearch=$$v},expression:"tlsSearch"}}),(_vm.filteredTlsEntries && _vm.filteredTlsEntries.length)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredTlsEntries,"hide-default-footer":"","disable-pagination":"","dense":"","group-by":_vm.groupby,"show-group-by":"","expanded":_vm.expanded},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_c('router-link',{attrs:{"target":"_blank","to":{
                    name: 'Analysis',
                    params: {
                      id: _vm.pcap.id,
                      filter: _vm.filtersni(props.item.name),
                      index: 'none',
                      view: 'l',
                      analysisid: 'none',
                    },
                  }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-server")]),_vm._v(" "+_vm._s(props.item.name)+" ")],1)],1),_c('td',[_c('router-link',{attrs:{"target":"_blank","to":{
                    name: 'Analysis',
                    params: {
                      id: _vm.pcap.id,
                      filter: _vm.filtertlsip(props.item.value),
                      index: 'none',
                      view: 'l',
                      analysisid: 'none',
                    },
                  }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-lan")]),_vm._v(" "+_vm._s(props.item.value)+" ")],1)],1)])]}}],null,false,3387058047)}):_c('div',{staticClass:"text-center grey--text"},[_vm._v(" No TLS SNI entries found. ")])],1)],1),_c('v-btn',{staticClass:"refresh-btn",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":_vm.refreshData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }