<template>
  <v-menu
    offset-x
    left
    v-if="currentAnalysis"
    :close-on-content-click="false"
    v-model="menuopen"
  >
    <decodeasdialog></decodeasdialog>
    <editcap v-if="currentAnalysis.pcaplist.editcap"></editcap>

    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon v-bind="attrs" v-on="on">
        <v-icon color="blue-grey lighten-4" dark>build</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list v-if="menuitems" subheader>
        <v-subheader>Actions</v-subheader>

        <!-- Decode as Menu Item without Tooltip -->
        <v-list-item v-if="currentUser" @click.stop="decodeas">
          <v-list-item-action>
            <v-icon>blur_on</v-icon>
          </v-list-item-action>
          <v-list-item-title>Decode as</v-list-item-title>
        </v-list-item>

        <!-- Copy Link Menu Item without Tooltip -->
        <v-list-item @click.stop="linkToCurrent">
          <v-list-item-action>
            <v-icon>link</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            <router-link
              v-if="currentAnalysis.filter"
              style="text-decoration: none; color: inherit"
              @click.native.prevent
              id="linkAnchor"
              :to="{
                name: 'Analysis',
                params: {
                  id: currentAnalysis.pcapid,
                  index: 'none',
                  analysisid: 'none',
                  filter: currentAnalysis.filter,
                  view: 'l'
                }
              }"
            >
              Copy Link
            </router-link>
            <router-link
              v-else
              style="text-decoration: none; color: inherit"
              @click.native.prevent
              id="linkAnchor"
              :to="{
                name: 'Analysis',
                index: 'none',
                analysisid: 'none',
                params: { id: currentAnalysis.pcapid, view: 'l' }
              }"
            >
              Copy Link
            </router-link>
          </v-list-item-title>
        </v-list-item>

        <!-- Edit Analysis Menu Item without Tooltip -->
        <v-list-item v-if="currentUser" @click.stop="analysisdrawer()">
          <v-list-item-action>
            <v-icon>slideshow</v-icon>
          </v-list-item-action>
          <v-list-item-title>Edit Analysis</v-list-item-title>
        </v-list-item>

        <!-- Dynamic Menu Items without Tooltip -->
        <template v-if="currentUser">
          <v-list-item
            v-for="(item, index) in menuitems"
            :key="index"
            v-on="on"
            @click="item.action()"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </template>

        <!-- Temporary Settings Section -->
        <v-subheader>Temporary Settings</v-subheader>

        <!-- Highlight Changes Toggle with Tooltip -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch
                  v-model="currentAnalysis.profiles.selected.highlightDecodeChanges"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Highlight changes</v-list-item-title>
            </v-list-item>
          </template>
          <span>Highlight changes in decode</span>
        </v-tooltip>

        <!-- Filter Snackbar Toggle with Tooltip -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch v-model="currentAnalysis.showFilterBoxOverlay"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Filter snackbar</v-list-item-title>
            </v-list-item>
          </template>
          <span>Good for demos or teaching</span>
        </v-tooltip>

        <!-- Streamfilter Toggle with Tooltip -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch v-model="currentAnalysis.streamFilter"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Streamfilter</v-list-item-title>
            </v-list-item>
          </template>
          <span>The display filters shows all streams that have a matching packet</span>
        </v-tooltip>

        <!-- Packet Info Toggle with Tooltip -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch
                  v-model="currentAnalysis.profiles.selected.currentPacketInfo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Packet Info</v-list-item-title>
            </v-list-item>
          </template>
          <span>Show packet info</span>
        </v-tooltip>

        <!-- Quick Filter Toggle with Tooltip -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch v-model="currentAnalysis.quickfilter"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Quick filter</v-list-item-title>
            </v-list-item>
          </template>
          <span>Filter in the table not on the backend</span>
        </v-tooltip>

        <!-- Debug Output Toggle with Tooltip -->
        <v-tooltip left v-if="currentAnalysis.features.debugging">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch v-model="currentAnalysis.debug"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Debug Output</v-list-item-title>
            </v-list-item>
          </template>
          <span>Debug Output</span>
        </v-tooltip>

        <!-- Timeline Toggle with Tooltip -->
        <v-tooltip left v-if="currentAnalysis.features.timeline">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch
                  v-model="currentAnalysis.profiles.selected.showTimeline"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Timeline</v-list-item-title>
            </v-list-item>
          </template>
          <span>Timeline</span>
        </v-tooltip>

        <!-- Color Packets Toggle with Tooltip -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch
                  v-model="currentAnalysis.profiles.selected.colorPackets"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Color Packets</v-list-item-title>
            </v-list-item>
          </template>
          <span>Color Packets</span>
        </v-tooltip>

        <!-- Dark Mode Toggle with Tooltip -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-switch v-model="currentAnalysis.profiles.selected.theme"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Dark mode</v-list-item-title>
            </v-list-item>
          </template>
          <span>Dark mode</span>
        </v-tooltip>

        <!-- Current Profile Section -->
        <v-subheader>
          Current Profile
          <v-btn class="ma-2" @click="loadallprofiles" text small>
            load all
          </v-btn>
        </v-subheader>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-select
                  v-model="cprofile"
                  :items="currentAnalysis.profiles.available"
                  item-value="id"
                  item-text="name"
                  return-object
                  hide-details
                  style="max-width: 200px"
                  label="Available profiles"
                  solo
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name | truncate(15, "...") }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-list-item-action>
            </v-list-item>
          </template>
          <span>Select Profile</span>
        </v-tooltip>

        <!-- Indexed Packet List View Selector with Tooltip -->
        <v-tooltip left v-if="currentAnalysis.features.enableindexedpacketlist">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action v-if="currentAnalysis.pcap">
                <v-select
                  v-if="currentAnalysis.pcap.origname"
                  solo
                  :items="availableviews"
                  v-model="selectedview"
                  item-text="indexername"
                  item-value="id"
                  return-object
                  label="Select view..."
                  hide-details
                  style="max-width: 200px"
                ></v-select>
              </v-list-item-action>
            </v-list-item>
          </template>
          <span>Select Packet View</span>
        </v-tooltip>
      </v-list>
    </v-card>
  </v-menu>
</template>


<script>
import { mapGetters } from "vuex";
import { sharkdhelper } from "@/common/sharkdhelper";
import Decodeasdialog from "@/components/dialogs/Decodeasdialog";
import Editcap from "@/components/dialogs/Editcap";
import { navigation } from "@/common/navigation";
import ApiProfile from "@/common/api/profile";

export default {
  props: ["on"],
  name: "miscmenu",
  components: {
    Editcap,
    Decodeasdialog,
  },
  data() {
    return {
      cprofile: null,
      menuopen: false,
      unindexed: { id: "none", indexername: "Unindexed" },
      selectedview: null,

      dialogDecodeAs: false,
      menuitems: [
        {
          text: "Autosize Columns",
          action: this.autoresize,
          icon: "view_column",
        },
        {
          text: "Edit Profile",
          action: this.gotoCurrent,
          icon: "edit",
        },
        {
          text: "Edit PCAP",
          action: this.saveSpecial,
          icon: "save",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentAnalysis",
      "avatarPic",
      "error",
    ]),
    availableviews() {
      var views = [this.unindexed];
      if (this.pcap && this.pcap.indices) {
        for (let index of this.pcap.indices) {
          views.push(index);
        }
      }
      return views;
    },
  },
  mixins: [sharkdhelper, navigation],
  mounted() {
    this.cprofile = this.currentAnalysis.profiles.selected;
  },
  watch: {
    cprofile(newVal, oldVal) {
      // Force refetches
      if (oldVal && "id" in oldVal && newVal && oldVal.id !== newVal.id) {
        this.currentAnalysis.profiles.selected = newVal;
        this.currentAnalysis.reloadprofiles = false;
        this.currentAnalysis.reloadprofiles = true;
      }
    },
    "currentAnalysis.profiles.selected"(newVal) {
      if (this.cprofile == null) {
        this.cprofile = newVal;
        return;
      }
      if (this.cprofile.id !== newVal.id && newVal) {
        this.cprofile = newVal;
      }
    },
    selectedview() {
      this.navigatePacketview(this.pcap.id, this.selectedview.id);
    },
    "currentAnalysis.debug"(newVal) {
      if (newVal) {
        this.currentAnalysis.showAnalysisDrawer = false;
      }
    },
    "currentAnalysis.showAnalysisDrawer"(newVal) {
      if (newVal) {
        this.currentAnalysis.debug = false;
      }
    },
  },
  filters: {
    truncate(text, length, suffix) {
      return text.substring(0, length) + suffix;
    },
  },
  methods: {
    setselected() {
      for (let v of this.availableviews) {
        if (this.currentAnalysis.index === "none") {
          this.selectedview = this.unindexed;
        }
        if (this.currentAnalysis.index === v.id) {
          this.selectedview = v;
        }
      }
    },
    showPacketinfo() {
      this.currentAnalysis.profiles.selected.currentPacketInfo = !this.currentAnalysis
        .profiles.selected.currentPacketInfo;
    },
    streamfilter() {
      this.currentAnalysis.streamFilter = !this.currentAnalysis.streamFilter;
    },
    autoresize() {
      this.menuopen = false;
      this.$eventHub.$emit("autosizecolumns", null);
    },
    saveSpecial() {
      this.menuopen = false;
      this.currentAnalysis.pcaplist.editcap = true;
    },
    analysisdrawer() {
      this.menuopen = false;
      this.currentAnalysis.showAnalysisDrawer = true;
    },
    decodeas() {
      this.$nextTick(() => {
        this.menuopen = false;
        this.currentAnalysis.decodeasdialog = true;
      });
    },
    loadallprofiles() {
      let args = {
        type: "public",
      };
      ApiProfile.list(args)
        .then(({ data }) => {
          this.currentAnalysis.profiles.available = data.profiles;
          this.assureAnalysisprofile();
        })
        .catch(() => {});
    },
    linkToCurrent() {
      this.menuopen = false;
      const copyText = document.getElementById("linkAnchor").href;
      const mockInput = document.createElement("input");
      document.body.appendChild(mockInput);
      mockInput.type = "text";
      mockInput.value = copyText;
      mockInput.style.opacity = 0;
      mockInput.style.position = "absolute";
      mockInput.style.top = "0px";
      mockInput.style.left = "0px";
      mockInput.select();
      document.execCommand("Copy");
      this.error.type = "info";
      this.error.msg = "Copied link";
      mockInput.parentNode.removeChild(mockInput);
    },
    gotoCurrent() {
      this.$router
        .push({
          name: "Analysis Profile",
          params: { profileid: this.currentAnalysis.profiles.selected.id },
        })
        .catch((err) => {});
    },
  },
};
</script>

<style>
/* Add any custom styles here */
</style>
