<template>
  <div id="app">

    <filterboxoverlay />
    <v-app>
      <errorbar></errorbar>
      <navigation v-if="currentAnalysis.navbar"></navigation>
   
      <v-main v-if="$route.meta.fullscreen" class="fullscreen">
        <router-view v-if="frontend_loaded" />    
      </v-main>

      <v-main v-else-if="$route.meta.widescreen">

          <div class="mx-auto w-full">
          <v-row align="center" justify="center">
            <v-col cols="11">
              <router-view v-if="frontend_loaded" :key="$route.fullPath" />
            </v-col>
          </v-row>     
          <Footer v-if="$route.meta.footer" style="margin-top: 300px"></Footer>
        </div>
  
      </v-main>

      <v-main v-else>
        <!--<v-container fluid fill-height>-->

          <div class="mx-auto w-full">
          <v-row align="center" justify="center" class="pt-3">
            <v-col cols="11">
              <router-view v-if="frontend_loaded" :key="$route.fullPath" />
              <loading v-else />
            </v-col>
          </v-row>
          <Footer v-if="$route.meta.footer" style="margin-top: 500px"></Footer>
        </div>   
      </v-main>

      <v-fade-transition>
        <v-overlay opacity="0.9" :value="currentAnalysis.longoperationoverlay">
          <v-row justify="center" align="center">
            <v-col cols="12">
              <loading />
            </v-col>
          </v-row>
        </v-overlay>
      </v-fade-transition>

    </v-app>
    <Cookie />
    <Tour></Tour>
    <Touraiselect v-if="currentAnalysis.showaiselecttour"></Touraiselect>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Cookie from "./components/Cookie";
import Touraiselect from "./components/Touraiselect.vue";
import Loading from "./components/Loading";
import Navigation from "./components/Navigation";
import Filterboxoverlay from "./components/Filterboxoverlay";
import Errorbar from "./components/Errorbar";
import { mapGetters } from "vuex";
import ApiUser from "@/common/api/user";
import { DEFAULT_PROFILE } from "@/common/config";
import ApiProfile from "@/common/api/profile";
import { displayfilter } from "@/common/displayfilter";
import { UPDATE_ANALYSIS } from "@/store/actions.type";
import { navigation } from "@/common/navigation";
import Tour from "./components/Tour.vue";

export default {
  name: "App",
  computed: {
    ...mapGetters([
      "currentAnalysis",
      "currentUser",
      "analysismessage",
      "error",
    ]),
    frontend_loaded: function() {
      return true
      return (
        this.currentAnalysis.frontendloadstatus.profiles &&
        this.currentAnalysis.frontendloadstatus.info
      ); //  && this.currentAnalysis.frontendloadstatus.user
    },
  },
  components: {
    Tour,
    Touraiselect,
    Footer,
    Filterboxoverlay,
    Navigation,
    Cookie,
    Errorbar,
    Loading,
  },
  mixins: [displayfilter, navigation],
  async mounted() {
    this.$eventHub.$on("active-pcap", (id) => {
      if (id == null) {
        this.pcap = { origname: "" };
        return;
      }
      this.getPcap(id);
    });

    setTimeout(() => {
      if (!this.currentAnalysis.frontendloadstatus.info) {
        console.warn("CHECK_INFO seems stuck, forcing frontend_loaded to true");
        this.currentAnalysis.frontendloadstatus.info = true;
      }
    }, 2000); // Force after 10 seconds
    //this.getPcap(this.currentAnalysis.pcapid);
    /* set displayfilter externally not from navbar, e.g. by rightclick actions */
    // this.$eventHub.$on("displayfilter-set", filter => {
    //   this.displayfilter = filter;
    //   // forward event to packetlist
    //   this.$eventHub.$emit("displayfilter", this.displayfilter);
    // });
  },
  beforeDestroy() {
    this.pcap = { origname: "Empty" };
    this.$eventHub.$off("active-pcap");
    // this.$eventHub.$off("displayfilter-set");
  },
  watch: {
    "currentAnalysis.reloadprofiles": function(n, o) {
      if (n) {
        this.reLoadProfiles();
      }
    },

    "currentAnalysis.forcererender": function(n, o) {
      if (n) {
        //this.loadProfiles();
      }
    },
    "currentAnalysis.profiles.selected": function(n, o) {
      if (n.id && o.id && n.id != o.id) {
        this.selectProfile();
      }
      this.$vuetify.theme.dark = n.theme;
    },
    $route(to, from) {
      this.routeChange(to, from);
    },
    "currentAnalysis.profiles.selected.theme": function(n) {
      this.$vuetify.theme.dark = n;
    },
  },
  methods: {
    // this gets executed every time we change route
    // it is an important functoin for resetting values and preparing for a new view

    routeChange: async function(to) {
      this.currentAnalysis.longoperationoverlay = false;
      this.currentAnalysis.longoperationoverlayData.text = "";
      this.currentAnalysis.longoperationoverlayData.progress = 0;

      this.currentAnalysis.loading = true;
      this.currentAnalysis.loadingpackets = false;
      this.currentAnalysis.curfield.fieldname = null;

      this.error.msg = null;

      // this.resetDisplayfilter();

      //this.resetAnalysis();
      await this.loadProfiles();

      this.currentAnalysis.pcap = null;

      if (this.currentAnalysis.profiles.modifications.maxPackets != 0) {
        this.currentAnalysis.profiles.selected.maxPackets = this.currentAnalysis.profiles.modifications.maxPackets;
      }

      // reset pcap selections
      this.currentAnalysis.pcaplist.checkedpcaps = [];

      // remove analysis message
      this.analysismessage.show = false;

      // remove drawer
      this.currentAnalysis.aiSelectedPackets = { packets: [], decoded: [] };
      this.currentAnalysis.showAnalysisDrawer = false;
      this.currentAnalysis.showAI = false;

      this.currentAnalysis.customgraph = {};

      this.currentAnalysis.pcaplist.editcap = false;
      // unmark packets
      this.currentAnalysis.packetlist.marked = [];

      this.currentAnalysis.uploadtags.length == 0;

      // reset state of analysis selection packetlist, iograph, taps
      this.toggle_exclusive = 0;

      if (to["name"] == "Analysis") {
        if (this.currentAnalysis.profiles.selected.closeNavDrawerOnPcapOpen) {
          this.currentAnalysis.showNavDrawer = false;
        } else {
          this.currentAnalysis.showNavDrawer = false;
        }

        if (to["params"]["view"] == "i") {
          this.toggle_exclusive = 1;
        }

        if (this.currentAnalysis.pcapid != to["params"]["id"]) {
          this.resetAnalysisWithoutReload();
          this.currentAnalysis.firstloadpacketlist = true;
        }

        this.currentAnalysis.pcapid = to["params"]["id"];

        this.updateRecentlyUsed(this.currentAnalysis.pcapid);

        if (to["params"]["analysisid"] !== undefined) {
          if (to["params"]["analysisid"] == "none") {
            this.currentAnalysis.analysisid = null;
          } else if (to["params"]["analysisid"] != "") {
            this.currentAnalysis.analysisid = to["params"]["analysisid"];
          }
        }

        //await this.taskPolling(this.currentAnalysis.pcapid)

        this.getPcap(this.currentAnalysis.pcapid, to);
      } else {
        this.pcap = { origname: "" };
      }
    },
    assureAnalysisprofile: function() {
      var searchfor = null;
      if (!this.currentUser) {
        if (this.currentAnalysis.profiles.selected) {
          searchfor = this.currentAnalysis.profiles.selected.id;
        } else {
          searchfor = DEFAULT_PROFILE;
        }
      } else {
        searchfor = this.currentUser.analysisprofile;
      }

      for (let p of this.currentAnalysis.profiles.available) {
        if (p.id === searchfor) {
          this.currentAnalysis.profiles.selected = p;
          this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
          return;
        }
      }

      // default to default
      searchfor = DEFAULT_PROFILE;
      for (let p of this.currentAnalysis.profiles.available) {
        if (p.id === searchfor) {
          this.currentAnalysis.profiles.selected = p;
          this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
          return;
        }
      }
    },
    selectProfile: function() {
      if (!this.currentUser) {
        return;
      }

      if (!("columns" in this.currentAnalysis.profiles.selected)) {
        ApiProfile.get(this.currentAnalysis.profiles.selected.id)
          .then(({ data }) => {
            this.currentAnalysis.profiles.selected = data;
            this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
          })
          .catch(({ response }) => {});
      }

      ApiUser.setanalysisprofile(this.currentAnalysis.profiles.selected.id)
        .then(() => {
          this.currentUser.analysisprofile = this.currentAnalysis.profiles.selected.id;
          this.$store.dispatch(UPDATE_ANALYSIS, this.currentAnalysis);
        })
        .catch(() => {});

      //this.loadProfiles()
    },
    reLoadProfiles: function() {
      let args = {
        type: "private",
      };

      if (this.currentAnalysis.profiles.selected) {
        args["selected"] = this.currentAnalysis.profiles.selected.id;
      }

      ApiProfile.list(args)
        .then(({ data }) => {
          this.currentAnalysis.profiles.available = data.profiles;
          this.assureAnalysisprofile();
          this.currentAnalysis.forcererender = true;
          this.currentAnalysis.reloadprofiles = false;
          this.currentAnalysis.frontendloadstatus.profiles = true;
        })
        .catch(() => {
          this.currentAnalysis.reloadprofiles = false;
        });
    },
    profilesareloaded: function() {
      if (this.currentAnalysis.profileneedsupdate) {
        return true;
      }

      if (this.currentAnalysis.profiles) {
        if (this.currentAnalysis.profiles.available.length > 0) {
          if (this.currentAnalysis.profiles.selected) {
            //console.log("Profiles already loaded: "+this.currentAnalysis.profiles)
            return true;
          }
        }
      }
      return false;
    },
    loadProfiles: function() {
      if (this.profilesareloaded()) {
        return;
      }

      let args = {
        type: "private",
      };

      if (this.currentAnalysis.profiles.selected) {
        args["selected"] = this.currentAnalysis.profiles.selected.id;
      }

      ApiProfile.list(args)
        .then(({ data }) => {
          this.currentAnalysis.profiles.available = data.profiles;
          this.assureAnalysisprofile();
          this.currentAnalysis.frontendloadstatus.profiles = true;
        })
        .catch(() => {});
    },
    updateRecentlyUsed: function(id) {
      if (this.currentUser && this.currentUser.username)
        ApiUser.updaterecentlyused(id)
          .then(() => {})
          .catch(() => {});
    },
    resetAnalysisWithoutReload() {
      this.df_prepare_for_packetlist("");
      this.currentAnalysis.selectStartDate = undefined;
      this.currentAnalysis.selectEndDate = undefined;
    },
    resetAnalysis: function() {
      this.currentAnalysis.selectStartDate = undefined;
      this.currentAnalysis.selectEndDate = undefined;
      this.df_apply_to_packetlist("");
    },
    // setselected: function() {
    //   for (let v of this.availableviews) {
    //     if (this.currentAnalysis.index == "none") {
    //       this.selectedview = this.unindexed;
    //     }

    //     if (this.currentAnalysis.index == v.id) {
    //       this.selectedview = v;
    //     }
    //   }
    // },
  },
  data: () => ({
    drawer: null,
    drawerRight: null,
    right: false,
    left: false,
    metaInfo: {
      title: "My Example App",
      titleTemplate: "%s - Yay!",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    },
  }),
};
</script>

<style>
/*
.fullscreen {
  height: 100%;
  background-color: white;
}

html {
  overflow-x: auto !important;
}*/

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.monofont {
  font-family: "Courier New", Courier, monospace !important;
}

/* work around https://github.com/vuetifyjs/vuetify/issues/9130 */
.v-card__text,
.v-card__title {
  word-break: normal;
  /* maybe !important  */
}
</style>
