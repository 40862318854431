<template>
  <v-card
    style="display: flex; flex-direction: column; height: 100%;"
    class="pa-0"
  >
    <!-- Chat Archive Dialog -->
    <v-dialog v-model="showChatArchive" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Chat Archive
          <v-spacer></v-spacer>
          <v-btn icon @click="showChatArchive = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          <p style="font-size: 0.9rem; color: #555;">
            These chats are saved locally in your browser. Clearing the archive
            will remove all saved chats.
          </p>
        </v-card-subtitle>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(chat, index) in chatArchives" :key="index">
              <v-list-item-content>
                <v-list-item-title>
                  {{ new Date(chat.timestamp).toLocaleString() }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ chat.messages.length }} messages
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="primary" @click="loadChat(index)">Load</v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" text @click="clearChatArchives"
            >Clear Archive</v-btn
          >
          <v-btn text @click="showChatArchive = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Advanced Settings Dialog -->
    <v-dialog v-model="showAdvancedSettings" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Advanced Settings
          <v-spacer></v-spacer>
          <v-btn icon @click="showAdvancedSettings = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          <v-alert type="info" outlined class="mt-2">
            <strong>Note:</strong> These settings influence the amount of data
            sent to the AI. Adjust them carefully to balance data size and
            processing.
          </v-alert>
          <v-alert
            v-if="errorMessage && !packetLimitExceeded"
            type="error"
            class="mb-2"
            dismissible
            @input="errorMessage = ''"
          >
            {{ errorMessage }}
            <v-btn text small @click="retrySend">Retry</v-btn>
          </v-alert>
        </v-card-subtitle>
        <v-card-text>
          <!-- (Settings content remains unchanged) -->
          <div class="d-flex flex-column gap-0">
            <!-- Settings content… -->
            <div class="data-size-indicator">
              <p>
                Total data based on selected packets that will be given to AI:
                {{ packetDataSizeKB }} KB / 80 KB
              </p>
              <v-progress-linear
                :value="(packetDataSize / (80 * 1024)) * 100"
                height="10"
                :color="
                  (packetDataSize / (80 * 1024)) * 100 > 100 ? 'red' : 'primary'
                "
                background-color="grey lighten-2"
              ></v-progress-linear>
            </div>
            <div>
              <v-switch
                v-model="useDecodedPackets"
                label="Use packet details"
                dense
                hide-details
              ></v-switch>
              <p class="text-subtitle-2 text-muted">
                Packet details provide additional information beyond what is
                visible in the packet list. They include fully decoded fields
                for each selected packet, offering higher quality analysis.
                However, enabling this feature may limit the total number of
                packets you can select.
              </p>
            </div>
            <div>
              <v-switch
                v-model="includeBytes"
                label="Include Payload"
                dense
                hide-details
              ></v-switch>
              <p class="text-subtitle-2 text-muted">
                Includes raw packet payload data, adding details not in decoded
                packets.
              </p>
            </div>
            <div>
              <v-switch
                v-model="bytesAsAscii"
                label="Bytes as ASCII"
                dense
                hide-details
              ></v-switch>
              <p class="text-subtitle-2 text-muted">
                Sends ASCII format of payload to AI instead of raw bytes.
              </p>
            </div>
            <div>
              <v-switch
                v-model="compressData"
                label="Compress Packets"
                dense
                hide-details
              ></v-switch>
              <p class="text-subtitle-2 text-muted">
                Compresses packets to reduce data sent to the AI by advanced
                techniques. (good default)
              </p>
            </div>
            <div>
              <v-switch
                v-model="flattenDisplayFields"
                label="Flatten Fields"
                dense
                hide-details
              ></v-switch>
              <p class="text-subtitle-2 text-muted">
                Simplifies field names to reduce data size. This may
                occasionally confuse the AI. (good default)
              </p>
            </div>
            <div>
              <v-select
                v-model="selectedModel"
                :items="modelOptions"
                label="Select AI Model"
                dense
                class="mt-4"
                outlined
                style="max-width: 200px;"
              ></v-select>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="showAdvancedSettings = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Header -->
    <v-card-title class="headline d-flex align-center">
      <div class="d-flex align-center">
        <img
          src="/openailogo.png"
          alt="AI Icon"
          style="width: 32px; height: 32px; vertical-align: middle; filter: brightness(0.8);"
        />
        <span class="ml-2 font-weight-bold">PacketSafari Copilot</span>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-btn icon @click="toggleAiChatFullscreen">
          <v-icon>
            {{
              currentAnalysis.isAiChatFullScreen
                ? "mdi-fullscreen-exit"
                : "mdi-fullscreen"
            }}
          </v-icon>
        </v-btn>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <v-card-subtitle>
      Chat with selected packets
    </v-card-subtitle>

    <!-- Chat Container -->
    <v-card-text
      class="chat-container"
      ref="chatContainer"
      style="flex: 1; overflow-y: auto;"
    >
      <!-- Loading indicator for decoding packets -->
      <div
        v-if="loadingPackets"
        class="loading-packets-indicator"
        style="margin-bottom: 16px;"
      >
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
        <div
          class="loading-text"
          style="text-align: center; margin-top: 8px; font-style: italic;"
        >
          Preparing packets for the LLM...
        </div>
      </div>
      <v-alert v-if="!userHasSubscription" type="info" outlined class="mb-4">
        This is a paid service that provides advanced AI analysis of selected
        packets using the latest AI models. To access this functionality, please
        subscribe to
        <router-link :to="{ name: 'Pricing' }">PacketSafari Pro</router-link>.
      </v-alert>
      <v-alert v-if="packetLimitExceeded" type="error" class="mb-4">
        Too much packet data selected ({{ packetDataSizeKB }} KB,
        {{ packets.length }} packets). Please select fewer packets<template
          v-if="useDecodedPackets"
        >
          or disable "Use packet details" in the settings.</template
        >
      </v-alert>
      <v-alert
        v-if="errorMessage && !packetLimitExceeded"
        type="error"
        class="mb-2"
        dismissible
        @input="errorMessage = ''"
      >
        {{ errorMessage }}
        <v-btn text small @click="retrySend">Retry</v-btn>
      </v-alert>

      <!-- Guidance Bubble (Initial Greeting Message) -->
      <div
        v-if="showGuidanceBubble"
        class="chat-bubble bubble-assistant guidance-bubble"
        id="guideancebubble"
      >
        <div class="bubble-text">
          <!-- Display the summary -->
          <div v-html="guidanceSummary"></div>

          <!-- Collapsible detailed guidance -->
          <div
            v-if="showDetailedGuidance"
            style="margin-top: 12px; border-top: 1px solid #ccc; padding-top: 12px;"
          >
            <div v-html="guidanceDetails"></div>
          </div>

          <div class="flex flex-row justify-center gap-4 mt-4">
            <v-btn text small @click="toggleGuidanceDetails">
              {{ showDetailedGuidance ? "Show Less" : "Show More" }}
            </v-btn>
            <v-btn text small @click="triggerTour">
              Guided Tour
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Conversation Bubbles -->
      <div
        v-for="(msg, index) in conversation.filter((m) => m.role !== 'system')"
        :key="index"
        :class="[
          'chat-bubble',
          msg.role === 'user' ? 'bubble-user' : 'bubble-assistant',
        ]"
      >
        <v-icon v-if="msg.role === 'user'" class="bubble-icon">
          mdi-account
        </v-icon>
        <div class="bubble-text">
          <TypingText
            v-if="
              msg.role === 'assistant' &&
                index === animatedAssistantMessageIndex
            "
            :text="renderMarkdown(msg.content)"
            :interval="10"
          />
          <span v-else v-html="renderMarkdown(msg.content)"></span>
        </div>
        <div v-if="msg.role === 'assistant'" class="copy-button-container">
          <v-btn small text icon @click="copyAssistantMessage(msg.content)">
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-if="loading" class="loading-indicator">
        <v-progress-linear
          :value="progress"
          height="4"
          color="primary"
        ></v-progress-linear>
        <div class="thinking-text">Thinking...</div>
      </div>
    </v-card-text>

    <v-card-actions
      class="d-flex flex-column"
      style="border-top: 1px solid #eee; margin-right: 16px;"
    >
      <!-- Conversation Starters -->
      <div
        v-if="conversation.length === 1"
        class="conversation-starters"
        id="conversationstarters"
        style="margin-bottom: 8px;"
      >
        <small>Conversation Starters:</small>
        <v-row align="center" justify="start" class="mt-1">
          <v-col
            v-for="(starter, index) in predefinedStarters"
            :key="index"
            cols="auto"
            class="pr-1"
          >
            <v-btn
              small
              rounded
              color="primary"
              :disabled="disableInteractions"
              @click="sendPredefined(starter)"
            >
              {{ starter }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4 my-2"></v-divider>
      </div>

      <!-- Chat Input & Action Buttons -->
      <div class="d-flex align-center" style="width: 100%;">
        <v-text-field
          v-model="userPrompt"
          label="Chat with your packets..."
          class="flex-grow-1 mr-2"
          id="aichatinput"
          @keyup.enter="sendPrompt"
          :disabled="disableInteractions"
        ></v-text-field>
        <v-btn
          color="primary"
          @click="sendPrompt"
          :disabled="disableInteractions"
          >Send</v-btn
        >
        <v-btn
          icon
          @click="resetChat"
          id="resetchat"
          :disabled="disableInteractions"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn icon @click="showChatArchive = true">
          <v-icon>mdi-folder</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="showAdvancedSettings = true"
          id="advancedsettings"
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApiAiChat from "@/common/api/aichat";
import ApiDecode from "@/common/api/decode";
import { mapGetters } from "vuex";
import axios from "axios";
import { marked } from "marked";
import { permissionchecks } from "@/common/permissions";
import TypingText from "@/components/TypingText.vue"; // Adjust the import path as needed
import { CHECK_AUTH } from "@/store/actions.type";

export default {
  name: "ChatDialog",
  components: {
    TypingText,
  },
  mixins: [permissionchecks],
  data() {
    return {
      progress: 0,
      guidanceNotification: "", // new property for notification message
      progressInterval: null,
      showDetailedGuidance: false,

      chatArchives: JSON.parse(localStorage.getItem("chatArchives")) || [],
      showChatArchive: false,
      showAdvancedSettings: false,
      dialog: false,
      userPrompt: "",
      selectedModel: "o3-mini",
      modelOptions: ["o3-mini", "o1-mini"],
      conversation: [],
      loading: false,
      errorMessage: "",
      loadingPackets: false,

      packetContext: undefined,
      compressData: true,
      flattenDisplayFields: true,
      lastUserPrompt: "",
      decodedPackets: [],
      useDecodedPackets: true,
      fellBackToNotUseDecodedPackets: false,
      includeBytes: true,
      fellBackToNotUseIncludeBytes: false,
      bytesAsAscii: true,
      lastPacketSelection: null,
      // Cache for the last decoding options used (includeBytes and bytesAsAscii)
      lastDecodedOptions: null,
      predefinedStarters: [
        "Summarize the packets",
        "Troubleshoot issues",
        "Security check",
      ],
      guidanceAnimated: true, // New flag for animation in the guidance bubble
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error", "avatarPic"]),
    pcap() {
      return this.currentAnalysis?.pcap || {};
    },
    packets() {
      return this.currentAnalysis?.aiSelectedPackets?.packets || [];
    },
    guidanceSummary() {
      if (this.packets.length) {
        return `
You have selected <b>${this.packets.length} packet${
          this.packets.length > 1 ? "s" : ""
        }</b>, which are highlighted in <span style="background-color: darkblue; color: white; border-radius: 5px; padding: 2px 5px;">blue</span> in the packet view on the left.
        ${
          this.guidanceNotification
            ? `${this.guidanceNotification}<br><br>`
            : ""
        }

Ask me anything about them using the text field below.
      `;
      }
      return `No packets selected. Please select packets from the left.`;
    },
    guidanceDetails() {
      if (this.packets.length) {
        const packetDetailsInfo =
          this.packetDataSize <= 80 * 1024
            ? "The selected data is within the 80KB limit. Detailed packet information is included."
            : "The selected data exceeds the 80KB limit after compression. Fallback logic has been applied: either payload details have been omitted or packet details have been disabled.";
        const settingsSummary = `
<strong>Current Advanced Settings:</strong><br>
- Use packet details: <strong>${
          this.useDecodedPackets ? "Enabled" : "Disabled"
        }</strong><br>
- Include Payload: <strong>${
          this.includeBytes ? "Enabled" : "Disabled"
        }</strong><br>
- Bytes as ASCII: <strong>${
          this.bytesAsAscii ? "Enabled" : "Disabled"
        }</strong><br>
- Compress Packets: <strong>${
          this.compressData ? "Enabled" : "Disabled"
        }</strong><br>
- Flatten Fields: <strong>${
          this.flattenDisplayFields ? "Enabled" : "Disabled"
        }</strong>
      `.trim();

        return `
<strong>Note:</strong> The final data provided to the AI is limited to 80KB (after compression, if enabled). If the current selection exceeds this limit, fallback logic is applied: either the payload is omitted or detailed packet analysis is disabled. Click the gear icon to tune settings.<br><br>
<strong>Packet Details:</strong> ${packetDetailsInfo}<br><br>
${settingsSummary}
      `;
      }
      return "";
    },

    showGuidanceBubble() {
      return this.conversation.length === 1;
    },
    userHasSubscription() {
      return this.hasSubscription(this.currentUser);
    },
    packetLimitExceeded() {
      return this.packetDataSize > 80 * 1024;
    },
    disableInteractions() {
      return (
        this.loading || !this.userHasSubscription || this.packetLimitExceeded
      );
    },
    packetDataString() {
      return this.packetContext;
    },

    // Calculate the size based on packetDataString.
    packetDataSize() {
      return new Blob([this.packetDataString]).size;
    },

    // Convert size to KB.
    packetDataSizeKB() {
      return (this.packetDataSize / 1024).toFixed(2);
    },
    animatedAssistantMessageIndex() {
      const filtered = this.conversation.filter((m) => m.role !== "system");
      let lastIndex = -1;
      filtered.forEach((msg, idx) => {
        if (msg.role === "assistant") {
          lastIndex = idx;
        }
      });
      return lastIndex;
    },
  },
  watch: {
    loading(newVal) {
      if (newVal) {
        this.progress = 0;
        this.startProgress();
      } else {
        this.progress = 0;
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      }
    },
    packets(newPackets, oldPackets) {
      // Optionally, use deep comparison if needed:
      if (JSON.stringify(newPackets) !== JSON.stringify(oldPackets)) {
        this.guidanceAnimated = false;
        // Reset the advanced options to true
        this.useDecodedPackets = true;
        this.includeBytes = true;
        this.bytesAsAscii = true;

        // You can also call any additional reset logic
        this.resetChat();
      }
    },
    conversation() {
      this.scrollToBottom();
    },
    useDecodedPackets() {
      this.initSystemPrompt();
    },
    includeBytes() {
      if (this.includeBytes && !this.useDecodedPackets) {
        this.useDecodedPackets = true;
      }
      this.initSystemPrompt();
    },
    compressData() {
      this.initSystemPrompt();
    },
    flattenDisplayFields() {
      this.initSystemPrompt();
    },
    bytesAsAscii() {
      if (this.bytesAsAscii && !this.includeBytes) {
        this.includeBytes = true;
      }
      this.initSystemPrompt();
    },
    "currentAnalysis.showAI"(newVal) {
      if (newVal) {
        // When the AI chat is (re)opened, enable animation for the guidance message.
        this.guidanceAnimated = true;
      }
    },
  },
  methods: {
    refreshUser() {
      this.$store.dispatch(CHECK_AUTH);
    },
    toggleAiChatFullscreen() {
      if (this.currentAnalysis.isAiChatFullScreen === undefined) {
        this.$set(this.currentAnalysis, "isAiChatFullScreen", false);
      }
      this.currentAnalysis.isAiChatFullScreen = !this.currentAnalysis
        .isAiChatFullScreen;
    },
    toggleGuidanceDetails() {
      this.showDetailedGuidance = !this.showDetailedGuidance;
    },
    copyAssistantMessage(content) {
      const textToCopy = content;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log("Assistant message copied to clipboard.");
        })
        .catch((err) => {
          console.error("Error copying text: ", err);
        });
    },
    startProgress() {
      const duration = 30000;
      const intervalTime = 50;
      const steps = duration / intervalTime;
      const increment = 100 / steps;
      this.progress = 0;
      this.progressInterval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += increment;
        } else {
          clearInterval(this.progressInterval);
          this.progressInterval = null;
        }
      }, intervalTime);
    },
    stopProgress() {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
      this.progress = 0;
    },
    clearChatArchives() {
      this.chatArchives = [];
      localStorage.removeItem("chatArchives");
    },
    async decodePackets() {
      if (!this.packets.length) return;
      if (this.packets.length > 100) {
        this.loadingPackets = true; // start showing loading indicator
      }

      let frameNumbers = this.packets
        .map((packet) => packet["frame#number"])
        .sort((a, b) => a - b);
      let key = this.currentAnalysis.pcap.id;
      let profileId = this.currentAnalysis.profiles.selected.id;
      let ranges = [];
      let start = frameNumbers[0],
        end = start;
      for (let i = 1; i < frameNumbers.length; i++) {
        if (frameNumbers[i] === end + 1) {
          end = frameNumbers[i];
        } else {
          ranges.push({ start, end });
          start = frameNumbers[i];
          end = start;
        }
      }
      ranges.push({ start, end });
      let allDecodedPackets = [];
      try {
        for (let range of ranges) {
          let params = {
            range:
              range.start === range.end
                ? `${range.start}`
                : `${range.start}-${range.end}`,
            pcapid: key,
            profileid: profileId,
          };
          try {
            let output = await ApiDecode.decoderange(params);
            let decoded = output.data;
            if (!decoded.result || !Array.isArray(decoded.result)) {
              console.error("Unexpected API response format:", decoded);
              continue;
            }
            let cleanedDecoded = decoded.result.map((packet) =>
              this.cleanDecodedPacket(packet)
            );
            cleanedDecoded.forEach((decodedPacket) => {
              let frameNumberField = this.flattenDisplayFields
                ? "number"
                : "frame.number";
              let regex = new RegExp(`${frameNumberField} == (\\d+)`);
              let frameNumber = decodedPacket.tree[0].n
                ?.find((item) => item.f?.startsWith(frameNumberField + " == "))
                ?.f.match(regex)?.[1];
              let originalPacket = this.packets.find(
                (pkt) => pkt["frame#number"] === parseInt(frameNumber)
              );
              if (originalPacket) {
                decodedPacket["frame#time_relative"] =
                  originalPacket["frame#time_relative"];
                decodedPacket["frame#time_delta_displayed"] =
                  originalPacket["frame#time_delta_displayed"];
                decodedPacket["_ws#col#Info"] = originalPacket["_ws#col#Info"];
              }
              allDecodedPackets.push(decodedPacket);
            });
          } catch (error) {
            console.error(`Error decoding range ${params.range}:`, error);
          }
        }
        this.decodedPackets = allDecodedPackets;
      } finally {
        this.loadingPackets = false; // hide loading indicator once done
      }
    },

    base64ToBinary(base64Str) {
      const raw = atob(base64Str);
      let binaryString = "";
      for (let i = 0; i < raw.length; i++) {
        binaryString += raw
          .charCodeAt(i)
          .toString(2)
          .padStart(8, "0");
      }
      return binaryString;
    },
    base64BinaryToAscii(base64Str) {
      const raw = atob(base64Str);
      let asciiString = "";
      for (let i = 0; i < raw.length; i++) {
        const charCode = raw.charCodeAt(i);
        asciiString += charCode >= 32 && charCode <= 126 ? raw[i] : ".";
      }
      return asciiString;
    },
    base64ToHexDump(base64Str) {
      const raw = atob(base64Str);
      let hexString = "";
      for (let i = 0; i < raw.length; i++) {
        hexString += raw
          .charCodeAt(i)
          .toString(16)
          .padStart(2, "0");
      }
      return hexString;
    },
    cleanDecodedPacket(data, parentLayer = null) {
      if (Array.isArray(data)) {
        return data
          .map((item) => this.cleanDecodedPacket(item, parentLayer))
          .filter(Boolean);
      } else if (typeof data === "object" && data !== null) {
        let cleaned = {};
        let hasF = Object.prototype.hasOwnProperty.call(data, "f");
        let currentLayer =
          hasF && typeof data.f === "string" && !data.f.includes("==")
            ? data.f
            : parentLayer;
        for (let key in data) {
          if (["h", "t", "e", "bg", "fg", "bitmask", "g", "s"].includes(key))
            continue;
          if (hasF && key === "l") continue;
          if (key === "bytes") {
            if (!this.includeBytes) continue;
            else {
              cleaned[key] = this.bytesAsAscii
                ? this.base64BinaryToAscii(data[key])
                : this.base64ToHexDump(data[key]);
              continue;
            }
          }
          let value = this.cleanDecodedPacket(data[key], currentLayer);
          if (key === "f" && typeof value === "string" && value.length > 40)
            continue;
          if (typeof value === "string")
            value = this.removeTrailingZeros(value);
          if (Array.isArray(value) && value.length === 1) value = value[0];
          if (
            key === "f" &&
            typeof value === "string" &&
            currentLayer &&
            value.startsWith(currentLayer + ".")
          ) {
            if (this.flattenDisplayFields)
              value = value.replace(currentLayer + ".", "");
          }
          if (
            value !== null &&
            value !== undefined &&
            !(Array.isArray(value) && value.length === 0)
          ) {
            cleaned[key] = value;
          }
        }
        return Object.keys(cleaned).length > 0 ? cleaned : null;
      }
      return data;
    },
    renderMarkdown(content) {
      content = content.replace(/```[\w]*\n/g, "").replace(/```/g, "");
      return marked(content);
    },
    closeDialog() {
      this.currentAnalysis.showAI = false;
    },
    notifyLambda() {
      const userMessages = this.conversation.filter(
        (msg) => msg.role === "user"
      );
      const pcapid = this.currentAnalysis?.pcap?.id || "Unknown PCAP ID";
      const selectedPackets = this.packets;
      let emailBody = `New AI chat interaction from ${this.currentUser.email}\n\n`;
      emailBody += `PCAP ID: ${pcapid}\n\n`;
      emailBody += "User Messages:\n";
      userMessages.forEach((msg, index) => {
        emailBody += `  ${index + 1}. ${msg.content}\n`;
      });
      emailBody += "\nSelected Packets:\n";
      selectedPackets.forEach((packet, index) => {
        emailBody += `\nPacket ${index + 1}:\n`;
        Object.entries(packet).forEach(([key, value]) => {
          emailBody += `  ${key}: ${value}\n`;
        });
      });
      const emailBodyHtml = emailBody.replace(/\n/g, " | ");
      const payload = {
        description: `New AI chat interaction from ${this.currentUser.email}`,
        data: emailBodyHtml,
      };
      axios.post(
        "https://95f7qv4x3j.execute-api.eu-central-1.amazonaws.com/v1",
        payload
      );
    },
    removeTrailingZeros(str) {
      return str.replace(/\d+\.\d+/g, (match) => parseFloat(match).toString());
    },
    async initSystemPrompt() {
      const HARD_LIMIT = 80 * 1024; // 80 KB limit for final data
      let finalData = null;
      let dataOptionUsed = "";
      let notificationMessage = "";

      // If detailed packets are enabled, ensure decodedPackets are current.
      // Re-fetch if the selection or decoding options (includeBytes, bytesAsAscii) have changed.
      if (this.useDecodedPackets) {
        const currentSelection = JSON.stringify(this.packets);
        const currentDecodedOptions = {
          includeBytes: this.includeBytes,
          bytesAsAscii: this.bytesAsAscii,
        };

        if (
          !this.decodedPackets ||
          this.decodedPackets.length === 0 ||
          this.lastPacketSelection !== currentSelection ||
          !this.lastDecodedOptions ||
          this.lastDecodedOptions.includeBytes !==
            currentDecodedOptions.includeBytes ||
          this.lastDecodedOptions.bytesAsAscii !==
            currentDecodedOptions.bytesAsAscii
        ) {
          await this.decodePackets();
          this.lastPacketSelection = currentSelection;
          this.lastDecodedOptions = currentDecodedOptions;
        }
      }

      // A simple post‑processing helper to clean the compressed JSON string.
      const postProcess = (str) =>
        str
          .replace(/\\"/g, '"')
          .replace(/\"/g, "")
          .replace(/\s+/g, " ")
          .replace(/\.{2,}/g, ".");

      // Helper: check if a string’s size is within the 80KB limit.
      const isWithinLimit = (str) => new Blob([str]).size <= HARD_LIMIT;

      // OPTION A:
      // Try using decoded packets with payload (if all advanced options are enabled)
      if (
        this.decodedPackets &&
        this.decodedPackets.length &&
        this.useDecodedPackets &&
        this.includeBytes &&
        this.bytesAsAscii
      ) {
        try {
          if (this.compressData) {
            const compResult = this.compressDataHigh(this.decodedPackets);
            let rawStr = JSON.stringify(compResult);
            rawStr = postProcess(rawStr);
            if (isWithinLimit(rawStr)) {
              finalData = rawStr;
              dataOptionUsed = "decoded packets with payload (compressed)";
              this.fellBackToNotUseIncludeBytes = false;
            } else {
              this.includeBytes = false;
              this.bytesAsAscii = false;
              this.fellBackToNotUseIncludeBytes = true;
            }
          } else {
            let rawStr = JSON.stringify(this.decodedPackets);
            if (isWithinLimit(rawStr)) {
              finalData = rawStr;
              dataOptionUsed = "decoded packets with payload (uncompressed)";
              this.includeBytes = false;
              this.bytesAsAscii = false;
              this.fellBackToNotUseIncludeBytes = false;
            } else {
              this.fellBackToNotUseIncludeBytes = true;
            }
          }
        } catch (error) {
          console.error(
            "Error processing decoded packets with payload:",
            error
          );
        }
      }

      // OPTION B:
      // If Option A did not yield data within 80KB, try using decoded packets without the payload.
      if (
        !finalData &&
        this.decodedPackets &&
        this.decodedPackets.length &&
        this.useDecodedPackets
      ) {
        try {
          // Remove the payload (bytes) from each packet.
          const decodedNoBytes = this.decodedPackets.map((packet) => {
            const copy = { ...packet };
            delete copy.bytes;
            return copy;
          });
          if (this.compressData) {
            const compResult = this.compressDataHigh(decodedNoBytes);
            let rawStr = JSON.stringify(compResult);
            rawStr = postProcess(rawStr);
            if (isWithinLimit(rawStr)) {
              finalData = rawStr;
              dataOptionUsed = "decoded packets without payload (compressed)";
              this.fellBackToNotUseDecodedPackets = false;
            } else {
              this.useDecodedPackets = false;
              this.fellBackToNotUseDecodedPackets = true;
            }
          } else {
            let rawStr = JSON.stringify(decodedNoBytes);
            if (isWithinLimit(rawStr)) {
              finalData = rawStr;
              dataOptionUsed = "decoded packets without payload (uncompressed)";
              this.fellBackToNotUseDecodedPackets = false;
            } else {
              this.useDecodedPackets = false;
              this.fellBackToNotUseDecodedPackets = true;
            }
          }
        } catch (error) {
          console.error(
            "Error processing decoded packets without payload:",
            error
          );
        }
      }

      // OPTION C:
      // If neither Option A nor B resulted in data within the 80KB limit, fall back to raw packet summary data.
      if (!finalData) {
        // Disable detailed packets.
        this.useDecodedPackets = false;
        try {
          if (this.compressData) {
            const compResult = this.compressDataHigh(this.packets);
            let rawStr = JSON.stringify(compResult);
            rawStr = postProcess(rawStr);
            finalData = rawStr;
            dataOptionUsed = "raw packet data (compressed)";
          } else {
            finalData = JSON.stringify(this.packets);
            dataOptionUsed = "raw packet data (uncompressed)";
          }
        } catch (error) {
          console.error("Error processing raw packet data:", error);
          finalData = JSON.stringify(this.packets);
          dataOptionUsed = "raw packet data (uncompressed)";
        }
      }

      // Compute final data size (for display purposes).
      const finalDataSize = new Blob([finalData]).size;
      const finalDataSizeKB = (finalDataSize / 1024).toFixed(2);

      this.packetContext = finalData;

      // Build a notification message based on the data option used.
      if (dataOptionUsed.includes("decoded packets with payload")) {
        notificationMessage =
          "Full packet details with payload are being used.";
      } else if (dataOptionUsed.includes("decoded packets without payload")) {
        notificationMessage =
          "Due to data size constraints, the packet payload has been excluded. To include payload for a more detailed analysis, please select fewer packets.";
      } else if (dataOptionUsed.includes("raw packet data")) {
        notificationMessage =
          "Due to data size constraints, detailed packet information has been disabled. To analyze more packets, consider disabling 'Use packet details' in Advanced Settings.";
      } else {
      }

      // Update the guidance notification property so that it appears in the guidance message.
      this.guidanceNotification = notificationMessage;

      // Build the system message that will be sent to the AI (notification not included here).
      const pcapName = this.currentAnalysis?.pcap?.origname || "Unknown_PCAP";
      const numPackets = this.packets.length;

      const dnsInfos = JSON.stringify(
        this.currentAnalysis?.pcap?.dnsinfos,
        null,
        2
      );
      const systemMessage = {
        role: "system",
        content: `
You are a highly experienced network engineer and packet analyst AI. Your task is to analyze network traffic from a PCAP file named "${pcapName}", which contains ${numPackets} selected packets. Use the provided packet data and metadata to offer clear, concise, and actionable insights.

The packet data has ${
          this.compressData
            ? "been compressed using an advanced scheme"
            : "not been compressed"
        }.
Data option used: **${dataOptionUsed}**.
Final data size: **${finalDataSizeKB} KB**.

### Packet Data:
${finalData}

### Additional Metadata:
- **Filename:** ${pcapName}
- **DNS Information:** ${dnsInfos}

## Analysis Instructions

- **Response Format:**
  Provide all responses strictly in **markdown format**.

- **Contextual Packet Analysis:**
  Consider the full context of the packets during your analysis:
  - **MAC Addresses:**
    Identify communicating devices based on their MAC addresses. For example, Palo Alto or Checkpoint addresses may indicate firewalls, VMware addresses suggest virtual machines, and Cisco addresses indicate routers.
  - **IP Analysis:**
    - For IPv4 packets, examine the **TTL (Time-To-Live)** values.
    - For IPv6 packets, review the **Hop Limit**.
    Inconsistent or unexpected values may indicate the presence of middleboxes or network misconfigurations.
  - **Packet Lengths:**
    Determine whether packets are data packets or control packets by examining their lengths. This is key to distinguishing normal communication from potential anomalies.
  - **Timing Analysis:**
    Analyze packet timings and delta times to identify issues such as latency, jitter, or retransmission events.

- **Usage Guidance:**
  **Important:** Only provide usage instructions if the user explicitly asks about how to use the tool. Otherwise, focus solely on packet analysis.

  *Usage Guidance (Do not display to the user unless requested):*
  - To select multiple packets in sequence, click the first packet, hold the <kbd>Shift</kbd> key, and click the last packet.
  - For non-contiguous selections, hold <kbd>Ctrl</kbd> (or <kbd>Command</kbd> on a Mac) while clicking each desired packet.
  - For small traces, select all packets by clicking the first packet, holding <kbd>Shift</kbd>, scrolling to the last packet, and clicking it to highlight all packets in blue.
  - Click the gear icon for advanced settings. By default, only the visible packet list information is used for analysis (excluding detailed packet content and payload). To include these details for a more comprehensive analysis, enable the **"Use packet details"** option.
  - The tool uses static coloring rules based on Wireshark display filters and does not highlight problematic packets—it simply provides an analysis of the selected packets.
  - The user can select packets from the packet list which just contains the relative time, packet number, information column, packet length, protocol and source and destination. Optionally the user can enable the "Use packet details" option to send the full packet details rather than just the summary information.

- **Troubleshooting Guidance:**
  - **Insufficient Data Check:**
    If the provided packet data or details are insufficient or could benefit from the whole packet details for a complete analysis based on the user question, inform the user that additional information may be required. Advise the user to either:
      - Select more packets from the packet list.
      - Enable the **"Use packet details"** option if it is turned off to send the full packet details rather than just the summary information. This is especially useful for payload, security and layer 7 protocol analysis.
  - Be specific by naming IP addresses, MAC addresses, and other relevant details for the devices involved.
  - If the selected packets seem unrelated to the issue, suggest selecting different packets or reviewing additional packets for further context.

- **Expert Insights:**
  Based on the analysis, suggest potential misconfigurations or anomalies (e.g., abnormal packet sizes, inconsistent TTL/Hop Limit values). Recommend further packet captures or complementary analysis tools if more detailed information is required.
    `.trim(),
      };

      this.conversation = [systemMessage];
    },
    triggerTour() {
      this.currentAnalysis.showaiselecttour = false;
      setTimeout(() => {
        this.currentAnalysis.showaiselecttour = true;
      }, 10); // delay in milliseconds
    },
    async sendPrompt() {
      if (!this.userPrompt.trim()) return;
      if (this.packetLimitExceeded) {
        this.errorMessage =
          "Cannot send prompt: too much packet data selected. Please reduce your selection or disable 'Use packet details' (if enabled).";
        return;
      }
      this.loading = true;
      this.errorMessage = "";
      this.lastUserPrompt = this.userPrompt.trim();
      this.conversation.push({
        role: "user",
        content: this.lastUserPrompt,
      });
      this.userPrompt = "";
      try {
        this.notifyLambda(this.conversation);
        const response = await ApiAiChat.chat(
          this.conversation,
          this.selectedModel
        );
        const assistantMsg = {
          role: "assistant",
          content: response.data.response,
        };
        this.conversation.push(assistantMsg);
        this.saveChatAutomatically();
      } catch (err) {
        console.error("Error sending prompt:", err);
        this.errorMessage =
          "Sorry, something went wrong while processing your request.";
      } finally {
        this.loading = false;
      }
    },
    sendPredefined(starter) {
      this.userPrompt = starter;
      this.sendPrompt();
    },
    saveChatAutomatically() {
      if (this.lastPacketSelection !== JSON.stringify(this.packets)) {
        this.lastPacketSelection = JSON.stringify(this.packets);
        this.chatArchives.push({
          timestamp: Date.now(),
          messages: [...this.conversation],
        });
        localStorage.setItem("chatArchives", JSON.stringify(this.chatArchives));
      }
    },
    toStr(val) {
      if (typeof val === "object" && val !== null) {
        return JSON.stringify(val);
      }
      return String(val);
    },
    // Enhanced compressDataHigh: now builds an extra pairMapping.
    compressDataHigh(data) {
      // 1. Build pair frequency for each key-value pair.
      const pairFreq = {};
      data.forEach((record) => {
        Object.entries(record).forEach(([key, value]) => {
          // Convert value to a proper string representation.
          const strValue = this.toStr(value);
          const pairStr = `${key}:${strValue}`;
          pairFreq[pairStr] = (pairFreq[pairStr] || 0) + 1;
        });
      });

      // 2. Create mapping for pairs that occur more than once.
      const pairMapping = {};
      let pairCount = 0;
      for (const [pairStr, count] of Object.entries(pairFreq)) {
        if (count > 1) {
          // You may adjust the threshold as needed.
          pairMapping[pairStr] = `p${pairCount}`;
          pairCount++;
        }
      }

      // 3. Create a unique set of keys for individual key compression.
      const keySet = new Set();
      data.forEach((record) => {
        Object.keys(record).forEach((key) => keySet.add(key));
      });
      const keyMapping = {};
      Array.from(keySet).forEach((key, index) => {
        keyMapping[key] = "k" + index;
      });

      // 4. Count frequency of all string values for individual value compression.
      const valueFreq = {};
      data.forEach((record) => {
        Object.values(record).forEach((val) => {
          const strVal = this.toStr(val);
          valueFreq[strVal] = (valueFreq[strVal] || 0) + 1;
        });
      });
      const valueMapping = {};
      let vCount = 0;
      for (const [val, count] of Object.entries(valueFreq)) {
        // Only map values that appear more than once and are longer than two characters.
        if (count > 1 && val.length > 2) {
          valueMapping[val] = "v" + vCount;
          vCount++;
        }
      }

      // 5. Produce the compressed data.
      const compressedData = data.map((record) => {
        // We'll accumulate tokens for pairs that have a mapping.
        const pairTokens = [];
        // And for the remaining fields, we use individual key/value mappings.
        const newRecord = {};

        Object.entries(record).forEach(([key, value]) => {
          const strValue = this.toStr(value);
          const pairStr = `${key}:${strValue}`;
          if (pairMapping[pairStr]) {
            // If this exact pair is common, we use its token.
            pairTokens.push(pairMapping[pairStr]);
          } else {
            // Otherwise, compress the key and value separately.
            const compKey = keyMapping[key];
            const compValue = valueMapping[strValue] || strValue;
            newRecord[compKey] = compValue;
          }
        });

        // If any pairs were replaced, store them in a dedicated field.
        if (pairTokens.length > 0) {
          newRecord["pairs"] = pairTokens;
        }
        return newRecord;
      });

      return {
        keyMapping, // e.g., { "someLongKey": "k0", "anotherKey": "k1", ... }
        valueMapping, // e.g., { "HTTP/1.1 200 OK": "v0", ... }
        pairMapping, // e.g., { "protocol:HTTP": "p0", ... }
        data: compressedData,
      };
    },
    generateCompressionPrompt(compressedResult) {
      const { keyMapping, valueMapping, pairMapping } = compressedResult;

      // Convert the mappings to pretty JSON strings.
      const keyMappingStr = JSON.stringify(keyMapping, null, 2);
      const valueMappingStr = JSON.stringify(valueMapping, null, 2);
      const pairMappingStr = JSON.stringify(pairMapping, null, 2);

      // Build the prompt explaining the enhanced compression scheme.
      const prompt = `
I am providing you with a compressed dataset that uses a multi-level mapping to reduce redundancy.

1. **Key Mapping:**
A JSON object named "keyMapping" maps each original key (from the input data) to a short key token.
For example, if an original key is "originalKeyName", it might be mapped to "k0".
Here is the complete key mapping:
${keyMappingStr}

2. **Value Mapping:**
A JSON object named "valueMapping" maps frequently occurring string values to short tokens.
For example, a frequently occurring value might be mapped to "v0".
Here is the complete value mapping:
${valueMappingStr}

3. **Pair Mapping:**
A JSON object named "pairMapping" maps frequently occurring key–value pairs to short tokens.
For example, if the key–value pair "protocol:HTTP" appears frequently, it might be mapped to "p0".
Here is the complete pair mapping:
${pairMappingStr}

4. **Data Array:**
The "data" array contains records where:
- If a key–value pair appears in the pair mapping, its corresponding token is stored in a dedicated "pairs" field.
- Other keys are replaced by their short tokens from "keyMapping", and their values are replaced by tokens from "valueMapping" if applicable.

Please use these mappings to decode the compressed data back to its original form when analyzing or summarizing the information.
  `.trim();

      return prompt;
    },
    async retrySend() {
      if (!this.lastUserPrompt) return;
      this.userPrompt = this.lastUserPrompt;
      this.sendPrompt();
    },
    archiveChat() {
      this.chatArchives.push({
        timestamp: Date.now(),
        messages: [...this.conversation],
      });
      localStorage.setItem("chatArchives", JSON.stringify(this.chatArchives));
      this.resetChat();
    },
    loadChat(index) {
      this.conversation = [...this.chatArchives[index].messages];
      this.showChatArchive = false;
    },
    resetChat() {
      this.notificationMessage = "";
      this.userPrompt = "";
      this.errorMessage = "";
      this.initSystemPrompt();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
  },
  mounted() {
    axios
      .post("https://95f7qv4x3j.execute-api.eu-central-1.amazonaws.com/v1", {
        data: {user: this.currentUser?.email},
        description: "AI Chat opened",
      })
      .catch(() => {});

    const tourCompleted = localStorage.getItem("autoaitourCompleted");
    if (!tourCompleted) {
      this.currentAnalysis.showaiselecttour = true;
    }
    this.refreshUser();
    this.dialog = true;
    if (this.currentAnalysis?.pcap?.origname) {
      // When the chat dialog is (re)opened, ensure animation is enabled.
      this.guidanceAnimated = true;
      this.initSystemPrompt();
    }
  },
};
</script>

<style scoped>
.chat-container {
  background-color: #f9f9f9;
  padding: 16px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  overflow-y: auto;
}
.chat-bubble {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  padding: 12px 16px;
  border-radius: 10px;
  max-width: 90%;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.bubble-assistant {
  background-color: #daf5d7;
  color: #333;
  margin-left: auto;
  flex-direction: row;
}
.bubble-user {
  background-color: #eef2f6;
  color: #333;
  margin-right: auto;
  flex-direction: row;
}
.bubble-icon {
  margin-right: 8px;
  font-size: 24px;
  align-self: flex-start;
}
.chat-bubble h1,
.chat-bubble h2,
.chat-bubble h3 {
  margin: 8px 0;
  font-weight: bold;
  color: #444;
}
.chat-bubble h1 {
  font-size: 1.2rem;
}
.chat-bubble h2 {
  font-size: 1.1rem;
}
.chat-bubble h3 {
  font-size: 1rem;
}
.chat-bubble p {
  margin: 6px 0;
  line-height: 1.5;
  margin-bottom: 0px !important;
}
::v-deep .chat-bubble p {
  margin: 6px 0;
  line-height: 1.5;
  margin-bottom: 0 !important;
}
::v-deep .chat-bubble hr {
  margin-top: 16px;
  margin-bottom: 16px;
}
.chat-bubble.bubble-assistant span.bubble-text p {
  margin-bottom: 0 !important;
  margin-top: 6px;
  line-height: 1.5;
}
.chat-bubble ol,
.chat-bubble ul {
  margin: 8px 0;
  padding-left: 20px;
}
.chat-bubble li {
  margin-bottom: 12px;
  line-height: 1.4;
}
.chat-bubble code {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px 4px;
  font-family: monospace;
  font-size: 90%;
}
.chat-bubble blockquote {
  border-left: 4px solid #ddd;
  margin: 8px 0;
  padding-left: 12px;
  color: #555;
  font-style: italic;
}
.guidance-bubble {
  animation: fadeIn 0.5s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.thinking-text {
  margin-top: 8px;
  font-size: 1rem;
  color: #666;
  text-align: center;
  font-style: italic;
}
.copy-button-container {
  text-align: right;
  margin-top: 4px;
}
</style>
