<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <!-- Header -->
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-24 w-auto"
        src="/images/packetsafari-logo.png"
        alt="PacketSafari"
      />
      <h2 class="mt-10 text-center text-2xl font-bold tracking-tight text-gray-900">
        Registration
      </h2>
    </div>

    <!-- Form Container -->
    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <!-- Info Alert -->
        <div class="mb-4 rounded-md bg-blue-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-blue-400" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fill-rule="evenodd"
                  d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-8 4a1 1 0 110-2 1 1 0 010 2zm.25-8a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0V6z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-blue-700">
                Registering gives you access to exclusive features.
                A registered account is required to use our paid services, ensuring you get the best experience.
              </p>
            </div>
          </div>
        </div>

        <!-- Registration Form -->
        <form class="space-y-6" @submit.prevent="submit">
          <!-- Username -->
          <div>
            <label for="username" class="block text-sm font-medium text-gray-700">
              Username
            </label>
            <div class="mt-1">
              <input
                id="username"
                name="username"
                type="text"
                v-model="user.username"
                maxlength="25"
                class="!block !w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-indigo-500 !focus:ring-indigo-500 !sm:text-sm"
                required
              />
              <p class="mt-1 text-xs text-gray-500">
                This is the username you will use to log in.
              </p>
              <p v-if="errors.username" class="mt-1 text-xs text-red-600">{{ errors.username }}</p>
            </div>
          </div>

          <!-- Firstname & Lastname -->
          <div class="grid grid-cols-2 gap-4">
            <div>
              <label for="firstname" class="block text-sm font-medium text-gray-700">
                Firstname
              </label>
              <div class="mt-1">
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  v-model="user.firstname"
                  maxlength="25"
                  class="!block !w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-indigo-500 !focus:ring-indigo-500 !sm:text-sm"
                  required
                />
                <p v-if="errors.firstname" class="mt-1 text-xs text-red-600">{{ errors.firstname }}</p>
              </div>
            </div>
            <div>
              <label for="lastname" class="block text-sm font-medium text-gray-700">
                Lastname
              </label>
              <div class="mt-1">
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  v-model="user.lastname"
                  maxlength="25"
                  class="!block !w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-indigo-500 !focus:ring-indigo-500 !sm:text-sm"
                  required
                />
                <p v-if="errors.lastname" class="mt-1 text-xs text-red-600">{{ errors.lastname }}</p>
              </div>
            </div>
          </div>

          <!-- E-mail -->
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              E-mail
            </label>
            <div class="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                v-model="user.email"
                autocomplete="email"
                class="!block !w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-indigo-500 !focus:ring-indigo-500 !sm:text-sm"
                required
              />
              <p v-if="errors.email" class="mt-1 text-xs text-red-600">{{ errors.email }}</p>
            </div>
          </div>

          <!-- Password with Toggle -->
          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="relative mt-1">
              <input
                :type="showpassword ? 'text' : 'password'"
                id="password"
                name="password"
                v-model="user.password"
                autocomplete="new-password"
                placeholder="At least 8 characters"
                class="!block !w-full !rounded-md !border-gray-300 !shadow-sm !focus:border-indigo-500 !focus:ring-indigo-500 !sm:text-sm !pr-10"
                required
              />
              <button
                type="button"
                class="absolute inset-y-0 right-0 flex items-center pr-3"
                @click="togglePassword"
              >
                <svg
                  v-if="showpassword"
                  class="h-5 w-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-5.523 0-10-4.477-10-10 0-1.114.184-2.18.525-3.175M9.88 9.88a3 3 0 104.242 4.242M15 15l3 3m6-3a9.966 9.966 0 01-2.637 4.086M3.363 6.637A9.966 9.966 0 011 10c0 5.523 4.477 10 10 10 1.886 0 3.68-.526 5.215-1.44"
                  />
                </svg>
                <svg
                  v-else
                  class="h-5 w-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-1.274 4.057-5.065 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </button>
              <p v-if="errors.password" class="mt-1 text-xs text-red-600">{{ errors.password }}</p>
            </div>
          </div>

          <!-- Administrator Checkbox -->
          <div v-if="offeradmincreation" class="flex items-center">
            <input
              id="makeadmin"
              name="makeadmin"
              type="checkbox"
              v-model="user.makeadmin"
              class="!h-4 !w-4 !rounded !border-gray-300 !text-indigo-600 !focus:ring-indigo-500"
            />
            <label for="makeadmin" class="ml-2 block text-sm text-gray-900">
              No administrator has been set yet. Add this user as an administrator?
            </label>
          </div>

          <!-- Terms of Service -->
          <div class="space-y-2">
            <p class="text-xs text-gray-600">
              By clicking submit you agree to the
              <a
                href="https://www.packetsafari.com/page/tospacketsafari/"
                target="_blank"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Terms of Service
              </a>
              and
              <a
                href="https://www.packetsafari.com/page/privacy/"
                target="_blank"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Privacy Policy
              </a>.
            </p>
            <div class="flex items-center">
              <input
                id="tos"
                name="tos"
                type="checkbox"
                v-model="checkbox"
                class="!h-4 !w-4 !rounded !border-gray-300 !text-indigo-600 !focus:ring-indigo-500"
                required
              />
              <label for="tos" class="ml-2 block text-sm text-gray-900">
                I agree to both
              </label>
            </div>
            <p v-if="errors.checkbox" class="mt-1 text-xs text-red-600">{{ errors.checkbox }}</p>
          </div>

          <!-- Submission Error Alert -->
          <div v-if="submitresponse" class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-11.707a1 1 0 00-1.414 0L7 9.586 5.707 8.293a1 1 0 00-1.414 1.414L5.586 11l-1.293 1.293a1 1 0 001.414 1.414L7 12.414l1.293 1.293a1 1 0 001.414-1.414L8.414 11l1.293-1.293a1 1 0 000-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm text-red-700">{{ submitresponse }}</p>
              </div>
            </div>
          </div>

          <!-- Submit Button -->
          <div>
            <button
              type="submit"
              :disabled="submitting"
              class="flex w-full justify-center rounded-md !bg-indigo-600 px-4 py-2 text-sm font-semibold !text-white shadow-sm hover:!bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600"
            >
              Submit
            </button>
          </div>
        </form>

        <!-- Link to Login -->
        <p class="mt-10 text-center text-sm text-gray-500">
          Already have an account?
          <a href="#/login" class="font-semibold text-indigo-600 hover:text-indigo-500">Login</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import ApiUser from "@/common/api/user";
import { CURRENT_TOS_VERSION } from "@/common/config";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Register",
  computed: {
    ...mapGetters(["currentUser", "currentAnalysis", "error", "avatarPic"]),
  },
  data() {
    return {
      submitresponse: "",
      offeradmincreation: false,
      submitting: false,
      user: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        username: "",
        acceptedtosversion: CURRENT_TOS_VERSION,
        makeadmin: false,
      },
      showpassword: false,
      checkbox: false,
      errors: {
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        checkbox: "",
      },
    };
  },
  mounted() {
    this.checkadmincreated();
  },
  methods: {
    checkadmincreated() {
      ApiUser.checkadmincreated()
        .then(({ data }) => {
          if (data["admincreated"] === false) {
            this.offeradmincreation = true;
          }
        })
        .catch(() => {});
    },
    validateForm() {
      let valid = true;
      // Reset errors
      this.errors = {
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        checkbox: "",
      };

      if (!this.user.username) {
        this.errors.username = "Username is required";
        valid = false;
      }
      if (!this.user.firstname) {
        this.errors.firstname = "Firstname is required";
        valid = false;
      } else if (this.user.firstname.length > 25) {
        this.errors.firstname = "Firstname must be less than 25 characters";
        valid = false;
      }
      if (!this.user.lastname) {
        this.errors.lastname = "Lastname is required";
        valid = false;
      } else if (this.user.lastname.length > 25) {
        this.errors.lastname = "Lastname must be less than 25 characters";
        valid = false;
      }
      if (!this.user.email) {
        this.errors.email = "E-mail is required";
        valid = false;
      } else if (!/.+@.+/.test(this.user.email)) {
        this.errors.email = "E-mail must be valid";
        valid = false;
      }
      if (!this.user.password) {
        this.errors.password = "Password is required";
        valid = false;
      } else if (this.user.password.length < 8) {
        this.errors.password = "Password must be at least 8 characters";
        valid = false;
      }
      if (!this.checkbox) {
        this.errors.checkbox = "You must agree to continue!";
        valid = false;
      }
      return valid;
    },
    submit() {
      this.submitresponse = "";
      if (!this.validateForm()) {
        return;
      }
      this.submitting = true;
      ApiService.post("user", this.user)
        .then(() => {
          this.error.type = "info";
          this.error.msg = "Registered!";
          if (this.currentAnalysis.features.ENABLE_COGNITO) {
            this.$router
              .push({
                name: "Signup code",
                params: { userin: this.user.username },
              })
              .catch(() => {});
          } else {
            this.$router
              .push({
                name: "Login",
                params: { userin: this.user.username },
              })
              .catch(() => {});
          }
          this.notifyLambda();
        })
        .catch(({ response }) => {
          this.submitresponse = response.data.error;
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    notifyLambda() {
      const description = "New user registration " + this.user.username;
      const payload = { data: {}, description: description };
      axios
        .post("https://95f7qv4x3j.execute-api.eu-central-1.amazonaws.com/v1", payload)
        .catch(() => {});
    },
    togglePassword() {
      this.showpassword = !this.showpassword;
    },
    clear() {
      this.user = {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        username: "",
        acceptedtosversion: CURRENT_TOS_VERSION,
        makeadmin: false,
      };
      this.checkbox = false;
      this.errors = {
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        checkbox: "",
      };
      this.submitresponse = "";
    },
  },
};
</script>

<style scoped>
/* Add any additional styles if necessary */
</style>
