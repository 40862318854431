var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentAnalysis.profiles.available.length > 0)?_c('v-row',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"packetlist","no-gutters":""}},[_c('v-dialog',{attrs:{"width":"950px"},model:{value:(_vm.dialogIllustration),callback:function ($$v) {_vm.dialogIllustration=$$v},expression:"dialogIllustration"}},[(_vm.dialogIllustration)?_c('packetillustration',{key:_vm.currentAnalysis.illustratepaket.packet.framenumber,model:{value:(_vm.currentAnalysis.illustratepaket),callback:function ($$v) {_vm.$set(_vm.currentAnalysis, "illustratepaket", $$v)},expression:"currentAnalysis.illustratepaket"}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"350px"},model:{value:(_vm.dialogGotoFrame),callback:function ($$v) {_vm.dialogGotoFrame=$$v},expression:"dialogGotoFrame"}},[_c('v-card',[_c('v-card-title',[_c('v-icon'),_c('div',{staticClass:"display-1"},[_vm._v("Goto Frame")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.gotoFrame(_vm.gotoFrameNumber)}}},[_c('v-text-field',{ref:"gotoframeelement",attrs:{"label":"Frame Number","solo":""},model:{value:(_vm.gotoFrameNumber),callback:function ($$v) {_vm.gotoFrameNumber=$$v},expression:"gotoFrameNumber"}})],1)],1)],1)],1),(_vm.currentAnalysis.profiles.selected.currentPacketInfo)?_c('v-col',{attrs:{"md":"auto"}},[_c('v-sheet',{staticClass:"py-1 pr-1 ma-0 d-flex"},[(_vm.currentAnalysis.loadingpackets)?[(
            _vm.currentAnalysis.pcap &&
              _vm.currentAnalysis.loadstatus.progress !=
                _vm.currentAnalysis.pcap.numberofpackets &&
              _vm.currentAnalysis.loadstatus.progress
          )?[_c('v-progress-circular',{staticClass:"mx-1",attrs:{"small":"","color":_vm.loadingcolor,"value":_vm.loadingprogress}}),_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":"","color":"secondary"}},[_vm._v(" Loaded: "+_vm._s(_vm.currentAnalysis.loadstatus.progress)+"/"+_vm._s(_vm.currentAnalysis.pcap.numberofpackets)+" ")])]:(false)?_c('v-progress-circular',{staticClass:"mx-1",attrs:{"small":"","indeterminate":"","color":"primary"}}):_vm._e()]:_vm._e(),(true)?[(_vm.currentAnalysis.selectStartDate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"secondary","label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" Time Interval: "+_vm._s(_vm._f("formatSelectedDate")(_vm.currentAnalysis.selectStartDate))+" - "+_vm._s(_vm._f("formatSelectedDate")(_vm.currentAnalysis.selectEndDate))+" ")])]}}],null,false,195925948)},[_c('span',[_vm._v("Time Interval selected in Graph")])]):_vm._e(),(_vm.currentAnalysis.iostart != undefined)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"red","label":"","small":"","close":""},on:{"click:close":function($event){return _vm.resetIOGraphSelection()}}},'v-chip',attrs,false),on),[_vm._v(" IO Graph Interval: "+_vm._s(_vm.currentAnalysis.iostart)+" - "+_vm._s(_vm.currentAnalysis.ioend)+" ")])]}}],null,false,24471834)},[_c('span',[_vm._v("Packet interval selected in IOGraph")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":"","color":"secondary"}},'v-chip',attrs,false),on),[_vm._v(" Total: "+_vm._s(_vm.currentAnalysis.total)+" ")])]}}],null,false,3155434028)},[_c('span',[_vm._v("Total packets in trace")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","color":"secondary","small":""}},'v-chip',attrs,false),on),[(_vm.currentAnalysis.limit >= _vm.currentAnalysis.matching)?[_vm._v("Displayed: "+_vm._s(_vm.currentAnalysis.totalshown))]:(_vm.currentAnalysis.filter)?[_vm._v("Displayed: "+_vm._s(_vm.currentAnalysis.totalshown)),(!_vm.currentAnalysis.profiles.selected.skipmatchcount)?[_vm._v(" of "+_vm._s(_vm.currentAnalysis.matching)+" matching")]:_vm._e()]:[_vm._v(" Displayed: "+_vm._s(_vm.currentAnalysis.totalshown)+" of total "+_vm._s(_vm.currentAnalysis.total)+" ")]],2)]}}],null,false,1377553529)},[_c('span',[_vm._v("Number of shown packets")])]),(_vm.currentAnalysis.limit < _vm.currentAnalysis.matching)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","label":"","color":"secondary"},on:{"click":function($event){return _vm.loadallpackets(300000)}}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-progress-download")]),_vm._v(" Load all matching packets ")],1)]}}],null,false,3162745277)},[_c('span',[_vm._v("Only "+_vm._s(_vm.currentAnalysis.limit)+" of a total of "+_vm._s(_vm.currentAnalysis.matching)+" loaded")])])]:_vm._e()]:_vm._e()],2)],1):_vm._e(),(_vm.currentAnalysis.features.enableindexedpacketlist)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" Index: "+_vm._s(_vm.currentAnalysis.index)+" ")])]}}],null,false,2732628349)},[_c('span')]):_vm._e(),(_vm.currentAnalysis.features.timeline)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" Range: "+_vm._s(_vm.currentAnalysis.timelinestart)+"/"+_vm._s(_vm.currentAnalysis.timelineend)+" ")])]}}],null,false,3599361466)},[_c('span',[_vm._v("Current analysis range")])]):_vm._e(),_c('v-col',{attrs:{"md":"auto"}},[_c('v-sheet',{staticClass:"py-1 pr-1 my-0 d-flex"},[(_vm.currentAnalysis.profiles.selected.filterbuttons)?_c('filterbuttons'):_vm._e()],1)],1),(_vm.currentPacketColorrule && _vm.currentPacketColorrule.length > 0)?[_c('v-icon',[_vm._v("mdi-slash-forward")]),_c('v-col',{attrs:{"md":"auto"}},[_c('v-sheet',{staticClass:"py-1 ma-0 d-flex"},_vm._l((_vm.currentPacketColorrule),function(value,key){return _c('i',{key:key},[(value.background == 'white')?_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","outline":"","small":"","color":"grey","text-color":value.foreground}},[_vm._v(_vm._s(value.rulename))]):_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":"","color":value.background,"text-color":value.foreground},on:{"click":function($event){return _vm.setDisplayFilter(value.rule)}}},[_vm._v(_vm._s(value.rulename))])],1)}),0)],1)]:_vm._e(),(_vm.currentAnalysis.curfield.fieldname)?_c('v-col',{attrs:{"md":"auto"}},[_c('v-sheet',{staticClass:"py-1 pr-1 my-0 d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(_vm.currentAnalysis.curfield.fieldname)+" ")])]}}],null,false,2982379745)},[_c('span',[_vm._v("Field")])])],1)],1):_vm._e(),_c('v-spacer'),(_vm.currentAnalysis.cachedresult)?_c('v-col',{attrs:{"md":"auto"}},[_c('v-sheet',{staticClass:"py-1 pr-1 my-0 d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":"","color":"red","close":""},on:{"click:close":function($event){return _vm.removeCurrentFromCacheAndRefresh()}}},'v-chip',attrs,false),on),[_vm._v("cached ")])]}}],null,false,1200753411)},[_c('span',[_vm._v("Cached result")])])],1)],1):_vm._e(),(true)?[_c('v-col',{attrs:{"md":"auto"}},[_c('v-sheet',{staticClass:"py-1 ma-0 d-flex"},[(_vm.canEditCurrentProfile)?_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","color":"green lighten-3","small":"","draggable":""}},[_vm._v("Profile: "+_vm._s(_vm.currentAnalysis.profiles.selected.name))]):_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","color":"grey lighten-3","small":""}},[_vm._v("Profile: "+_vm._s(_vm.currentAnalysis.profiles.selected.name)+" (read-only)")])],1)],1)]:_vm._e(),(_vm.currentAnalysis.profiles.selected.currentPacketInfo)?_c('v-col',{staticStyle:{"height":"calc(100% - 32px)"},attrs:{"cols":"12"}},[(_vm.modeloaded)?_c('ag-grid-vue',{class:_vm.theme,staticStyle:{"width":"100%","height":"100%"},attrs:{"gridOptions":_vm.gridOptions,"frameworkComponents":_vm.frameworkComponents}}):_vm._e()],1):_c('v-col',{staticStyle:{"height":"100%"},attrs:{"cols":"12"}},[(_vm.modeloaded)?_c('ag-grid-vue',{class:_vm.theme,staticStyle:{"width":"100%","height":"100%"},attrs:{"gridOptions":_vm.gridOptions,"frameworkComponents":_vm.frameworkComponents}}):_vm._e()],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }