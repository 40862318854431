<template>
  <v-footer padless color="grey lighten-5">
    <v-container>
      <v-row class="py-6" align="center" justify="space-between">
        <!-- Logo -->
        <v-col cols="12" md="4" class="text-center text-md-left">
          <v-img
            src="/images/packetsafari-title-logo.png"
            alt="Packetsafari Logo"
            contain
            max-width="150"
            class="mx-auto mx-md-0"
          ></v-img>
        </v-col>

        <!-- Navigation Links -->
        <v-col cols="12" md="8">
          <v-row justify="center" class="footer-links">
            <!-- Company Section -->
            <v-col cols="12" md="4" class="text-center">
              <h5 class="footer-title">Company</h5>
              <v-list dense nav>
                <!-- Internal Link -->
                <v-list-item link :to="'/support'">
                  <v-list-item-title>Contact</v-list-item-title>
                </v-list-item>
                <!-- External Link -->
                <v-list-item
                  link
                  href="https://www.packetsafari.com/page/tospacketsafari/"
                  target="_blank"
                  rel="noopener"
                >
                  <v-list-item-title>
                    ToS
                    <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                  </v-list-item-title>
                </v-list-item>
                <!-- External Link -->
                <v-list-item
                  link
                  href="https://www.packetsafari.com/page/privacy/"
                  target="_blank"
                  rel="noopener"
                >
                  <v-list-item-title>
                    Privacy Policy
                    <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Products Section -->
            <v-col cols="12" md="4" class="text-center">
              <h5 class="footer-title">Products</h5>
              <v-list dense nav>
                <!-- External Link -->
                <v-list-item
                  link
                  href="https://www.packetsafari.com/aishark"
                  target="_blank"
                  rel="noopener"
                >
                  <v-list-item-title>
                    AI Shark
                    <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                  </v-list-item-title>
                </v-list-item>
                <!-- External Link -->
                <v-list-item
                  link
                  href="https://labs.oripka.de/course/wsadven"
                  target="_blank"
                  rel="noopener"
                >
                  <v-list-item-title>
                    Ranger Lab
                    <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                  </v-list-item-title>
                </v-list-item>
                <!-- Internal Link -->
                <v-list-item link :to="'/copilot'">
                  <v-list-item-title>PacketSafari Copilot</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>

            <!-- Resources Section -->
            <v-col cols="12" md="4" class="text-center">
              <h5 class="footer-title">Resources</h5>
              <v-list dense nav>
                <!-- External Link -->
                <v-list-item
                  link
                  href="https://oripka.de/course/list"
                  target="_blank"
                  rel="noopener"
                >
                  <v-list-item-title>
                    Courses
                    <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                  </v-list-item-title>
                </v-list-item>
                <!-- External Link -->
                <v-list-item
                  link
                  href="https://www.packetsafari.com/blog"
                  target="_blank"
                  rel="noopener"
                >
                  <v-list-item-title>
                    Blog
                    <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <!-- Copyright / Bottom Padding -->
    <v-container class="py-3">
      <v-row>
        <v-col class="text-center" cols="12">
          <span class="caption">&copy; {{ new Date().getFullYear() }} PacketSafari. All rights reserved.</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.footer-title {
  font-weight: bold;
  margin-bottom: 8px;
}

/* Remove background from the lists and list items */
.footer-links .v-list,
.footer-links .v-list-item {
  background-color: transparent !important;
}

.footer-links .v-list-item {
  padding: 2px 0;
}
</style>
