<template>
  <div class="mt-4 flex flex-col items-center mx-auto">
    <!-- Header Section -->
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:text-center">
        <h1 class="text-4xl font-bold mb-4">Cases</h1>
        <p class="text-lg mb-4">
          These are user help requests for PCAP analysis. Users have submitted
          their requests seeking assistance with their PCAP files. Only cases
          with detailed descriptions (at least 20 characters) are displayed
          below.
        </p>
        <p class="text-lg mb-4">
          The cases are sorted so that the newest uploads appear first. Please
          review the cases and, if necessary, contact the user directly using
          the provided email.
        </p>
      </div>
    </div>

    <!-- Table Section -->
    <v-card class="pb-2 mt-4" max-width="900px">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="cases"
          :items-per-page="50"
          class="responsive-table"
        >
          <!-- PCAP link using the provided format -->
          <template v-slot:item.pcapid="{ item }">
            <a :href="`/analyze/l/${item.pcapid}/none/none`" target="_blank">
              View PCAP
            </a>
          </template>

          <!-- Description with wrapped text -->
          <template v-slot:item.description="{ item }">
            <div class="description">
              {{ item.description }}
            </div>
          </template>

          <!-- Display the nicely formatted date from the created property -->
          <template v-slot:item.created="{ item }">
            {{ formatDate(item.created) }}
          </template>

          <!-- User column: link to profile if no email address and username is not 'anonymous' -->
          <template v-slot:item.author="{ item }">
            <v-btn
              v-if="item.email"
              color="primary"
              small
              @click="contactUser(item.email)"
            >
              {{ item.author ? item.author : item.email }}
            </v-btn>
            <template v-else>
              <template
                v-if="item.author && item.author.toLowerCase() !== 'anonymous'"
              >
                <router-link :to="`/profile/${item.author}`">
                  {{ item.author }}
                </router-link>
              </template>
              <template v-else>
                {{ item.author }}
              </template>
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import ApiCase from "@/common/api/case";
import { commonmethods } from "@/common/common";
import { mapGetters } from "vuex";
import { permissionchecks } from "@/common/permissions";

export default {
  name: "tasklist",
  data() {
    return {
      cases: [],
      headers: [
        {
          text: "PCAP",
          value: "pcapid",
          align: "left",
          sortable: false,
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
        {
          text: "Date",
          value: "created",
          sortable: false,
        },
        {
          text: "User",
          value: "author",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getCases();
  },
  mixins: [permissionchecks, commonmethods],
  computed: {
    ...mapGetters(["currentAnalysis", "currentUser", "error"]),
  },
  methods: {
    getCases() {
      ApiCase.list(this.type)
        .then(({ data }) => {
          // Filter cases with a description of at least 20 characters.
          // Then sort descending by the created date (newest first).
          this.cases = data
            .filter(
              (item) => item.description && item.description.trim().length >= 20
            )
            .sort((a, b) => new Date(b.created) - new Date(a.created));
        })
        .catch(({ response }) => {
          console.error("Error fetching cases:", response);
        });
    },
    contactUser(email) {
      window.location.href = `mailto:${email}`;
    },
    formatDate(dateStr) {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
  },
};
</script>

<style scoped>
.explanation {
  margin-bottom: 16px;
  font-style: italic;
  color: #555;
}

/* Ensure the table is responsive and the description text wraps */
.responsive-table {
  overflow-x: auto;
}
.description {
  white-space: normal;
  word-wrap: break-word;
  max-width: 400px; /* Adjust as needed */
}
</style>
