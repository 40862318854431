// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import vuetify from './plugins/vuetify';
import router from './router'
import Axios from 'axios'
import 'typeface-roboto/index.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
//import moment from 'moment'
import moment from 'moment-timezone'
import ApiService from '@/common/api.service'
//import JwtService from '@/common/jwt.service'
import store from '@/store'
import { CHECK_AUTH, CHECK_INFO } from '@/store/actions.type'

import VueMeta from 'vue-meta'

Vue.prototype.$http = Axios;

ApiService.init()

Vue.filter('formatDateOnly', function (value) {
  if (value) {
    // easy peasy
    
    return moment.utc(value).tz(moment.tz.guess()).format('DD.MM.YYYY')
  }
})

Vue.filter('formatDate', function (value) {
  if (value) {
    // easy peasy
    
    return moment.utc(value).tz(moment.tz.guess()).format('DD.MM.YYYY HH:mm')
  }
})

Vue.filter('formatSelectedDate', function (value) {
  if (value) {
    // easy peasy
    // the guess will 
    return moment.utc(value).tz(moment.tz.guess()).format('HH:mm:ss DD.MM.YYYY')
  }
})

Vue.filter('formatDateMonth', function (value) {
  if (value) {
    // easy peasy
    return moment.utc(value).tz(moment.tz.guess()).format('MMMM YYYY')
  }
})

Vue.filter('formatDelta', function (value) {
  return Number(value).toFixed(2);
})


Vue.filter('formatDuration', function (value) {
  if (value) {
    var m = moment.duration(value)
    //return moment.duration(value).humanize();
    return m.seconds();
  }
})

Vue.filter('formatDurationHuman', function (value) {
  if (value) {

    var m = moment.duration(value, 'seconds')
    return m.humanize();
  }
})

Vue.filter('cleanFilename', function (value) {
  if (value) {
    return value.replace(".pcapng", "").replace(".pcap", "")
  }
})

import auth from './store/auth.module'

router.beforeEach(
  
  (to, from, next) => {
    
    let s = auth
    let r = router
    let sto = store

    let user = s.getters.currentUser(s.state)
    let checkedauth = s.getters.currentAnalysis(s.state)
    // if the user object is not populated and we did not check auth before
    // this is to avoid requests before every route change
    if(!user){
      return Promise
      .all([store.dispatch(CHECK_AUTH), store.dispatch(CHECK_INFO)])
      .then(next)
    }
    next()
  }
)
import 'typeface-poppins';
Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue();

import VueFlags from "@growthbunker/vueflags";
import VueGtag from "vue-gtag";

Vue.use(VueFlags, {
  iconPath: '/images/flags/',
});

Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: { id: "G-5JT6T6MKK9" },
  bootstrap: false
}, router);

new Vue({
  vuetify,
  store,
  router,
  components: { App },
  render: h => h(App)
}).$mount('#app')

/*eslint-disable no-unused-vars*/
/* eslint-disable no-new */
/*
var vm = new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: `
	<App/>  
`
})
*/
