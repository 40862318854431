import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt.service'
import { API_URL } from '@/common/config'
import { SET_LOADING_STATUS, SET_ERROR, PURGE_AUTH } from '@/store/mutations.type'
import { cacheAdapterEnhancer } from 'axios-extensions';
import store from '@/store'
import { LOGOUT, PURGE_AUTH_ACTION } from "@/store/actions.type";
const ApiService = {

  getmessage(error) {

    var msg = "Server unavailable"
    if (error.response == undefined) {
      msg = "Network error"
    }
    if (error.response.data["error"] != null) {
      msg = error.response.data["error"]
      return msg;
    }
    if (error.response.data["message"] != null) {
      msg = error.response.data["message"]
    }
    if (error.response.data["msg"] != null) {
      msg = error.response.data["msg"]
      this.$store
        .dispatch(PURGE_AUTH)
        .then(() => this.$router.push({ name: 'Login' }).catch(err => { }))
    }

    if (error.response.status == 429) {
      msg = "Too many requests only " + msg + " allowed"
    }
    if (error.response.status == 403) {
      msg = "Operation not permitted"
    }
    if (error.response.status == 401) {
      msg = "Operation not permitted"
    }

    if (error.response.status == 404) {
      msg = "Does not exist"
    }
    return msg
  },

  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
    Vue.axios.defaults.adapter = cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false })
  },

  setHeader() {
    var token = JwtService.getToken()

    if (token !== undefined && token != null) {

      Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
      delete Vue.axios.defaults.headers.common['Authorization'];
    }
  },
  setRefreshHeader() {
    var token = JwtService.getRefreshToken()

    if (token !== undefined && token != null) {

      Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
      delete Vue.axios.defaults.headers.common['Authorization'];
    }
  },

  query(resource, params) {
    this.setHeader()

    return Vue.axios
      .get(resource, params)
      .catch((error) => {
        var err = this.getmessage(error)
        store.commit(SET_ERROR, err)
        throw error
      })
  },
  async streamPost(resource, params, onChunkReceived) {
    this.setHeader();

    try {
      const token = JwtService.getToken();
      const response = await fetch(API_URL + "/" + resource, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        // Process each new line
        let lines = buffer.split("\n");
        buffer = lines.pop(); // Keep last incomplete line in buffer

        for (const line of lines) {
          if (line.trim()) {
            onChunkReceived(line + "\n"); // Append newline to preserve formatting
          }
        }
      }
    } catch (error) {
      console.error("Streaming request error:", error);
      store.commit(SET_ERROR, "Streaming request failed.");
      throw error;
    }
  },
  query_w_retry(resource, params, retry = 0) {
    this.setHeader()

    return Vue.axios
      .get(resource, params)
      .catch((error) => {

        if (retry > 0) {
          var err = this.getmessage(error)
          store.commit(SET_ERROR, err)
          throw error
        } else {
          return this.query_w_retry(resource, params, 1)
        }
      })
  },
  querydoc(resource, params) {
    this.setHeader()

    return Vue.axios({
      method: 'get',
      url: resource,
      responseType: 'document'
    }).catch((error) => {
      var err = this.getmessage(error)
      store.commit(SET_ERROR, err)
      throw error
    })
  },

  get(resource, slug = '') {
    this.setHeader()
    return Vue.axios
      .get(`${resource}/${slug}`)
      .catch((error) => {
        var err = this.getmessage(error)
        store.commit(SET_ERROR, err)
        throw error
      })
  },

  post(resource, params) {
    this.setHeader()
    return Vue.axios.post(`${resource}`, params)
      .catch((error) => {

        var err = this.getmessage(error)
        store.commit(SET_ERROR, err)
        throw error
      })

  },


  put(resource, params) {
    this.setHeader()
    return Vue.axios
      .put(`${resource}`, params)
      .catch((error) => {
        var err = this.getmessage(error)
        store.commit(SET_ERROR, err)
        throw error
      })
  },

  delete(resource, slug = '') {
    this.setHeader()

    let res = `${resource}`
    if (slug && slug != '') {
      res = `${resource}/${slug}`
    }
    //return Vue.axios.delete(`${resource}`, params)
    return Vue.axios.delete(res)
      .catch((error) => {
        var err = this.getmessage(error)
        store.commit(SET_ERROR, err)
        throw error
      })
  },
  downloadtoken(params) {
    this.setHeader()
    return Vue.axios
      .post(API_URL + "/sharkd/download", params,
        {}
      )
      .then((response) => {


        const url = "data:" + response.data.mime + ";base64," + response.data.data
        let filename = response.data.file
        //const url = window.URL.createObjectURL(new Blob([response.data]));
        //const dis = response.request.getResponseHeader('Content-Disposition');
        //let filename = decodeURI(dis.match(/filename=(.*)/)[1])
        const link = document.createElement('a');
        link.href = url;
        filename = filename.replace(/"/g, "");
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        var err = this.getmessage(error)
        store.commit(SET_ERROR, err)
        throw error
      })
  },
  downloadpcap(pcapid, params) {
    this.setHeader()
    return Vue.axios
      .get(API_URL + "/ajax/download/pcap/" + pcapid,
        { responseType: 'blob' }
      )
      .then((response) => {

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const dis = response.request.getResponseHeader('Content-Disposition');
        let filename = decodeURI(dis.match(/filename=(.*)/)[1])
        const link = document.createElement('a');
        link.href = url;
        filename = filename.replace(/"/g, "");
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        var err = this.getmessage(error)
        store.commit(SET_ERROR, err)
        throw error
      })
  }

  /*
    update (resource, slug, params) {
      return Vue.axios.put(`${resource}/${slug}`, params)
    },
  
    delete (resource) {
      return Vue.axios
        .delete(resource)
        .catch((error) => {
          throw new Error(`[RWV] ApiService ${error}`)
        })
    }
  */
}



/* axios refresh token handling*/
// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}


function wait(ms) {
  return new Promise((resolve) => { setTimeout(resolve, ms) });
}

let DEFAULTDEBOUNCETIME = 1000
let MAXDEBOUNCETIME = 3520
let MAXRETRIES = 29

axios.interceptors.response.use(async function (response) {

  let edata;
  // Check that response.data is not null before using the "in" operator.
  if (response.data && "packets" in response.data) {
    edata = response.data.packets;
  } else {
    // You might want to handle the case where response.data is null or doesn't contain 'packets'
    edata = response.data || {}; // Fallback to an empty object if null
  }

  if (response.config._trytimes && response.config._trytimes > MAXRETRIES) {
    console.log("Aborting request too many trytimes");
    response.status = 403;
    if (response.data) {
      response.data.error = "Too many retries";
    } else {
      response.data = { error: "Too many retries" };
    }
    return response;
  }

  if ("progress" in edata) {
    // 3 means loading is finished; so check if we are still loading
    if (edata.err != 3) {
      const originalRequest = response.config;

      if (originalRequest._waittime) {
        if (originalRequest._waittime < MAXDEBOUNCETIME) {
          originalRequest._waittime *= 2;
          console.log("Backing off timer to " + originalRequest._waittime);
        } else {
          console.log("Keeping old timer " + originalRequest._waittime);
        }
      } else {
        originalRequest._waittime = DEFAULTDEBOUNCETIME;
        originalRequest._trytimes = 0; // how often we tried
        console.log("Setting timer to " + originalRequest._waittime);
      }
      originalRequest._trytimes++;

      store.commit(SET_LOADING_STATUS, edata);
      await wait(originalRequest._waittime);
      return axios(originalRequest);
    }
  } else {
    edata = { err: 3 };
  }

  store.commit(SET_LOADING_STATUS, edata);
  return response;

}, function (error) {
  // Error handling code remains unchanged
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {
    if (originalRequest.url == "auth/login") {
      return Promise.reject(error);
    }

    if (originalRequest.url.includes("auth/logout")) {
      store.dispatch(PURGE_AUTH_ACTION);
      isRefreshing = false;
      return Promise.reject(error);
    }

    if (originalRequest.url.includes("auth/refresh")) {
      store.dispatch(PURGE_AUTH_ACTION);
      isRefreshing = false;
      return Promise.reject(error);
    }

    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return axios(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    const refreshToken = JwtService.getRefreshToken();
    return new Promise(function (resolve, reject) {
      ApiService.setRefreshHeader();
      return axios.post(API_URL + "/auth/refresh")
        .then(({ data }) => {
          JwtService.saveToken(data.access_token);
          JwtService.getRefreshToken(data.refresh_token);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
          originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
          processQueue(null, data.access_token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => { isRefreshing = false; });
    });
  }

  return Promise.reject(error);
});



export default ApiService
