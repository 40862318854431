<template>
  <v-container fluid>
    <v-container fluid class="pa-6">
    <v-row justify="center">
      <v-col cols="12" md="8" class="text-center">
        <h1 class="display-2 font-weight-bold mb-4">DNS Dashboard</h1>
        <p class="subtitle-1">
          Here you can explore and analyze DNS query and response traffic in detailed tables. Use the filtering tools to search domain names, detect anomalies, and gain actionable insights into your network’s DNS.
        </p>
      </v-col>
    </v-row>
  </v-container>
    <!-- If no PCAP is loaded, display an alert -->
    <div v-if="!pcap">
      <v-alert type="warning" dense>
        No PCAP data loaded. Please load a PCAP file to view DNS and TLS
        entries.
      </v-alert>
    </div>

    <!-- Otherwise, render the DNS and TLS sections -->
    <div v-else>
      <!-- DNS Section -->
      <v-row class="ma-7">
        <v-col cols="12">
          <div class="section-header">
            <v-icon left class="mr-2">mdi-domain</v-icon>
            <span class="headline">DNS Entries</span>
          </div>
          <p class="grey--text mb-3">
            These entries represent the DNS data extracted from the capture. Use
            the search field to filter results.
          </p>
          <v-text-field
            v-model="dnsSearch"
            append-icon="mdi-magnify"
            label="Search DNS"
            single-line
            hide-details
            class="mb-3"
          ></v-text-field>
          <v-data-table
            v-if="filteredDnsEntries && filteredDnsEntries.length"
            class="elevation-1"
            :headers="headers"
            :items="filteredDnsEntries"
            hide-default-footer
            disable-pagination
            dense
            :group-by="groupby"
            show-group-by
            :expanded="expanded"
          >
            <template v-slot:item="props">
              <tr>
                <td>
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'Analysis',
                      params: {
                        id: pcap.id,
                        filter: filternamer(props.item.name),
                        index: 'none',
                        view: 'l',
                        analysisid: 'none',
                      },
                    }"
                  >
                    <v-icon small class="mr-1">mdi-web</v-icon>
                    {{ props.item.name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'Analysis',
                      params: {
                        id: pcap.id,
                        filter: filterip(props.item.value),
                        index: 'none',
                        view: 'l',
                        analysisid: 'none',
                      },
                    }"
                  >
                    <v-icon small class="mr-1">mdi-lan</v-icon>
                    {{ props.item.value }}
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div v-else class="text-center grey--text">
            No DNS entries found.
          </div>
        </v-col>
      </v-row>

      <!-- TLS SNI Section -->
      <v-row class="ma-7">
        <v-col cols="12">
          <div class="section-header">
            <v-icon left class="mr-2">mdi-lock</v-icon>
            <span class="headline">TLS SNI Entries</span>
          </div>
          <p class="grey--text mb-3">
            The table below lists the TLS SNI entries detected in the capture.
            Click on an entry for detailed analysis.
          </p>
          <v-text-field
            v-model="tlsSearch"
            append-icon="mdi-magnify"
            label="Search TLS SNI"
            single-line
            hide-details
            class="mb-3"
          ></v-text-field>
          <v-data-table
            v-if="filteredTlsEntries && filteredTlsEntries.length"
            class="elevation-1"
            :headers="headers"
            :items="filteredTlsEntries"
            hide-default-footer
            disable-pagination
            dense
            :group-by="groupby"
            show-group-by
            :expanded="expanded"
          >
            <template v-slot:item="props">
              <tr>
                <td>
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'Analysis',
                      params: {
                        id: pcap.id,
                        filter: filtersni(props.item.name),
                        index: 'none',
                        view: 'l',
                        analysisid: 'none',
                      },
                    }"
                  >
                    <v-icon small class="mr-1">mdi-server</v-icon>
                    {{ props.item.name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'Analysis',
                      params: {
                        id: pcap.id,
                        filter: filtertlsip(props.item.value),
                        index: 'none',
                        view: 'l',
                        analysisid: 'none',
                      },
                    }"
                  >
                    <v-icon small class="mr-1">mdi-lan</v-icon>
                    {{ props.item.value }}
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div v-else class="text-center grey--text">
            No TLS SNI entries found.
          </div>
        </v-col>
      </v-row>

      <!-- Floating Refresh Button -->
      <v-btn fab dark color="primary" class="refresh-btn" @click="refreshData">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { navigation } from "@/common/navigation";
import { mapGetters } from "vuex";
import { displayfilter } from "@/common/displayfilter";

export default {
  name: "Dns",
  props: {
    pcap: {
      type: Object,
      default: null,
    },
  },
  mixins: [displayfilter, navigation],
  data() {
    return {
      groupby: [],
      expanded: [],
      dnsentries: [],
      tlsentries: [],
      dnsSearch: "",
      tlsSearch: "",
    };
  },
  mounted() {
    this.loadEntries();
  },
  computed: {
    ...mapGetters(["currentAnalysis", "error", "currentUser"]),
    headers() {
      return [
        { text: "Host", align: "left", value: "name" },
        { text: "IP", align: "left", value: "value" },
      ];
    },
    // Filter DNS entries based on search input
    filteredDnsEntries() {
      if (!this.dnsSearch) {
        return this.dnsentries;
      }
      return this.dnsentries.filter((entry) => {
        const searchTerm = this.dnsSearch.toLowerCase();
        return (
          entry.name.toLowerCase().includes(searchTerm) ||
          entry.value.toLowerCase().includes(searchTerm)
        );
      });
    },
    // Filter TLS entries based on search input
    filteredTlsEntries() {
      if (!this.tlsSearch) {
        return this.tlsentries;
      }
      return this.tlsentries.filter((entry) => {
        const searchTerm = this.tlsSearch.toLowerCase();
        return (
          entry.name.toLowerCase().includes(searchTerm) ||
          entry.value.toLowerCase().includes(searchTerm)
        );
      });
    },
  },
  watch: {
    pcap(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadEntries();
      }
    },
  },
  methods: {
    // Load entries from the provided pcap object
    loadEntries() {
      if (this.pcap) {
        this.dnsentries = this.pcap.dnsinfos || [];
        this.tlsentries = this.pcap.tlsinfos || [];
      }
    },
    // Refresh the entries (for example, if the pcap data changes)
    refreshData() {
      this.loadEntries();
    },
    // Generate filter for DNS name
    filternamer(name) {
      return (
        'dns.qry.name == "' + name + '"' + ' or dns.resp.name == "' + name + '"'
      );
    },
    // Generate filter for IP address (DNS)
    filterip(ip) {
      if (ip.includes(".")) {
        return "dns.a == " + ip + " or ip.addr == " + ip;
      }
      if (ip.includes(":")) {
        return "dns.aaaa == " + ip + " or ipv6.addr == " + ip;
      }
    },
    // Generate filter for TLS SNI name
    filtersni(name) {
      return (
        'tls.handshake.extensions_server_name == "' +
        name +
        '"' +
        ' or dns.qry.name == "' +
        name +
        '"' +
        ' or dns.resp.name == "' +
        name +
        '"'
      );
    },
    // Generate filter for TLS IP
    filtertlsip(ip) {
      if (ip.includes(".")) {
        return "ip.addr == " + ip;
      }
      if (ip.includes(":")) {
        return "ipv6.addr == " + ip;
      }
    },
    // Example filter function based on DNS name (if needed elsewhere)
    filtername(name) {
      const f = 'dns.qry.name == "' + name + '"';
      this.df_apply_to_packetlist(f);
      this.currentAnalysis.showConnections = false;
      this.currentAnalysis.showIOGraph = false;
      this.navigatePacketview(
        this.currentAnalysis.pcapid,
        this.currentAnalysis.index,
        f
      );
    },
    // Context menu handler (available for future features)
    contextMenu(e, item) {
      e.preventDefault();
      this.menu.showMenu = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;
      this.menu.items = [
        {
          title: "DNS Filter",
          action: function() {
            this.filter(item);
          }.bind(this),
        },
      ];
      this.$nextTick(() => {
        this.menu.showMenu = true;
      });
    },
  },
};
</script>

<style scoped>
.refresh-btn {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.headline {
  font-weight: 600;
  font-size: 1.25rem;
}
</style>
