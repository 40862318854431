import ApiService from '@/common/api.service'
import JwtService from '@/common/jwt.service'
import { LOGIN, LOGOUT, REGISTER, CHECK_AUTH, CHECK_INFO, UPDATE_USER, UPDATE_ANALYSIS, PURGE_AUTH_ACTION } from './actions.type'
import { SET_LOADING_STATUS, SET_AUTH, PURGE_AUTH, SET_ERROR, SET_ANALYSIS, SET_INFO } from './mutations.type'

import { AVATAR_PATH } from '@/common/config'
import { AVATAR_GENERIC } from '@/common/config'


const state = {
  analysismessage: {
    msg: null,
    show: false,
    title: null,
    domelem: null
  },
  error: {
    msg: "noerror",
    type: "info"
  },

  user: {},
  checkedauth: false, //wether we checked for authentication
  authenticated: false,

  analysis: {
    showaiselecttour: false,
    filterboxoverlay: {
      text: null
    },
    frontendloadstatus: {
      profiles: false,
      info: false,
      user: false
    },
    iostart: undefined,
    ioend: undefined,
    selectStartDate: undefined,
    selectEndDate: undefined,
    recentlyused: [],
    loadstatus: {
      progress: 0,
      status: 0
    },
    navbar: true,
    rendering: {
      fields: {

      }
    },
    sharkdinfo: {},
    /* current packet for hexview and decode */
    cpacket: { decode: [], offsets: [] },
    fielddefinitions: [],
    theme: false, // false is light, true is dark
    curfield: {
      fieldname: ""
    },
    pcaplist: {
      type: "public",
      search: "",
      searchhelpdialog: false,
      ipinformation: true,
      tagdialog: false,
      massindex: false,
      massedit: false,
      decrypt: false,
      editcap: false,
      checkedpcaps: []
    },
    profiles: {
      /* keeps some temporary values */
      modifications: {
        maxPackets: 0
      },
      available: [],
      selected: {
      },
      needreload: true
    },
    indexerprofiles: {
      available: [],
      currentpcap: []
    },
    triggers: {
      refreshpacketlist: false
    },
    features: {
      timeline: false,
      iograph: true,
      packetinfo: true,
      debugging: false,
      debugdecodecaching: false,
      streammatching: true,
      indexerconfiguration: false,
      filterhistory: false,
      iplookup: false,
      enableupload: false,
      enableanonupload: false,
      enableusers: false,
      enablelogin: true,
      enableregistration: false,
      enablerangeselection: true,
      enableindexedpacketlist: false,
      enablecognito: true
    },
    illustratepaket: {
      framenumber: null,
      packet: null,
      info: null
    },
    packetlist: {
      marked: [],
      // dispalyed frame numbers
      displayed: []
    },
    sessionfilterhistory: [],
    analysistabs: 0,
    longoperationoverlay: false,
    longoperationoverlayData: {
      text: "",
      progress: 0,
    },
    firstloadpacketlist: true, // first load of packetlist
    cache: null,
    forcererender: false,
    reloadprofiles: false,
    analysisid: null,
    index: null,
    uploadtags: [],
    debug: false,
    currentpacket: 1,
    // indicates where user last clicked (hexview or decode)
    decodefocus: null,
    dndfilter: null,
    customgraph: {},
    showncolumns: [],
    authorized: true,
    showColors: false,
    showIOGraph: false,
    showTapstatistics: false,
    currentTap: "",
    showTimeline: false,
    showConnections: false,
    showCurrentPacketInfo: false,
    showAnalysisDrawer: false,
    showNavDrawer: false,
    showfilterbuttondialog: false,
    reinitalizegrid: false,
    pcapid: null,
    pcap: null,
    start: 0,
    end: 0,
    total: 0,
    totalshown: 0,
    matching: 0,
    loadingpackets: false,
    limit: 0,
    window: 1000,
    filter: "",
    timereferences: null,
    columnsparam: null,
    quickfilter: false,
    cachedresult: false,
    filterneedsupdate: false,
    profileneedsupdate: false,
    decodecacheinvalidate: false,
    packetlistcacheinvalidate: false,
    applyfilter: true,
    streamFilter: false,
    followdata: {},
    showFollow: false,
    showAI: false,
    aiSelectedPackets: { packets: [], decoded: [] },
    isAiChatFullScreen: false,
    showFilterBoxOverlay: false,
    filterstate: true,
    packetlistpacket: null,
    startDate: null,
    endDate: null,
    timeReference: null,
    indexerdialog: false,
    settingsdialog: false,
    decodeasdialog: false,
    coloringrulesdialog: false,
    detailstab: 0,
    profile: null,
    timelinestart: 0,
    indexedfields: []
  }
}

const getters = {
  error(state) {
    return state.error
  },
  analysismessage(state) {
    return state.analysismessage
  },
  avatarPic(state) {
    if (state.user.profilepic != "") {
      return AVATAR_PATH + state.user.profilepic
    }
    return AVATAR_PATH + AVATAR_GENERIC
  },
  currentUser(state) {

    if (state.authenticated) {
      return state.user
    }
    return null
  },
  isAuthenticated(state) {
    return state.authenticated
  },
  currentAnalysis(state) {
    return state.analysis
  }
}


// const LRU = require("lru-cache");
// export const usercache = new LRU({ max: 5 });

const actions = {
  [UPDATE_ANALYSIS](context, analysis) {
    context.commit(SET_ANALYSIS, analysis)
  },
  [LOGIN](context, credentials) {

    return new Promise((resolve) => {

      //this.$router.push({ name: ''Packet Analyzer'' })

      ApiService
        .post('auth/login', credentials)
        .then(({ data }) => {

          context.commit(SET_AUTH, data)
          resolve(data)
        })
    })
  },
  [LOGOUT](context) {
    ApiService.post('auth/logout')
      .then(() => {
        context.commit(PURGE_AUTH)

      })
      .catch(() => {
        context.commit(PURGE_AUTH)
      })

  },
  [PURGE_AUTH_ACTION](context) {
    context.commit(PURGE_AUTH)
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      // usercache.reset()
      ApiService
        .post('users', { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [CHECK_AUTH](context) {
    var token = JwtService.getToken();

    if (token != null) {
      ApiService.setHeader()
      ApiService
        .query('user')
        .then(({ data }) => {

          context.commit(SET_AUTH, data)
          state.analysis.frontendloadstatus.user = true
        })
        .catch(() => {
          //context.commit(SET_ERROR, response.data.errors)
          context.commit(PURGE_AUTH)
        })
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  [CHECK_INFO](context) {
    var token = JwtService.getToken();
    if (Object.keys(context.state.analysis.sharkdinfo).length == 0) {
      ApiService.setHeader();


      function fetchInfo(retries = 3) {
        ApiService.query('config/info')
          .then(({ data }) => {
            context.commit(SET_INFO, data);
          })
          .catch((error) => {
            console.error("CHECK_INFO failed:", error);
            if (retries > 0) {
              console.log(`Retrying CHECK_INFO... Attempts left: ${retries}`);
              setTimeout(() => fetchInfo(retries - 1), 200); // Retry after 2 seconds
            }
          });
      }
      fetchInfo();
    }
  },
  [UPDATE_USER](context, payload) {
    // usercache.reset()
    ApiService.put('/user', payload)
      .then(({ data }) => {

        context.commit(SET_AUTH, data.user)
        alert("Updated!")
        //this.$router.push({ name: ''Packet Analyzer'' })
        return data
      })
      .catch(() => {

        alert("Updated failed!")
      })
  }
}

const mutations = {
  [SET_INFO](state, info) {
    state.analysis.features.enableupload = info.features.ENABLE_UPLOAD
    state.analysis.features.enableanonupload = info.features.ENABLE_ANON_UPLOAD
    state.analysis.features.enablelogin = info.features.ENABLE_LOGIN
    state.analysis.features.enableregistration = info.features.ENABLE_REGISTRATION
    state.analysis.features.enablecognito = info.features.ENABLE_COGNITO
    state.analysis.sharkdinfo = info
    state.analysis.frontendloadstatus.info = true
  },
  [SET_ANALYSIS](state, analysis) {
    state.analysis = analysis
  },
  [SET_ERROR](state, error) {
    state.error.type = "error"
    state.error.msg = error
  },
  [SET_LOADING_STATUS](state, status) {
    if ("err" in status) {
      state.analysis.loadstatus.status = status["err"]
      if (status["err"] == 3 && state.analysis.pcap) {
        state.analysis.loadstatus.progress = state.analysis.pcap.numberofpackets
        return
      }
    }
    if ("progress" in status) {
      state.analysis.loadstatus.progress = status["progress"]
    }
  },
  [SET_AUTH](state, user) {
    state.checkedauth = true
    state.authenticated = true
    state.profileneedsupdate = true
    state.user = user
    state.error.msg = ""
    if (state.user.access_token)
      JwtService.saveToken(state.user.access_token)
    if (state.user.refresh_token)
      JwtService.saveRefreshToken(state.user.refresh_token)
  },
  [PURGE_AUTH](state) {
    state.profileneedsupdate = true
    state.checkedauth = true
    JwtService.destroyToken()
    JwtService.destroyRefreshToken()
    state.authenticated = false
    state.user = {}
    state.error.msg = ""

  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
